import { Auth } from "aws-amplify";
import { push, RouterAction } from "react-router-redux";
import { toast } from "react-toastify";
import * as Redux from "redux";
import { ThunkAction } from "redux-thunk";
import apiError from "src/lib/apiError";

import Logger from "../../lib/logger";
import { IStore } from "../../types/store/store";
import {
  IGetCameraLocationsSuccess,
  IPurgeCameraLocations,
  purgeCameraLocations
} from "../camera-locations/camera-locations.actions";
import {
  IGetCustomerSuccess,
  IPurgeCustomers,
  purgeCustomers
} from "../customers/customers.actions";
import { IPurgeImages, purgeImages } from "../images/images.actions";
import {
  IGetSitesSuccess,
  IPurgeSites,
  purgeSites
} from "../sites/sites.actions";
import {
  getUserCamerasRequest,
  getUserRequest,
  getUserSuccess,
  IGetUserSuccess,
  IPurgeUsers,
  purgeUsers
} from "../users/users.actions";
import * as usersApi from "../users/users.api";
import { IPurgeVideos, purgeVideos } from "../videos/videos.actions";
import * as AT from "./auth.constants";

export type AuthActions =
  | ISignIn
  | ISignInSuccess
  | ISignInFailure
  | ISignOut
  | IPurgeCustomers
  | IPurgeCameraLocations
  | IPurgeImages
  | IPurgeSites
  | IPurgeUsers
  | IPurgeVideos
  | IGetUserSuccess
  | IGetCustomerSuccess
  | IGetSitesSuccess
  | IGetCameraLocationsSuccess;
type ThunkResult<R> = ThunkAction<
  R,
  IStore,
  undefined,
  AuthActions | RouterAction
>;

export interface ISignIn {
  type: AT.SIGN_IN;
}

export interface ISignInSuccess {
  admin: boolean;
  type: AT.SIGN_IN_SUCCESS;
}

export interface ISignInFailure {
  type: AT.SIGN_IN_FAILURE;
}

export const signIn: Redux.ActionCreator<ISignIn> = () => {
  return { type: AT.SIGN_IN };
};

export const signInSuccess: Redux.ActionCreator<ISignInSuccess> = (admin: true) => {
  return {
    admin,
    type: AT.SIGN_IN_SUCCESS
  };
};

export const signInFailure: Redux.ActionCreator<ISignInFailure> = () => {
  return { type: AT.SIGN_IN_FAILURE };
};

export const signInRequest = (username: string,
  password: string): ThunkResult<boolean | any> => {
  return async dispatch => {
    Logger.info("ATTEMPTED SIGNIN", username);
    dispatch(signIn());

    try {
      const auth = await Auth.signIn(username, password);
      let admin = false;

      Logger.info(auth);

      if (
        auth.challengeName &&
        (auth.challengeName === "NEW_PASSWORD_REQUIRED" || auth.challengeName === "SMS_MFA")
      ) {
        return auth;
      // Successful login for admin user
      } else if (
        auth.signInUserSession.idToken.payload["custom:role"] === "admin"
      ) {
        admin = true;
        dispatch(signInSuccess(admin));
        dispatch(signInRedirect(auth));
      // Successful login for non-admin user
      } else {
        dispatch(signInSuccess());
        dispatch(signInRedirect(auth));

        return false;
      }
    } catch (error) {
      Logger.error("SIGN IN ERROR", error);
      toast.error(apiError(error));
      dispatch(signInFailure());

      return false;
    }
  };
};

export const signInRedirect = (auth: any): ThunkResult<boolean | any> => {
  return async dispatch => {
    const { payload } = auth.signInUserSession.idToken;

    try {
      if (payload["custom:role"] === "admin") {
        dispatch(getUserRequest());
        dispatch(push("/admin/cameras"));

        return;
      } else {
        const user = await usersApi.getUser();
        
        dispatch(getUserSuccess(user));

        if (user && user.id) {
          dispatch(getUserCamerasRequest(user.id, true));
        }

        return;
      }
    } catch (error) {
      Logger.error("REDIRECT", error);

      return;
    }
  };
};

export const checkLoggedIn = (): ThunkResult<boolean | any> => {
  return async dispatch => {
    try {
      const session = await Auth.currentSession();
      const { payload } = session.getIdToken();

      if (payload["custom:role"] === "admin") {
        dispatch(getUserRequest());

        if (!window.location.pathname.match(/admin|customer/)) {
          dispatch(push("/admin/customers"));
        }

        return;
      } else {
        const user = await usersApi.getUser();

        dispatch(getUserSuccess(user));

        if (user && user.id) {
          dispatch(getUserCamerasRequest(user.id, !window.location.pathname.match(/customer/)));
        }

        return;
      }
    } catch (error) {
      Logger.error("REDIRECT", error);

      return;
    }
  };
};
export interface ISignOut {
  type: AT.SIGN_OUT;
}

export const signOut: Redux.ActionCreator<ISignOut> = () => {
  return { type: AT.SIGN_OUT };
};

export const signOutRequest = (redirect: boolean): ThunkResult<void> => {
  return async dispatch => {
    try {
      await Auth.signOut();
      dispatch(signOut());
      dispatch(purgeCustomers());
      dispatch(purgeCameraLocations());
      dispatch(purgeImages());
      dispatch(purgeSites());
      dispatch(purgeUsers());
      dispatch(purgeVideos());

      if (redirect) {
        dispatch(push("/logout"));
      }
    } catch (error) {
      dispatch(signOut());

      if (redirect) {
        dispatch(push("/logout"));
      }
    }
  };
};

export const confirmSignupRequest = (user: any,
  password: string): ThunkResult<void> => {
  return async dispatch => {
    try {
      await Auth.completeNewPassword(
        user, password, null
      );
      dispatch(signInRequest(user.username, password));
    } catch (error) {
      Logger.error(error);
    }
  };
};

export const confirmMFACodeRequest = (user: any,
  mfaCode: string): ThunkResult<void> => {
  return async dispatch => {
    try {
      const auth = await Auth.confirmSignIn(
        user, mfaCode, "SMS_MFA"
      );

      let admin = false;

      Logger.info(auth);

      if (
        auth.signInUserSession.idToken.payload["custom:role"] === "admin"
      ) {
        admin = true;
        dispatch(signInSuccess(admin));
        dispatch(signInRedirect(auth));

        return true;
      } else {
        dispatch(signInSuccess());
        dispatch(signInRedirect(auth));
  
        return false;
      }
    } catch (error) {
      Logger.error("SIGN IN ERROR", error);
      toast.error(apiError(error));
      dispatch(signInFailure());

      return false;
    }
  };
};
