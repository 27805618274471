import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import Text from "../text";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { Column, Row } from "hedron";
import { Button, Link } from "../";
import { History } from "history";

interface ICameraLocationListItemProps {
  kind?: string;
  cameraLocation: ICameraLocation;
  history: History;
  customerId?: string;
}

const menuColor = theme.variants(
  "mode", "kind", { default: { normal: styles.primaryDarkColor } }
);

const CameraLocationListItemWrapper: any = styled.div`
  background: ${menuColor};
  padding: ${styles.paddingUnit * 2}px;
`;

const CameraLocationListItem: React.SFC<ICameraLocationListItemProps> = ({
  kind,
  cameraLocation,
  history,
  customerId
}) => (
  <CameraLocationListItemWrapper kind={kind}>
    <Row>
      <Column xs={9}>
        <Text fontSize="h3">{cameraLocation.name}</Text>
      </Column>
    </Row>

    {cameraLocation.public !== 0 && (
      <Row>
        <Column xs={12}>
          <Text fontSize="h5">Public URL:</Text>
          <br />
          <Link
            style={{ wordWrap: "break-word" }}
            to={`/public/${customerId}/site/${cameraLocation.site_id}/camera/${
              cameraLocation.uuid
            }/images`}
          >
            {`https://${window.location.hostname}/public/${customerId}/site/${
              cameraLocation.site_id
            }/camera/${cameraLocation.uuid}/images`}
          </Link>
        </Column>
      </Row>
    )}
    <Row justifyContent="flex-start">
      <Column xs={4} sm={6}>
        <Button
          align="none"
          text="Manage"
          clickHandler={() =>
            history.push(`/admin/customer/${customerId}/site/${
              cameraLocation.site_id
            }/camera/${cameraLocation.uuid}`)
          }
        />
      </Column>
      <Column xs={4} sm={6}>
        <Button
          align="none"
          text="View"
          clickHandler={() =>
            history.push(`/customer/${customerId}/site/${cameraLocation.site_id}/camera/${
              cameraLocation.uuid
            }/images`)
          }
        />
      </Column>
    </Row>
  </CameraLocationListItemWrapper>
);

CameraLocationListItemWrapper.defaultProps = { kind: "default" };

export default CameraLocationListItem;
