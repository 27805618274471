import * as React from "react";
import styled, { keyframes } from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Loading = require("./loading-img.gif");

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CenteredLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} ease-in-out 0.3s forwards;
  width: 50px; 
  height: 50px; 
`;

const DeletionLoader: React.FC = () => (
  <CenteredLoadingContainer>
    <img src={Loading} alt="loading" />
  </CenteredLoadingContainer>
);

export default DeletionLoader;
