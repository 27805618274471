import * as React from "react";
import Text from "../text/text";
import Textarea from "../textarea";
import ValidationError from "../validation-error";

const IPWhitelistTextarea: React.FC<{textAreaProps: Parameters<typeof Textarea>[0], errors?: string}> = ({ textAreaProps, errors }) => {
  return (
    <div style={{ marginBlock: 20 }}>
      <Text fontSize="small">IP Whitelist</Text>
      <Textarea
        rows={4}
        {...textAreaProps}
      />
      <Text fontSize="small">Enter comma-separated IP addresses to restrict access to this customer&apos;s sites</Text>
      <ValidationError>{errors}</ValidationError>
    </div>
  );
};

export default IPWhitelistTextarea;