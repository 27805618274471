import logger from "src/lib/logger";
import axios from "axios";
import Headers from "../../lib/auth";
import _ from "lodash";
import { IFolderSizeMetric } from "../camera-locations/camera-locations.api";
import qs from "qs";
import { PaginatedParams, PaginatedData } from "src/types/pagination";

export const getCustomer = async (
  customerId: string,
  role = "client"
): Promise<ICustomer> => {
  try {
    const headers = await Headers(role);

    const result = await axios({
      headers,
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/${role}/customer/${customerId}`
    });

    logger.info("GET CUSTOMER", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET CUSTOMER ERROR", error);
    throw error;
  }
};

const DEFAULT_PAGINATION = {
  limit: 99999,
  offset: 0,
  sort: [["name", "DESC"]] as [string, "DESC" | "ASC"][],
  page: 1
};

const DEFAULT_FILTERS = {};

export const getCustomers = async (
  pagination: PaginatedParams<ICustomer> = DEFAULT_PAGINATION,
  filters: Partial<ICustomer> = DEFAULT_FILTERS
): Promise<PaginatedData<ICustomer>> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "GET",
      params: {
        pagination,
        filters
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
      url: `${process.env.REACT_APP_API_URL}/admin/customers`
    });

    logger.info("GET ALL CUSTOMERS", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET ALL CUSTOMERS ERROR", error);
    throw error;
  }
};

export type CreateCustomerParams = Pick<ICustomer, "dark_logo" | "logo" | "name" | "security_level"> & { sub_domain: string; whitelist: string; };
export const createCustomer = async (customer: CreateCustomerParams): Promise<ICustomer> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: customer,
      headers,
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/admin/customer`
    });

    logger.info("CREATE CUSTOMER", result);

    return result.data.data;
  } catch (error) {
    logger.error("CREATE CUSTOMER ERROR", error);
    throw error;
  }
};

export const updateCustomer = async (
  customerId: string,
  customer: Partial<ICustomer>
): Promise<ICustomer> => {
  try {
    const headers = await Headers();
    const data = _.pickBy(customer, _.identity) as Partial<ICustomer>;

    // Add back in boolean properties
    data.security_level = customer.security_level;

    if (!data.logo || (data.logo && data.logo.length) <= 0) {
      data.logo = null;
    }

    if (!data.dark_logo || (data.dark_logo && data.dark_logo.length) <= 0) {
      data.dark_logo = null;
    }

    const result = await axios({
      data,
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/customer/${customerId}`
    });

    logger.info("UPDATE CUSTOMER", result);

    return result.data.data;
  } catch (error) {
    logger.error("UPDATE CUSTOMER ERROR", error);
    throw error;
  }
};

export const suspendCustomer = async (customerId: string): Promise<unknown> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/customer/status/${customerId}`
    });

    logger.info("SUSPEND CUSTOMER", result);

    return result.data.data;
  } catch (error) {
    logger.error("SUSPEND CUSTOMER ERROR", error);
    throw error;
  }
};

export const deleteCustomer = async (customerId: string): Promise<unknown> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/admin/customer/${customerId}`
    });

    logger.info("DELETE CUSTOMER", result);

    return result.data.data;
  } catch (error) {
    logger.error("DELETE CUSTOMER ERROR", error);
    throw error;
  }
};

export const getCustomerConfig = async (customerName: string): Promise<ICustomerConfig> => {
  try {
    const result = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/public/customer-config/${customerName}`
    });

    logger.info("GET CUSTOMER CONFIG", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET CUSTOMER CONFIG ERROR", error);
    throw error;
  }
};

/**
 * Interface for the customer reducer
 */
export interface ICustomerStore {
  customer: number | null;
  customers: ICustomer[];
  currentCustomer: ICustomer | null;
  customerConfig: ICustomerConfig;
  gettingCustomers: boolean;
  gettingCustomer: boolean;
  updatingCustomer: boolean;
  creatingCustomer: boolean;
  deletingCustomer: boolean;
  suspendingCustomer: boolean;
  customerCount: number;
}

interface IPWhitelist {
  whitelistedIPs: string[];
}

export interface ICustomer {
  id: number;
  name: string;
  slug: string;
  logo: string | null;
  dark_logo: string | null;
  domain: string;
  sub_domain?: string;
  status: string;
  sites?: IMinifiedSite[];
  latest_metrics?: IFolderSizeMetric[];
  security_level: number;
  ip_whitelist: IPWhitelist | null;
  user_count: number;
  sites_count: number;
  camera_locations_count: number;
  search_terms?: string;
  createdAt: string;
}
export interface IMinifiedSite {
  id: number;
  name: string;
  camera_locations?: IMinifiedCameraLocation[];
}
export interface IMinifiedCameraLocation {
  id: number;
  name: string;
}
export interface ICustomerConfig {
  name: string;
  logo: string;
  dark_logo: string;
}
