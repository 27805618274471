import {
  Form, Formik, FormikProps 
} from "formik";
import { Column, Row } from "hedron";
import React from "react";
import * as Yup from "yup";
import {
  TextInput,
  Button,
  Text,
  ValidationError,
  TextButton
} from "../../../components";

interface ILoginFormProps {
  handleSubmit: (values: { username: string; password: string },
    resetForm: () => void) => void;
  handleForgotPassword: () => void;
}

export const LoginForm: React.FC<ILoginFormProps> = ({ handleSubmit, handleForgotPassword }) => {
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          password: "",
          username: ""
        }}
        onSubmit={(values: { username: string; password: string },
          { resetForm }: { resetForm: () => void }) => handleSubmit(values, resetForm)}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .min(8, "Password must be at least 8 characters long.")
            .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!-{}"'|_^=@#$&£()\\`.+,/"]{8,}/,
              {
                message:
                      "Password must contain one capital, lowercase and number."
              })
            .required("Required"),
          username: Yup.string().required("Required")
        })}
      >
        {(props: FormikProps<{
                username: string;
                password: string;
              }>) => {
          const {
            values, handleChange, handleBlur, errors
          } = props;

          return (
            <Form>
              <Row>
                <Column fluid={true}>
                  <Text fontSize="small">Username</Text>
                  <TextInput
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    onBlur={handleBlur}
                    kind="secondary"
                  />
                  <ValidationError>{errors.username}</ValidationError>
                </Column>
              </Row>
              <Row>
                <Column fluid={true}>
                  <Text fontSize="small">Password</Text>
                  <TextInput
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    type="password"
                    kind="secondary"
                  />
                  <ValidationError>{errors.password}</ValidationError>
                </Column>
              </Row>

              <Row>
                <Column
                  fluid={true}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Button
                    style={{ minWidth: "100px" }}
                    text="Login"
                    type="submit"
                    kind="outline"
                  />
                </Column>
              </Row>
              <Row>
                <Column
                  fluid={true}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <TextButton
                    text="I forgot my password"
                    clickHandler={handleForgotPassword}
                  />
                </Column>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};