export const getContainerHeight = () => {
  const windowHeight = window.innerHeight;
  let minusHeight = 0;
  const footer = document.getElementById("footer");
  const header = document.getElementById("header");

  if (footer && header) {
    minusHeight = footer.clientHeight + header.clientHeight;
  }

  return windowHeight - minusHeight;
};