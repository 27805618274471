import React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import { Text } from "../";
import DropdownItem from "./dropdown-item";
import { AiFillCaretDown } from "react-icons/ai";

interface DropdownItem {
  text: string;
  data: unknown;
  isDisabled?: boolean;
}
interface IDropdownProps {
  kind?: string;
  selected: number;
  style?: React.CSSProperties;
  onSelect: (data: unknown) => void;
  items: Array<DropdownItem>;
  isOverlayActive?: boolean;
  direction: "up" | "down";
}

interface IDropdownState {
  open: boolean;
}
interface IDropdownHeaderWrapper {
  open?: boolean;
  isOverlayActive?: boolean;
  direction: "up" | "down";
}

interface IDropdownWrapper {
  kind?: string;
  direction: "up" | "down";
}

const textColor = theme.variants(
  "mode", "kind", { default: { normal: styles.secondaryTextColor } }
);

const DropdownWrapper = styled.div<IDropdownWrapper>`
  position: relative;

  transform: ${props => (props.kind === "header" ? "translateX(-50%)" : "")};

  @media (max-width: ${styles.mobileBreakPoint}) {
    display: ${props => (props.kind === "header" ? "none" : "block")};
  }
`;

const DropDownItemsWrapper = styled.div<IDropdownHeaderWrapper>`
  opacity: ${props => (props.isOverlayActive ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 150px;
  margin: 0;

  @media (max-width: ${styles.mobileBreakPoint}) {
    margin: 0 10px;
  }

  background-color: ${styles.secondaryDarkColor};
  padding: ${styles.paddingUnit}px;
  border-radius: ${props => (props.direction === "up" ? "0px" : "0 0 0px 0px")};
  border-right: 3px solid ${styles.primaryDarkColor};
  border-bottom: 3px solid ${styles.primaryDarkColor};
  border-top: ${props =>
    props.direction === "down"
      ? "none"
      : `1px solid ${styles.primaryAccentColor}`};

  bottom: ${props => props.direction === "up" && "-20px"};
  z-index: 100;
`;

const DropdownHeaderWrapper = styled.div<IDropdownHeaderWrapper>`
  opacity: ${props => (props.isOverlayActive ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  position: relative;
  margin: 0px;
  color: ${textColor};
  background-color: ${styles.secondaryDarkColor};
  padding: 10px 35px 10px 15px;
  margin-right: 5px;
  border: 1px solid ${styles.fadedTextColor};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  cursor: pointer;
  display: ${props => props.open && props.direction === "up" && "none"};
  box-shadow: 0 0 1px 0px ${styles.fadedTextColor} inset,
    0 0 1px 0px ${styles.fadedTextColor};
  svg {
    position: absolute;
    right: 10px;
    top: 55%;
    transform: translateY(-50%);
  }
  :hover {
    color: ${styles.primaryAccentColor};
    border: 1px solid ${styles.primaryAccentColor};
  }
`;

class Dropdown extends React.Component<IDropdownProps, IDropdownState> {
  protected wrapper: HTMLDivElement;
  protected boundOnMousedown: () => void;
  constructor(props: IDropdownProps) {
    super(props);
    this.boundOnMousedown = this.handleClickOutside.bind(this);
    this.state = { open: false };
  }

  public UNSAFE_componentWillMount(): void {
    document.addEventListener(
      "mousedown", this.boundOnMousedown, false
    );
  }

  public componentWillUnmount(): void {
    document.removeEventListener(
      "mousedown", this.boundOnMousedown, false
    );
  }

  public render() {
    const {
      kind,
      style,
      selected,
      items,
      isOverlayActive,
      direction
    } = this.props;

    const { open } = this.state;

    const ref = (element: HTMLDivElement) => {
      this.wrapper = element;
    };

    return (
      <div ref={ref}>
        <DropdownWrapper
          direction={direction}
          style={style}
          kind={kind || "default"}
        >
          <DropdownHeaderWrapper
            direction={direction}
            onClick={() => this.handleClick()}
            open={open}
            isOverlayActive={isOverlayActive}
          >
            {items[selected] && <Text>{items[selected].text}</Text>}
            <AiFillCaretDown size={12} />
          </DropdownHeaderWrapper>
          {open && (
            <DropDownItemsWrapper
              direction={direction}
              isOverlayActive={isOverlayActive}
            >
              {items.map((item, index) => (
                <DropdownItem
                  onSelect={() => this.handleSelect(item)}
                  key={`dropdown-${index}`}
                  text={item.text}
                  isDisabled={item.isDisabled}
                />
              ))}
            </DropDownItemsWrapper>
          )}
        </DropdownWrapper>
      </div>
    );
  }

  private handleSelect(item: DropdownItem) {
    this.setState({ open: false });
    this.props.onSelect(item.data);
  }

  private handleClick() {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  private handleClickOutside(e: Event) {
    if (this.wrapper && this.wrapper.contains(e.target as Node | null)) {
      return;
    } else {
      if (this.wrapper) {
        this.setState({ open: false });
      }
    }
  }
}

export default Dropdown;
