import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import {
  Text, TextInput, Button, ValidationError
} from "../../components";
import { Column, Row } from "hedron";
import {
  Formik, Form, FormikProps
} from "formik";
import * as Yup from "yup";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import _ from "lodash";
import { createCameraLocationRequest } from "../../store/camera-locations/camera-locations.actions";
import {
  ICameraLocation, ImagePosition, LiveImageSettings 
} from "../../store/camera-locations/camera-locations.api";
import FormikCheckbox from "../../components/checkbox/formik-checkbox";

import { DropdownFormik } from "../../components/dropdown-formik";
import { times } from "src/utils/times";

import { delays } from "src/utils/delays";
import { Label } from "react-foundation";
import { styles } from "src/lib/styles";
import MobileSpacer from "src/components/MobileSpacer";

interface IRouterProps {
  siteId: string;
  customerId: string;
}

type ICameraLocationProps = RouteComponentProps<IRouterProps>;

interface IStateProps {
  isCreating: boolean;
}

interface ICameraLocationDispatchProps {
  createCameraLocation: (site: ICameraLocation) => void;
}
type Props = ICameraLocationProps & IStateProps & ICameraLocationDispatchProps;

interface IFormikValues {
  job_ref: string;
  name: string;
  longitude: string;
  latitude: string;
  public: boolean;
  delay: number;
  start_time?: string;
  end_time?: string;
  video_generation_enabled: boolean;
}
class CreateCameraLocation extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <>
        <div style={{
          background: styles.secondaryDarkColor,
          borderRadius: "15px",
          marginBottom: "80px"
        }}>
          <Row alignContent="space-between">
            <Column xs={9}>
              <Text fontSize="h1">New Camera</Text>
            </Column>
          </Row>
          <Row />
          <Row>
            <Column fluid={true} xs={12} lg={6}>
              <Formik
                initialValues={{
                  end_time: "15:00",
                  delay: 0,
                  job_ref: "",
                  latitude: "",
                  longitude: "",
                  name: "",
                  public: false,
                  start_time: "09:00",
                  video_generation_enabled: true
                }}
                onSubmit={(values: IFormikValues) => this.handleSubmit(values)}
                validationSchema={Yup.object().shape({
                  job_ref: Yup.string().required("Required"),
                  name: Yup.string().required("Required")
                })}
              >
                {(props: FormikProps<IFormikValues>) => {
                  const {
                    values, handleChange, handleBlur, errors, setFieldValue
                  } = props;

                  return (
                    <Form>
                      <Row>
                        <Column>
                          <TextInput
                            onChange={handleChange}
                            value={values.name}
                            name="name"
                            placeholder="Name"
                            onBlur={handleBlur}
                          />
                          <ValidationError>{errors.name}</ValidationError>
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <TextInput
                            onChange={handleChange}
                            value={values.job_ref}
                            name="job_ref"
                            placeholder="Job Ref"
                            onBlur={handleBlur}
                          />
                          <ValidationError>{errors.job_ref}</ValidationError>
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <TextInput
                            onChange={handleChange}
                            value={values.longitude}
                            name="longitude"
                            placeholder="Longitude"
                            onBlur={handleBlur}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <TextInput
                            onChange={handleChange}
                            value={values.latitude}
                            name="latitude"
                            placeholder="Latitude"
                            onBlur={handleBlur}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column xs={6} >
                          <FormikCheckbox
                            value={values.public}
                            name="public"
                            label="Public"
                          />
                        </Column>
                        <Column
                          xs={6}
                          style={{ visibility: values.public ? "visible" : "hidden" }}
                        >
                          <Label style={{
                            marginBottom: "0.5rem",
                            display: "block" 
                          }}>Delay</Label>
                          <DropdownFormik
                            name="delay"
                            placeholder="Delay"
                            onChange={value => setFieldValue("delay", value)}
                            items={delays}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <Button
                            disabled={this.props.isCreating}
                            text="Submit"
                            type="submit"
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <Text weight="bold">{"Video Generation"}</Text>
                        </Column>
                      </Row>

                      <Row>
                        <Column>
                          <FormikCheckbox
                            value={
                              values.public
                                ? values.video_generation_enabled
                                : false
                            }
                            name="video_generation_enabled"
                            label="Enabled"
                          />
                        </Column>
                      </Row>

                      {values.video_generation_enabled && (
                        <Row>
                          <Column xs={12} lg={6}>
                            <DropdownFormik
                              name="start_time"
                              placeholder="Start Time"
                              onChange={value => setFieldValue("start_time", value)}
                              items={times.map(time => ({
                                text: time,
                                value: time
                              }))}
                            />
                          </Column>
                          <Column xs={12} lg={6}>
                            <DropdownFormik
                              name="end_time"
                              placeholder="End Time"
                              onChange={value => setFieldValue("end_time", value)}
                              items={times.map(time => ({
                                text: time,
                                value: time
                              }))}
                            />
                          </Column>
                          <ValidationError>
                            {errors.start_time || errors.end_time}
                          </ValidationError>
                        </Row>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </Column>
          </Row>
          <Row />
        </div>
        <MobileSpacer />
      </>
    );
  }

  private handleSubmit(values: IFormikValues) {
    const { createCameraLocation, match } = this.props;

    const liveImageSettings: LiveImageSettings = {
      crop: {
        height: 3375,
        width: 6000,
        offsetY: 250,
        offsetX: 0
      },
      resize: {
        height: 1080,
        width: 1920
      },
      client: {
        timestamp: {
          enabled: false,
          position: ImagePosition.bottomRight
        },
        text: {
          message: "interval.tv",
          enabled: false,
          position: ImagePosition.bottomLeft
        }
      },
      admin: {
        timestamp: {
          enabled: true,
          position: ImagePosition.bottomRight
        },
        hardware_ref: {
          enabled: true,
          position: ImagePosition.bottomLeft
        },
        text: {
          message: "",
          enabled: false,
          position: ImagePosition.bottomLeft
        }
      }
    };
    
    const videoSettings = {
      days_of_week: [
        2,
        3,
        4,
        5,
        6
      ],
      enabled: values.video_generation_enabled,
      end_time: values.end_time,
      start_time: values.start_time
    };

    delete values.start_time;
    delete values.end_time;

    const camera: ICameraLocation = _.extend(values, {
      public: values.public ? 1 : 0,
      site_id: match.params.siteId,
      video_settings: videoSettings,
      live_image_settings: liveImageSettings
    });

    createCameraLocation(camera);
  }
}

const mapStateToProps = (state: IStore, props: Props): IStateProps => {
  return { isCreating: state.cameraLocations.creatingCameraLocation };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): ICameraLocationDispatchProps => {
  return {
    createCameraLocation: (cameraLocation: ICameraLocation) =>
      dispatch(createCameraLocationRequest(cameraLocation))
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(CreateCameraLocation);
