import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import {
  signInRequest,
  confirmSignupRequest,
  signInRedirect,
  confirmMFACodeRequest
} from "../../../store/auth/auth.actions";
import { getCustomerConfigRequest } from "../../../store/customers/customers.actions";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import {
  Card,
  Text
} from "../../../components";
import {
  Row, Column, Page
} from "hedron";
import { styles } from "../../../lib/styles";
import styled from "styled-components";
import { getSubDomain } from "../lib/getSubDomain";
import { ICustomerConfig } from "src/store/customers/customers.api";
import signUrl from "src/lib/signS3Url";
import { IUser } from "src/types/store/users";
import { NewPassword } from "./new-password";
import { RequestNewPassword } from "./request-new-password";
import { LoginForm } from "./login-form";
import { MFAForm } from "./mfa-form";

interface IRouterProps {
  test: string; // This one is coming from the router
}

interface IStateProps {
  customerConfig: ICustomerConfig;
}
interface IProps extends RouteComponentProps<IRouterProps> {
  title: string;
}

interface IDispatchProps {
  redirect: (payload: any) => void;
  signIn: (username: string, password: string) => boolean | any;
  confirmSignup: (user: any, newPassword: string) => void;
  confirmMFACode: (user: any, mfaCode: string) => void;
  getCustomerConfig: (customerName: string) => void;
}

type Props = IProps & IDispatchProps & IStateProps;

const LogoImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  padding: 10px;
  margin: auto;
`;

const ClientLogoImage = styled.img`
  max-height: 400px;
  max-width: 100%;
  margin: 15px;
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const CardWrapper = styled.div`
  display: flex;
  margin: 50px 0;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
    padding: 30px;
    width: auto;
  }

  section {
    padding: 20px;
    @media (max-width: 768px) {
      padding: 10px;
    }
  }
`;

const CardPadding = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 20px 20px 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ClientNameWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 30px auto 20px auto;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 12px;
`;

enum LoginScreen {
  Login,
  NewPassword,
  ResetPassword,
  MFA
}

const Login: React.FC<Props> = ({
  customerConfig, signIn, getCustomerConfig, confirmSignup, confirmMFACode
}) => {
  const [screen, setScreen] = React.useState(LoginScreen.Login);
  const [user, setUser] = React.useState<IUser | null>(null);
  const [customerLogo, setCustomerLogo] = React.useState<string | null>(null);
  const [MFAPhoneNumber, setMFAPhoneNumber] = React.useState<string | null>(null);

  React.useEffect(() => {
    const subdomain = getSubDomain(window.location.href);

    if (subdomain) {
      getCustomerConfig(subdomain);
    }
  }, [getCustomerConfig]);

  /**
   * Set the logo image url properly
   */
  const getCustomerLogoUrl = React.useCallback((customerConfig: ICustomerConfig) => {
    if (customerConfig && customerConfig.dark_logo) {
      if (customerConfig.dark_logo.includes("amazonaws")) {
        signUrl(customerConfig.dark_logo).then(signedUrl => {
          setCustomerLogo(signedUrl);
        });
      } else {
        setCustomerLogo(customerConfig.dark_logo);
      }
    }
  }, []);

  React.useEffect(() => {
    if (customerConfig) {
      getCustomerLogoUrl(customerConfig);
    }
  }, [getCustomerLogoUrl, customerConfig]);

  const submitSignIn = React.useCallback(async ({
    username,
    password
  }: {
      username: string;
      password: string;
    },
    resetForm: () => void) => {
    const user = await signIn(username, password);

    if (user) {
      if (user.challengeName) {
        resetForm();
        setUser(user);

        switch (user.challengeName) {
          case "SMS_MFA": {
            if (user.challengeParam && user.challengeParam.CODE_DELIVERY_DESTINATION) {
              setMFAPhoneNumber(user.challengeParam.CODE_DELIVERY_DESTINATION);
            }

            setScreen(LoginScreen.MFA);
            break;
          }
          case "NEW_PASSWORD_REQUIRED": {
            setScreen(LoginScreen.NewPassword);
            break;
          }
        }
      }
    }
  }, [signIn]);

  const renderForm = React.useCallback(() => {
    if (screen === LoginScreen.Login) {
      return (
        <LoginForm handleSubmit={submitSignIn} handleForgotPassword={() => setScreen(LoginScreen.ResetPassword)} />
      );
    } else if (screen === LoginScreen.MFA) {
      return (<MFAForm user={user} destinationNumber={MFAPhoneNumber} handleSubmit={confirmMFACode} />);
    } else if (screen === LoginScreen.NewPassword) {
      return (
        <NewPassword user={user} confirmSignup={confirmSignup} />
      );
    } else {
      return (
        <RequestNewPassword handleBack={() => setScreen(LoginScreen.Login)} />
      );
    }
  }, [
    MFAPhoneNumber,
    confirmMFACode,
    confirmSignup,
    screen,
    submitSignIn,
    user
  ]);

  return (
    <Page
      fluid={true}
      style={{
        background: styles.secondaryDarkColor,
        height: "100vh",
        overflowY: "auto"
      }}
    >
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <LogoImage src={"/logo.jpg"} />
      </div>
      
      <CardWrapper>
        <Card>
          <CardPadding>
            {customerConfig &&
                (customerLogo ? (
                  <LogoWrapper>
                    <ClientLogoImage src={customerLogo} />
                  </LogoWrapper>
                ) : (
                  <ClientNameWrapper>{customerConfig.name}</ClientNameWrapper>
                ))}

            <Column fluid={true}>
              {screen === LoginScreen.Login && (
                <Text
                  style={{
                    color: styles.primaryDarkColor,
                    display: "block",
                    textAlign: "center"
                  }}
                  fontSize="h4"
                >
                    Login to your camera
                </Text>
              )}
            </Column>
            <Column fluid={true}>
              {renderForm()}
            </Column>
          </CardPadding>
        </Card>
      </CardWrapper>
      <Row
        justifyContent="center"
        alignItems="center"
        style={{
          width: "100%",
          textAlign: "center"
        }}
      >
        <Column fluid={true} style={{ margin: "50px 0" }}>
          <Text fontSize="p">Interval Films Timelapse Viewer</Text>
        </Column>
      </Row>
    </Page>
  );
};

const mapStateToProps = (state: IStore): IStateProps => {
  return { customerConfig: state.customers.customerConfig };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return {
    confirmSignup: (user: string, newPassword: string) =>
      dispatch(confirmSignupRequest(user, newPassword)),
    confirmMFACode: (user: string, mfaCode: string) =>
      dispatch(confirmMFACodeRequest(user, mfaCode)),
    getCustomerConfig: (customerName: string) =>
      dispatch(getCustomerConfigRequest(customerName)),
    redirect: auth => dispatch(signInRedirect(auth)),
    signIn: (username: string, password: string) =>
      dispatch(signInRequest(username, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
