import { Column, Row } from "hedron";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as Redux from "redux";
import { ThunkDispatch } from "redux-thunk";
import logger from "src/lib/logger";
import { signOutRequest } from "src/store/auth/auth.actions";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { ICustomer } from "src/store/customers/customers.api";
import { getSitesRequest } from "src/store/sites/sites.actions";
import { ISite } from "src/store/sites/sites.api";
import { IStore } from "src/types/store/store";
import { IUser, IUserCameraAssociations } from "src/types/store/users";

import {
  Header, Logo, Menu, Navigation
} from "../../components";
import { CombinedCamerasDropdown } from "../../components/camera-dropdown";
import { cameraLocationGetByUUID } from "../../store/camera-locations/camera-locations.getters";
import { getCustomerRequest } from "../../store/customers/customers.actions";
import { siteGetById } from "../../store/sites/sites.getters";
import { getUserCamerasRequest } from "../../store/users/users.actions";
import { getVideosRequest } from "../../store/videos/videos.actions";
import { IAuthStore } from "../../types/store/auth";
import { IVideo, IVideoParams } from "../../types/store/videos";

interface IVideoContainerRouterProps {
  customerId: string;
  siteId: string;
  cameraLocationUUID: string;
}

interface IDispatchProps {
  getUserCameras: (userId: number) => void;
  getCustomer: (customerId: string) => void;
  getSites: (customerId: string) => void;
  getVideos: (cameraLocationId: string, params: IVideoParams) => void;
  signOut: () => void;
}

interface IStoreProps {
  user: IUser | null;
  auth: IAuthStore;
  cameraLocation: ICameraLocation | null;
  cameraLocations: ICameraLocation[];
  customer: ICustomer | null;
  site: ISite | null;
  sites: ISite[] | null;
  videos: IVideo[];
  videosTotal: number;
  userCameras: IUserCameraAssociations[];
}

type IVideoContainerProps = RouteComponentProps<IVideoContainerRouterProps>;

export const LiveIpCamera: React.FC<IVideoContainerProps & IStoreProps & IDispatchProps> = ({
  match,
  customer,
  getCustomer,
  auth,
  getSites,
  userCameras,
  user,
  getUserCameras,
  location,
  history,
  signOut,
  videos,
  sites,
  cameraLocation,
  cameraLocations
}) => {
  React.useEffect(() => {
    try {
      const { customerId } = match.params;
      const isPublic = location.pathname.includes("public");
  
      if (isPublic && user) {
        const newPath = location.pathname.replace("public", "customer");
  
        // If user is logged in, and link is public, then redirect
        history.push(newPath);
      }
  
      getCustomer(match.params.customerId);

      if (!auth.authenticated) {
        // If admin, or if they aren't logged in at all,
        // we need to get sites rather than use userCameras
        getSites(customerId);
      }

      if ((!userCameras || userCameras.length === 0) && user) {
        getUserCameras(user.id);
      }
    } catch (error) {
      logger.error(error);
    }
  }, [
    auth.authenticated,
    getCustomer,
    getSites,
    getUserCameras,
    history,
    location.pathname,
    match.params,
    user,
    userCameras
  ]);

  const handleCameraChange = React.useCallback((userCameraLocation: IUserCameraAssociations) => {
    let url = "customer";

    if (match.path.includes("/public/")) {
      url = "public";
    }

    history.push(`/${url}/${userCameraLocation.site.customer.id}/site/${userCameraLocation.site.id}/camera/${userCameraLocation.uuid}/images`);
  }, [history, match.path]);

  const adminMenuitem = auth.admin
    ? [
      {
        function: () => history.push("/admin/customers"),
        label: "Admin"
      }
    ]
    : [];

  const loginMenuItem = auth.authenticated
    ? [
      {
        function: () => signOut(),
        label: "Logout"
      }
    ]
    : [
      {
        function: () => signOut(),
        label: "Login"
      }
    ];

  const headerHeight = document.getElementById("header")?.clientHeight;

  return (
    <div style={{ height: "100%" }}>
      <Header>
        <Row alignItems="center" style={{
          width: "100vw",
          padding: "10px"
        }}>
          <Logo logo={customer?.logo || ""} />
          <Column xs={7} sm={6} md={5} fluid={true}>
            <CombinedCamerasDropdown
              auth={auth}
              customer={customer}
              sites={sites}
              handleCameraChange={data => handleCameraChange(data)}
              userCameras={userCameras}
              cameraLocations={cameraLocations}
              cameraLocation={cameraLocation}
            />
          </Column>
          <Navigation
            customerId={match.params.customerId}
            siteId={match.params.siteId}
            ip_camera_url={cameraLocation?.ip_camera_url}
            hasVideos={videos.length > 0}
            cameraLocationUUID={match.params.cameraLocationUUID}
            public_url={match.path.includes("/public/") ? true : false}
          />
          <Menu menuItems={[...adminMenuitem, ...loginMenuItem]} />
        </Row>
      </Header>
      {cameraLocation?.ip_camera_url && (
        <div style={{
          display: "flex",
          height: `calc(100vh - ${headerHeight}px)`,
          width: "100%"
        }}>
          <iframe
            src={cameraLocation?.ip_camera_url}
            width="100%"
            height="100%"
            style={{ flexGrow: 1 }}
            frameBorder="0"
            allowFullScreen> </iframe>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IStore, props: IVideoContainerProps): IStoreProps => {
  const { match } = props;

  return {
    auth: state.auth,
    cameraLocation: cameraLocationGetByUUID(state, match.params.cameraLocationUUID),
    cameraLocations: state.cameraLocations.cameraLocations,
    customer: state.customers.currentCustomer,
    site: siteGetById(state, match.params.siteId),
    sites: state.sites.sites,
    user: state.users.currentUser,
    userCameras: state.users.userCameras,
    videos: state.videos.videos,
    videosTotal: state.videos.total
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>,
  props: IVideoContainerProps): IDispatchProps => {
  // based on the url we know if we want to run public or private actions
  let role = "client";

  if (props.match.path.includes("/public/")) {
    role = "public";
  }

  return {
    getCustomer: (customerId: string) =>
      dispatch(getCustomerRequest(customerId, role)),
    getSites: (customerId: string) => dispatch(getSitesRequest(
      customerId, "active", role
    )),
    getUserCameras: userId => dispatch(getUserCamerasRequest(userId, false)),
    getVideos: (cameraLocationId: string, params: IVideoParams) =>
      dispatch(getVideosRequest(
        cameraLocationId, params, role
      )),
    signOut: () => dispatch(signOutRequest(true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveIpCamera);
