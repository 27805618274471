import React from "react";
import { styles } from "src/lib/styles";
import styled from "styled-components";
import {
  Box, Icon, Text 
} from "..";

interface PaginationProps {
  total: number;
  gotoPage: (page: number) => void;
  pageIndex: number;
}

export const Pagination: React.FC<PaginationProps> = props => {
  const {
    gotoPage,
    pageIndex,
    total
  } = props;

  const pageCount = Math.floor(total / 10);

  const handleClick = (pageNumber: number) => {
    gotoPage(pageNumber);
  };

  return (
    <Wrapper>
      <Box>
        {total} videos total
      </Box>
      <Box flexDir="row" align="center">
        {pageIndex - 1 >= 0 && (
          <Icon icon="chevron-left" onClick={() => handleClick(pageIndex - 1)} />
        )}
        {([
          -3,
          -2,
          -1,
          0,
          1,
          2,
          3
        ]).map(n => {
          const pageNumber = pageIndex + n;
          const pageNumberPlusOne = pageNumber + 1;

          if (pageNumberPlusOne > 0 && pageNumberPlusOne <= pageCount) {
            return (
              <NumberBox 
                active={n === pageIndex}
                onClick={() => handleClick(pageNumber)} 
                key={pageNumber}
              >
                <Text>
                  {pageNumberPlusOne}
                </Text>
              </NumberBox>
            );
          } else {
            return null;
          }
        })}
        {pageIndex + 1 < pageCount && (
          <Icon icon="chevron-right" onClick={() => handleClick(pageIndex + 1)} />
        )}
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  background: ${styles.secondaryDarkColor};
`;

const NumberBox = styled(Box)<{active: boolean}>`
  display: flex;
  cursor: pointer;
  margin: 0 2px;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  background: ${props => props.active && styles.formBackground};
`;