export const PURGE_IMAGES = "@images/PURGE_IMAGES";
export type PURGE_IMAGES = typeof PURGE_IMAGES;

export const GET_IMAGES_BY_DATE = "@images/GET_IMAGES_BY_DATE";
export type GET_IMAGES_BY_DATE = typeof GET_IMAGES_BY_DATE;
export const GET_IMAGES_BY_DATE_SUCCESS = "@images/GET_IMAGES_BY_DATE_SUCCESS";
export type GET_IMAGES_BY_DATE_SUCCESS = typeof GET_IMAGES_BY_DATE_SUCCESS;
export const GET_IMAGES_BY_DATE_FAILURE = "@images/GET_IMAGES_BY_DATE_FAILURE";
export type GET_IMAGES_BY_DATE_FAILURE = typeof GET_IMAGES_BY_DATE_FAILURE;

export const GET_AVAILABLE_DATE = "@images/GET_AVAILABLE_DATE";
export type GET_AVAILABLE_DATE = typeof GET_AVAILABLE_DATE;
export const GET_AVAILABLE_DATE_SUCCESS = "@images/GET_AVAILABLE_DATE_SUCCESS";
export type GET_AVAILABLE_DATE_SUCCESS = typeof GET_AVAILABLE_DATE_SUCCESS;
export const GET_AVAILABLE_DATE_FAILURE = "@images/GET_AVAILABLE_DATE_FAILURE";
export type GET_AVAILABLE_DATE_FAILURE = typeof GET_AVAILABLE_DATE_FAILURE;

export const CHECK_UNMIGRATED_IMAGES = "@images/CHECK_UNMIGRATED_IMAGES";
export type CHECK_UNMIGRATED_IMAGES = typeof CHECK_UNMIGRATED_IMAGES;
export const CHECK_UNMIGRATED_IMAGES_SUCCESS =
  "@images/CHECK_UNMIGRATED_IMAGES_SUCCESS";
export type CHECK_UNMIGRATED_IMAGES_SUCCESS = typeof CHECK_UNMIGRATED_IMAGES_SUCCESS;
export const CHECK_UNMIGRATED_IMAGES_FAILURE =
  "@images/CHECK_UNMIGRATED_IMAGES_FAILURE";
export type CHECK_UNMIGRATED_IMAGES_FAILURE = typeof CHECK_UNMIGRATED_IMAGES_FAILURE;

export const MIGRATE_IMAGES = "@images/MIGRATE_IMAGES";
export type MIGRATE_IMAGES = typeof MIGRATE_IMAGES;
export const MIGRATE_IMAGES_SUCCESS = "@images/MIGRATE_IMAGES_SUCCESS";
export type MIGRATE_IMAGES_SUCCESS = typeof MIGRATE_IMAGES_SUCCESS;
export const MIGRATE_IMAGES_FAILURE = "@images/MIGRATE_IMAGES_FAILURE";
export type MIGRATE_IMAGES_FAILURE = typeof MIGRATE_IMAGES_FAILURE;
export const UPDATE_IMAGE = "@images/UPDATE_IMAGE";
export type UPDATE_IMAGE = typeof UPDATE_IMAGE;
export const UPDATE_IMAGE_SUCCESS = "@images/UPDATE_IMAGE_SUCCESS";
export type UPDATE_IMAGE_SUCCESS = typeof UPDATE_IMAGE_SUCCESS;
export const UPDATE_IMAGE_FAILURE = "@images/UPDATE_IMAGE_FAILURE";
export type UPDATE_IMAGE_FAILURE = typeof UPDATE_IMAGE_FAILURE;

export const CHECK_NEW_IMAGES = "@images/CHECK_NEW_IMAGES";
export type CHECK_NEW_IMAGES = typeof CHECK_NEW_IMAGES;
export const CHECK_NEW_IMAGES_SUCCESS = "@images/CHECK_NEW_IMAGES_SUCCESS";
export type CHECK_NEW_IMAGES_SUCCESS = typeof CHECK_NEW_IMAGES_SUCCESS;
export const CHECK_NEW_IMAGES_FAILURE = "@images/CHECK_NEW_IMAGES_FAILURE";
export type CHECK_NEW_IMAGES_FAILURE = typeof CHECK_NEW_IMAGES_FAILURE;
