import React from "react";
import moment from "moment";
import Icon from "../icon";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import { Button } from "..";

export const CookieNotice: React.FC = () => {
  const [hasAcknowledgedCookies, setHasAcknowledgedCookies] = React.useState(false);

  React.useEffect(() => {
    try {
      if (window.self !== window.top) {
        // Page is inside of an iframe
        setHasAcknowledgedCookies(true);
      } else {
        // See if the user has previously accepted
        const hasAcknowledged = document.cookie.split("; ").reduce((r, v) => {
          const parts = v.split("=");
          
          return parts[0] === "hasAcknowledgedCookies" ? decodeURIComponent(parts[1]) : r;
        }, "");
    
        if (hasAcknowledged) {
          setHasAcknowledgedCookies(true);
        }
      }
    } catch (e) {
      // If any of the above fails, show the cookie 
      // warning - which is the default behaviour 
      // so do nothing
    }
  }, []);

  const handleAllowCookie = React.useCallback(() => {
    const expireDate = moment().add(1, "year").format("ddd, D MMM YYYY");
    
    document.cookie = `hasAcknowledgedCookies=true; expires=${expireDate} 12:00:00 UTC`;
    setHasAcknowledgedCookies(true);
  }, []);

  return (
    <>
      {!hasAcknowledgedCookies && (
        <Background>
          <CookieModal>
            <CloseWrapper>
              <Icon size={15} icon="close" onClick={handleAllowCookie} />
            </CloseWrapper>
            <p>We use cookies on this website to ensure you get the best experience.</p>
            <a href="https://timelapse.co.uk/legal/professional-privacy-policy/" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            <Button text="Accept" clickHandler={handleAllowCookie} />
          </CookieModal>
        </Background>
      )}
    </>
  );
};

const Background = styled.div`
  position: fixed;
  background: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const CookieModal = styled.div`
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    background: ${styles.formBackground};
    display: flex;
    border-radius: 15px;
    box-shadow: -1px 5px 7px 7px rgb(0 0 0 / 30%);
    padding: 3.5rem 3rem 2rem 3rem;
    flex-direction: column;
    transform: translate(-50%, -50%);
    width: 250px;
    text-align: center;


    a {
      padding-top: 1.4rem;
      color: white;
      font-size: 0.8rem;
      border: none;
      outline: 0;
      background: none;
      cursor: pointer;
      text-decoration: none;
      font-weight: bold;
      letter-spacing: 1px;
    }

    button {
      margin-top: 1.5rem;
    }
    @media (max-width: 600px){
      width: 220px;
      padding: 2.5rem 2rem 2rem 2rem;
    }
`;