import React from "react";
import { AudioSettings } from "src/store/camera-locations/camera-locations.api";
import {
  Text, Box, Dropdown 
} from "..";

export const SelectAudio: React.FC<{
  onSelect: (v: unknown) => void;
  items: {
    data: AudioSettings | undefined;
    text: string;
  }[];
  selected: number;
}> = ({
  onSelect, items, selected
}) => {
  return (
    <Box pad={{ vertical: "md" }}>
      <Box>
        <Text weight="bold">
            Would you like to add a soundtrack?
        </Text>
        <Box pad={{ top: "sm" }}>
          <Dropdown
            direction="down"
            isOverlayActive
            onSelect={onSelect}
            items={items}
            selected={selected} />
        </Box>
      </Box>
    </Box>
  );
};
