/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import Text, { ITextProps } from "../text/text";

interface ITextButtonStyleProps {
  bordered?: boolean;
  kind?: string;
}

type PassedThroughTextProps = Omit<ITextProps, "children">;

interface ITextButtonProps extends ITextButtonStyleProps {
  text: React.ReactNode | (string & {});
  type?: JSX.IntrinsicElements["button"]["type"];
  clickHandler?: (e: React.MouseEvent<HTMLElement>) => void;
  align?: "left" | "right" | "none";
  disabled?: boolean;
  textProps?: PassedThroughTextProps;
  style?: React.CSSProperties;
}

const TextButtonWrapper = styled.button<ITextButtonStyleProps>`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 0px;
  color: ${styles.secondaryTextColor};
  display: block;
  padding: 6px 10px;
  border-radius: 4px;
  margin: 20px;
  ${props => props.bordered ? `border: 1px solid ${styles.secondaryTextColor};` : ""}

  span {
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 1;
    font-size: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & > svg:first-child:not(:only-child) {
      margin-right: 1ch;
    }
    & > svg:last-child:not(:only-child) {
      margin-left: 1ch;
    }
  }

  :hover {
    color: ${styles.primaryAccentColor};
  }
`;

const TextButton: React.FC<ITextButtonProps> = ({
  text,
  clickHandler,
  align,
  type,
  bordered = true,
  textProps,
  ...rest
}) => (
  <TextButtonWrapper
    {...rest}
    type={type}
    style={{
      float: align ? align : "left",
      ...rest.style 
    }}
    onClick={clickHandler}
    bordered={bordered}
  >
    <Text {...textProps}>
      {text}
    </Text>
  </TextButtonWrapper>
);

TextButtonWrapper.defaultProps = { kind: "default" };

export default TextButton;
