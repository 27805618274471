export const PURGE_VIDEOS = "@video/PURGE_VIDEOS";
export type PURGE_VIDEOS = typeof PURGE_VIDEOS;

export const GET_VIDEOS = "@video/GET_VIDEOS";
export type GET_VIDEOS = typeof GET_VIDEOS;
export const GET_VIDEOS_SUCCESS = "@video/GET_VIDEOS_SUCCESS";
export type GET_VIDEOS_SUCCESS = typeof GET_VIDEOS_SUCCESS;
export const GET_VIDEOS_FAILURE = "@video/GET_VIDEOS_FAILURE";
export type GET_VIDEOS_FAILURE = typeof GET_VIDEOS_FAILURE;

export const CREATE_VIDEO = "@video/CREATE_VIDEO";
export type CREATE_VIDEO = typeof CREATE_VIDEO;
export const CREATE_VIDEO_SUCCESS = "@video/CREATE_VIDEO_SUCCESS";
export type CREATE_VIDEO_SUCCESS = typeof CREATE_VIDEO_SUCCESS;
export const CREATE_VIDEO_FAILURE = "@video/CREATE_VIDEO_FAILURE";
export type CREATE_VIDEO_FAILURE = typeof CREATE_VIDEO_FAILURE;

export const UPDATE_VIDEO = "@video/UPDATE_VIDEO";
export type UPDATE_VIDEO = typeof UPDATE_VIDEO;
export const UPDATE_VIDEO_SUCCESS = "@video/UPDATE_VIDEO_SUCCESS";
export type UPDATE_VIDEO_SUCCESS = typeof UPDATE_VIDEO_SUCCESS;
export const UPDATE_VIDEO_FAILURE = "@video/UPDATE_VIDEO_FAILURE";
export type UPDATE_VIDEO_FAILURE = typeof UPDATE_VIDEO_FAILURE;

export const DELETE_VIDEO = "@video/DELETE_VIDEO";
export type DELETE_VIDEO = typeof DELETE_VIDEO;
export const DELETE_VIDEO_SUCCESS = "@video/DELETE_VIDEO_SUCCESS";
export type DELETE_VIDEO_SUCCESS = typeof DELETE_VIDEO_SUCCESS;
export const DELETE_VIDEO_FAILURE = "@video/DELETE_VIDEO_FAILURE";
export type DELETE_VIDEO_FAILURE = typeof DELETE_VIDEO_FAILURE;

export const GENERATE_VIDEO = "@video/GENERATE_VIDEO";
export type GENERATE_VIDEO = typeof GENERATE_VIDEO;
export const GENERATE_VIDEO_SUCCESS = "@video/GENERATE_VIDEO_SUCCESS";
export type GENERATE_VIDEO_SUCCESS = typeof GENERATE_VIDEO_SUCCESS;
export const GENERATE_VIDEO_FAILURE = "@video/GENERATE_VIDEO_FAILURE";
export type GENERATE_VIDEO_FAILURE = typeof GENERATE_VIDEO_FAILURE;
