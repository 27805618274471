import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import "./styles.scss";

import { Icon } from "./Icon";
import { TableEmpty } from "./TableEmpty";
import { TableLoading } from "./TableLoading";
import { TablePagination } from "./TablePagination";

import type {
  ColumnDef,
  RowSelectionState,
  SortingState,
  VisibilityState,
  PaginationState,
  RowData
} from "@tanstack/react-table";
import type {
  Dispatch,
  SetStateAction
} from "react";
import { SortType } from "src/types/pagination";
import { TableBody } from "./TableBody";
import { TableRow } from "./TableRow";
import { TableCell } from "./TableCell";
import { TableHead } from "./TableHead";
import { ICustomer } from "src/store/customers/customers.api";

/**
 * See table meta type docs at
 * https://tanstack.com/table/v8/docs/api/core/table#meta
 */
declare module "@tanstack/table-core" {
  interface TableMeta<TData extends RowData> {
    rowLoadingId?: string,
    setRowLoadingId?: Dispatch<SetStateAction<string>>
  }
}
export interface TableProps<TData = Record<string, unknown>> {
  columns: Array<ColumnDef<TData>>;
  data: TData[],
  totalDataCount?: number;
  pagination: PaginationState;
  loading?: boolean;
  setPagination: (pagination: PaginationState) => void;
  title: string,
  refetchData: () => void;
  rowLoadingId?: string;
  setRowLoadingId?: Dispatch<SetStateAction<string>>;
  setSortOrder: Dispatch<SetStateAction<SortType<ICustomer>>>;
}

export function CustomerTable<TData = Record<string, unknown>>(props: TableProps<TData>) {
  const [sort, setSort] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<keyof TData | undefined>();
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const setSortOrder = props.setSortOrder;

  useEffect(() => {
    if (sort.length) {
      switch (sort[0].id) {
        case "Customer":
          setSortOrder([["name", sort[0].desc ? "DESC" : "ASC"]]);
          break;
        case "Sites":
          setSortOrder([["sites_count", sort[0].desc ? "DESC" : "ASC"]]);
          break;
        case "Cameras":
          setSortOrder([["camera_locations_count", sort[0].desc ? "DESC" : "ASC"]]);
          break;
        case "Users":
          setSortOrder([["user_count", sort[0].desc ? "DESC" : "ASC"]]);
          break;
        case "Date Created":
          setSortOrder([["createdAt", sort[0].desc ? "DESC" : "ASC"]]);
          break;
      }
    }
  }, [setSortOrder, sort]);

  const table = useReactTable<TData>({
    columns: props.columns,
    data: props.data ?? [],
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    state: {
      sorting: sort,
      globalFilter,
      rowSelection,
      columnVisibility,
      pagination: {
        pageIndex: props.pagination?.pageIndex,
        pageSize: props.pagination?.pageSize
      }
    },
    pageCount: Math.max(1, Math.ceil((props.totalDataCount ?? props.pagination?.pageSize ?? 0) / (props.pagination?.pageSize))),
    onPaginationChange: props.setPagination,
    manualPagination: true,
    meta: {
      rowLoadingId: props?.rowLoadingId,
      setRowLoadingId: props?.setRowLoadingId
    },
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onSortingChange:  setSort,
    onGlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualSorting: true,
    manualFiltering: true
  });

  return (

    <div className="table-container">

      {/* Table content */}

      <div className="table-contents-container">
        <table
          role="table"
          className={clsx("overflow-hidden", { "animate-pulse-faded pointer-events-none": props.loading })}
        >
          {/* THead */}

          <TableHead
            role="rowgroup"
            data-table-role="thead"
          >
            {table.getHeaderGroups().map(headerGroup => !(headerGroup.id in columnVisibility) && (
              <tr
                key={headerGroup.id}
                role="row"
              >
                {headerGroup.headers.map(columnHeader => (
                  <th
                    key={columnHeader.id}
                    role="columnheader"
                    className={clsx({
                      isResizing: columnHeader.column.getIsResizing(),
                      canSort: columnHeader.column.getCanSort(),
                      isSorted: columnHeader.column.getIsSorted()
                    })}
                    onClick={columnHeader.column.getToggleSortingHandler()}
                    {...{
                      colSpan: columnHeader.colSpan,
                      style: {
                        width: columnHeader.getSize(),
                        textAlign: columnHeader.id === "Actions" ? "right" : "left"
                      }
                    }}
                  >
                    {flexRender(columnHeader.column.columnDef.header,
                      columnHeader.getContext())}

                    {columnHeader.column.getCanSort() && (
                      columnHeader.column.getIsSorted() ? (
                        columnHeader.column.getIsSorted() === "asc" ? (
                          <Icon.FillCaretUp className="sorting-icon" />
                        ) : (
                          <Icon.FillCaretDown className="sorting-icon" />
                        )
                      ) : (
                        <Icon.FillCaretsVertical className="sorting-icon" />
                      )
                    )}

                    {columnHeader.column.getCanResize() && (
                      <div
                        onMouseDown={columnHeader.getResizeHandler()}
                        onTouchStart={columnHeader.getResizeHandler()}
                        className={clsx("resizer", { isResizing: columnHeader.column.getIsResizing() })}
                        // eslint-disable-next-line react/no-children-prop
                        children={<Icon.ResizeHorizontal />}
                      />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </TableHead>

          {/* Tbody */}

          <TableBody
            role="rowgroup"
            data-table-role="tbody"
          >
            {table.getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                role="row"
                className={clsx({ isSelected: row.getIsSelected() })}
              >
                {row.getVisibleCells().map(rowData => {
                  return (
                    <TableCell
                      key={rowData.id}
                      name={rowData.column.id}
                      hideName={rowData.column.id === "Actions"}
                      tdProps={{
                        role: "Cell",
                        className: clsx({
                          isResizing: rowData.column.getIsResizing(),
                          isRowLoading: table.options.meta?.rowLoadingId === row.id
                        }),
                        style: { width: rowData.column.getSize() }
                      }}
                    >

                      {flexRender(rowData.column.columnDef.cell, rowData.getContext())}

                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </table>

        {!props.data?.length && (props.loading ? (
          <TableLoading />
        ) : (
          <TableEmpty />
        ))}
      </div>

      {props.pagination && (
        <TablePagination
          table={table}
          loading={props.loading}
          pagination={props.pagination}
        />
      )}

    </div>
  
  );
}
