import React from "react";
import { LocaleUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import styled from "styled-components";
import { isMonthDisabled } from "./utils/date-helpers";
import { Dropdown } from "..";

interface IProps {
  date: Date;
  localeUtils: LocaleUtils;
  onChange: (yearAndMonth: Date) => void;
  fromMonth: Date;
  toMonth: Date;
  datesList: string[];
}

const DropdownWrappers = styled.div`
  display: flex;
`;

export default class YearMonthSelect extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const {
      date, localeUtils, fromMonth, toMonth, datesList
    } = this.props;

    const months = localeUtils.getMonths();
    const years: number[] = [];

    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }

    return (
      <form className="DayPicker-Caption">
        <DropdownWrappers>
          <Dropdown
            style={{ marginRight: "10px" }}
            isOverlayActive={true}
            direction={"down"}
            selected={date.getMonth()}
            onSelect={data => this.handleSelect("month", data as number)}
            items={months.map((month, i) => {
              return {
                data: i,
                isDisabled: isMonthDisabled(
                  i, date.getFullYear(), datesList
                ),
                text: month
              };
            })}
          />
          {years.length > 1 && (
            <Dropdown
              isOverlayActive={true}
              direction={"down"}
              selected={years.findIndex(y => y === date.getFullYear())}
              onSelect={data => this.handleSelect("year", years[data as number])}
              items={years.map((year, i) => {
                return {
                  data: i,
                  text: year.toString()
                };
              })}
            />
          )}
        </DropdownWrappers>
      </form>
    );
  }

  private handleSelect(type: "year" | "month", data: number) {
    if (type === "year") {
      this.props.onChange(new Date(data, this.props.date.getMonth()));
    } else {
      this.props.onChange(new Date(this.props.date.getFullYear(), data));
    }
  }
}
