export const SIGN_OUT = "@auth/SIGN_OUT";
export type SIGN_OUT = typeof SIGN_OUT;

export const SIGN_IN = "@auth/SIGN_IN";
export type SIGN_IN = typeof SIGN_IN;

export const SIGN_IN_SUCCESS = "@auth/SIGN_IN_SUCCESS";
export type SIGN_IN_SUCCESS = typeof SIGN_IN_SUCCESS;

export const SIGN_IN_FAILURE = "@auth/SIGN_IN_FAILURE";
export type SIGN_IN_FAILURE = typeof SIGN_IN_FAILURE;
