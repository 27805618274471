/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";

export interface ITextProps extends React.HTMLProps<HTMLSpanElement> {
  children: React.ReactNode | (string & {});
  fontSize?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "small";
  weight?: "bold" | "normal";
  type?: string;
}

const textColor = theme.variants(
  "mode", "type", {
    dark: { normal: styles.primaryDarkColor },
    default: { normal: styles.textColor },
    faded: { normal: styles.fadedTextColor },
    nav: { normal: styles.fadedTextColor }
  }
);

const letterSpacing = theme.variants(
  "mode", "type", {
    default: { normal: "normal" },
    nav: { normal: 1 }
  }
);

const textTransform = theme.variants(
  "mode", "type", {
    default: { normal: "none" },
    nav: { normal: "uppercase" }
  }
);

const textWeight = theme.variants(
  "mode", "weight", {
    bold: { normal: 700 },
    default: { normal: 300 },
    nav: { normal: 400 }
  }
);

const textSize = theme.variants(
  "mode", "fontSize", {
    h1: { normal: "24px" },
    h2: { normal: "22px" },
    h3: { normal: "20px" },
    h4: { normal: "18px" },
    h5: { normal: "16px" },
    h6: { normal: "12px" },
    p: { normal: "14px" },
    small: { normal: "10px" }
  }
);

const TextWrapper: any = styled.span`
  color: ${textColor};
  font-weight: ${textWeight};
  letter-spacing: ${letterSpacing}px;
  font-size: ${textSize};
  text-transform: ${textTransform};
`;

const Text: React.FC<ITextProps> = ({
  children,
  fontSize,
  weight,
  style,
  ...rest
}) => (
  <TextWrapper {...rest} style={style} weight={weight} fontSize={fontSize}>
    {children}
  </TextWrapper>
);

TextWrapper.defaultProps = {
  fontSize: "p",
  type: "default",
  weight: "normal"
};

export default Text;
