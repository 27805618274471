import {
  Formik, FormikValues, FormikProps, Form 
} from "formik";
import { Row, Column } from "hedron";
import React from "react";
import { IUser } from "src/types/store/users";
import * as Yup from "yup";
import {
  TextInput,
  Button,
  Text,
  ValidationError
} from "../../../components";

interface INewPasswordProps {
  user: IUser | null;
  confirmSignup: (user: any, newPassword: string) => void;
}

type Props = INewPasswordProps;

export const NewPassword: React.FC<Props> = ({ user, confirmSignup }) => {
  const submitPasswordChange = React.useCallback((newPassword: string) => {
    confirmSignup(user, newPassword);
  }, [confirmSignup, user]);

  return (
    <React.Fragment>
      <Formik
        initialValues={{ newPassword: "" }}
        onSubmit={(values: FormikValues) =>
          submitPasswordChange(values.newPassword)
        }
        validationSchema={Yup.object().shape({
          newPassword: Yup.string()
            .min(8, "Password must be at least 8 characters long.")
            .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!-{}"'|_^=@#$&£()\\`.+,/"]{8,}/,
              {
                message:
                      "Password must contain one capital, lowercase and number."
              })
            .required("Required")
        })}
      >
        {(props: FormikProps<{
                newPassword: string;
              }>) => {
          const {
            values, handleChange, handleBlur, errors
          } = props;

          return (
            <Form>
              <Row>
                <Column fluid={true}>
                  <Text style={{
                    display: "block",
                    textAlign: "center"
                  }}>
                        Please create a new password.
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column fluid={true}>
                  <Text fontSize="small">New Password</Text>
                  <TextInput
                    onChange={handleChange}
                    value={values.newPassword}
                    name="newPassword"
                    onBlur={handleBlur}
                    type="password"
                  />
                  <ValidationError>{errors.newPassword}</ValidationError>
                </Column>
              </Row>

              <Row>
                <Column
                  fluid={true}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Button text="Submit" type="submit" kind="outline" />
                </Column>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};