import React from "react";
import DayPicker, { DayModifiers } from "react-day-picker";
import "react-day-picker/lib/style.css";
import styled from "styled-components";
import * as moment from "moment";
import YearMonthSelect from "./year-month-select";
import { isDisabled, convertDateToUTC } from "./utils/date-helpers";
import { styles } from "../../lib/styles";

interface IProps {
  className: string;
  handleDateChange: (date: moment.Moment) => void;
  initialDate: Date | moment.Moment;
  datesList: string[];
  handleClose: () => void;
}

interface IState {
  activeDate: Date;
  month: Date;
}

const initialState: IState = {
  activeDate: moment.utc().toDate(),
  month: new Date()
};

const Container = styled.div`
  position: absolute;
  bottom: 40px;
  background: ${styles.secondaryDarkColor};
  outline: none;
  left: 0;
  z-index: 99;

  &.compare-a {
    left: auto;
    right: 0;
  }
  @media (max-width: 768px) {
    background: ${styles.secondaryDarkColor};
    justify-content: center;
    &.compare-a {
      left: 50%;
      right: auto;
    }
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    bottom: 0;
    padding-bottom: 30px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`;

const RangeIndicator = styled.div`
  padding: 30px;
  padding-bottom: 5px;
  font-size: 0.9rem;
`;

const StyledRangePicker = styled.div`
  * {
    outline: none;
  }
  .IntervalRangePicker {
    min-width: 330px;
    background: rgb(16, 28, 46);
    min-height: 345px;

    .DayPicker-Day {
      height: 25px;
      width: 25px;
      font-size: 15px;
      color: white;

      border-radius: 100%;
      outline: none;
      &:not(.DayPicker-Day--outside) {
        &:not(.DayPicker-Day--disabled):hover {
          background-color: rgb(82, 179, 174) !important;
        }

        &.DayPicker-Day--disabled {
          opacity: 0.2;
        }

        &.DayPicker-Day--selected {
          color: rgb(16, 28, 46);
          background-color: rgb(82, 179, 174);
          border-radius: 100%;
        }
      }
    }

    .DayPicker-Week {
      padding-bottom: 1px;
    }
  }
`;

export default class SingleDatePicker extends React.Component<IProps> {
  public readonly state: IState = initialState;

  constructor(props: IProps) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
  }

  public componentDidMount() {
    if (this.props.initialDate) {
      const activeDate = moment.utc(this.props.initialDate).toDate();

      this.setState({
        activeDate: convertDateToUTC(activeDate),
        month: moment.utc(this.props.initialDate).toDate()
      });
    }
  }

  public render() {
    const { datesList, className } = this.props;
    const { activeDate, month } = this.state;
    const modifiers = { disabled: (day: Date) => isDisabled(day, datesList) };
    const fromMonth = new Date(new Date(datesList[datesList.length - 1]));
    const toMonth = new Date(new Date(datesList[0]));

    return (
      <StyledRangePicker>
        <Container className={className}>
          <RangeIndicator>
            {activeDate
              ? moment.utc(activeDate).format("ddd DD MMM YYYY")
              : "Pick a date"}
          </RangeIndicator>
          <DayPicker
            className="IntervalRangePicker"
            initialMonth={moment.utc(this.props.initialDate).toDate()}
            month={month}
            numberOfMonths={1}
            fromMonth={new Date(datesList[datesList.length - 1])}
            toMonth={new Date(datesList[0])}
            selectedDays={activeDate}
            modifiers={modifiers}
            onDayClick={(day, dayModifiers) =>
              this.handleDayClick(day, dayModifiers)
            }
            captionElement={({ date, localeUtils }) => (
              <YearMonthSelect
                date={date}
                localeUtils={localeUtils}
                onChange={this.handleYearMonthChange}
                fromMonth={fromMonth}
                toMonth={toMonth}
                datesList={datesList}
              />
            )}
          />
        </Container>
      </StyledRangePicker>
    );
  }

  private handleYearMonthChange(month: Date) {
    const { datesList } = this.props;
    const fromMonth = new Date(new Date(datesList[datesList.length - 1]));
    const toMonth = new Date(new Date(datesList[0]));
    let setMonth = month;

    if (month < fromMonth) {
      setMonth = fromMonth;
    }

    if (month > toMonth) {
      setMonth = toMonth;
    }

    this.setState({ month: setMonth });
  }
  private handleDayClick(day: Date, modifiers?: DayModifiers) {
    if (modifiers && modifiers.disabled) {
      return;
    }

    this.setState({ activeDate: day });
    this.props.handleDateChange(moment.utc(day));
  }
}
