import React from "react";
import { styles } from "src/lib/styles";
import styled from "styled-components";

import { Icon } from "../";
import {
  canSkipNextInterval,
  canSkipPrevInterval,
  getInterval
} from "../../lib/date-helpers";
import moment from "moment";

const ImageArrowsLeftWrapper = styled.div<IArrow>`
  opacity: ${props => (props.isOverlayActive ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  padding: ${styles.paddingUnit}px;
  transform: translateY(-50%);
  padding-left: 5px;
  left: 0;
  background: ${styles.primaryOverlayColour};
  border-radius: 0px 20px 20px 0px;
  display: flex;
`;

const ImageArrowsRightWrapper = styled.div<IArrow>`
  opacity: ${props => (props.isOverlayActive ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: ${styles.paddingUnit}px;
  padding-right: 5px;
  background: ${styles.primaryOverlayColour};
  border-radius: 20px 0px 0px 20px;
  display: flex;
`;

const ImageArrowsWrapper = styled.div`
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0;
  z-index: 2;
`;

interface IImageArrowsWrapperProps {
  handleNext: () => void;
  handlePrev: () => void;
  total: number;
  current: number | null;
  dates: string[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  isOverlayActive?: boolean;
}

interface IArrow {
  isOverlayActive?: boolean;
}

const ImageArrows: React.SFC<IImageArrowsWrapperProps> = ({
  current,
  endDate,
  dates,
  handleNext,
  handlePrev,
  total,
  startDate,
  isOverlayActive
}) => {
  const interval = getInterval(startDate, endDate);
  const targetEndDate = endDate.clone().subtract(interval, "days");
  const targetStartDate = startDate.clone().add(interval, "days");

  return (
    <React.Fragment>
      {!(
        current &&
        current <= 0 &&
        canSkipPrevInterval(targetEndDate, moment.utc(dates[dates.length - 1]))
      ) && (
        <ImageArrowsWrapper onClick={handlePrev} style={{ left: 0 }}>
          <ImageArrowsLeftWrapper isOverlayActive={isOverlayActive}>
            <Icon kind="hover" icon="chevron-left" size={16} />
          </ImageArrowsLeftWrapper>
        </ImageArrowsWrapper>
      )}

      {!(
        current &&
        current >= total - 1 &&
        canSkipNextInterval(targetStartDate, moment.utc([0]))
      ) && (
        <ImageArrowsWrapper onClick={handleNext} style={{ right: 0 }}>
          <ImageArrowsRightWrapper isOverlayActive={isOverlayActive}>
            <Icon kind="hover" icon="chevron-right" size={16} />
          </ImageArrowsRightWrapper>
        </ImageArrowsWrapper>
      )}
    </React.Fragment>
  );
};

export default ImageArrows;
