import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import { Text } from "../../components";
import { Column, Row } from "hedron";
import { IDeletionStats } from "src/store/deletion-queue/deletion-queue.api";
import { getDeletionStatsRequest } from "src/store/deletion-queue/deletion-queue.actions";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import { styles } from "../../lib/styles";
import DeletionItemsList from "src/components/deletion-queue/DeletionItemsList";
import { DeletionStats } from "src/components/deletion-queue/DeletionStats";
import { DeletionStatsLoading } from "src/components/deletion-queue/utils/DeletionStatsLoading";

interface IStateProps {
  deletionStats: IDeletionStats;
  deletionStatsLoading: boolean;
}

interface IDeletionDispatchProps {
  getDeletionStats: () => void;
}

export const DeletionQueuePage: React.FC<IStateProps & IDeletionDispatchProps & RouteComponentProps> = ({
  getDeletionStats, deletionStats, deletionStatsLoading
}) => {
  React.useEffect(() => {
    getDeletionStats();
  }, [getDeletionStats]);

  return (
    <div style={{
      backgroundColor: styles.primaryDarkColor,
      borderRadius: "15px",
      paddingBottom: "60px"
    }}>
      <Row>
        <Column>
          <Text fontSize="h1">Deletion Queue</Text>
          <Text fontSize="h4" style={{
            color: "#afbbcc50",
            paddingLeft: "10px" 
          }}>
            {deletionStats.deletion_items && deletionStats.deletion_items.length && !deletionStatsLoading ? (<>(Items are deleted in the order shown below)</>) : null }
          </Text>
        </Column>
      </Row>

      {deletionStatsLoading ? (
        <DeletionStatsLoading />
      ) : (
        <>
          <DeletionStats deletionStats={deletionStats} />
          <DeletionItemsList items={deletionStats.deletion_items || []} />
        </>
      )}

    </div>
  );
};

const mapStateToProps = (state: IStore): IStateProps => {
  return {
    deletionStats: state.deletionStats.deletionStats,
    deletionStatsLoading: state.deletionStats.gettingDeletionStats
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDeletionDispatchProps => {
  return { getDeletionStats: () => dispatch(getDeletionStatsRequest()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletionQueuePage);
