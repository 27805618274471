import * as React from "react";
import { Icon } from "..";
import SingleDatePicker from "./single-date-picker";
import * as moment from "moment";

interface IProps {
  className: string;
  datesList: string[];
  initialDate: Date | moment.Moment;
  activeTime: Date | string | moment.Moment;
  handleDateChange: (date: moment.Moment) => void;
}

interface IState {
  isCalendarOpen: boolean;
}

const initialState: IState = { isCalendarOpen: false };

class RenderSingleDatePicker extends React.Component<IProps, IState> {
  public readonly state: IState = initialState;

  public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    if (nextState.isCalendarOpen !== this.state.isCalendarOpen) {
      return true;
    }

    if (nextProps.datesList !== this.props.datesList) {
      return true;
    }

    if (nextProps.initialDate !== this.props.initialDate) {
      return true;
    }

    if (nextProps.activeTime !== this.props.activeTime) {
      return true;
    }

    return false;
  }

  public render() {
    const {
      initialDate, datesList, className 
    } = this.props;

    return (
      <div>
        <Icon icon="calendar" onClick={() => this.handleOpenCalendar()} />
        {this.state.isCalendarOpen && (
          <SingleDatePicker
            className={className}
            handleClose={() => this.handleCloseCalendar()}
            initialDate={initialDate}
            datesList={datesList}
            handleDateChange={(date: moment.Moment) =>
              this.handleDateChange(date)
            }
          />
        )}
      </div>
    );
  }

  private handleDateChange(date: moment.Moment) {
    this.setState({ isCalendarOpen: false });
    this.props.handleDateChange(date);
  }

  private handleOpenCalendar() {
    const { isCalendarOpen } = this.state;

    this.setState({ isCalendarOpen: !isCalendarOpen });
  }
  private handleCloseCalendar() {
    this.setState({ isCalendarOpen: false });
  }
}

export default RenderSingleDatePicker;
