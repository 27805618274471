import * as React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";

interface ICardProps {
  kind?: string;
  children: React.ReactChild;
}

const CardWrapper = styled.div<{kind: ICardProps["kind"]}>`
  display: flex;
  background: #eaeaea;
  z-index: 10;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 450px;
  justify-content: space-between;
  height: auto;
  border-radius: 10px;

  box-shadow: 0px 0px 110px 15px ${styles.primaryDarkColor};
  > section {
    width: 100%;
  }
`;

const Card: React.SFC<ICardProps> = ({ kind, children }) => (
  <CardWrapper id="header" kind={kind}>
    {children}
  </CardWrapper>
);

CardWrapper.defaultProps = { kind: "default" };

export default Card;
