import React from "react";
import { Box } from "../box";
import Text from "../text";
import Dropdown from "../dropdown";
import moment from "moment";

interface SelectTimesProps {
  setStart: (val: string) => void;
  start: string;
  setEnd: (val: string) => void;
  end: string;
}

export const SelectTimes: React.FC<SelectTimesProps> = props => {
  const timesOfDay = React.useMemo(() => {
    const hours = [];

    // For each hour of the day
    for (let hour = 0; hour < 24; hour++) {
      // For every 15 minutes
      for (let quarter = 0; quarter < 4; quarter++) {
        // Format and push to the hours array
        hours.push(moment({
          hour,
          minute: 15 * quarter
        }).format("HH:mm"));
      }
    }

    hours.push("23:59");

    return hours;
  }, []);

  return (
    <Box>
      <Box>
        <Text weight="bold">
          Select the time of day
        </Text>
      </Box>
      <Box
        flexDir="row"
        justify="center"
        align="center"
      >
        <Box
          flexDir="row"
          justify="center"
          align="center"
          pad={{ top: "sm" }}
        >
          <Text 
            type="small" 
            weight="bold"
          >
            FROM
          </Text>
          <Box pad={{ horizontal: "sm" }}>
            <Dropdown 
              direction="down"
              isOverlayActive
              onSelect={value => props.setStart(value as string)}
              items={timesOfDay.map(time => ({
                data: time,
                text: time 
              }))}
              selected={timesOfDay.findIndex(time => time === props.start)} />
          </Box>
        </Box>
        <Box
          flexDir="row"
          justify="center"
          align="center"
          pad={{ top: "sm" }}
        >
          <Text
            type="small"
            weight="bold">
            TO
          </Text>
          <Box pad={{ horizontal: "sm" }}>
            <Dropdown 
              direction="down"
              isOverlayActive
              onSelect={value => props.setEnd(value as string)}
              items={timesOfDay.map(time => ({
                data: time,
                text: time 
              }))}
              selected={timesOfDay.findIndex(time => time === props.end)} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};