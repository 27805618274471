import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";

interface IDropdownItemProps {
  kind?: string;
  text: string;
  style?: any;
  onSelect: (e: Event) => void;
  isSelected: boolean
}

const textColor = theme.variants(
  "mode", "kind", { default: { normal: styles.textColor } }
);

const DropdowItemWrapper: any = styled.div`
  color: ${textColor};
  cursor: pointer;
  padding: 10px;
  margin: 5px 15px;
  margin-top: 0;
  transition: color 0.2s ease-in-out;
  scroll-margin-top: 2.5em;

  &:hover {
    color: ${styles.primaryAccentColor};
  }

  &.selected {
    position: relative;
    color: white;

    &:after {
      content: '';
      position: absolute;
      height: 4px;
      width: 4px;
      border-radius: 100%;
      background: lightgreen;
      top: 50%;
      left: -5px;
      transform: translateY(-50%);
    }
  }

  
`;

const DropdownItem: React.SFC<IDropdownItemProps> = ({
  kind,
  style,
  text,
  onSelect,
  isSelected
}) => (
  <DropdowItemWrapper className={isSelected ? "selected" : "selectable"} style={style} kind={kind} onClick={onSelect}>
    <span>{text}</span>
  </DropdowItemWrapper>
);

DropdownItem.defaultProps = { kind: "default" };

export default DropdownItem;
