import "react-day-picker/lib/style.css";

import moment from "moment";
import * as React from "react";

import { Icon, RangeIndicator } from "..";
import { IImageStore } from "../../types/store/images";

import DateRangePicker from "./date-range-picker";
import "react-day-picker/lib/style.css";

interface IProps {
  activeStartDate: moment.Moment;
  activeEndDate: moment.Moment;
  imageStore: IImageStore;
  handleDateChange: (startDate: moment.Moment, endDate: moment.Moment) => void;
}

interface IState {
  isCalendarOpen: boolean;
}

const initialState: IState = { isCalendarOpen: false };

class RenderDatePicker extends React.Component<IProps, IState> {
  public readonly state: IState = initialState;

  public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    if (nextState.isCalendarOpen !== this.state.isCalendarOpen) {
      return true;
    }

    if (nextProps.imageStore !== this.props.imageStore) {
      return true;
    }

    if (nextProps.activeStartDate !== this.props.activeStartDate) {
      return true;
    }

    if (nextProps.activeEndDate !== this.props.activeEndDate) {
      return true;
    }

    return false;
  }

  public render() {
    const {
      activeStartDate, activeEndDate, imageStore
    } = this.props;

    const { isCalendarOpen } = this.state;

    return (
      <div style={{
        position: "relative",
        display: "flex"
      }}>
        <Icon icon="calendar" onClick={() => this.handleOpenCalendar()} />
        {activeEndDate.diff(activeStartDate, "days") > 0 && (
          <RangeIndicator startDate={activeStartDate} endDate={activeEndDate} />
        )}
        {isCalendarOpen && (
          <DateRangePicker
            startDate={activeStartDate.utc().format()}
            endDate={activeEndDate.utc().format()}
            datesList={imageStore.dates}
            handleClose={() => this.handleCloseCalendar}
            handleDateChange={(startDate: moment.Moment,
              endDate: moment.Moment) => this.handleDateChange(startDate, endDate)}
          />
        )}
      </div>
    );
  }

  private handleDateChange(startDate: moment.Moment, endDate: moment.Moment) {
    this.setState({ isCalendarOpen: false });
    this.props.handleDateChange(startDate, endDate);
  }

  private handleOpenCalendar() {
    const { isCalendarOpen } = this.state;

    this.setState({ isCalendarOpen: !isCalendarOpen });
  }
  private handleCloseCalendar() {
    this.setState({ isCalendarOpen: false });
  }
}

export default RenderDatePicker;
