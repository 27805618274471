import * as Redux from "redux";
import * as AT from "./auth.constants";
import { IAuthStore } from "../../types/store/auth";
import { AuthActions } from "./auth.actions";

const initialState: IAuthStore = {
  admin: false,
  authenticated: false,
  authenticating: false,
  error: {},
  isSigningUp: false,
  signedUp: false
};

export const auth: Redux.Reducer<IAuthStore> = (state: IAuthStore = initialState,
  action: AuthActions): IAuthStore => {
  switch (action.type) {
    case AT.SIGN_IN:
      return {
        ...state,
        admin: false,
        authenticating: true
      };
    case AT.SIGN_IN_SUCCESS:
      return {
        ...state,
        admin: action.admin,
        authenticated: true,
        authenticating: false
      };
    case AT.SIGN_IN_FAILURE:
      return {
        ...state,
        admin: false,
        authenticated: false,
        authenticating: false
      };
    case AT.SIGN_OUT:
      return {
        ...state,
        admin: false,
        authenticated: false
      };
    default:
      return state;
  }
};
