import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";

interface ITextareaStyleProps {
  kind?: string;
}

type ITextareaProps = Omit<JSX.IntrinsicElements["textarea"], "ref"> & ITextareaStyleProps;

const textColor = theme.variants(
  "mode", "kind", {
    default: { normal: styles.secondaryTextColor },
    secondary: { normal: styles.primaryDarkColor }
  }
);

const backgroundColour = theme.variants(
  "mode", "kind", {
    default: { normal: styles.formBackground },
    secondary: { normal: "transparent" }
  }
);

const TextareaWrapper = styled.textarea<ITextareaStyleProps>`
  resize: none;
  background: ${backgroundColour};
  border: none;
  outline: none;
  color: ${textColor};
  border-bottom: solid 1px transparent;
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  padding: 10px;
  font-family: inherit;
  box-shadow: 0 0 0 1px ${styles.fadedTextColor};

  &:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: ${textColor};
    transition: background-color 5000s ease-in-out 0s;
    color: ${textColor};
    box-shadow: 0 0 0 1px ${styles.primaryAccentColor};
  }
`;

const Textarea: React.FC<ITextareaProps> = ({ kind, ...rest }) => (
  <TextareaWrapper {...rest} kind={kind} />
);

Textarea.defaultProps = { kind: "default" };

export default Textarea;
