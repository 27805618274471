/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Storage } from "aws-amplify";
import { IoIosArrowBack } from "react-icons/io";
import {
  Form, Formik, FormikProps 
} from "formik";
import { Row } from "hedron";
import _ from "lodash";
import * as React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as Redux from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ImageUploader } from "src/components/image-uploader";
import signUrl from "src/lib/signS3Url";
import { styles } from "src/lib/styles";
import { ICustomer } from "src/store/customers/customers.api";
import { ISite } from "src/store/sites/sites.api";
import { IStore } from "src/types/store/store";
import * as Yup from "yup";
import awsmobile from "src/aws-exports";
import {
  RemovableImageSelectThumbnail, Text,
  TextButton,
  TextInput,
  ValidationError
} from "src/components";
import {
  deleteCustomerRequest, getCustomerRequest, suspendCustomerRequest, updateCustomerRequest
} from "src/store/customers/customers.actions";
import { getSitesRequest } from "src/store/sites/sites.actions";
import { SecuritySection } from "src/components/security-section";
import styled from "styled-components";
import Spacer from "src/components/Spacer";
import { Sections } from "src/components/sections";
import { getFolderSize } from "../../lib/getFolderSize";
import MobileSpacer from "src/components/MobileSpacer";

Modal.setAppElement("#root");
interface ICustomersRouterProps {
  customerId: string;
}

type ICustomersProps = RouteComponentProps<ICustomersRouterProps>;

interface IStateProps {
  customer: ICustomer | null;
  isUpdating: boolean;
  isDeleting: boolean;
  sites: ISite[];
}

const siteStatuses = [
  {
    data: "active",
    text: "active"
  },
  {
    data: "suspended",
    text: "suspended"
  }
] as const;

interface ICustomersDispatchProps {
  deleteCustomer: (customerId: string) => void;
  suspendCustomer: (customerId: string) => void;
  getSites: (customerId: string, status?: string, role?: string) => void;
  getCustomer: (customerId: string) => void;
  updateCustomer: (customerId: string, customer: Partial<ICustomer>) => void;
}

type EditFormProps = Partial<ICustomer> & {
  whitelist: string
};

const EditCustomerPage: React.FC<ICustomersProps & IStateProps & ICustomersDispatchProps> = props => {
  const {
    customer,
    sites,
    history,
    deleteCustomer,
    isUpdating,
    isDeleting,
    suspendCustomer,
    match,
    getSites,
    getCustomer
  } = props;

  const [removeLoginLogo, setRemoveLoginLogo] = React.useState(false);
  const [removeViewerLogo, setRemoveViewerLogo] = React.useState(false);
  const [viewerLogo, setViwerLogo] = React.useState<string>();
  const [loginLogo, setLoginLogo] = React.useState<string>();
  const [signedViewerLogo, setSignedViewerLogo] = React.useState<string | null>();
  const [signedLoginLogo, setSignedLoginLogo] = React.useState<string | null>();
  const [siteStatus, setSiteStatus] = React.useState<(typeof siteStatuses)[number]["data"]>("active");
  const formikRef = React.useRef<Formik<EditFormProps> | null>(null);

  /**
   * Set the logo image url properly
   */
  const getCustomerLogoUrl = React.useCallback(() => {
    const unSignedViewerLogo = viewerLogo || props.customer?.logo;

    if (unSignedViewerLogo?.includes("amazonaws")) {
      signUrl(unSignedViewerLogo).then(signedUrl => {
        setSignedViewerLogo(signedUrl);
      });
    } else {
      setSignedViewerLogo(viewerLogo);
    }

    const unSignedloginLogo = loginLogo || props.customer?.dark_logo;

    if (unSignedloginLogo?.includes("amazonaws")) {
      signUrl(unSignedloginLogo).then(signedUrl => {
        setSignedLoginLogo(signedUrl);
      });
    } else {
      setSignedLoginLogo(loginLogo);
    }
  }, [
    loginLogo,
    props.customer?.dark_logo,
    props.customer?.logo,
    viewerLogo
  ]);

  React.useEffect(() => {
    getCustomerLogoUrl();
  }, [getCustomerLogoUrl]);

  React.useEffect(() => {
    getCustomer(match.params.customerId);

    getSites(
      match.params.customerId, undefined, "admin"
    );
  }, [
    getCustomer,
    getSites,
    match
  ]);

  const { activeSites, suspendedSites } = React.useMemo(() => {
    return sites.reduce((acc, site) => {
      if (site.status === "active") {
        acc.activeSites.push(site);
      } else {
        acc.suspendedSites.push(site);
      }

      return acc;
    }, {
      activeSites: [] as ISite[],
      suspendedSites: [] as ISite[] 
    });
  }, [sites]);
  
  const uploadToS3 = async (file: File, customer: ICustomer): Promise<string> => {
    let result: any;
    const path = `${customer.slug}/logos/${file.name}`;

    try {
      result = await Storage.put(
        path, file, { level: "public" }
      );
    } catch (error) {
      console.error(error);
    }
    const s3BucketPath = `https://${awsmobile.Storage.bucket}.s3.${awsmobile.Storage.region}.amazonaws.com/public/`;

    return `${s3BucketPath}${result.key}`;
  };

  const handleSubmit = (values: Partial<ICustomer>) => {
    const {
      match, updateCustomer, customer
    } = props;

    if (viewerLogo) {
      values.logo = viewerLogo;
    }

    if (loginLogo) {
      values.dark_logo = loginLogo;
    }

    if (removeViewerLogo) {
      values.logo = null;
    }

    if (removeLoginLogo) {
      values.dark_logo = null;
    }

    if (customer?.domain) {
      const sub = customer.domain.split(".")[0];

      if (sub === values.sub_domain) {
        delete values.sub_domain;
      }
    }

    updateCustomer(match.params.customerId, values);
  };

  let statusAction = "";

  if (customer) {
    switch (customer.status) {
      case "active":
        statusAction = "Active";
        break;
      case "suspended":
      case "Suspended":
      case "enable":
        statusAction = "Suspended";
        break;
    }
  }
  
  const backIcon = React.useRef(<IoIosArrowBack color="currentColor" size="20" />).current;
  const currentSites = siteStatus === "active" ? activeSites : suspendedSites;
  
  return (
    <>
      <div style={{ paddingBottom: "60px" }}>
        <Row style={{ marginBlock: 20 }}>
          <Styled.BackButton onClick={() => history.goBack()} disabled={!customer}>
            {customer ? 
              <>
                {backIcon}{" "}<span>{customer.name}</span>
              </> : 
              backIcon
            }
          </Styled.BackButton>
        </Row>

        {/* Sites */}

        <Row style={{
          marginBlock: 20,
          alignItems: "center",
          gap: 20
        }}>
          <Text weight="bold" fontSize="h4">Sites</Text>
        
          {siteStatuses.map(status => (
            <Styled.CheckboxContainer key={status.text}>
              <Styled.CheckboxInput id={"active_site_" + status.text} type="radio" name="active_sites" checked={siteStatus === status.data} onChange={({ target }) => target.checked && setSiteStatus(status.data)} />
              <Styled.CheckboxLabel htmlFor={"active_site_" + status.text}>
                {_.capitalize(status.text)} ({(status.data === "active" ? activeSites : suspendedSites).length})
              </Styled.CheckboxLabel>
            </Styled.CheckboxContainer>
          ))}
        
          <Spacer />
        
          <Styled.CTAButton onClick={() => props.history.push(`/admin/customer/${match.params.customerId}/new-site`)}>
          Add site
          </Styled.CTAButton>
        </Row>

        <Row style={{ marginBlock: 20 }}>
          {currentSites?.length ? (
            <Styled.SiteTilesContainer>
              {currentSites.map(site => (
                <Styled.SiteTile key={site.id} onClick={() =>
                  history.push(`/admin/customer/${site.customer_id}/site/${site.id}`)}>
                  <h3 className="site-tile-title" title={site.name}>
                    {site.name}
                  </h3>
                  <small className="site-tile-text">
                    {getFolderSize(site) ? `${getFolderSize(site)} GB` : "Size N/A"} 
                  </small>
                </Styled.SiteTile>
              ))}
            </Styled.SiteTilesContainer>
          ) : (
            <p style={{
              textAlign: "center",
              width: "100%",
              marginBlock: 20 
            }}>No sites found</p>
          )}
        </Row>

        <br />

        {/* Settings */}

        <Row style={{
          marginBlock: 20,
          alignItems: "center",
          gap: 20
        }}>
          <Text weight="bold" fontSize="h3">Settings</Text>
        
          <Spacer />
        
          <Styled.CTAButton
            disabled={isUpdating || isDeleting}
            type="submit"
            onClick={() => formikRef.current?.submitForm() ?? alert("Form action not found")}
          >
          Save
          </Styled.CTAButton>
        </Row>

        <div style={{ marginBlock: 20 }}>
          <Sections.Container>
            {customer && (
              <Formik
                ref={formikRef}
                initialValues={{
                  dark_logo: customer.dark_logo,
                  logo: customer.logo,
                  name: customer.name,
                  sub_domain: customer.domain === null ? "" : customer.domain.split(".")[0],
                  security_level: customer.security_level || 0,
                  whitelist: customer.ip_whitelist && customer.ip_whitelist.whitelistedIPs ? customer.ip_whitelist.whitelistedIPs.join(",") : ""
                }}
                onSubmit={(values: EditFormProps) => handleSubmit(values)}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Required"),
                  security_level: Yup.number().required(),
                  whitelist: Yup.string().when("security_level", {
                    is: 1,
                    then: Yup.string().matches(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}(,((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4})?$/, { message: "Please use valid IP addresses." })
                  }) 
                })}
              >
                {(props: FormikProps<EditFormProps>) => {
                  const {
                    values, errors, handleChange, handleBlur, setFieldValue
                  } = props;

                  return (
                    <Form onSubmitCapture={() => alert("SUBMITTING")}>
                      <Sections.Section header={HeaderComponent => (
                        <HeaderComponent>
                      This customer is <span style={{ color: statusAction === "Active" ? "#00C853" : "#FF3D00" }}>
                            {_.lowerCase(statusAction)}
                          </span>.
                        </HeaderComponent>
                      )}>
                        <TextButton align="none" text={
                          (statusAction === "Active" ? "Suspend" : "Activate") + " customer"
                        } clickHandler={() => suspendCustomer(match.params.customerId)} type="button" style={{
                          display: "inline",
                          margin: "0 2ch 0 0"
                        }} />
                        <TextButton align="none" text="Delete" textProps={{}} clickHandler={() => window.confirm("Are you sure?\n\nThis will delete all sites, cameras and images associated with " + (customer?.name ?? "this customer") + " and cannot be undone!") && customer && deleteCustomer(customer.id.toString())} type="button" style={{
                          display: "inline",
                          margin: "0 2ch 0 0"
                        }} />
                      </Sections.Section>
                    
                      <Sections.Section header="Settings">
                        <label>
                          <Text>Customer name</Text>
                          <div style={{ maxWidth: 350 }}>
                            <TextInput
                              onChange={handleChange}
                              value={values.name}
                              name="name"
                              onBlur={handleBlur}
                            />
                          </div>
                          <ValidationError>{errors.name}</ValidationError>
                        </label>
                        <br />
                        <br />
                        <label>
                          <Text>Sub domain</Text>
                          <div style={{ maxWidth: 350 }}>
                            <TextInput
                              onChange={handleChange}
                              value={values.sub_domain}
                              name="sub_domain"
                              onBlur={handleBlur}
                            />
                          </div>
                        </label>
                      </Sections.Section>
                    
                      <Sections.Section header="Security">
                        <SecuritySection 
                          onSelectSecurityLevel={value => setFieldValue("security_level", value)} 
                          securityLevel={values.security_level}
                          handleWhitelistChange={handleChange}
                          whitelistValue={values.whitelist}
                          whitelistErrors={errors.whitelist}
                          onBlurWhitelistTextInput={handleBlur}
                        />
                        <br />
                      </Sections.Section>
                    
                      <Sections.Section header="Viewer logo">
                        <div style={{ maxWidth: 350 }}>
                          <ImageUploader
                            allowedFiles={[
                              "image/png",
                              "image/jpg",
                              "image/jpeg"
                            ]}
                            handleUpload={async files => {
                              if (files.length) {
                                const s3Path = await uploadToS3(files[0], customer);

                                setViwerLogo(s3Path);
                                setRemoveLoginLogo(false);
                                getCustomerLogoUrl();
                              }
                            }}
                            multiple={false}
                            label="Drag & drop to upload a logo for the viewer"
                          />
                        </div>
                        {signedViewerLogo && (
                          <RemovableImageSelectThumbnail
                            imageSrc={signedViewerLogo}
                            onRemove={() => {
                              setSignedViewerLogo(null);
                              setRemoveViewerLogo(true);
                            }}
                            removeText="Remove logo"
                          />
                        )}
                      </Sections.Section>
                    
                      <Sections.Section header="Login logo">
                        <div style={{ maxWidth: 350 }}>
                          <ImageUploader
                            allowedFiles={[
                              "image/png",
                              "image/jpg",
                              "image/jpeg"
                            ]}
                            handleUpload={async files => {
                              if (files.length) {
                                const s3Path = await uploadToS3(files[0], customer);

                                setLoginLogo(s3Path);
                                setRemoveLoginLogo(false);
                                getCustomerLogoUrl();
                              }
                            }}
                            multiple={false}
                            label="Drag & drop to upload a logo for login"
                          />
                        </div>
                        {signedLoginLogo && (
                          <RemovableImageSelectThumbnail
                            imageSrc={signedLoginLogo}
                            onRemove={() => {
                              setSignedLoginLogo(null);
                              setRemoveLoginLogo(true);
                            }}
                            removeText="Remove logo"
                          />
                        )}
                      </Sections.Section>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Sections.Container>
        </div>

      </div>
      <MobileSpacer />
    </>
  );
};

const Styled = {
  BackButton: styled.button`
    background: none;
    border: none;
    color: ${styles.textColor};
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    padding: 0;

    svg {
      margin-right: 1ch;
    }
  `,
  CTAButton: styled.button`
    background: ${styles.primaryAccentColor};
    border: none;
    border-radius: 6px;
    color: ${styles.primaryDarkColor};
    padding: 8px 12px;
    cursor: pointer;
    &:disabled {
      background: ${styles.formBackground};
      cursor: not-allowed;
    }
  `,
  CheckboxContainer: styled.span`
    display: inline-flex;
    align-items: center;

    label, input {
      cursor: pointer;
    }
  `,
  CheckboxLabel: styled.label`
    font-weight: 500;
    font-size: small;
    user-select: none;
  `,
  CheckboxInput: styled.input`
    appearance: checkbox;
    margin-right: 0.5rem;
    accent-color: ${styles.primaryAccentColor};
    margin: 0;
    padding: 0;
    & + label {
      margin-left: 7px;
    }
  `,
  SiteTilesContainer: styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 1rem;
  `,
  SiteTile: styled.li`
    background: ${styles.primaryOverlayColour};
    color: ${styles.textColor};
    border-radius: 6px;
    padding: 20px;
    padding-right: 26px;
    box-shadow: 0 0 0 1px ${styles.fadedTextColor};
    flex: 1 0 300px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 0 1px ${styles.textColor};
      .site-tile-title {
        color: white;
      }
    }

    .site-tile-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 18px;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }

    .site-tile-text {
      font-weight: 900;
      font-size: smaller;
    }
  `
};

const mapStateToProps = (state: IStore, props: ICustomersProps & IStateProps & ICustomersDispatchProps): IStateProps => {
  return {
    customer: state.customers.currentCustomer,
    isDeleting: state.customers.deletingCustomer,
    isUpdating: state.customers.updatingCustomer,
    sites: state.sites.sites
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): ICustomersDispatchProps => {
  return {
    deleteCustomer: (customerId: string) =>
      dispatch(deleteCustomerRequest(customerId)),
    getSites: (
      customerId: string, status?: string, role?: string
    ) => dispatch(getSitesRequest(
      customerId, status, role
    )),
    getCustomer: (customerId: string) => dispatch(getCustomerRequest(customerId)),
    suspendCustomer: (customerId: string) =>
      dispatch(suspendCustomerRequest(customerId)),
    updateCustomer: (customerId: string, customer: Partial<ICustomer>) =>
      dispatch(updateCustomerRequest(customerId, customer))
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(EditCustomerPage);
