import * as React from "react";
import * as _ from "lodash";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { ICustomer } from "src/store/customers/customers.api";
import { ISite } from "src/store/sites/sites.api";
import { IUserCameraAssociations } from "src/types/store/users";
import { CameraDropdown } from "..";
import { IAuthStore } from "src/types/store/auth";
import { IDropdownProps } from "./camera-dropdown";

interface IProps {
  cameraLocations: ICameraLocation[];
  customer: ICustomer | null;
  sites: ISite[] | null;
  userCameras: IUserCameraAssociations[];
  cameraLocation: ICameraLocation | null;
  handleCameraChange: (data: IUserCameraAssociations) => void;
  auth: IAuthStore;
  showCameraSwitcherButtons?: IDropdownProps["showCameraSwitcherButtons"]
}

const CombinedCamerasDropdown: React.SFC<IProps> = props => {
  // If Admin, only show active cameras
  let items = (props.auth.admin ? props.userCameras.filter(camera => camera.status === "active") : props.userCameras);

  // If no userCameras, then format the items array
  // using sites and props.cameraLocations
  if (!items || items.length === 0) {
    items = [];

    props.cameraLocations.map(camera => {
      if (props.sites && props.customer) {
        const cameraSite = props.sites.find(site => site.id === camera.site_id);

        if (cameraSite) {
          items.push({
            id: camera.id,
            uuid: camera.uuid,
            name: camera.name,
            site: {
              customer: {
                id: props.customer.id,
                name: props.customer.name
              },
              id: cameraSite.id,
              name: cameraSite.name
            },
            status: camera.status,
            job_ref: camera.job_ref,
            hardware_ref: camera.hardware_ref
          });
        }
      }
    });
  }

  if (props.customer) {
    const selected = _.findIndex(items, x =>
      props.cameraLocation ? x.id === props.cameraLocation.id : false);
      
    return (
      <CameraDropdown
        onSelect={(data: IUserCameraAssociations) =>
          props.handleCameraChange(data)
        }
        selected={selected}
        userCameras={items}
        auth={props.auth}
        showCameraSwitcherButtons={props.showCameraSwitcherButtons}
      />
    );
  }

  return null;
};

export default CombinedCamerasDropdown;
