import React from "react";
// import { styles } from "src/lib/styles";
import styled from "styled-components";

const ImageSelectThumbnail: React.FC<{opacity?: number, dimensions?: {width: number, height: number}}> = ({
  children, opacity, dimensions 
}) => {
  return (
    <Styled.Container style={{
      "--child-opacity": opacity,
      "--aspect-ratio": dimensions ? dimensions.width / dimensions.height : "16/9" 
    } as React.CSSProperties}>
      {children}
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	aspect-ratio: var(--aspect-ratio, 16/9);
	max-width: 400px;
	background: rgba(255,255,255,0.1);
	border: 1px solid rgba(255,255,255,0.2);
	border-radius: 4px;
	background-image: linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%), linear-gradient(-45deg, rgba(255,255,255,0.1) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(255,255,255,0.1) 75%), linear-gradient(-45deg, transparent 75%, rgba(255,255,255,0.1) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
	& > * {
		width: 100%;
		height: 100%;
		object-fit: contain;
		opacity: var(--child-opacity, 1);
		-webkit-user-drag: none;
	}
`
};

export default ImageSelectThumbnail;