import { AxiosError } from "axios";

export default (error: AxiosError): string => {
  if (error.response) {
    if (error.response.data.errors) {
      return error.response.data.errors[0].details;
    } else {
      return "Sorry there seems to be a problem. Please contact the system administrator.";
    }
  } else if (error.message) {
    return error.message;
  } else {
    return "An unknown error occurred. Please make sure you are connected to the internet and try again";
  }
};
