import {
  Form, Formik, FormikProps 
} from "formik";
import { Column, Row } from "hedron";
import React from "react";
import * as Yup from "yup";
import {
  TextInput,
  Button,
  Text,
  ValidationError
} from "src/components";

export interface FormValues {
  newPassword: string;
}

interface INewPasswordFormProps {
  handleSubmit: (values: FormValues) => void;
  adminSettingPassword: boolean;
}

export const NewPasswordForm: React.FC<INewPasswordFormProps> = ({ handleSubmit, adminSettingPassword }) => {
  return (
    <Formik
      initialValues={{ newPassword: "" }}
      onSubmit={(values: FormValues) => handleSubmit(values)}
      validationSchema={
        Yup.object().shape({
          newPassword: Yup.string()
            .min(8, "Password must be at least 8 characters long.")
            .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!-{}"'|_^=@#$&£()\\`.+,/"]{8,}/,
              { message: "Password must contain one capital, lowercase and number." })
            .required("Required")
        })}
    >
      {(props: FormikProps<FormValues>) => {
        const {
          values, handleChange, handleBlur, errors
        } = props;

        return (
          <Form>
            <Row>
              <Column md={12} style={{ paddingTop: 0 }}>
                <Text fontSize="small">Reset Password</Text>
                <TextInput
                  onChange={handleChange}
                  value={values.newPassword}
                  name="newPassword"
                  onBlur={handleBlur}
                />
                <ValidationError>{errors.newPassword}</ValidationError>
              </Column>
            </Row>

            <Row>
              <Column>
                <Button
                  disabled={adminSettingPassword}
                  text="Reset Password"
                  type="submit"
                />
              </Column>
            </Row>

          </Form>
        );
      }}
    </Formik>
  );
};
