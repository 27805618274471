import * as _ from "lodash";
import {
  goBack, push, replace, RouterAction
} from "react-router-redux";
import { toast } from "react-toastify";
import * as Redux from "redux";
import { ThunkAction } from "redux-thunk";
import apiError from "src/lib/apiError";

import { IStore } from "../../types/store/store";
import {
  IUser,
  IUserCameraAssociations,
  IUserCameraInput,
  IUserInput
} from "../../types/store/users";
import {
  getCameraLocationsSuccess,
  IGetCameraLocationsSuccess
} from "../camera-locations/camera-locations.actions";
import { IGetCustomerSuccess } from "../customers/customers.actions";
import { getSitesSuccess, IGetSitesSuccess } from "../sites/sites.actions";
import * as usersApi from "./users.api";
import * as AT from "./users.constants";

export type UserActions =
  | ISetUser
  | IPurgeUsers
  | IGetUser
  | IGetUserSuccess
  | IGetUserFailure
  | IGetUsers
  | IGetUsersSuccess
  | IGetUsersFailure
  | ICreateUser
  | ICreateUserSuccess
  | ICreateUserFailure
  | IUpdateUser
  | IUpdateUserSuccess
  | IUpdateUserFailure
  | IDeleteUser
  | IDeleteUserSuccess
  | IDeleteUserFailure
  | IAdminUpdateUserAccess
  | IAdminUpdateUserAccessSuccess
  | IAdminUpdateUserAccessFailure
  | IResetUserPassword
  | IResetUserPasswordSuccess
  | IGetUserCameras
  | IGetUserCamerasSuccess
  | IGetUserCamerasFailure
  | IResetUserPasswordFailure
  | IGetCustomerSuccess
  | IGetSitesSuccess
  | IGetCameraLocationsSuccess;

type ThunkResult<R> = ThunkAction<
  R,
  IStore,
  undefined,
  UserActions | RouterAction
>;

export interface IPurgeUsers {
  type: AT.PURGE_USERS;
}

export const purgeUsers: Redux.ActionCreator<IPurgeUsers> = () => {
  return { type: AT.PURGE_USERS };
};

export interface ISetUser {
  type: AT.SET_USER;
}

export const setUser: Redux.ActionCreator<ISetUser> = () => {
  return { type: AT.SET_USER };
};

// ----------- GET USER ACTIONS -------- //
export interface IGetUser {
  type: AT.GET_USER;
}

export interface IGetUserSuccess {
  user: IUser;
  type: AT.GET_USER_SUCCESS;
}

export interface IGetUserFailure {
  type: AT.GET_USER_FAILURE;
}

export const getUser: Redux.ActionCreator<IGetUser> = () => {
  return { type: AT.GET_USER };
};

export const getUserSuccess: Redux.ActionCreator<IGetUserSuccess> = (user: IUser) => {
  return {
    type: AT.GET_USER_SUCCESS,
    user
  };
};

export const getUserFailure: Redux.ActionCreator<IGetUserFailure> = () => {
  return { type: AT.GET_USER_FAILURE };
};

export const getUserRequest = (): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getUser());

    try {
      const user = await usersApi.getUser();

      dispatch(getUserSuccess(user));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getUserFailure());
      toast.error(apiError(error));
    }
  };
};
// ----------- END GET USER ACTIONS -------- //

// ----------- GET USERS ACTIONS -------- //
export interface IGetUsers {
  type: AT.GET_USERS;
}

export interface IGetUsersSuccess {
  users: IUser[];
  type: AT.GET_USERS_SUCCESS;
}

export interface IGetUsersFailure {
  type: AT.GET_USERS_FAILURE;
}

export const getUsers: Redux.ActionCreator<IGetUsers> = () => {
  return { type: AT.GET_USERS };
};

export const getUsersSuccess: Redux.ActionCreator<IGetUsersSuccess> = (users: IUser[]) => {
  return {
    type: AT.GET_USERS_SUCCESS,
    users
  };
};

export const getUsersFailure: Redux.ActionCreator<IGetUsersFailure> = () => {
  return { type: AT.GET_USERS_FAILURE };
};

export const getUsersRequest = (): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getUsers());

    try {
      const users = await usersApi.getUsers();

      dispatch(getUsersSuccess(users));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getUsersFailure());
      toast.error(apiError(error));
    }
  };
};
// ----------- END GET USERS ACTIONS -------- //

// ----------- CREATE USERS ACTIONS -------- //
export interface ICreateUser {
  type: AT.CREATE_USER;
}

export interface ICreateUserSuccess {
  type: AT.CREATE_USER_SUCCESS;
}

export interface ICreateUserFailure {
  type: AT.CREATE_USER_FAILURE;
}

export const createUser: Redux.ActionCreator<ICreateUser> = () => {
  return { type: AT.CREATE_USER };
};

export const createUserSuccess: Redux.ActionCreator<ICreateUserSuccess> = () => {
  return { type: AT.CREATE_USER_SUCCESS };
};

export const createUserFailure: Redux.ActionCreator<ICreateUserFailure> = () => {
  return { type: AT.CREATE_USER_FAILURE };
};

export const createUserRequest = (user: IUserInput): ThunkResult<void> => {
  return async dispatch => {
    dispatch(createUser());

    try {
      await usersApi.createUser(user);
      dispatch(createUserSuccess());
      toast.success("Successfully created user!");
      dispatch(getUserRequest());
      dispatch(goBack());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(createUserFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END CREATE USERS ACTIONS -------- //

// ----------- UPDATE USERS ACTIONS -------- //
export interface IUpdateUser {
  type: AT.UPDATE_USER;
}

export interface IUpdateUserSuccess {
  type: AT.UPDATE_USER_SUCCESS;
}

export interface IUpdateUserFailure {
  type: AT.UPDATE_USER_FAILURE;
}

export const updateUser: Redux.ActionCreator<IUpdateUser> = () => {
  return { type: AT.UPDATE_USER };
};

export const updateUserSuccess: Redux.ActionCreator<IUpdateUserSuccess> = () => {
  return { type: AT.UPDATE_USER_SUCCESS };
};

export const updateUserFailure: Redux.ActionCreator<IUpdateUserFailure> = () => {
  return { type: AT.UPDATE_USER_FAILURE };
};

export const updateUserRequest = (userId: number,
  user: IUser): ThunkResult<void> => {
  return async dispatch => {
    dispatch(updateUser());

    try {
      await usersApi.updateUser(userId, user);
      dispatch(updateUserSuccess());
      toast.success("Successfully updated user details!");
      dispatch(getUserRequest());
      dispatch(replace(`/admin/user/${userId}`));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(updateUserFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END UPDATE USERS ACTIONS -------- //

// ----------- DELETE USERS ACTIONS -------- //
export interface IDeleteUser {
  type: AT.DELETE_USER;
}

export interface IDeleteUserSuccess {
  type: AT.DELETE_USER_SUCCESS;
}

export interface IDeleteUserFailure {
  type: AT.DELETE_USER_FAILURE;
}

export const deleteUser: Redux.ActionCreator<IDeleteUser> = () => {
  return { type: AT.DELETE_USER };
};

export const deleteUserSuccess: Redux.ActionCreator<IDeleteUserSuccess> = () => {
  return { type: AT.DELETE_USER_SUCCESS };
};

export const deleteUserFailure: Redux.ActionCreator<IDeleteUserFailure> = () => {
  return { type: AT.DELETE_USER_FAILURE };
};

export const deleteUserRequest = (userId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(deleteUser());

    try {
      await usersApi.deleteUser(userId);
      dispatch(deleteUserSuccess());
      dispatch(push("/admin/users"));
      toast.success("Successfully deleted user!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(deleteUserFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END DELETE USERS ACTIONS -------- //

// ----------- RESET USER PASSWORD ACTIONS -------- //
export interface IResetUserPassword {
  type: AT.ADMIN_RESET_PASSWORD;
}

export interface IResetUserPasswordSuccess {
  type: AT.ADMIN_RESET_PASSWORD_SUCCESS;
}

export interface IResetUserPasswordFailure {
  type: AT.ADMIN_RESET_PASSWORD_FAILURE;
}

export const adminResetUserPassword: Redux.ActionCreator<IResetUserPassword> = () => {
  return { type: AT.ADMIN_RESET_PASSWORD };
};

export const adminResetUserPasswordSuccess: Redux.ActionCreator<IResetUserPasswordSuccess> = () => {
  return { type: AT.ADMIN_RESET_PASSWORD_SUCCESS };
};

export const adminResetUserPasswordFailure: Redux.ActionCreator<IResetUserPasswordFailure> = () => {
  return { type: AT.ADMIN_RESET_PASSWORD_FAILURE };
};

export const adminResetUserPasswordRequest = (username: string,
  newPassword: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(adminResetUserPassword());

    try {
      await usersApi.adminResetUserPassword({
        newPassword,
        username
      });
      dispatch(adminResetUserPasswordSuccess());
      dispatch(push("/admin/users"));
      toast.success("Successfully reset the users password!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      } else {
        toast.warn("This action may have caused further issues. Please contact Gravitywell.");
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(adminResetUserPasswordFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END DELETE USERS ACTIONS -------- //

// ----------- UPDATE USER ACCESS ACTIONS -------- //
export interface IAdminUpdateUserAccess {
  type: AT.ADMIN_UPDATE_USER_ACCESS;
}

export interface IAdminUpdateUserAccessSuccess {
  type: AT.ADMIN_UPDATE_USER_ACCESS_SUCCESS;
}

export interface IAdminUpdateUserAccessFailure {
  type: AT.ADMIN_UPDATE_USER_ACCESS_FAILURE;
}

export const adminUpdateUserAccess: Redux.ActionCreator<IAdminUpdateUserAccess> = () => {
  return { type: AT.ADMIN_UPDATE_USER_ACCESS };
};

export const adminUpdateUserAccessSuccess: Redux.ActionCreator<IAdminUpdateUserAccessSuccess> = () => {
  return { type: AT.ADMIN_UPDATE_USER_ACCESS_SUCCESS };
};

export const adminUpdateUserAccessFailure: Redux.ActionCreator<IAdminUpdateUserAccessFailure> = () => {
  return { type: AT.ADMIN_UPDATE_USER_ACCESS_FAILURE };
};

export const adminUpdateUserAccessRequest = (
  userId: number,
  userCameras: IUserCameraInput[], canDownload: boolean
): ThunkResult<void> => {
  return async dispatch => {
    dispatch(adminUpdateUserAccess());

    try {
      await usersApi.adminUpdateUserAccess(
        userId, userCameras, canDownload
      );
      dispatch(adminUpdateUserAccessSuccess());
      dispatch(getUsersRequest());
      toast.success("Successfully updated user cameras!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      } else {
        toast.warn("This action may have caused further issues. Please contact Gravitywell.");
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(adminUpdateUserAccessFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END UPDATE USER ACCESS ACTIONS -------- //

// ----------- GET USER CAMERAS -------- //

export interface IGetUserCameras {
  type: AT.GET_USER_CAMERAS;
}

export interface IGetUserCamerasSuccess {
  type: AT.GET_USER_CAMERAS_SUCCESS;
  userCameras: IUserCameraAssociations[];
}

export interface IGetUserCamerasFailure {
  type: AT.GET_USER_CAMERAS_FAILURE;
}

export const getUserCameras: Redux.ActionCreator<IGetUserCameras> = () => {
  return { type: AT.GET_USER_CAMERAS };
};

export const getUserCamerasSuccess: Redux.ActionCreator<IGetUserCamerasSuccess> = (userCameras: IUserCameraAssociations[]) => {
  return {
    type: AT.GET_USER_CAMERAS_SUCCESS,
    userCameras
  };
};

export const getUserCamerasFailure: Redux.ActionCreator<IGetUserCamerasFailure> = () => {
  return { type: AT.GET_USER_CAMERAS_FAILURE };
};

export const getUserCamerasRequest = (userId: number,
  redirect?: boolean): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getUserCameras());

    let userCameras;

    try {
      userCameras = await usersApi.getUserCameras(userId);
    } catch (err) {
      dispatch(getUserCamerasFailure());

      if (err.response && err.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      } else {
        dispatch(push("/error", err));
      }

      return;
    }

    if (!userCameras || userCameras.length === 0) {
      // No access to any cameras!
      dispatch(getUserCamerasFailure());

      dispatch(push("/error", {
        code: "404",
        linkText: "Try again",
        message:
            "This user currently has no cameras set up. Please try again later!"
      }));

      return;
    }

    dispatch(getUserCamerasSuccess(userCameras));

    let sites: Array<{ id: number; name: string }> = [];
    let customers: Array<{ id: number; name: string }> = [];

    _.map(userCameras, camera => {
      sites.push(camera.site);
      customers.push(camera.site.customer);
    });
    sites = _.uniqBy(sites, "id");
    customers = _.uniqBy(customers, "id");
    dispatch(getSitesSuccess(sites));
    dispatch(getCameraLocationsSuccess(userCameras));

    if (redirect) {
      // Check local storage for the last viewed camera
      const lastViewedCameraItem = window.localStorage.getItem("@interval-films-last-camera");

      // If we have one, redirect there - otherwise, fall back to the first camera the user has access to
      if (lastViewedCameraItem) {
        const {
          customerId,
          siteId,
          cameraLocationUUID 
        } = JSON.parse(lastViewedCameraItem);

        dispatch(push(`/customer/${customerId}/site/${siteId}/camera/${cameraLocationUUID}/images`));
      } else {
        dispatch(push(`/customer/${userCameras[0].site.customer.id}/site/${userCameras[0].site.id}/camera/${userCameras[0].uuid}/images`));
      }
    }
  };
};
// ----------- END GET USER CAMERAS -------- //
