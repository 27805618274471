import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import Text from "../text";
import { ISite } from "src/store/sites/sites.api";
import { Column, Row } from "hedron";
import { Button } from "../";
import { History } from "history";
import { bytesToSize } from "src/utils/bytes-to-size";
import * as _ from "lodash";

interface ISiteLitsItemProps {
  kind?: string;
  site: ISite;
  history: History;
}

const menuColor = theme.variants(
  "mode", "kind", { default: { normal: styles.primaryDarkColor } }
);

const SiteLitsItemWrapper: any = styled.div`
  background: ${menuColor};
  padding: ${styles.paddingUnit * 2}px;
`;

const SiteLitsItem: React.SFC<ISiteLitsItemProps> = ({
  kind,
  site,
  history
}) => {
  const folderSize = React.useMemo(() => {
    if (!site.latest_metrics || !site.latest_metrics.length) {
      return null;
    }

    const combinedMetric = _.reduce(
      site.latest_metrics,
      (acc, value) => {
        acc += value.size;

        return acc;
      },
      0
    );

    return bytesToSize(combinedMetric);
  }, [site]);

  return (
    <SiteLitsItemWrapper kind={kind}>
      <Row justifyContent="space-between">
        <Column xs={12}>
          <Text fontSize="h3">{site.name}</Text>
          {folderSize &&
          <Text type="faded" fontSize="h3">{` (${folderSize})`}</Text>
          }
        </Column>
        <Column xs={12}>
          <Button
            text="Manage"
            clickHandler={() =>
              history.push(`/admin/customer/${site.customer_id}/site/${site.id}`)
            }
          />
        </Column>
      </Row>
    </SiteLitsItemWrapper>
  );
};

SiteLitsItemWrapper.defaultProps = { kind: "default" };

export default SiteLitsItem;
