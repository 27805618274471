import { IStore } from "src/types/store/store";
import { ICustomer } from "src/store/customers/customers.api";
import _ from "lodash";

export const customerGetById = (state: IStore,
  customerId: string | number): ICustomer | null => {
  if (customerId) {
    customerId = typeof customerId === "number" ? customerId : parseInt(customerId, 10);

    const customer = _.find(state.customers.customers,
      c => c.id === customerId);

    return customer ? customer : null;
  } else {
    return null;
  }
};
