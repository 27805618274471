import React from "react";
import { ITransform, IDimensions } from "./image-view-panel-base";

interface TimelapseImageProps {
  compare: boolean;
  transform: ITransform;
  maxDimensions: IDimensions;
  getImageUrl: (compare: boolean) => string | undefined;
}

export const TimelapseImage: React.FC<TimelapseImageProps> = props => {
  const { getImageUrl, compare } = props;
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined);
  
  React.useEffect(() => {
    const url = getImageUrl(compare);

    setImageUrl(url);
  }, [getImageUrl, compare]);

  return (
    <img
      alt="Timelapse Image"
      width={props.maxDimensions.width}
      id={compare ? "compare-view-panel" : "image-view-panel"}
      height={props.maxDimensions.height}
      src={imageUrl}
      style={
        compare ?
          {
            cursor: "pointer",
            height: props.maxDimensions.height,
            left: props.transform.posLeft + "px",
            position: "absolute",
            top: props.transform.posTop + "px",
            transform: `translate(${props.transform.left}px, ${
              props.transform.top
            }px) scale(${props.transform.scale})`,
            transformOrigin: "0 0",
            width: props.maxDimensions.width
          } : {
            position: "absolute",
            top: 0,
            left: 0
          }}
    />
  );
};
