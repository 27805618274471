/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import {
  Text, Button, Dropdown, TextInput
} from "../../components";
import { ICustomer } from "src/store/customers/customers.api";
import { getCustomersRequest } from "src/store/customers/customers.actions";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import { IUser } from "../../types/store/users";
import { styles } from "../../lib/styles";
import { CustomerTable } from "src/components/table/CustomerTable";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import { TableActionButton } from "src/components/table/TableActionButton";
import { Styled } from "src/components/camera-location-list/camera-location-list";
import { useEffect, useState } from "react";
import { PaginatedParams, SortType } from "src/types/pagination";
import { useSessionStorage } from "usehooks-ts";
import styled from "styled-components";
import { CustomerTableLogo } from "src/components/logo/CustomerTableLogo";
import { bytesToSize } from "src/utils/bytes-to-size";
import _ from "lodash";

interface ICustomersRouterProps {
  test: string; // This one is coming from the router
}

const customerStatuses = [
  {
    data: "active",
    text: "active"
  },
  {
    data: "suspended",
    text: "suspended"
  }
];

type customerStatus = {
  data: string;
  text: string;
};

interface ICustomersProps extends RouteComponentProps<ICustomersRouterProps> {
  title: string;
}

interface IStateProps {
  customers: ICustomer[];
  currentUser: IUser | null;
  customerCount: number;
  loadingCustomers: boolean;
}

interface ICustomersDispatchProps {
  getCustomers: (
    pagination: PaginatedParams<ICustomer>,
    filters: Partial<ICustomer>
  ) => void;
}

export const CustomersPage: React.FC<ICustomersProps & IStateProps & ICustomersDispatchProps> = ({
  getCustomers, customers, history, customerCount, loadingCustomers
}) => {
  const [customerStatus, setCustomerStatus] = useState("active");
  const [sortOrder, setSortOrder] = useState<SortType<ICustomer>>([["createdAt", "DESC"]]);

  const [pagination, setPagination] = useSessionStorage<PaginationState>("CustomerPaginationState", {
    pageSize: 100,
    pageIndex: 0
  });

  const [filters, setFilters] = useState<Partial<ICustomer>>({
    status: "active",
    search_terms: ""
  });

  useEffect(() => {
    onFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    pagination,
    sortOrder
  ]);

  const onFetchData = () => {
    getCustomers({
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      sort: sortOrder,
      page: 1
    }, filters);
  };

  /** Submit the form on enter */
  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onFetchData();
    }
  };

  const handleSelect = (data: string) => {
    setFilters({
      ...filters,
      status: data
    });
    setCustomerStatus(data);
  };

  const columns:Array<ColumnDef<ICustomer>> = [
    {
      header: "Customer",
      id: "Customer",
      accessorFn: item => item.logo ? item.logo : item.name,
      size: 60,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {item.row.original.name}
          </div>
        );
      }
    },
    {
      header: "Logo",
      id: "Logo",
      accessorFn: item => item.logo ? item.logo : item.name,
      enableSorting: false,
      size: 60,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {item.row.original.logo ? <CustomerTableLogo logo={item.row.original.logo}/> : "No logo"}
          </div>
        );
      }
    },
    {
      header: "Sites",
      id: "Sites",
      accessorFn: item => item.sites,
      size: 30,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {item.row.original.sites_count}
          </div>
        );
      }
    },
    {
      header: "Cameras",
      id: "Cameras",
      accessorFn: item => item.name,
      size: 30,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            { item.row.original.camera_locations_count}
          </div>
        );
      }
    },
    {
      header: "Users",
      id: "Users",
      accessorFn: item => item.name,
      size: 30,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {item.row.original.user_count}
          </div>
        );
      }
    },
    {
      header: "Total Data Size",
      id: "Total Data Size",
      accessorFn: item => item.name,
      size: 30,
      enableSorting: false,
      cell: item => {
        const combinedMetric = _.reduce(
          item.row.original.latest_metrics,
          (acc, value) => {
            acc += value.size;
    
            return acc;
          },
          0
        );
    
        const dataSize = bytesToSize(combinedMetric);

        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {dataSize}
          </div>
        );
      }
    },
    {
      header: "Date Created",
      id: "Date Created",
      accessorFn: item => item.createdAt,
      size: 30,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {new Date(item.row.original.createdAt).toLocaleString()}

          </div>
        );
      }
    },
    {
      header: "Actions",
      id: "Actions",
      accessorFn: item => item.id,
      size: 100,
      enableSorting: false,
      enableResizing: false,
      cell: item => {
        const customerId = item.row.original.id;

        return (
          <Styled.ActionContainer>
            <TableActionButton
              text="Manage"
              newTabPath={`/admin/customer/${customerId}`}
              onClick={() =>
                history.push(`/admin/customer/${customerId}`)
              }
            />
          </Styled.ActionContainer>
        );
      }
    }
  ];

  return (
    <div style={{ paddingBottom: "60px" }}>
      <CStyled.MainContainer>
        <CStyled.HeaderContainer>
          <Text fontSize="h2">Customers</Text>
          <CStyled.AddCustContainer>
            <Button
              clickHandler={() => history.push("/admin/new-customer")}
              text="Add Customer"
            />
          </CStyled.AddCustContainer>
        </CStyled.HeaderContainer>
        <CStyled.HeaderContainer>
          <CStyled.FilterContainer>
            <label>Showing only</label>
            <Dropdown
              style={{
                width: "auto",
                maxWidth: "fit-content"
              }}
              items={customerStatuses}
              direction="down"
              selected={customerStatuses.findIndex(status => customerStatus === status.data)}
              isOverlayActive
              onSelect={e => handleSelect(e as customerStatus["data"])}
            />
            <label>Customers</label>
          </CStyled.FilterContainer>
          <CStyled.SearchContainer>
            <CStyled.SearchInput
              placeholder="Search customers"
              onChange={e => setFilters({
                ...filters,
                search_terms: e.target.value
              })}
              onKeyPress={e => handleKeypress(e)}
              value={filters.name}
            />
            <Styled.CTAButton onClick={() => onFetchData()}>
            Search
            </Styled.CTAButton>
          </CStyled.SearchContainer>
        </CStyled.HeaderContainer>
        <CustomerTable
          title="Customers"
          columns={columns}
          data={customers}
          loading={loadingCustomers}
          pagination={pagination}
          totalDataCount={customerCount}
          refetchData={onFetchData}
          setPagination={setPagination}
          setSortOrder={setSortOrder}
        />
      </CStyled.MainContainer>
    </div>
  );
};

const CStyled = {
  MainContainer: styled.div`
    backgroundColor: ${styles.primaryDarkColor};
    borderRadius: "15px";
    paddingBottom: "60px";
    padding: "2rem";
  `,

  HeaderContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;  
  `,

  FilterContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    @media screen and (max-width: ${styles.mobileBreakPoint}) {
      width: 50%;
    }
  `,

  SearchContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 250px;
    min-width: 250px;

    @media screen and (max-width: ${styles.mobileBreakPoint}) {
      margin-top: 20px;
      width: 100%;
      margin-left: auto;
      padding-left: 10px;
    }
  `,

  AddCustContainer: styled.div`
    padding-right: 10px;

    @media screen and (max-width: ${styles.mobileBreakPoint}) {
      text-align: right;
    }
  `,
  SearchInput: styled(TextInput)`
    width: 100%;
    flex: 1;
    ` 
};

const mapStateToProps = (state: IStore): IStateProps => {
  return {
    currentUser: state.users.currentUser,
    customers: state.customers.customers,
    customerCount: state.customers.customerCount,
    loadingCustomers: state.customers.gettingCustomer
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): ICustomersDispatchProps => {
  return {
    getCustomers: (pagination: PaginatedParams<ICustomer>,
      filters: Partial<ICustomer>) => dispatch(getCustomersRequest(pagination, filters))
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(CustomersPage);
