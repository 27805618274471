import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import {
  UserListItem, Text, Button
} from "../../components";
import { Column, Row } from "hedron";
import { IUser } from "../../types/store/users";
import { getUsersRequest } from "../../store/users/users.actions";
import MobileSpacer from "src/components/MobileSpacer";

interface IUserProps extends RouteComponentProps {
  title: string;
}

interface IStateProps {
  users: IUser[];
}

interface IUserDispatchProps {
  getUsersRequest: () => void;
}

type Props = IUserProps & IStateProps & IUserDispatchProps;

class Users extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    this.props.getUsersRequest();
  }

  public render() {
    return (
      <div style={{ paddingBottom:"100px" }}>
        <Row alignContent="space-between">
          <Column xs={9}>
            <Text fontSize="h1">Users</Text>
          </Column>
          <Column xs={3}>
            <Button
              align="right"
              clickHandler={() => this.props.history.push("/admin/new-user")}
              text="Add User"
            />
          </Column>
        </Row>
        <Row />
        {this.renderUsers()}
        <MobileSpacer/>
      </div>
    );
  }

  private renderUsers() {
    const { users, history } = this.props;

    if (!users) {
      return [];
    }

    return users.map((user, index) => (
      <Row key={`user-${index}`}>
        <Column>
          <UserListItem history={history} user={user} />
        </Column>
      </Row>
    ));
  }
}

const mapStateToProps = (state: IStore): IStateProps => {
  return { users: state.users.users };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IUserDispatchProps => {
  return { getUsersRequest: () => dispatch(getUsersRequest()) };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(Users);
