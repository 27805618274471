import * as React from "react";
import * as Redux from "redux";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import AdminMenuItem from "../admin-menu-item";
import Logo from "../../assets/images/logo.jpg";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { signOutRequest } from "src/store/auth/auth.actions";
import { IStore } from "src/types/store/store";

interface IAdminMenuProps {
  kind?: string;
}

interface IDispatchProps {
  signOut: () => void;
}

const menuColor = theme.variants(
  "mode", "kind", { default: { normal: styles.primaryDarkColor } }
);

const AdminMenuWrapper = styled.div<IAdminMenuProps>`
  background: ${menuColor};
  height: 100vh;
  width: 100%;
  background: #101C2E;
  display: flex;
  flex-direction: column;
`;

const AdminMenuLogoWrapper = styled.div`
@media screen and (max-width: ${styles.mobileBreakPoint}) {
   
  display: none;
  
}
`;

const AdminMenu: React.FC<IAdminMenuProps & IDispatchProps> = ({ kind, signOut }) => {
  const active = React.useMemo(() => {
    if (window.location.pathname.includes("camera")) return "cameras";

    if (window.location.pathname.includes("user")) return "users";

    if (window.location.pathname.includes("deletion-queue")) return "deletion-queue";

    if (window.location.pathname.includes("customer")) return "customers";

    return undefined;
  }, []);

  return (
    <AdminMenuWrapper kind={kind}>
      <AdminMenuLogoWrapper>
        <img src={Logo} alt="logo" style={{
          width: "80%",
          height: "auto",
          padding: "20px 0px 20px 20px"
        }}/>
      </AdminMenuLogoWrapper>
      <AdminMenuItem
        kind={active === "customers" ? "active" : "default"}
        to="/admin/customers"
        text="Customers"
      />
      <AdminMenuItem
        kind={active === "cameras" ? "active" : "default"}
        to="/admin/cameras"
        text="Cameras"
      />
      <AdminMenuItem
        kind={active === "users" ? "active" : "default"}
        to="/admin/users"
        text="Users"
      />
      <AdminMenuItem
        kind={active === "deletion-queue" ? "active" : "default"}
        to="/admin/deletion-queue"
        text="Deletion Queue"/>
      <AdminMenuItem text="Logout" onClick={() => signOut()} />
    </AdminMenuWrapper>
  );
};

AdminMenuWrapper.defaultProps = { kind: "default" };

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return { signOut: () => dispatch(signOutRequest(true)) };
};

export default connect(() => {
  return {};
}, mapDispatchToProps)(AdminMenu);
