import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { styles } from "../../lib/styles";

const BaseStyles = createGlobalStyle`
  :root, body, #root {
    color-scheme: dark; /*dark mode scroller*/
  }
  
  body {
    overflow: hidden;
    font-family: "Roboto";
    font-weight: 300;
    width: 100%;
    margin: 0;
    background: ${styles.primaryDarkColor};
    color: ${styles.textColor};
    overscroll-behavior: none;

    &.react-draggable-transparent-selection{
      overflow: hidden;
      position: fixed;
    }
    @media (min-width: 768px){
      position: fixed;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
  ${reset}
  thead, tbody, tfoot { vertical-align: middle }
  td, th, tr { vertical-align: inherit }
  }
`;

export default BaseStyles;
