import * as Redux from "redux";
import * as AT from "./images.constants";
import { IImageStore } from "../../types/store/images";
import { ImageActions } from "./images.actions";
import _ from "lodash";

const initialState: IImageStore = {
  compareImages: [],
  dates: [],
  gettingDates: false,
  gettingImages: false,
  gettingUnmigrated: false,
  images: [],
  latestImageDate: null,
  migratingImages: false,
  unmigrated: null,
  updatingImage: false
};

export const images: Redux.Reducer<IImageStore> = (state: IImageStore = initialState,
  action: ImageActions): IImageStore => {
  switch (action.type) {
    case AT.CHECK_NEW_IMAGES_SUCCESS:
      return {
        ...state,
        latestImageDate: action.latestImageDate
      };
    case AT.GET_IMAGES_BY_DATE:
      return {
        ...state,
        gettingImages: true
      };
    case AT.GET_IMAGES_BY_DATE_SUCCESS:
      if (action.compare) {
        return {
          ...state,
          compareImages: action.images,
          gettingImages: false
        };
      }

      return {
        ...state,
        gettingImages: false,
        images: action.images
      };
    case AT.GET_IMAGES_BY_DATE_FAILURE:
      return {
        ...state,
        gettingImages: false
      };
    case AT.GET_AVAILABLE_DATE:
      return {
        ...state,
        dates: [],
        gettingDates: true,
        images: [],
        latestImageDate: null
      };
    case AT.GET_AVAILABLE_DATE_SUCCESS:
      return {
        ...state,
        dates: action.dates,
        gettingDates: false,
        latestImageDate: action.dates.length ? action.dates[0] : null
      };
    case AT.GET_AVAILABLE_DATE_FAILURE:
      return {
        ...state,
        dates: [],
        gettingDates: false
      };
    case AT.CHECK_UNMIGRATED_IMAGES:
      return {
        ...state,
        gettingUnmigrated: true,
        unmigrated: null
      };
    case AT.CHECK_UNMIGRATED_IMAGES_SUCCESS:
      return {
        ...state,
        gettingUnmigrated: false,
        unmigrated: action.unmigrated
      };
    case AT.CHECK_UNMIGRATED_IMAGES_FAILURE:
      return {
        ...state,
        gettingUnmigrated: false,
        unmigrated: 0
      };
    case AT.MIGRATE_IMAGES:
      return {
        ...state,
        migratingImages: true
      };
    case AT.MIGRATE_IMAGES_SUCCESS:
      return {
        ...state,
        migratingImages: false
      };
    case AT.MIGRATE_IMAGES_FAILURE:
      return {
        ...state,
        migratingImages: false
      };

    // UPDATE IMAGE
    case AT.UPDATE_IMAGE:
      return {
        ...state,
        updatingImage: true
      };
    case AT.UPDATE_IMAGE_SUCCESS: {
      const imagesToUpdate = state.images;

      imagesToUpdate[action.imageIndex].status = action.status;

      return {
        ...state,
        images: imagesToUpdate,
        updatingImage: false
      };
    }
    case AT.UPDATE_IMAGE_FAILURE:
      return {
        ...state,
        updatingImage: false
      };

    case AT.PURGE_IMAGES:
      return initialState;
    default:
      return state;
  }
};
