import React, { useCallback } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import styled from "styled-components";
import { styles } from "../../lib/styles";

import { Storage } from "aws-amplify";
import awsmobile from "src/aws-exports";
import { Icon, Loading } from "..";
import { S3Image } from "src/components/s3-image/s3-image";
import Button from "../button";
import * as _ from "lodash";
import Text from "../text/text";
import moment from "moment";

interface ImageUploaderProps {
  allowedFiles: string[];
  label?: string;
  defaultValue?: string;
  uploadPath: string;
}

export interface S3DropzoneRef {
  file: string | null;
}

export const S3Dropzone = React.forwardRef<S3DropzoneRef, ImageUploaderProps>(({
  allowedFiles, label, uploadPath, defaultValue
}, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentFile, setCurrentFile] = React.useState<string | null>(defaultValue || null);
  const [uploadError, setUploadError] = React.useState<string | null>(null);

  const uploadToS3 = React.useCallback(async (file: File) => {
    let result: any;
    const path = `${uploadPath}/${moment.utc().toISOString()}-${file.name}`;

    try {
      result = await Storage.put(
        path, file, { level: "public" }
      );
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
    const s3BucketPath = `https://${awsmobile.Storage.bucket}.s3.${awsmobile.Storage.region}.amazonaws.com/public/`;

    setCurrentFile(`${s3BucketPath}${result.key}`);
  }, [uploadPath]);

  const onDrop = useCallback(acceptedFiles => {
    setIsLoading(true);

    if (acceptedFiles[0]) {
      uploadToS3(acceptedFiles[0]);
    }
  }, [uploadToS3]);

  const onDropRejected = useCallback((rejected: FileRejection[]) => {
    setIsLoading(false);

    if (rejected[0] && rejected[0]?.errors[0]) {
      setUploadError(rejected[0].errors[0]?.message || null);
    }
  }, []);

  const {
    getRootProps, getInputProps, isDragActive, isDragReject
  } = useDropzone({
    onDrop,
    accept: allowedFiles,
    onDropRejected
  });

  React.useImperativeHandle(
    ref, () => ({ file: currentFile }), [currentFile]
  );

  const isImage = React.useMemo(() => {
    return _.every(allowedFiles, fileType => fileType.includes("image"));
  }, [allowedFiles]);

  const isVideo = React.useMemo(() => {
    return _.every(allowedFiles, fileType => fileType.includes("video"));
  }, [allowedFiles]);

  const fileName = React.useMemo(() => {
    if (currentFile) {
      const fileSplit = currentFile.split("/");

      return _.last(fileSplit);
    }

    return null;
  }, [currentFile]);

  return (
    <>
      <StyledDropZone {...getRootProps()} className={isDragReject ? "reject" : "accept"}>
        {isLoading ? <Loading className="loading-spinner" /> :
          <>
            {currentFile ?
              <>
                {isImage ?
                  <S3Image className="current-file" s3Url={currentFile}/> :
                  isVideo ?
                    <div className="icon-and-label">
                      <Icon icon="video" size={35}/>

                      <Text className="file-link">{fileName || ""}</Text>
                    </div> :
                    <Text className="file-link">File Uploaded!</Text>

                }
              </>
              :
              <>
                <input {...getInputProps()} />
                {(isDragActive && !isDragReject) && <p>Drop file here ...</p>}
                {!isDragActive && <p>{label || "Drag and drop file here, or click to select from the file browser."}</p>}
              </>
            }

          </>}
      </StyledDropZone>
      {!!currentFile &&
        <Button text="remove" clickHandler={() => setCurrentFile(null)} type="button"/>
      }
      {uploadError && <p>{uploadError}</p>}
      {isDragReject && <p>File type not accepted</p>}
    </>

  );
});

const StyledDropZone = styled.div`
  cursor: pointer;
  position: relative;
  &:hover {
    opacity: 1;
  }
  transition: opacity 0.2s ease-in-out;
  border-radius: 6px;
  border: 1px solid transparent;
  &.reject {
    border: 1px solid ${styles.secondaryAccentColor};
  }
  margin: 0.5rem 0;
  background-color: ${styles.fadedTextColor};
  opacity: 0.9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  .current-file {
    max-height: 200px;
    max-width: 400px;
  }

  .loading-spinner {
    top: 50%;
    transform: translateY(-50%);
  }

  .icon-and-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;