import * as AT from "./images.constants";
import { IStore } from "../../types/store/store";
import * as Redux from "redux";
import { ThunkAction } from "redux-thunk";
import { RouterAction, push } from "react-router-redux";
import { toast } from "react-toastify";
import apiError from "src/lib/apiError";
import { IImage } from "../../types/store/images";

import * as imagesApi from "./images.api";
import moment from "moment";

export type ImageActions =
  | IPurgeImages
  | IGetImagesByDate
  | IGetImagesByDateSuccess
  | IGetImagesByDateFailure
  | IGetAvailableDates
  | IGetAvailableDatesSuccess
  | IGetAvailableDatesFailure
  | ICheckUnmigrated
  | ICheckUnmigratedSuccess
  | ICheckUnmigratedFailure
  | IMigrateImages
  | IMigrateImagesSuccess
  | IMigrateImagesFailure
  | ICheckForNewImages
  | ICheckForNewImagesSuccess
  | ICheckForNewImagesFailure
  | IUpdateImage
  | IUpdateImageSuccess
  | IUpdateImageFailure;

type ThunkResult<R> = ThunkAction<
  R,
  IStore,
  undefined,
  ImageActions | RouterAction
>;

export interface IPurgeImages {
  type: AT.PURGE_IMAGES;
}

export const purgeImages: Redux.ActionCreator<IPurgeImages> = () => {
  return { type: AT.PURGE_IMAGES };
};

// ----------- GET IMAGES ACTIONS -------- //
export interface IGetImagesByDate {
  type: AT.GET_IMAGES_BY_DATE;
  date: moment.Moment;
}

export interface IGetImagesByDateSuccess {
  compare: boolean;
  images: IImage[];
  type: AT.GET_IMAGES_BY_DATE_SUCCESS;
}

export interface IGetImagesByDateFailure {
  type: AT.GET_IMAGES_BY_DATE_FAILURE;
}

export const getImagesByDate: Redux.ActionCreator<IGetImagesByDate> = (date: moment.Moment) => {
  return {
    date,
    type: AT.GET_IMAGES_BY_DATE
  };
};

export const getImagesByDateSuccess: Redux.ActionCreator<
  IGetImagesByDateSuccess
> = (images: IImage[], compare: boolean) => {
  return {
    compare,
    images,
    type: AT.GET_IMAGES_BY_DATE_SUCCESS
  };
};

export const getImagesByDateFailure: Redux.ActionCreator<
  IGetImagesByDateFailure
> = () => {
  return { type: AT.GET_IMAGES_BY_DATE_FAILURE };
};

export const getImagesByDateRequest = (
  startDate: string,
  endDate: string,
  jobRef: string,
  status: string,
  role = "client",
  compare = false
): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getImagesByDate(startDate));

    try {
      const images = await imagesApi.getImages(
        startDate,
        endDate,
        jobRef,
        status,
        role
      );

      dispatch(getImagesByDateSuccess(images, compare));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getImagesByDateFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END GET IMAGES ACTIONS -------- //

// ----------- GET IMAGES DATES -------- //
export interface IGetAvailableDates {
  type: AT.GET_AVAILABLE_DATE;
}

export interface IGetAvailableDatesSuccess {
  dates: string[];
  type: AT.GET_AVAILABLE_DATE_SUCCESS;
}

export interface IGetAvailableDatesFailure {
  type: AT.GET_AVAILABLE_DATE_FAILURE;
}

export const getAvailableDates: Redux.ActionCreator<IGetAvailableDates> = (cameraLocationId: string) => {
  return {
    cameraLocationId,
    type: AT.GET_AVAILABLE_DATE
  };
};

export const getAvailableDatesSuccess: Redux.ActionCreator<
  IGetAvailableDatesSuccess
> = (dates: string[]) => {
  return {
    dates,
    type: AT.GET_AVAILABLE_DATE_SUCCESS
  };
};

export const getAvailableDatesFailure: Redux.ActionCreator<
  IGetAvailableDatesFailure
> = () => {
  return { type: AT.GET_AVAILABLE_DATE_FAILURE };
};

export const getAvailableDatesRequest = (cameraLocationUUID: string,
  role = "client"): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getAvailableDates(cameraLocationUUID));

    try {
      const dates = await imagesApi.getAvailableDates(cameraLocationUUID, role);

      dispatch(getAvailableDatesSuccess(dates));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getAvailableDatesFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END GET IMAGES DATES -------- //

// ----------- GET UNMIGRATED IMAGES -------- //
export interface ICheckUnmigrated {
  type: AT.CHECK_UNMIGRATED_IMAGES;
}

export interface ICheckUnmigratedSuccess {
  unmigrated: number;
  type: AT.CHECK_UNMIGRATED_IMAGES_SUCCESS;
}

export interface ICheckUnmigratedFailure {
  type: AT.CHECK_UNMIGRATED_IMAGES_FAILURE;
}

export const checkUnMigratedImages: Redux.ActionCreator<
  ICheckUnmigrated
> = () => {
  return { type: AT.CHECK_UNMIGRATED_IMAGES };
};

export const checkUnMigratedImagesSuccess: Redux.ActionCreator<
  ICheckUnmigratedSuccess
> = (unmigrated: number) => {
  return {
    type: AT.CHECK_UNMIGRATED_IMAGES_SUCCESS,
    unmigrated
  };
};

export const checkUnMigratedImagesFailure: Redux.ActionCreator<
  ICheckUnmigratedFailure
> = () => {
  return { type: AT.CHECK_UNMIGRATED_IMAGES_FAILURE };
};

export const checkUnMigratedImagesRequest = (jobRef: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(checkUnMigratedImages());

    try {
      const unMigratedImages = await imagesApi.checkUnMigratedImages(jobRef);

      dispatch(checkUnMigratedImagesSuccess(unMigratedImages));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(checkUnMigratedImagesFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END GET IMAGES DATES -------- //

// ----------- MIGRATE IMAGES -------- //
export interface IMigrateImages {
  type: AT.MIGRATE_IMAGES;
}

export interface IMigrateImagesSuccess {
  unmigrated: number;
  type: AT.MIGRATE_IMAGES_SUCCESS;
}

export interface IMigrateImagesFailure {
  type: AT.MIGRATE_IMAGES_FAILURE;
}

export const migrateImages: Redux.ActionCreator<IMigrateImages> = () => {
  return { type: AT.MIGRATE_IMAGES };
};

export const migrateImagesSuccess: Redux.ActionCreator<
  IMigrateImagesSuccess
> = (unmigrated: number) => {
  return {
    type: AT.MIGRATE_IMAGES_SUCCESS,
    unmigrated
  };
};

export const migrateImagesFailure: Redux.ActionCreator<
  IMigrateImagesFailure
> = () => {
  return { type: AT.MIGRATE_IMAGES_FAILURE };
};

export const migrateImagesRequest = (jobRef: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(migrateImages());

    try {
      const result = await imagesApi.migrateImagesRequest(jobRef);

      dispatch(migrateImagesSuccess(result));
      dispatch(checkUnMigratedImagesRequest(jobRef));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(migrateImagesFailure());
      toast.warn("Processing images. This may take a while, try pressing the migrate images button again in 10 minutes.");
    }
  };
};
//
// // ----------- UPDATE IMAGES ACTIONS -------- //
export interface IUpdateImage {
  type: AT.UPDATE_IMAGE;
}

export interface IUpdateImageSuccess {
  status: string | undefined;
  imageIndex: number;
  type: AT.UPDATE_IMAGE_SUCCESS;
}

export interface IUpdateImageFailure {
  type: AT.UPDATE_IMAGE_FAILURE;
}

export const updateImage: Redux.ActionCreator<IUpdateImage> = () => {
  return { type: AT.UPDATE_IMAGE };
};

export const updateImageSuccess: Redux.ActionCreator<IUpdateImageSuccess> = (status: string | undefined,
  imageIndex: number) => {
  return {
    imageIndex,
    status,
    type: AT.UPDATE_IMAGE_SUCCESS
  };
};

export const updateImageFailure: Redux.ActionCreator<
  IUpdateImageFailure
> = () => {
  return { type: AT.UPDATE_IMAGE_FAILURE };
};

export const updateImageRequest = (
  imageIndex: number,
  imageId: number,
  image: IImage
): ThunkResult<void> => {
  return async dispatch => {
    dispatch(updateImage());

    try {
      delete image.id;
      await imagesApi.updateImage(imageId, image);
      dispatch(updateImageSuccess(image.status, imageIndex));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(updateImageFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END UPDATE IMAGES ACTIONS -------- //

// // ----------- CHECK FOR NEW IMAGES ACTIONS -------- //
export interface ICheckForNewImages {
  type: AT.CHECK_NEW_IMAGES;
}

export interface ICheckForNewImagesSuccess {
  latestImageDate: string;
  type: AT.CHECK_NEW_IMAGES_SUCCESS;
}

export interface ICheckForNewImagesFailure {
  type: AT.CHECK_NEW_IMAGES_FAILURE;
}

export const checkNewImage: Redux.ActionCreator<ICheckForNewImages> = () => {
  return { type: AT.CHECK_NEW_IMAGES };
};

export const checkNewImageSuccess: Redux.ActionCreator<
  ICheckForNewImagesSuccess
> = (latestImageDate: string) => {
  return {
    latestImageDate,
    type: AT.CHECK_NEW_IMAGES_SUCCESS
  };
};

export const checkNewImageFailure: Redux.ActionCreator<
  ICheckForNewImagesFailure
> = () => {
  return { type: AT.CHECK_NEW_IMAGES_FAILURE };
};

export const checkNewImageRequest = (jobRef: string,
  role = "client"): ThunkResult<void> => {
  return async dispatch => {
    dispatch(checkNewImage());

    try {
      const latest = await imagesApi.checkNewImage(jobRef, role);

      dispatch(checkNewImageSuccess(latest.taken_at));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(checkNewImageFailure());
    }
  };
};

// ----------- END CHECK FOR NEW IMAGES ACTIONS -------- //
