// @ts-ignore
import themedStyling from "styled-theming";

export const styles = {
  // colors
  fadedTextColor: "rgba(175, 187, 204, 0.5)",
  primaryAccentColor: "#52b3ae",
  primaryDarkColor: "#091119",
  secondaryTextColor: "#afbbcc",
  textColor: "#afbbcc",
  lightGrey: "#e2e1ee",
  white: "#ffffff",
  lightBlue: "#b6dfe9",
  primaryOverlayColour: "rgba(16,28,46, 0.8)",
  primaryTextColor: "#ffffff",
  secondaryAccentColor: "#f26d6d",
  secondaryDarkColor: "#101c2e",
  formBackground: "#192638",
  // font weights
  primaryFontWeight: "300",
  secondaryFontWeight: "400",
  header: "90px",
  // everything else
  mobileBreakPoint: "800px",
  paddingUnit: 10,
  // status colours
  healthyColour: "#52b3ae",
  warningColour: "#E5BB69",
  dangerColour: "#f26d6d"
};

export type ColorName = keyof Pick<typeof styles, 
  "fadedTextColor" |
  "primaryAccentColor" |
  "primaryDarkColor" |
  "secondaryTextColor" |
  "textColor" |
  "lightGrey" |
  "white" |
  "primaryOverlayColour" |
  "primaryTextColor" |
  "secondaryAccentColor" |
  "secondaryDarkColor" |
  "formBackground"
>;

export const theme = themedStyling;
