import * as React from "react";

import { getLiveImageUrl } from "../../store/images/images.api";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Icon } from "src/components";
import { toast } from "react-toastify";
import { getCameraLocationByUUID, ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { cameraLocationGetByUUID } from "src/store/camera-locations/camera-locations.getters";
import { ICustomer } from "src/store/customers/customers.api";
import { ISite } from "src/store/sites/sites.api";
import { siteGetById } from "src/store/sites/sites.getters";
import { IAuthStore } from "src/types/store/auth";
import { IUser, IUserCameraAssociations } from "src/types/store/users";
import { IVideo } from "src/types/store/videos";

interface ILiveImageRouterProps {
  customerId: string;
  siteId: string;
  cameraLocationUUID: string;
}

type ILiveImageProps = RouteComponentProps<ILiveImageRouterProps>;

interface IStoreProps {
  user: IUser | null;
  auth: IAuthStore;
  cameraLocation: ICameraLocation | null;
  cameraLocations: ICameraLocation[];
  customer: ICustomer | null;
  site: ISite | null;
  sites: ISite[] | null;
  videos: IVideo[];
  videosTotal: number;
  userCameras: IUserCameraAssociations[];
}

export const LiveImage: React.FC<ILiveImageProps & IStoreProps> = ({
  match, history, location
}) => {
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [cameraLocation, setCameraLocation] = React.useState<ICameraLocation>();
  const [fetchingImageUrl, setFetchingImageUrl] = React.useState(false);
  const [iconActive, setIconActive] = React.useState(false);

  const requestType = React.useMemo(() => {
    let requestType: "client" | "admin" | "public" = "public";

    if (location.pathname.match(new RegExp(/admin/))) {
      requestType = "admin";
    } else if (location.pathname.match(new RegExp(/customer/))) {
      requestType = "client";
    }

    return requestType;
  }, [location.pathname]);

  // fecth the latest image url signed by the server for permissions checks
  const getLiveImage = React.useCallback(async () => {
    setFetchingImageUrl(true);

    try {
      const liveImageUrlResponse = await getLiveImageUrl(String(cameraLocation?.id), requestType);

      setImageUrl(liveImageUrlResponse.url);
    } catch (error) {
      toast.error("Could not load live image");

      setTimeout(() => {
        history.goBack();
      }, 1500);
    }

    setFetchingImageUrl(false);
  }, [
cameraLocation?.id,
requestType,
history
  ]);

  // fecth the latest image url signed by the server for permissions checks
  const getCameraLocationDetails = React.useCallback(async (cameraLocationUUID: string) => {
    setFetchingImageUrl(true);

    try {
      if (!cameraLocation) {
        let role = "client";

        if (match.path.includes("/public/")) {
          role = "public";
        }

        const cameraLocation = await getCameraLocationByUUID(cameraLocationUUID, role);

        setCameraLocation(cameraLocation);
      }
    } catch (error) {
      toast.error("Could not load camera details");
    }

    setFetchingImageUrl(false);
  }, [cameraLocation, match.path]);

  React.useEffect(() => {
    let interval = 0;

    if (cameraLocation?.id) {
      getLiveImage();

      interval = setInterval(() => {
        getLiveImage();
      }, 30000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    match,
    getLiveImage,
    cameraLocation?.id
  ]);

  React.useEffect(() => {
    if (match.params.cameraLocationUUID) {
      if (!cameraLocation) {
        getCameraLocationDetails(match.params.cameraLocationUUID);
      } else {
        document.title = cameraLocation.hardware_ref || cameraLocation.job_ref;
      }
    }
  }, [
    cameraLocation,
    getCameraLocationDetails,
    match.params.cameraLocationUUID
  ]);

  // fade in the icon
  const handleMouseMove = React.useCallback(() => {
    if (!iconActive) {
      setIconActive(true);

      setTimeout(() => {
        setIconActive(false);

        return;
      }, 2000);
    }
  }, [iconActive]);

  React.useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);

    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, [handleMouseMove]);

  const backUrl = React.useMemo(() => {
    if (requestType === "admin") {
      return `/admin/customer/${match.params.customerId}/site/${match.params.siteId}`;
    } else if (requestType === "client") {
      return `/customer/${match.params.customerId}/site/${match.params.siteId}/camera/${match.params.cameraLocationUUID}/images`;
    } else {
      return `/public/${match.params.customerId}/site/${match.params.siteId}/camera/${match.params.cameraLocationUUID}/images`;
    }
  }, [
    match.params.cameraLocationUUID,
    match.params.customerId,
    match.params.siteId,
    requestType
  ]);

  return (<PageWrapper>
    <div className={`back-icon ${iconActive && "active"} `} onClick={() => history.push(backUrl)}>
      <Icon icon="chevron-left"/>
    </div>
    {fetchingImageUrl && <p>Loading...</p>}
    {!!imageUrl && <img src={imageUrl} />}
  </PageWrapper>);
};

const PageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;

  img {
    position: absolute;
    display: block;
    max-width: 100vw;
    max-height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .back-icon {
    position: absolute;
    top: 2rem;
    left: 2rem;
    opacity: 0;
    transition: 0.5s ease-in-out opacity;

    &.active {
      opacity: 1;
    }
  }
`;

const mapStateToProps = (state: IStore, props: ILiveImageProps): IStoreProps => {
  const { match } = props;

  return {
    auth: state.auth,
    cameraLocation: cameraLocationGetByUUID(state, match.params.cameraLocationUUID),
    cameraLocations: state.cameraLocations.cameraLocations,
    customer: state.customers.currentCustomer,
    site: siteGetById(state, match.params.siteId),
    sites: state.sites.sites,
    user: state.users.currentUser,
    userCameras: state.users.userCameras,
    videos: state.videos.videos,
    videosTotal: state.videos.total
  };
};

export default connect(mapStateToProps, {})(LiveImage);
