import React from "react";

export default function Spacer(props: {
  className?: React.AllHTMLAttributes<HTMLDivElement>["className"];
  style?: React.AllHTMLAttributes<HTMLDivElement>["style"];
}) {
  return <Spacer.Div {...props} />;
}

const Div = ({ ...props }) => (
  <div {...props} style={{
    flex: "1",
    pointerEvents: "none",
    ...props.style
  }} />
);

Spacer.Div = Div;

const Span = ({ ...props }) => (
  <span {...props} style={{
    flex: "1",
    pointerEvents: "none",
    ...props.style
  }} />
);

Spacer.Span = Span;
