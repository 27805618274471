import {
  Form, Formik, FormikProps 
} from "formik";
import { Column, Row } from "hedron";
import React from "react";
import * as Yup from "yup";
import {
  TextInput,
  Button,
  Text,
  ValidationError
} from "src/components";
import { IUser } from "src/types/store/users";

export interface FormValues {
  phone_number: string;
}

interface IUpdatePhoneNumberFormProps {
  handleSubmit: (values: FormValues) => void;
  adminSettingPassword: boolean;
  currentPhoneNumber: IUser["phone_number"];
}

export const UpdatePhoneNumberForm: React.FC<IUpdatePhoneNumberFormProps> = ({
  handleSubmit, adminSettingPassword, currentPhoneNumber 
}) => {
  return (
    <Formik
      initialValues={{ phone_number: currentPhoneNumber }}
      onSubmit={(values: FormValues) => handleSubmit(values)}
      validationSchema={
        Yup.object().shape({ phone_number: Yup.string().matches(/^\+[1-9]{1}[0-9]{3,14}$/, { message: "Please enter a valid international phone number (e.g. use +44 rather than 0 for UK numbers)" }).nullable() })}
    >
      {(props: FormikProps<FormValues>) => {
        const {
          values, handleChange, handleBlur, errors
        } = props;

        const getButtonText = () => {
          if (currentPhoneNumber && values.phone_number.length) {
            return "Update 2FA";
          } else if (currentPhoneNumber && !values.phone_number.length) {
            return "Disable 2FA";
          } else {
            return "Enable 2FA";
          }
        };

        return (
          <Form>
            <Row>
              <Column md={12} style={{ paddingTop: 0 }}>
                <Text fontSize="small">Provide a valid phone number to enable 2FA for this user</Text>
                <TextInput
                  onChange={handleChange}
                  value={values.phone_number ?? undefined}
                  name="phone_number"
                  placeholder="Phone number"
                  onBlur={handleBlur}
                />
                <ValidationError>{errors.phone_number}</ValidationError>
              </Column>
            </Row>

            <Row>
              <Column>
                <Button
                  disabled={adminSettingPassword || !!errors.phone_number}
                  text={getButtonText()}
                  type="submit"
                />
              </Column>
            </Row>

          </Form>
        );
      }}
    </Formik>
  );
};
