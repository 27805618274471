import React from "react";
import { ITransform } from "../image-view-panel/image-view-panel-base";

const OVER_TRANSFORMATION_TOLERANCE = 0;
const DOUBLE_TAP_THRESHOLD = 200;
const MOUSE_WHEEL_ZOOM_IN_AMOUNT = 1.025; // closer to 1 is slower
const MOUSE_WHEEL_ZOOM_OUT_AMOUNT = 0.975; // closer to 1 is slower

interface Ipoint {
  x: number;
  y: number;
}

interface IClientPosition {
  clientX: number;
  clientY: number;
}

interface Iprops {
  children?: any;
  initialTop: number;
  initialLeft: number;
  initialScale: number | string;
  minScale: number | string;
  maxScale: number;
  zoomButtons: boolean;
  height: number;
  width: number;
  onZoom?: (zoom: number) => void;
  hasZoomed: (zoomed: boolean) => void;
  maxDimensions: { height: number; width: number };
  isOverlayActive?: boolean;
  handleMouseMove: () => void;
  handleToggleCompare: () => void;
  onTransform: (args: ITransform) => void;
}

interface IStateProps {
  initialLeft?: number;
  initialScale?: number | string;
  initialTop?: number;
  left: number;
  scale: number;
  top: number;
  posTop: number;
  posLeft: number;
  autofitScale: number;
}

const rangeBind = (
  lowerBound: number, upperBound: number, value: number
) =>
  Math.min(upperBound, Math.max(lowerBound, value));

const invert = (value: number) => value * -1;

const getRelativePosition = (event: { clientX: number; clientY: number },
  relativeToElement: any) => {
  const rect = relativeToElement.getBoundingClientRect();

  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top
  };
};

const getMidpoint = (pointA: Ipoint, pointB: Ipoint) => ({
  x: (pointA.x + pointB.x) / 2,
  y: (pointA.y + pointB.y) / 2
});

const getDistanceBetweenPoints = (pointA: Ipoint, pointB: Ipoint) =>
  Math.sqrt(Math.pow(pointA.y - pointB.y, 2) + Math.pow(pointA.x - pointB.x, 2));

export default class ImagePinchZoomPan extends React.Component<
  Iprops,
  IStateProps
> {
  public static defaultProps: {
    initialTop: number;
    initialLeft: number;
    initialScale: string | number;
    minScale: string | number;
    maxScale: number;
    zoomButtons: boolean;
    height: string;
  };

  protected lastUnzoomedNegativeSpace: { height: number; width: number };
  protected lastPanPointerPosition: Ipoint | null;
  protected animation: number;
  protected mouseDown: boolean;
  protected image: any;
  protected minScale: number;
  protected container: any;
  protected lastPointerUpTimeStamp: number;
  protected lastPinchLength: number;
  protected lastPinchMidpoint: { x: number; y: number };
  protected posTop: number;
  protected posLeft: number;
  protected hasZoomed: boolean;
  protected maxScale: number;
  protected t: any;
  public scale: number;
  public autofitScale: number;

  constructor(props: Iprops) {
    super(props);

    this.state = {
      autofitScale: 1,
      left: 1,
      posLeft: 0,
      posTop: 0,
      scale: 1,
      top: 1
    };
    this.t = null;
    this.posTop = 0;
    this.posLeft = 0;
    this.hasZoomed = false;
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
    this.handleImageLoad = this.handleImageLoad.bind(this);
    this.calculateAutofitScale = this.calculateAutofitScale.bind(this);
  }

  public render() {
    const childElement = React.Children.only(this.props.children);

    const imageRef = (element: HTMLElement) => {
      this.image = element;
    };

    return (
      <div
        style={{
          height: `${this.props.height}px`,
          overflow: "hidden",
          position: "relative",
          width: `${this.props.width}px`
        }}
      >
        {React.cloneElement(childElement, {
          onDragStart: (event: React.DragEvent) => event.preventDefault(),
          onLoad: this.handleImageLoad,
          onMouseDown: this.handleMouseDown,
          onMouseMove: this.handleMouseMove,
          onMouseUp: this.handleMouseUp,
          onTouchEnd: this.handleTouchEnd,
          onTouchStart: this.handleTouchStart,
          onWheel: this.handleMouseWheel,
          ref: imageRef
        })}
      </div>
    );
  }

  public UNSAFE_componentWillMount() {
    window.addEventListener("mousemove", this.props.handleMouseMove);
  }
  public componentDidMount() {
    this.image.addEventListener(
      "touchmove", this.handleTouchMove, { passive: false }
    );
    // Using the child image's original parent enables flex items, e.g., dimensions not explicitly set
    this.container = this.image.parentNode.parentNode;
    this.ensureConstraints();
    this.transformToProps();
  }

  public componentDidUpdate(prevProps: Iprops) {
    if (this.image.offsetWidth && this.image.offsetHeight) {
      if (this.t) {
        clearTimeout(this.t);
      }

      this.t = setTimeout(() => {
        this.ensureConstraints();

        // reset to new props
        if (
          prevProps.maxDimensions.height !== this.props.maxDimensions.height ||
          prevProps.maxDimensions.width !== this.props.maxDimensions.width
        ) {
          this.transformToProps();
        }
      }, 200);
    }
  }

  public componentWillUnmount() {
    this.props.onTransform({
      left: this.state.left,
      top: this.state.top,
      scale: this.state.scale,
      posTop: this.posTop,
      posLeft: this.posLeft
    });
    this.image.removeEventListener("touchmove", this.handleTouchMove);
    window.removeEventListener("mousemove", this.props.handleMouseMove);
  }

  public transformToProps() {
    let scale: number;

    if (typeof this.props.initialScale === "string") {
      scale = this.calculateAutofitScale();
    } else {
      scale = this.props.initialScale;
    }

    this.applyTransform(
      this.props.initialTop,
      this.props.initialLeft,
      scale,
      0
    );
  }

  public zoomIn(amount: number, midpoint?: { x: number; y: number }) {
    midpoint = midpoint || {
      x: this.container.offsetWidth / 2,
      y: this.container.offsetHeight / 2
    };

    this.zoom(
      this.state.scale && this.state.scale * amount, midpoint, 0
    );
  }

  public zoomOut(amount: number, midpoint?: { x: number; y: number }) {
    midpoint = midpoint || {
      x: this.container.offsetWidth / 2,
      y: this.container.offsetHeight / 2
    };

    this.zoom(
      this.state.scale && this.state.scale * amount, midpoint, 0
    );
  }

  private calculateAutofitScale() {
    let offsetWidth = 0;
    let offsetHeight = 0;

    if (this.image.offsetWidth > 0 && this.image.offsetHeight > 0) {
      offsetWidth = this.image.offsetWidth;
      offsetHeight = this.image.offsetHeight;
    }
    let autofitScale = 1;

    if (offsetWidth > 0) {
      autofitScale = Math.min(this.props.width / offsetWidth, autofitScale);
    }

    if (offsetHeight > 0) {
      autofitScale = Math.min(this.props.height / offsetHeight, autofitScale);
    }

    this.setState({ autofitScale });

    return autofitScale;
  }

  //  event handlers
  private handleTouchStart(event: React.TouchEvent) {
    if (this.animation) {
      cancelAnimationFrame(this.animation);
    }
    const touches = event.touches;

    if (touches.length === 2) {
      this.pinchStart(touches);
      this.lastPanPointerPosition = null;
    } else if (touches.length === 1) {
      this.pointerDown(touches[0]);
    }
  }

  private handleTouchMove(event: React.TouchEvent) {
    const touches = event.touches;

    if (touches.length === 2) {
      //  suppress viewport scaling
      event.preventDefault();
      this.pinchChange(touches);
    } else if (touches.length === 1) {
      const swipingDown = this.pan(touches[0]) > 0;

      if (this.state.top === undefined) {
        event.preventDefault();
      } else if (swipingDown && this.state.top < 0) {
        //  suppress pull-down-refresh since swiping down will reveal the hidden overflow of the image
        event.preventDefault();
      }
    }
  }

  private handleTouchEnd(event: React.TouchEvent) {
    if (event.touches && event.touches.length > 0) {
      return null;
    }

    //  We allow transient +/-5% over-pinching.
    //  Animate the bounce back to constraints if applicable.
    this.ensureValidTransform();
    this.pointerUp(event.timeStamp);
    //  suppress mouseUp, in case of tap
    event.preventDefault();

    return null;
  }

  private handleMouseDown(event: React.MouseEvent) {
    if (this.animation) {
      cancelAnimationFrame(this.animation);
    }

    this.mouseDown = true;

    this.pointerDown({
      clientX: event.clientX,
      clientY: event.clientY
    });
  }

  private handleMouseMove(event: React.MouseEvent) {
    if (!this.mouseDown) {
      return null;
    }

    return this.pan(event);
  }

  private handleMouseUp(event: React.PointerEvent) {
    this.pointerUp(event.timeStamp);

    if (this.mouseDown) {
      this.mouseDown = false;
    }
  }

  private handleMouseWheel(event: React.WheelEvent) {
    if (this.animation) {
      cancelAnimationFrame(this.animation);
    }
    const point = getRelativePosition(event, this.container);

    if (event.deltaY > 0) {
      if (this.state.scale && this.state.scale > this.minScale) {
        this.zoomOut(MOUSE_WHEEL_ZOOM_OUT_AMOUNT, point);
        event.preventDefault();
      }
    } else if (event.deltaY < 0) {
      if (this.state.scale && this.state.scale < this.props.maxScale) {
        this.zoomIn(MOUSE_WHEEL_ZOOM_IN_AMOUNT, point);
        event.preventDefault();
      }
    }
  }

  private handleImageLoad() {
    if (this.t) {
      clearTimeout(this.t);
    }

    this.t = setTimeout(() => {
      this.ensureConstraints();
    }, 80);

    if (this.state.scale !== this.calculateAutofitScale()) {
      this.props.hasZoomed(true);
      this.hasZoomed = true;
    } else {
      this.props.hasZoomed(false);
      this.hasZoomed = false;
    }
  }

  // actions
  private pointerDown(clientPosition: IClientPosition) {
    this.props.handleMouseMove();

    this.lastPanPointerPosition = getRelativePosition(clientPosition,
      this.container);
  }

  private pan(pointerClientPosition: IClientPosition) {
    const pointerPosition = getRelativePosition(pointerClientPosition,
      this.container);

    if (this.lastPanPointerPosition) {
      const translateX = pointerPosition.x - this.lastPanPointerPosition.x;
      const translateY = pointerPosition.y - this.lastPanPointerPosition.y;
      const top = this.state.top + translateY;
      const left = this.state.left + translateX;

      // use 0 tolerance to prevent over-panning (doesn't look good)
      this.move(
        top, left, 0
      );
      this.lastPanPointerPosition = pointerPosition;

      return translateY > 0
        ? 1 // swiping down
        : translateY < 0
          ? -1 // swiping up
          : 0;
    } else {
      return 0;
    }
  }

  private pointerUp(timeStamp: number) {
    if (
      this.lastPointerUpTimeStamp &&
      this.lastPointerUpTimeStamp + DOUBLE_TAP_THRESHOLD > timeStamp
    ) {
      // reset
      this.transformToProps();
    }

    this.lastPointerUpTimeStamp = timeStamp;
  }

  private move(
    top: number, left: number, tolerance: number
  ) {
    this.applyTransform(
      top, left, this.state.scale || 0, tolerance
    );
  }

  private pinchStart(touches: React.TouchList) {
    const pointA = getRelativePosition(touches[0], this.container);
    const pointB = getRelativePosition(touches[1], this.container);

    this.lastPinchLength = getDistanceBetweenPoints(pointA, pointB);
  }

  private pinchChange(touches: React.TouchList) {
    const pointA = getRelativePosition(touches[0], this.container);
    const pointB = getRelativePosition(touches[1], this.container);
    const length = getDistanceBetweenPoints(pointA, pointB);
    let scale = 1;

    if (this.state.scale) {
      scale = (this.state.scale * length) / this.lastPinchLength;
    }
    const midpoint = getMidpoint(pointA, pointB);

    this.zoom(
      scale, midpoint, OVER_TRANSFORMATION_TOLERANCE
    );
    this.lastPinchMidpoint = midpoint;
    this.lastPinchLength = length;
  }

  private zoom(
    scale: number | undefined = 0.5,
    midpoint: { x: number; y: number },
    tolerance: number
  ) {
    scale = this.getValidTransform(
      0, 0, scale, tolerance
    ).scale;

    if (scale !== this.calculateAutofitScale() && this.hasZoomed === false) {
      this.props.hasZoomed(true);
      this.hasZoomed = true;
    }

    const incrementalScalePercentage =
      (this.state.scale - scale) / this.state.scale;

    const translateY =
      (midpoint.y - this.state.top) * incrementalScalePercentage;

    const translateX =
      (midpoint.x - this.state.left) * incrementalScalePercentage;

    const top = this.state.top + translateY;
    const left = this.state.left + translateX;

    if (this.props.onZoom) {
      this.props.onZoom(scale);
    }

    this.applyTransform(
      top, left, scale, tolerance
    );
  }

  // state validation and transformation methods
  private applyTransform(
    requestedTop: number,
    requestedLeft: number,
    requestedScale: number,
    tolerance: number
  ) {
    const {
      top, left, scale
    } = this.getValidTransform(
      requestedTop,
      requestedLeft,
      requestedScale,
      tolerance
    );

    if (
      this.state.scale === scale &&
      this.state.top === top &&
      this.state.left === left
    ) {
      this.calculateCenterImage(scale);

      return;
    }

    this.setState({
      left,
      scale,
      top
    },
    () => {
      this.props.onTransform({
        left,
        top,
        scale,
        posTop: this.state.posTop,
        posLeft: this.state.posLeft
      });
    });
    this.calculateCenterImage(scale);
  }

  private getValidTransform(
    top: number,
    left: number,
    scale: number,
    tolerance: number
  ) {
    const transform = {
      left: left || 0,
      scale: scale || 1,
      top: top || 0
    };

    const lowerBoundFactor = 1.0 - tolerance;
    const upperBoundFactor = 1.0 + tolerance;

    transform.scale = rangeBind(
      this.minScale * lowerBoundFactor,
      this.props.maxScale * upperBoundFactor,
      transform.scale
    );

    // get dimensions by which scaled image overflows container
    const negativeSpace = this.calculateNegativeSpace(transform.scale);

    const overflow = {
      height: Math.max(0, invert(negativeSpace.height)),
      width: Math.max(0, invert(negativeSpace.width))
    };

    // prevent moving by more than the overflow
    // example: overflow.height = 100, tolerance = 0.05 => top is constrained between -105 and +5
    transform.top = rangeBind(
      invert(overflow.height) * upperBoundFactor,
      overflow.height * upperBoundFactor - overflow.height,
      transform.top
    );

    transform.left = rangeBind(
      invert(overflow.width) * upperBoundFactor,
      overflow.width * upperBoundFactor - overflow.width,
      transform.left
    );

    return transform;
  }

  private ensureValidTransform() {
    this.applyTransform(
      this.state.top, this.state.left, this.state.scale, 0
    );
  }

  // sizing methods
  private ensureConstraints() {
    if (this.image) {
      if (this.image.offsetWidth && this.image.offsetHeight) {
        const negativeSpace = this.calculateNegativeSpace(1);

        if (
          !this.lastUnzoomedNegativeSpace ||
          negativeSpace.height !== this.lastUnzoomedNegativeSpace.height ||
          negativeSpace.width !== this.lastUnzoomedNegativeSpace.width
        ) {
          // image and/or container dimensions have been set / updated
          this.applyConstraints();
          this.forceUpdate();
        }
      }
    }
  }

  private applyConstraints() {
    let minScale: number;

    if (typeof this.props.minScale === "string") {
      minScale = this.calculateAutofitScale();
    } else {
      minScale = this.props.minScale;
    }

    if (this.minScale !== minScale) {
      this.minScale = minScale;
      this.ensureValidTransform();
    } else {
      this.calculateCenterImage(this.state.scale);
    }

    this.lastUnzoomedNegativeSpace = this.calculateNegativeSpace(1);
  }

  private calculateNegativeSpace(scale = this.state.scale) {
    // get difference in dimension between container and scaled image
    const width = this.props.width - scale * this.image.offsetWidth;
    const height = this.props.height - scale * this.image.offsetHeight;

    return {
      height,
      width
    };
  }

  private calculateCenterImage(scale: number) {
    if (this.image.clientHeight && this.image.clientWidth) {
      const posTop = Math.max(0,
        (this.props.height - this.image.clientHeight * scale) / 2);

      const posLeft = Math.max(0,
        (this.props.width - this.image.clientWidth * scale) / 2);

      this.setState({
        posTop,
        posLeft
      });
    }
  }
}

ImagePinchZoomPan.defaultProps = {
  height: "100vh",
  initialLeft: 0,
  initialScale: "auto",
  initialTop: 0,
  maxScale: 1,
  minScale: "auto",
  zoomButtons: true
};
