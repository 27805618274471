import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import {
  Text, TextInput, Button, ValidationError
} from "../../components";
import { Column, Row } from "hedron";
import {
  Formik, FormikProps, Form
} from "formik";
import * as Yup from "yup";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import _ from "lodash";
import { IVideo } from "src/types/store/videos";
import { createVideoRequest } from "../../store/videos/videos.actions";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { cameraLocationGetByUUID } from "src/store/camera-locations/camera-locations.getters";
import MobileSpacer from "src/components/MobileSpacer";

interface IRouterProps {
  customerId: string;
  cameraLocationUUID: string;
  siteId: string;
}

type IVideoProps = RouteComponentProps<IRouterProps>;

interface IStateProps {
  isCreating: boolean;
  cameraLocation: ICameraLocation | null;
}

interface IVideoDispatchProps {
  createVideo: (video: IVideo) => void;
}

type Props = IVideoProps & IStateProps & IVideoDispatchProps;

class CreateVideo extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <React.Fragment>
        <Row alignContent="space-between">
          <Column xs={9}>
            <Text fontSize="h1">Add new video</Text>
          </Column>
        </Row>
        <Row />
        <Row>
          <Column>
            <Formik
              initialValues={{
                download_url: "",
                embed_url: "",
                name: ""
              }}
              onSubmit={(values: any) => this.handleSubmit(values)}
              validationSchema={Yup.object().shape({
                download_url: Yup.string().url("Must be a valid URL"),
                embed_url: Yup.string()
                  .url("Must be a valid URL")
                  .required("Required"),
                name: Yup.string().required("Required")
              })}
            >
              {(props: FormikProps<{
                  name: string;
                  embed_url: string;
                  download_url: string;
                }>) => {
                const {
                  values, handleChange, handleBlur, errors
                } = props;

                return (
                  <Form>
                    <Row>
                      <Column xs={12} lg={5}>
                        <TextInput
                          onChange={handleChange}
                          value={values.name}
                          name="name"
                          placeholder="Name"
                          onBlur={handleBlur}
                        />
                        <ValidationError>{errors.name}</ValidationError>
                      </Column>
                    </Row>
                    <Row>
                      <Column xs={12} lg={5}>
                        <TextInput
                          onChange={handleChange}
                          value={values.embed_url}
                          name="embed_url"
                          placeholder="Embed URL"
                          onBlur={handleBlur}
                        />
                        <ValidationError>{errors.embed_url}</ValidationError>
                      </Column>
                    </Row>
                    <Row>
                      <Column xs={12} lg={5}>
                        <TextInput
                          onChange={handleChange}
                          value={values.download_url}
                          name="download_url"
                          placeholder="Download URL"
                          onBlur={handleBlur}
                        />
                        <ValidationError>{errors.download_url}</ValidationError>
                      </Column>
                    </Row>
                    <Row>
                      <Column xs={12} lg={5}>
                        <Button
                          disabled={this.props.isCreating}
                          text="Submit"
                          type="submit"
                        />
                      </Column>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Column>
        </Row>
        <MobileSpacer />
      </React.Fragment>
    );
  }

  private handleSubmit(values: IVideo) {
    const {
      createVideo, cameraLocation, match 
    } = this.props;

    if (cameraLocation) {
      values.customer_id = match.params.customerId;
      values.site_id = match.params.siteId;
      values.camera_location_id = String(cameraLocation?.id);
      values.type = "manual";
      createVideo(values);
    }
  }
}

const mapStateToProps = (state: IStore, props: Props): IStateProps => {
  const { match } = props;

  return {
    isCreating: state.videos.creatingVideo,
    cameraLocation: cameraLocationGetByUUID(state, match.params.cameraLocationUUID) 
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IVideoDispatchProps => {
  return { createVideo: (video: IVideo) => dispatch(createVideoRequest(video)) };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(CreateVideo);
