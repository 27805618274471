import React from "react";
import styled from "styled-components";
import { styles } from "src/lib/styles";

const BottomPadder = styled.div`
  @media screen and (max-width: ${styles.mobileBreakPoint}) {
    padding-bottom: 100px;
  }
`;

const MobileSpacer: React.FC = () => {
  return <BottomPadder />;
};

export default MobileSpacer;
