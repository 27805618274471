import {
  Form,
  Formik, FormikProps, FormikValues 
} from "formik";
import React from "react";
import { IUser } from "src/types/store/users";
import {
  Button,
  Text, TextInput, ValidationError 
} from "../../../components";
import * as Yup from "yup";
import { Column, Row } from "hedron";

interface IMFAFormProps {
  user: IUser | null;
  destinationNumber: string | null;
  handleSubmit: (user: any, mfaCode: string) => void;
}

interface FormValues {
  mfaCode: string;
}

export const MFAForm: React.FC<IMFAFormProps> = ({
  user, destinationNumber, handleSubmit 
}) => {
  const submitMFAForm = React.useCallback((mfaCode: string) => {
    handleSubmit(user, mfaCode);
  }, [handleSubmit, user]);

  return (
    <React.Fragment>
      <Text style={{
        display: "block",
        textAlign: "center"
      }}>
        Please enter the MFA code sent to {destinationNumber || "your phone number"}
      </Text>
      <Formik
        initialValues={{ mfaCode: "" }}
        onSubmit={(values: FormikValues) => submitMFAForm(values.mfaCode) 
        }
        validationSchema={
          Yup.object().shape({
            mfaCode: Yup.string()
              .min(6, "MFA code must be 6 characters long.")
              .max(6, "MFA code must be 6 characters long.")
              .matches(/^[0-9]*/,
                { message: "MFA code must contain numbers only." })
              .required("Required")
          })}
      >
        {(props: FormikProps<FormValues>) => {
          const {
            values, handleChange, handleBlur, errors
          } = props;

          return (
            <Form>
              <Row>
                <Column fluid={true}>
                  <Text fontSize="small">MFA Code</Text>
                  <TextInput
                    onChange={handleChange}
                    value={values.mfaCode}
                    kind="secondary"
                    name="mfaCode"
                    onBlur={handleBlur}
                  />
                  <ValidationError>{errors.mfaCode}</ValidationError>
                </Column>
              </Row>

              <Row>
                <Column
                  fluid={true}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Button text="Submit" type="submit" kind="outline" />
                </Column>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};