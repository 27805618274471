import * as React from "react";
import styled from "styled-components";
import { Field } from "formik";
import { styles, theme } from "../../lib/styles";

export interface IDropdownProps {
  kind?: string;
  style?: any;
  items: Array<{ text: string; value: any }>;
  name: string;
  placeholder: string;
  defaultValue?: any;
  onChange?: (value: any) => void;
  onClear?: () => void;
}

const textColor = theme.variants(
  "mode", "kind", { default: { normal: styles.textColor } }
);

const DropdownWrapper = styled.div<{kind?: string}>`
  color: ${textColor};
`;

const DropdownHeaderWrapper = styled.div<{kind?: string}>`
  border-bottom: 1px ${styles.secondaryTextColor} solid;
`;

const Dropdown = styled(Field)`
  appearance: none;
  padding: 5px;
  width: 100%;
  color: ${styles.secondaryTextColor};
  border: none;
  background-color: transparent;
`;

const Option = styled.option`
  width: 100%;
`;

const ClearButton = styled.div`
  cursor: pointer;
  padding: 5px;
  :hover {
    opacity: 0.8;
  }
`;

export const DropdownFormik: React.FC<IDropdownProps> = ({
  kind, style, items, name, placeholder, defaultValue, onChange, onClear
}) => {
  return (
    <DropdownWrapper style={style} kind={kind || "default"}>
      <DropdownHeaderWrapper>
        <div style={{
          display: "flex",
          flex: "row"
        }}>
          <Dropdown name={name} component="select" defaultValue={defaultValue} onChange={ (e: React.ChangeEvent) => {
            if (onChange) {
            // @ts-ignore
              onChange(e.target.value);
            }
          }}>
            <Option disabled={true} selected={defaultValue === undefined}>
              {placeholder}
            </Option>
            {items.map(op => {
              return (
                <Option key={op.value} value={op.value}>
                  {op.text}
                </Option>
              );
            })}
          </Dropdown>
          {/** Clear button - only show if there's a selected value */}
          {onClear && defaultValue !== undefined && 
            <ClearButton onClick={onClear}>x</ClearButton>}
        </div>
        
      </DropdownHeaderWrapper>
    </DropdownWrapper>
  );
};
