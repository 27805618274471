import * as Redux from "redux";
import * as AT from "./videos.constants";
import { IVideoStore } from "../../types/store/videos";
import { VideoActions } from "./videos.actions";

const initialState: IVideoStore = {
  creatingVideo: false,
  userVideoGenerating: false,
  userVideoSuccess: false,
  userVideoFailure: false,
  deletingVideo: false,
  getVideos: false,
  updatingVideo: false,
  videos: [],
  total: 0
};

export const videos: Redux.Reducer<IVideoStore> = (state: IVideoStore = initialState,
  action: VideoActions): IVideoStore => {
  switch (action.type) {
    case AT.GET_VIDEOS:
      return {
        ...state,
        getVideos: true,
        videos: []
      };
    case AT.GET_VIDEOS_SUCCESS:
      return {
        ...state,
        getVideos: false,
        videos: action.videos,
        total: action.total
      };
    case AT.GET_VIDEOS_FAILURE:
      return {
        ...state,
        getVideos: false
      };
    // CREATE VIDEO
    case AT.CREATE_VIDEO:
      return {
        ...state,
        creatingVideo: true 
      };
    case AT.CREATE_VIDEO_SUCCESS:
      return {
        ...state,
        creatingVideo: false 
      };
    case AT.CREATE_VIDEO_FAILURE:
      return {
        ...state,
        creatingVideo: false 
      };

    // UPDATE VIDEO
    case AT.UPDATE_VIDEO:
      return {
        ...state,
        updatingVideo: true 
      };
    case AT.UPDATE_VIDEO_SUCCESS:
      return {
        ...state,
        updatingVideo: false 
      };
    case AT.UPDATE_VIDEO_FAILURE:
      return {
        ...state,
        updatingVideo: false 
      };

    // DELETE VIDEO
    case AT.DELETE_VIDEO:
      return {
        ...state,
        deletingVideo: true 
      };
    case AT.DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        deletingVideo: false 
      };
    case AT.DELETE_VIDEO_FAILURE:
      return {
        ...state,
        deletingVideo: false 
      };

    // GENERATE_VIDEO
    case AT.GENERATE_VIDEO:
      return {
        ...state,
        userVideoGenerating: true,
        userVideoSuccess: false,
        userVideoFailure: false 
      };
    case AT.GENERATE_VIDEO_SUCCESS:
      return {
        ...state,
        userVideoGenerating: false,
        userVideoSuccess: true 
      };
    case AT.GENERATE_VIDEO_FAILURE:
      return {
        ...state,
        userVideoGenerating: false,
        userVideoFailure: true 
      };

    case AT.PURGE_VIDEOS:
      return initialState;
    default:
      return state;
  }
};
