import Amplify from "aws-amplify";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./App";
import awsconfig from "./aws-exports";

import * as Sentry from "@sentry/browser";

// eslint-disable-next-line @typescript-eslint/no-var-requires
require("es6-promise").polyfill();
require("isomorphic-fetch");
// set up amplify config
Amplify.configure(awsconfig);
// Applying global styles
Sentry.init({ dsn: "https://14583c1f0f3d4bd3b79a5c3b532fdf2f@sentry.io/1338050" });
ReactDOM.render(<App />, document.getElementById("root"));

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
