import { IStore } from "src/types/store/store";
import { IUser } from "src/types/store/users";
import _ from "lodash";

export const userGetById = (state: IStore, userId: number): IUser | null => {
  if (userId) {
    const user = _.find(state.users.users, c => c.id === userId);

    return user ? user : null;
  } else {
    return null;
  }
};
