import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import { IImage } from "src/types/store/images";
import getImageByType from "src/lib/getImageByType";
import { DateTimeIndicator } from "..";
import moment from "moment";

interface IImageIntervalLineProps {
  kind?: string;
  hovered?: boolean;
  dragged?: boolean;
  isDragging?: boolean;
  image?: IImage;
  isPlaying: boolean;
}

interface IImageIntervalLineState {
  thumb_uri: string;
}

const backgroundColor = theme.variants(
  "mode", "kind", {
    active: { normal: styles.primaryAccentColor },
    default: { normal: styles.white }
  }
);

const width = theme.variants(
  "mode", "kind", {
    active: { normal: 3 },
    default: { normal: 1 }
  }
);

const height = theme.variants(
  "mode", "kind", {
    active: { normal: 30 },
    default: { normal: 10 }
  }
);

const borderRadius = theme.variants(
  "mode", "kind", {
    active: { normal: 30 },
    default: { normal: 0 }
  }
);

const ImageIntervalLineWrapper = styled.div<{kind?:string}>`
  @media (min-width: ${styles.mobileBreakPoint}) {
    position: relative;
  }
`;

const IntervalLine = styled.div<{kind?:string}>`
  background: ${backgroundColor};
  height: ${height}px;
  width: ${width}px;
  top: 0px;
  align-self: "center";
  border-radius: ${borderRadius}px;

  &.buffering {
    opacity: 0.5;
    height: ${height / 2}px;
  }
`;

const ImagePreview = styled.div<{kind?:string}>`
  left: 50%;
  top: -20px;
  position: absolute;
  transform: translate(-50%, -100%);
  img {
    max-width: 200px;
    max-height: 200px;
  }
  @media (max-width: ${styles.mobileBreakPoint}) {
    display: none;
  }
  img {
    background: ${styles.secondaryDarkColor};
    margin-top: 10px;
    border: 4px solid ${styles.secondaryDarkColor};
  }
`;

const initialState: IImageIntervalLineState = { thumb_uri: "" };

class ImageIntervalLine extends React.Component<
  IImageIntervalLineProps,
  IImageIntervalLineState
> {
  public readonly state: IImageIntervalLineState = initialState;

  public componentDidUpdate(prevProps: IImageIntervalLineProps) {
    const {
      image, hovered, dragged
    } = this.props;

    if (
      (hovered && !prevProps.hovered) ||
      (dragged && image !== prevProps.image)
    ) {
      if (image) {
        getImageByType("thumbnail", image.thumbnails).then(thumb => {
          if (thumb.s3_url) {
            this.setState({ thumb_uri: thumb.s3_url });
          }
        });
      }
    }
  }
  public render() {
    const {
      kind, hovered, dragged, isDragging, image
    } = this.props;

    if (image) {
      return (
        <ImageIntervalLineWrapper>
          <IntervalLine kind={kind} className={this.isBuffering(image)} />

          {((!isDragging && hovered) || dragged) && (
            <ImagePreview>
              <DateTimeIndicator
                dateTime={moment.utc(image.taken_at)}
                top={0}
              />
              <img src={this.state.thumb_uri} />
            </ImagePreview>
          )}
        </ImageIntervalLineWrapper>
      );
    }

    return null;
  }

  private isBuffering(image: IImage): string {
    if (this.props.isPlaying) {
      return image.loaded ? "loaded" : "buffering";
    } else {
      return "loaded";
    }
  }
}

export default ImageIntervalLine;
