import * as AT from "./videos.constants";
import { IStore } from "../../types/store/store";
import * as Redux from "redux";
import { ThunkAction } from "redux-thunk";
import {
  RouterAction, goBack, push
} from "react-router-redux";
import { IVideo, IVideoParams } from "../../types/store/videos";
import { toast } from "react-toastify";
import apiError from "src/lib/apiError";
import * as videosApi from "./videos.api";
import { AudioSettings } from "../camera-locations/camera-locations.api";

export type VideoActions =
  | IGetVideos
  | IPurgeVideos
  | IGetVideosSuccess
  | IGetVideosFailure
  | ICreateVideo
  | ICreateVideoFailure
  | ICreateVideoSuccess
  | IUpdateVideo
  | IUpdateVideoFailure
  | IUpdateVideoSuccess
  | IDeleteVideo
  | IDeleteVideoFailure
  | IDeleteVideoSuccess
  | IGenerateVideo
  | IGenerateVideoFailure
  | IGenerateVideoSuccess;
type ThunkResult<R> = ThunkAction<
  R,
  IStore,
  undefined,
  VideoActions | RouterAction
>;

export interface IPurgeVideos {
  type: AT.PURGE_VIDEOS;
}

export const purgeVideos: Redux.ActionCreator<IPurgeVideos> = () => {
  return { type: AT.PURGE_VIDEOS };
};

export interface IGetVideos {
  type: AT.GET_VIDEOS;
}

export interface IGetVideosSuccess {
  videos: IVideo[];
  total: number;
  type: AT.GET_VIDEOS_SUCCESS;
}

export interface IGetVideosFailure {
  type: AT.GET_VIDEOS_FAILURE;
}

export const getVideos: Redux.ActionCreator<IGetVideos> = () => {
  return { type: AT.GET_VIDEOS };
};

export const getVideosSuccess: Redux.ActionCreator<IGetVideosSuccess> = (videos: IVideo[], total: number) => {
  return {
    type: AT.GET_VIDEOS_SUCCESS,
    videos,
    total
  };
};

export const getVideosFailure: Redux.ActionCreator<IGetVideosFailure> = () => {
  return { type: AT.GET_VIDEOS_FAILURE };
};

export const getVideosRequest = (
  cameraLocationId: string,
  params: IVideoParams,
  role = "client"
): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getVideos());
    
    try {
      const { videos, total } = await videosApi.getVideos(
        cameraLocationId, params, role
      );

      dispatch(getVideosSuccess(videos ? videos : [], total));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getVideosFailure());
      toast.error(apiError(error));
    }
  };
};

export interface ICreateVideo {
  type: AT.CREATE_VIDEO;
}

export interface ICreateVideoSuccess {
  type: AT.CREATE_VIDEO_SUCCESS;
}

export interface ICreateVideoFailure {
  type: AT.CREATE_VIDEO_FAILURE;
}

export const createVideo: Redux.ActionCreator<ICreateVideo> = () => {
  return { type: AT.CREATE_VIDEO };
};

export const createVideoSuccess: Redux.ActionCreator<ICreateVideoSuccess> = () => {
  return { type: AT.CREATE_VIDEO_SUCCESS };
};

export const createVideoFailure: Redux.ActionCreator<ICreateVideoFailure> = () => {
  return { type: AT.CREATE_VIDEO_FAILURE };
};

export const createVideoRequest = (video: IVideo): ThunkResult<void> => {
  return async dispatch => {
    dispatch(createVideo());

    try {
      await videosApi.createVideo(video);
      dispatch(createVideoSuccess());
      toast.success("Successfully created video!");
      dispatch(getVideosRequest(video.camera_location_id, {}));
      dispatch(goBack());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(createVideoFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END CREATE VIDEO ACTIONS -------- //

// ----------- UPDATE VIDEO ACTIONS -------- //
export interface IUpdateVideo {
  type: AT.UPDATE_VIDEO;
}

export interface IUpdateVideoSuccess {
  type: AT.UPDATE_VIDEO_SUCCESS;
}

export interface IUpdateVideoFailure {
  type: AT.UPDATE_VIDEO_FAILURE;
}

export const updateVideo: Redux.ActionCreator<IUpdateVideo> = () => {
  return { type: AT.UPDATE_VIDEO };
};

export const updateVideoSuccess: Redux.ActionCreator<IUpdateVideoSuccess> = () => {
  return { type: AT.UPDATE_VIDEO_SUCCESS };
};

export const updateVideoFailure: Redux.ActionCreator<IUpdateVideoFailure> = () => {
  return { type: AT.UPDATE_VIDEO_FAILURE };
};

export const updateVideoRequest = (videoId: string,
  video: IVideo): ThunkResult<void> => {
  return async dispatch => {
    dispatch(updateVideo());

    try {
      await videosApi.updateVideo(videoId, video);
      dispatch(updateVideoSuccess());
      dispatch(goBack());
      toast.success("Successfully updated video details!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(updateVideoFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END UPDATE VIDEOS ACTIONS -------- //

// ----------- DELETE VIDEOS ACTIONS -------- //
export interface IDeleteVideo {
  type: AT.DELETE_VIDEO;
}

export interface IDeleteVideoSuccess {
  type: AT.DELETE_VIDEO_SUCCESS;
}

export interface IDeleteVideoFailure {
  type: AT.DELETE_VIDEO_FAILURE;
}

export const deleteVideo: Redux.ActionCreator<IDeleteVideo> = () => {
  return { type: AT.DELETE_VIDEO };
};

export const deleteVideoSuccess: Redux.ActionCreator<IDeleteVideoSuccess> = () => {
  return { type: AT.DELETE_VIDEO_SUCCESS };
};

export const deleteVideoFailure: Redux.ActionCreator<IDeleteVideoFailure> = () => {
  return { type: AT.DELETE_VIDEO_FAILURE };
};

export const deleteVideoRequest = (videoId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(deleteVideo());

    try {
      await videosApi.deleteVideo(videoId);
      dispatch(deleteVideoSuccess());
      toast.success("Successfully deleted video!");
      dispatch(goBack());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(deleteVideoFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END DELETE VIDEOS ACTIONS -------- //

// ----------- GENERATE VIDEOS ACTIONS -------- //
export interface IGenerateVideo {
  type: AT.GENERATE_VIDEO;
}

export interface IGenerateVideoSuccess {
  type: AT.GENERATE_VIDEO_SUCCESS;
}

export interface IGenerateVideoFailure {
  type: AT.GENERATE_VIDEO_FAILURE;
}

export const generateVideo: Redux.ActionCreator<IGenerateVideo> = () => {
  return { type: AT.GENERATE_VIDEO };
};

export const generateVideoSuccess: Redux.ActionCreator<IGenerateVideoSuccess> = () => {
  return { type: AT.GENERATE_VIDEO_SUCCESS };
};

export const generateVideoFailure: Redux.ActionCreator<IGenerateVideoFailure> = () => {
  return { type: AT.GENERATE_VIDEO_FAILURE };
};

export interface IVideoGenerationParams {
  name: string;
  /** yyyy-MM-dd */
  start_date: string;
  /** yyyy-MM-dd */
  end_date: string;
  /** [1,2,3,4,5,6,7] */
  days_of_week: number[];
  /** HH:mm */
  end_time: string;
  /** HH:mm */
  start_time: string;
  /** Seconds */
  video_length: string;
  notify_email?: string;
  audio?: AudioSettings;
}

export const generateVideoRequest = (cameraLocationId: string, params: IVideoGenerationParams): ThunkResult<void> => {
  return async dispatch => {
    dispatch(generateVideo());

    try {
      await videosApi.generateVideoForCameraLocation(cameraLocationId, params);
      dispatch(generateVideoSuccess());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      const errorMessage = apiError(error);

      if (errorMessage.includes("not enough images")) {
        // This is a common error, but we want it to look more like feedback rather than a failure.
        toast.info("There were not enough images available in the date & time range you selected to generate a video. Please include more images.");
      } else {
        toast.error(apiError(error));
      }

      dispatch(generateVideoFailure());
    }
  };
};

// ----------- END GENERATE VIDEOS ACTIONS -------- //
