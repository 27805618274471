import * as AT from "./customers.constants";
import { IStore } from "../../types/store/store";
import * as Redux from "redux";
import { ThunkAction } from "redux-thunk";
import {
  goBack, RouterAction, push
} from "react-router-redux";
import { toast } from "react-toastify";
import apiError from "src/lib/apiError";
import { ICustomer, ICustomerConfig } from "src/store/customers/customers.api";
import * as customersApi from "./customers.api";
import { CreateCustomerParams } from "./customers.api";
import { PaginatedParams } from "src/types/pagination";

export type CustomerActions =
  | ISetCustomer
  | IPurgeCustomers
  | IGetCustomers
  | IGetCustomersSuccess
  | IGetCustomersFailure
  | IGetCustomer
  | IGetCustomerSuccess
  | IGetCustomerFailure
  | ICreateCustomer
  | ICreateCustomerSuccess
  | ICreateCustomerFailure
  | IUpdateCustomer
  | IUpdateCustomerSuccess
  | IUpdateCustomerFailure
  | ISuspendCustomer
  | ISuspendCustomerSuccess
  | ISuspendCustomerFailure
  | IDeleteCustomer
  | IDeleteCustomerSuccess
  | IDeleteCustomerFailure
  | IGetCustomerConfig
  | IGetCustomerConfigSuccess
  | IGetCustomerConfigFailure;

type ThunkResult<R> = ThunkAction<
  R,
  IStore,
  undefined,
  CustomerActions | RouterAction
>;

export interface IPurgeCustomers {
  type: AT.PURGE_CUSTOMERS;
}

export const purgeCustomers: Redux.ActionCreator<IPurgeCustomers> = () => {
  return { type: AT.PURGE_CUSTOMERS };
};

export interface ISetCustomer {
  type: AT.SET_CUSTOMER;
}

export const setCustomer: Redux.ActionCreator<ISetCustomer> = () => {
  return { type: AT.SET_CUSTOMER };
};

// ----------- GET CUSTOMER ACTIONS -------- //
export interface IGetCustomer {
  type: AT.GET_CUSTOMER;
}

export interface IGetCustomerSuccess {
  customer: ICustomer;
  type: AT.GET_CUSTOMER_SUCCESS;
}

export interface IGetCustomerFailure {
  type: AT.GET_CUSTOMER_FAILURE;
}

export const getCustomer: Redux.ActionCreator<IGetCustomer> = () => {
  return { type: AT.GET_CUSTOMER };
};

export const getCustomerSuccess: Redux.ActionCreator<IGetCustomerSuccess> = (customer: ICustomer) => {
  return {
    customer,
    type: AT.GET_CUSTOMER_SUCCESS
  };
};

export const getCustomerFailure: Redux.ActionCreator<IGetCustomerFailure> = () => {
  return { type: AT.GET_CUSTOMER_FAILURE };
};

export const getCustomerRequest = (customerId: string,
  role = "client"): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getCustomer());

    try {
      const customer = await customersApi.getCustomer(customerId, role);

      dispatch(getCustomerSuccess(customer));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getCustomerFailure());
      toast.error(apiError(error));
    }
  };
};
// ----------- END GET CUSTOMERS ACTIONS -------- //

// ----------- GET CUSTOMERS ACTIONS -------- //
export interface IGetCustomers {
  type: AT.GET_CUSTOMERS;
}

export interface IGetCustomersSuccess {
  customers: ICustomer[];
  count: number;
  type: AT.GET_CUSTOMERS_SUCCESS;
}

export interface IGetCustomersFailure {
  type: AT.GET_CUSTOMERS_FAILURE;
}

export const getCustomers: Redux.ActionCreator<IGetCustomers> = () => {
  return { type: AT.GET_CUSTOMERS };
};

export const getCustomersSuccess: Redux.ActionCreator<IGetCustomersSuccess> = (customers: ICustomer[], count: number) => {
  return {
    customers,
    count,
    type: AT.GET_CUSTOMERS_SUCCESS
  };
};

export const getCustomersFailure: Redux.ActionCreator<IGetCustomersFailure> = () => {
  return { type: AT.GET_CUSTOMERS_FAILURE };
};

const DEFAULT_PAGINATION = {
  limit: 99999,
  offset: 0,
  sort: [["name", "DESC"]] as [string, "DESC" | "ASC"][],
  page: 1
};

const DEFAULT_FILTERS = {};

export const getCustomersRequest = (pagination: PaginatedParams<ICustomer> = DEFAULT_PAGINATION,
  filters: Partial<ICustomer> = DEFAULT_FILTERS): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getCustomers());

    try {
      const customers = await customersApi.getCustomers(pagination, filters);

      dispatch(getCustomersSuccess(customers.result, customers.count));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getCustomersFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END GET CUSTOMERS ACTIONS -------- //

// ----------- CREATE CUSTOMERS ACTIONS -------- //
export interface ICreateCustomer {
  type: AT.CREATE_CUSTOMER;
}

export interface ICreateCustomerSuccess {
  type: AT.CREATE_CUSTOMER_SUCCESS;
}

export interface ICreateCustomerFailure {
  type: AT.CREATE_CUSTOMER_FAILURE;
}

export const createCustomer: Redux.ActionCreator<ICreateCustomer> = () => {
  return { type: AT.CREATE_CUSTOMER };
};

export const createCustomerSuccess: Redux.ActionCreator<ICreateCustomerSuccess> = () => {
  return { type: AT.CREATE_CUSTOMER_SUCCESS };
};

export const createCustomerFailure: Redux.ActionCreator<ICreateCustomerFailure> = () => {
  return { type: AT.CREATE_CUSTOMER_FAILURE };
};

export const createCustomerRequest = (customer: CreateCustomerParams): ThunkResult<void> => {
  return async dispatch => {
    dispatch(createCustomer());

    try {
      await customersApi.createCustomer(customer);
      dispatch(createCustomerSuccess());
      dispatch(getCustomersRequest());
      dispatch(goBack());
      toast.success("Successfully created customer!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(createCustomerFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END CREATE CUSTOMERS ACTIONS -------- //

// ----------- UPDATE CUSTOMERS ACTIONS -------- //
export interface IUpdateCustomer {
  type: AT.UPDATE_CUSTOMER;
}

export interface IUpdateCustomerSuccess {
  type: AT.UPDATE_CUSTOMER_SUCCESS;
}

export interface IUpdateCustomerFailure {
  type: AT.UPDATE_CUSTOMER_FAILURE;
}

export const updateCustomer: Redux.ActionCreator<IUpdateCustomer> = () => {
  return { type: AT.UPDATE_CUSTOMER };
};

export const updateCustomerSuccess: Redux.ActionCreator<IUpdateCustomerSuccess> = () => {
  return { type: AT.UPDATE_CUSTOMER_SUCCESS };
};

export const updateCustomerFailure: Redux.ActionCreator<IUpdateCustomerFailure> = () => {
  return { type: AT.UPDATE_CUSTOMER_FAILURE };
};

export const updateCustomerRequest = (customerId: string,
  customer: Partial<ICustomer>): ThunkResult<void> => {
  return async dispatch => {
    dispatch(updateCustomer());

    try {
      await customersApi.updateCustomer(customerId, customer);
      dispatch(updateCustomerSuccess());
      dispatch(getCustomersRequest());
      toast.success("Successfully updated customer details!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(updateCustomerFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END UPDATE CUSTOMERS ACTIONS -------- //

// ----------- SUSPEND CUSTOMERS ACTIONS -------- //
export interface ISuspendCustomer {
  type: AT.SUSPEND_CUSTOMER;
}

export interface ISuspendCustomerSuccess {
  type: AT.SUSPEND_CUSTOMER_SUCCESS;
}

export interface ISuspendCustomerFailure {
  type: AT.SUSPEND_CUSTOMER_FAILURE;
}

export const suspendCustomer: Redux.ActionCreator<ISuspendCustomer> = () => {
  return { type: AT.SUSPEND_CUSTOMER };
};

export const suspendCustomerSuccess: Redux.ActionCreator<ISuspendCustomerSuccess> = () => {
  return { type: AT.SUSPEND_CUSTOMER_SUCCESS };
};

export const suspendCustomerFailure: Redux.ActionCreator<ISuspendCustomerFailure> = () => {
  return { type: AT.SUSPEND_CUSTOMER_FAILURE };
};

export const suspendCustomerRequest = (customerId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(suspendCustomer());

    try {
      await customersApi.suspendCustomer(customerId);
      dispatch(suspendCustomerSuccess());
      toast.success("Successfully updated customer status!");
      dispatch(getCustomerRequest(customerId));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(suspendCustomerFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END SUSPEND CUSTOMERS ACTIONS -------- //

// ----------- DELETE CUSTOMERS ACTIONS -------- //
export interface IDeleteCustomer {
  type: AT.DELETE_CUSTOMER;
}

export interface IDeleteCustomerSuccess {
  type: AT.DELETE_CUSTOMER_SUCCESS;
}

export interface IDeleteCustomerFailure {
  type: AT.DELETE_CUSTOMER_FAILURE;
}

export const deleteCustomer: Redux.ActionCreator<IDeleteCustomer> = () => {
  return { type: AT.DELETE_CUSTOMER };
};

export const deleteCustomerSuccess: Redux.ActionCreator<IDeleteCustomerSuccess> = () => {
  return { type: AT.DELETE_CUSTOMER_SUCCESS };
};

export const deleteCustomerFailure: Redux.ActionCreator<IDeleteCustomerFailure> = () => {
  return { type: AT.DELETE_CUSTOMER_FAILURE };
};

export const deleteCustomerRequest = (customerId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(deleteCustomer());

    try {
      await customersApi.deleteCustomer(customerId);
      dispatch(deleteCustomerSuccess());
      toast.success("Successfully deleted customer!");
      // TODO: this should really just get the one customer, not all in the future
      dispatch(getCustomersRequest());
      dispatch(push("/admin/customers"));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(deleteCustomerFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END DELETE CUSTOMERS ACTIONS -------- //
//
// ----------- DELETE CUSTOMERS ACTIONS -------- //
export interface IGetCustomerConfig {
  type: AT.GET_CUSTOMER_CONFIG;
}

export interface IGetCustomerConfigSuccess {
  customerConfig: ICustomerConfig;
  type: AT.GET_CUSTOMER_CONFIG_SUCCESS;
}

export interface IGetCustomerConfigFailure {
  type: AT.GET_CUSTOMER_CONFIG_FAILURE;
}

export const getCustomerConfig: Redux.ActionCreator<IGetCustomerConfig> = () => {
  return { type: AT.GET_CUSTOMER_CONFIG };
};

export const getCustomerConfigSuccess: Redux.ActionCreator<IGetCustomerConfigSuccess> = (customerConfig: ICustomerConfig) => {
  return {
    customerConfig,
    type: AT.GET_CUSTOMER_CONFIG_SUCCESS
  };
};

export const getCustomerConfigFailure: Redux.ActionCreator<IGetCustomerConfigFailure> = () => {
  return { type: AT.GET_CUSTOMER_CONFIG_FAILURE };
};

export const getCustomerConfigRequest = (customerName: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getCustomerConfig());

    try {
      const result = await customersApi.getCustomerConfig(customerName);

      dispatch(getCustomerConfigSuccess(result));
    } catch (error) {
      dispatch(getCustomerConfigFailure());
      // Fails silently
      // toast.error(apiError(error));
    }
  };
};

// ----------- END DELETE CUSTOMERS ACTIONS -------- //
