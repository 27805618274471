import styled, { css } from "styled-components";

export interface BoxProps {
  margins?: MarginType;
  width?: string;
  height?: string;
  align?: "flex-start" | "center" | "flex-end" | "baseline" | "stretch";
  flexDir?: "row" | "column" | "row-responsive" | "row-reverse" | "column-reverse";
  justify?: "space-around" | "space-between" | "center" | "flex-end" | "space-evenly" | "flex-start" | "stretch";
  pad?: PadType;
  wrap?: boolean;
}

const edges = {
  xs: "5px",
  sm: "10px",
  md: "15px",
  lg: "20px",
  xl: "25px"
};

type EdgeSizeType = keyof typeof edges;
type EdgeType = "none" | EdgeSizeType | {bottom?: EdgeSizeType | string, horizontal?: EdgeSizeType | string, left?: EdgeSizeType | string, right?: EdgeSizeType | string, top?: EdgeSizeType | string, vertical?: EdgeSizeType | string} | string;
type MarginType = EdgeType;
type PadType = EdgeType;

/**
 * Box
 */
export const Box = styled.div<BoxProps>`
  ${({
    margins, align, flexDir, justify, pad, wrap, width, height
  }) => {
    const calculatedMargin = calculateEdges(margins);
    const calculatedPadding = calculateEdges(pad);

    return css`
      display: flex;
      box-sizing: border-box;
      flex-direction: ${flexDir || "column"};
      flex-wrap: ${wrap ? "wrap" : "nowrap"};
      justify-content: ${justify || "flex-start"};
      align-items: ${align || "flex-start"};
      margin: ${calculatedMargin || "0px"};
      padding: ${calculatedPadding || "0px"};
      width: ${width || "auto"};
      height: ${height || "auto"};
  `;
  }}
`;

const calculateEdgeSize = (edgeSize: EdgeSizeType | string) : string => {
  if (Object.keys(edges).includes(edgeSize)) {
    const namedEdgeSize = edgeSize as EdgeSizeType;

    return edges[namedEdgeSize];
  }

  return edgeSize;
};

const calculateEdges = (edge?: EdgeType): string => {
  let top = "0px";
  let right = "0px";
  let bottom = "0px";
  let left = "0px";

  if (edge) {
    if (typeof edge !== "string") {
      if (edge.top) {
        top = calculateEdgeSize(edge.top);
      }

      if (edge.right) {
        right = calculateEdgeSize(edge.right);
      }

      if (edge.bottom) {
        bottom = calculateEdgeSize(edge.bottom);
      }

      if (edge.left) {
        left = calculateEdgeSize(edge.left);
      }

      if (edge.vertical) {
        top = calculateEdgeSize(edge.vertical);
        bottom = calculateEdgeSize(edge.vertical);
      }

      if (edge.horizontal) {
        right = calculateEdgeSize(edge.horizontal);
        left = calculateEdgeSize(edge.horizontal);
      }
    } else {
      return calculateEdgeSize(edge);
    }
  }

  return `${top} ${right} ${bottom} ${left}`;
};
