import React from "react";
import { Box } from "../box";
import Text from "../text";
import TextInput from "../text-input";
import { Checkbox } from "../checkbox/formik-checkbox";

interface NotifyEmailProps {
  setEmailAddress: (val: string) => void;
  emailAddress: string;
  allowKeys?: (allow: boolean) => void;
}

export const NotifyEmail: React.FC<NotifyEmailProps> = props => {
  const [notify, setNotify] = React.useState<boolean>(false);

  return (
    <Box>
      <Box flexDir="row" align="center">
        <Box pad={{ right: "sm" }}>
          <Checkbox 
            name="notify"
            checked={notify} 
            onChange={() => setNotify(!notify)} 
          />
        </Box>
        <Text>Notify me when ready</Text>
      </Box>
      {notify && (
        <Box pad={{ vertical: "sm" }} width="100%">
          <Box pad={{ vertical: "sm" }}>
            <Text weight="bold">Please enter your email address</Text>
          </Box>
          <TextInput
            value={props.emailAddress} 
            type="email"
            onFocus={() => props.allowKeys && props.allowKeys(false)}
            onBlur={() => props.allowKeys && props.allowKeys(true)}
            onChange={e => props.setEmailAddress(e.target.value || "")}
          />
        </Box>
      )}
    </Box>
  );
};