import * as React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";

interface ICameraLocationNameProps {
  kind?: string;
  style?: any;
  children: React.ReactChild;
}

const CameraLocationNameWrapper: any = styled.div`
  color: ${styles.textColor};
  background-color: ${styles.primaryOverlayColour};
  padding: 6px 15px;
  border: 1px solid ${styles.fadedTextColor};
  border-radius: 20px;
  z-index: 10;
  position: absolute;
  top: calc(${styles.header} + 10px);
  left: 10px;
`;

const CameraLocationName: React.SFC<ICameraLocationNameProps> = ({
  kind,
  style,
  children
}) => (
  <CameraLocationNameWrapper style={style} kind={kind}>
    {children}
  </CameraLocationNameWrapper>
);

CameraLocationNameWrapper.defaultProps = { kind: "default" };

export default CameraLocationName;
