import { styles } from "src/lib/styles";
import styled from "styled-components";

export const VideoPageBounds = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10vw;
  @media (max-width: 768px){
    padding: 0 5vw;
  }
  height: calc(100vh - ${styles.header});
  overflow-y: scroll;
  margin: 0 auto;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: ${styles.primaryDarkColor};        /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${styles.formBackground};    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid ${styles.primaryDarkColor};  /* creates padding around scroll thumb */
  }
`;