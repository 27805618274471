import React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import {
  Text, TextInput, Button, ValidationError
} from "src/components";
import { Column, Row } from "hedron";
import {
  Formik, FormikProps, Form
} from "formik";
import * as Yup from "yup";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import { createCustomerRequest } from "../../store/customers/customers.actions";
import { CreateCustomerParams } from "../../store/customers/customers.api";
import { SecuritySection } from "src/components/security-section";
import { styles } from "../../lib/styles";
import MobileSpacer from "src/components/MobileSpacer";

interface IRouterProps {
  siteId: string;
  customerId: string;
}

type ICustomerProps = RouteComponentProps<IRouterProps>;

interface IStateProps {
  isCreating: boolean;
}

interface ICustomerDispatchProps {
  createCustomer: (site: CreateCustomerParams) => void;
}
type Props = ICustomerProps & IStateProps & ICustomerDispatchProps;

export const CreateCustomer: React.FC<Props> = ({ createCustomer, isCreating }) => {
  const handleSubmit = (values: CreateCustomerParams) => {
    createCustomer(values);
  };

  return (
    <>
      <div style={{
        background: styles.secondaryDarkColor,
        borderRadius: "15px",
        paddingBottom: "60px"
      }}>
        <Row alignContent="space-between">
          <Column xs={9}>
            <Text fontSize="h1">Create new customer</Text>
          </Column>
        </Row>
        <Row />
        <Row>
          <Column>
            <Formik
              initialValues={{
                dark_logo: "",
                logo: "",
                name: "",
                sub_domain: "",
                security_level: 0,
                whitelist: ""
              }}
              onSubmit={(values: CreateCustomerParams) => handleSubmit(values)}
              validationSchema={Yup.object().shape({
                logo: Yup.string().url("Must be a valid URL"),
                name: Yup.string().required("Required"),
                security_level: Yup.number().required(),
                whitelist: Yup.string().when("security_level", {
                  is: 1,
                  then: Yup.string().matches(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}(,((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4})?$/, { message: "Please use valid IP addresses." })
                })
              })}
            >
              {(props: FormikProps<CreateCustomerParams>) => {
                const {
                  values, handleChange, handleBlur, errors, setFieldValue
                } = props;

                return (
         
                  <Form>
                    <Row>
                      <Column xs={12} lg={5}>
                        <TextInput
                          onChange={handleChange}
                          value={values.name}
                          name="name"
                          placeholder="Name"
                          onBlur={handleBlur}
                        />
                        <ValidationError>{errors.name}</ValidationError>
                      </Column>
                    </Row>
                    <div style={{ padding: "20px" }}>
                      <SecuritySection 
                        onSelectSecurityLevel={value => setFieldValue("security_level", value)} 
                        securityLevel={values.security_level}
                        handleWhitelistChange={handleChange}
                        whitelistValue={values.whitelist}
                        whitelistErrors={errors.whitelist}
                        onBlurWhitelistTextInput={handleBlur}
                      />
                    </div>
                    <Row>
                      <Column xs={12} lg={5}>
                        <TextInput
                          onChange={handleChange}
                          value={values.sub_domain}
                          name="sub_domain"
                          placeholder="Sub domain"
                          onBlur={handleBlur}
                        />
                      </Column>
                    </Row>
            
                    <Row>
                      <Column xs={12} lg={5}>
                        <Button
                          disabled={isCreating}
                          text="Submit"
                          type="submit"
                        />
                      </Column>
                    </Row>
                  </Form>
          
                );
              }}
            </Formik>
          </Column>
        </Row>
        <Row />
      </div>
      <MobileSpacer/>
    </>
  );
};

const mapStateToProps = (state: IStore): IStateProps => {
  return { isCreating: state.customers.creatingCustomer };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): ICustomerDispatchProps => {
  return {
    createCustomer: (customer: CreateCustomerParams) =>
      dispatch(createCustomerRequest(customer))
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(CreateCustomer);
