import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import { Icon, Text } from "../";
import DropdownItem from "./camera-dropdown-item";
import { IUserCameraAssociations } from "src/types/store/users";
import * as _ from "lodash";
import { IAuthStore } from "src/types/store/auth";

export interface IDropdownProps {
  kind?: string;
  selected: number;
  style?: React.CSSProperties;
  onSelect: (data: IUserCameraAssociations) => void;
  userCameras: IUserCameraAssociations[];
  isOverlayActive?: boolean;
  auth: IAuthStore;
  showCameraSwitcherButtons?: boolean;
}

interface IDropdownState {
  open: boolean;
  prevCamera: IUserCameraAssociations | null;
  nextCamera: IUserCameraAssociations | null;
}
interface IDropdownHeaderWrapper {
  userCameras: IUserCameraAssociations[];
  open?: boolean;
  isOverlayActive?: boolean;
}

interface IUserCamerasBySite {
  [key: string]: IUserCameraAssociations[];
}

class Dropdown extends React.Component<IDropdownProps, IDropdownState> {
  protected wrapper: HTMLDivElement;
  protected boundOnMousedown: () => void;
  private scrollerRef: React.RefObject<HTMLDivElement>;

  constructor(props: IDropdownProps) {
    super(props);
    this.boundOnMousedown = this.handleClickOutside.bind(this);
    
    const { prevCamera, nextCamera } = this.getPrevNextCameras();
    
    this.state = {
      open: false,
      prevCamera,
      nextCamera 
    };
    this.scrollerRef = React.createRef();
  }

  public UNSAFE_componentWillMount() {
    document.addEventListener(
      "mousedown", this.boundOnMousedown, false
    );
  }

  public componentWillUnmount() {
    document.removeEventListener(
      "mousedown", this.boundOnMousedown, false
    );
  }

  public componentDidUpdate(_prevProps: Readonly<IDropdownProps>, prevState: Readonly<IDropdownState>): void {
    if (!prevState.open && this.state.open && this.scrollerRef.current) {
      this.scrollerRef.current.querySelector(".selected")?.scrollIntoView();
    }

    if (this.props.selected !== _prevProps.selected) {
      const { prevCamera, nextCamera } = this.getPrevNextCameras();

      this.setState({
        prevCamera,
        nextCamera 
      });
    }
  }

  private getPrevNextCameras(): {
    prevCamera: IDropdownState["prevCamera"];
    nextCamera: IDropdownState["nextCamera"];
    } {
    let prevCamera: IDropdownState["prevCamera"] = null;
    let nextCamera: IDropdownState["nextCamera"] = null;
    
    // initialise prevCamera and nextCamera if selected/possible
    if (this.props.selected !== -1) {
      const selectedCamera = this.props.userCameras[this.props.selected];
      // group by site
      const userCamerasBySite = this.getUserCamerasBySite();
      // sort alphabetically like we do in render - emulating order for consistency
      const userCamerasBySiteArr = Object.entries(userCamerasBySite).sort((a, b) => a[0].localeCompare(b[0]));

      userCamerasBySiteArr.forEach(([_, arr]) => arr.sort((a, b) => a.name.localeCompare(b.name)));

      const flatCameras = _.flatten(userCamerasBySiteArr.map(([_, arr]) => arr));
      const selectedIndex = _.findIndex(flatCameras, x => x.id === selectedCamera.id);

      prevCamera = selectedIndex > 0 ? flatCameras[selectedIndex - 1] : null;
      nextCamera = selectedIndex < flatCameras.length - 1 ? flatCameras[selectedIndex + 1] : null;
    }

    return {
      prevCamera,
      nextCamera 
    };
  }
  
  private makeSiteName(cam: IUserCameraAssociations): string {
    return `${cam.site.customer.name} - ${cam.site.name}`.trim();
  }

  private getUserCamerasBySite(): IUserCamerasBySite {
    const userCamerasBySite: IUserCamerasBySite = {};

    this.props.userCameras.forEach(cameraLocation => {
      const customerSiteName = this.makeSiteName(cameraLocation);

      if (userCamerasBySite[customerSiteName]) {
        userCamerasBySite[customerSiteName].push(cameraLocation);
      } else {
        userCamerasBySite[customerSiteName] = [cameraLocation];
      }
    });

    return userCamerasBySite;
  }
  
  public render() {
    const {
      kind,
      style,
      selected,
      userCameras,
      isOverlayActive,
      auth
    } = this.props;

    const { open } = this.state;

    const ref = (element: HTMLDivElement) => {
      this.wrapper = element;
    };

    const userCamerasBySite = this.getUserCamerasBySite();

    const selectedSiteName =
      selected !== -1 && userCameras
        ? `${userCameras[selected].site.name} `
        : "";

    const selectedCameraName =
      selected !== -1 && userCameras
        ? `${userCameras[selected].name} `
        : "";

    return (
      <div ref={ref} style={{
        display: "flex",
        alignItems: "center"
      }}>
        <DropdownWrapper style={Object.assign(
          {}, { flex: "1 0 auto" }, style
        )} kind={kind || "default"}>
          <DropdownHeaderWrapper
            userCameras={userCameras}
            onClick={() => this.handleClick()}
            open={open}
            isOverlayActive={isOverlayActive}
          >
            <ChevronWrapper>
              <Icon
                icon="camera"
                size={25}
                style={{ transform: "translate(-30px, 0px)" }}
              />
            </ChevronWrapper>
            <Text
              style={{
                color: `${styles.fadedTextColor}`,
                fontSize: "10px",
                fontWeight: 400,
                letterSpacing: "2px",
                textTransform: "uppercase"
              }}
            >
              {selectedSiteName}
            </Text>
            <Text
              style={{
                overflow: "hidden",
                paddingTop: "1px",
                whiteSpace: "nowrap"
              }}
            >
              {selectedCameraName}
            </Text>
            {userCameras.length > 1 && (
              <ChevronWrapper style={{ right: 0 }}>
                <Icon
                  icon={open ? "chevron-up" : "chevron-down"}
                  style={{ transform: "translateY(2px)" }}
                  size={13}
                />
              </ChevronWrapper>
            )}
          </DropdownHeaderWrapper>
          {open && (
            <DropDownItemsWrapper
              isOverlayActive={isOverlayActive}
              userCameras={userCameras}
              ref={this.scrollerRef}
            >
              {Object.keys(userCamerasBySite)
                .sort((a, b) => a.localeCompare(b))
                .map((
                  key, _i, sitesArr
                ) => {
                  const prevSite = _i > 0 ? sitesArr[_i - 1] : null;
                  const nextSite = _i < sitesArr.length - 1 ? sitesArr[_i + 1] : null;

                  return (
                    <SiteGroup key={key}>
                      <p>{key}</p>
                      {userCamerasBySite[key]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((
                          item, index, camerasArr
                        ) => {
                          const prevCamera = index > 0 ? camerasArr[index - 1] : prevSite ? userCamerasBySite[prevSite!][userCamerasBySite[prevSite!].length - 1] : null;
                          const nextCamera = index < camerasArr.length - 1 ? camerasArr[index + 1] : nextSite ? userCamerasBySite[nextSite!][0] : null;
                          const isSelected = userCameras[selected]?.id === item?.id;

                          return (
                            <DropdownItem
                              isSelected={isSelected}
                              onSelect={() => {
                                this.setState({
                                  prevCamera,
                                  nextCamera 
                                });
                                this.handleSelect(item);
                              }}
                              key={`dropdown-${index}`}
                              text={ auth.admin && item.hardware_ref 
                                ? `${item.name}: ${item.hardware_ref}` 
                                : `${item.name}`}
                            />
                          );
                        })}
                    </SiteGroup>
                  );
                })}
            </DropDownItemsWrapper>
          )}
        </DropdownWrapper>
        {this.props.showCameraSwitcherButtons && this.props.auth.admin && userCameras.length > 1 && (
          <section style={{ flex: 0 }}>
            <CameraSwitcherButton title="Previous camera" disabled={!this.state.prevCamera} onClick={() => this.handleSelect(this.state.prevCamera!)}>
              <Icon icon="chevron-left" size={10} color="white" />
            </CameraSwitcherButton>
            <CameraSwitcherButton title="Next camera" disabled={!this.state.nextCamera} onClick={() => this.handleSelect(this.state.nextCamera!)}>
              <Icon icon="chevron-right" size={10} color="white" />
            </CameraSwitcherButton>
          </section>
        )}
      </div>
    );
  }

  private handleSelect(item: IUserCameraAssociations) {
    this.setState({ open: false });
    this.props.onSelect(item);
  }

  private handleClick() {
    const { open } = this.state;
    const { userCameras } = this.props;

    if (userCameras.length > 1) {
      this.setState({ open: !open });
    }
  }

  private handleClickOutside(e: Event) {
    if (this.wrapper && this.wrapper.contains(e.target as Node | null)) {
      return;
    } else {
      if (this.wrapper) {
        this.setState({ open: false });
      }
    }
  }
}

const CameraSwitcherButton = styled.button`
  background: ${styles.primaryDarkColor};
  width: 1.5em;
  aspect-ratio: 1;
  border-radius: 2px;
  margin: 1px 1em;
  margin-left: 20px;
  border: none;
  padding: 2px;
  cursor: pointer;
  color: ${styles.secondaryTextColor};
  transition: color 0.2s ease-in-out;
  &:disabled {
    opacity: 0.66;
    background: #222;
    cursor: not-allowed;
  }
  &:hover:not(:disabled) {
    background: ${styles.primaryAccentColor};
    svg {
      fill: white !important;
    }
  }
`;

const SiteGroup = styled.div`
  p {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 10px;
    padding-bottom: 5px;
    margin: 0px 15px;
    color: ${styles.fadedTextColor};
  }
  margin-top: 0;
  transition: color 0.2s ease-in-out;
`;

const textColor = theme.variants(
  "mode", "kind", { default: { normal: styles.secondaryTextColor } }
);

const DropdownWrapper = styled.div<{kind?: string}>`
  position: relative;
  max-width: 360px;
  display: flex;
  flex-direction: row;
  padding-left: 20px;

  @media (max-width: ${styles.mobileBreakPoint}) {
    padding-left: 0px;

    &:after {
      display: block;
      position: absolute;
      height: 35px;
      right: -15px;
      width: 1px;
      background: rgba(255, 255, 255, 0.2);
      content: "";
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const ChevronWrapper = styled.div`
  position: absolute;
  bottom: 0px;
`;

const DropDownItemsWrapper = styled.div<IDropdownHeaderWrapper>`
  position: absolute;
  width: 100%;
  top: 100%;
  background: ${styles.secondaryDarkColor};
  z-index: 9;
  border-radius: 0;
  padding-top: 25px;
  padding-bottom: 10px;
  border-right: 3px solid ${styles.primaryDarkColor};
  border-bottom: 3px solid ${styles.primaryDarkColor};
  max-height: 500px;
  overflow-y: auto;

  @media (max-width: 500px) {
    max-height: 300px;
    width: 90vw;
    left: 0;
  }
`;

const DropdownHeaderWrapper = styled.div<IDropdownHeaderWrapper>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 30px;
  width: 100%;
  color: ${textColor};
  justify-content: space-between;
  cursor: ${props => (props.userCameras.length > 1 ? "pointer" : "inherit")};
  span {
    max-width: 165px;
  }
  @media (min-width: ${styles.mobileBreakPoint}) {
    span {
      max-width: 190px;
    }
  }

  div svg:hover {
    fill: ${styles.secondaryTextColor};
    cursor: ${props =>
    props.userCameras.length > 1 ? "pointer" : "default"};
  }
  :hover {
    color: ${styles.primaryAccentColor};
  }
`;

export default Dropdown;
