import { Row, Column } from "hedron";
import React from "react";
import { IDeletionItem } from "src/store/deletion-queue/deletion-queue.api";
import DeletionItem from "./DeletionItem";

interface DeletionItemsListProps {
    items: IDeletionItem[];
    }

const DeletionItemsList = (props: DeletionItemsListProps) => {
  return (
    <>
      {
        props.items && props.items.map(item => 
          item.id ? (
            <Row key={item.id}>
              <Column>
                <DeletionItem
                  id={item.id}
                  name={item.name || "Unknown"}
                  siteName={item.site_name || "Unknown"}
                  imagesRemaining={item.images_remaining || 0}
                />
              </Column>
            </Row>
          ) : null)
      }
    </>
  );
};

export default DeletionItemsList;
