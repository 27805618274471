import _ from "lodash";
import { ISite } from "src/store/sites/sites.api";
import { bytesToSize } from "src/utils/bytes-to-size";

export const getFolderSize = (site: ISite) => {
  if (!site.latest_metrics || !site.latest_metrics.length) {
    return null;
  }

  const combinedMetric = _.reduce(
    site.latest_metrics,
    (acc, value) => {
      acc += value.size;

      return acc;
    },
    0
  );

  return bytesToSize(combinedMetric);
};
