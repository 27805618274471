import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import { Link, LinkProps } from "react-router-dom";

interface ILinkProps extends LinkProps {
  kind?: string;
}

const textColor = theme.variants(
  "mode", "kind", { default: { normal: styles.primaryAccentColor } }
);

const hoverColor = theme.variants(
  "mode", "kind", { default: { normal: styles.primaryAccentColor } }
);

const LinkText: any = styled.span`
  color: ${textColor};
  transition: ease-in-out 0.2;

  :hover {
    color: ${hoverColor};
    opacity: 0.8;
  }
`;

const LinkWrapper: React.SFC<ILinkProps> = ({
  children,
  kind,
  to,
  replace,
  innerRef,
  ...rest
}) => (
  <Link
    style={{ textDecoration: "none" }}
    to={to}
    replace={replace}
    innerRef={innerRef}
  >
    <LinkText kind={kind} {...rest}>
      {children}
    </LinkText>
  </Link>
);

LinkText.defaultProps = { kind: "default" };

export default LinkWrapper;
