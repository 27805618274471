import * as React from "react";
import styled, { keyframes } from "styled-components";
import { styles } from "../../lib/styles";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Loading = require("./loading-img.gif");

interface ILoadingProps {
  kind?: string;
  className?: string;
}

const fadeIn = keyframes`
100%{
    opacity: 1
}

`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99999;
  display: flex;
  justify-content: center;
  height: 36px;
  width: 100%;
  background: ${styles.primaryDarkColor};
  animation: ${fadeIn} ease-in-out 0.3s forwards;
  opacity: 0;
`;

const LoadingWrapper: React.SFC<ILoadingProps> = ({ className }) => (
  <LoadingContainer className={className}>
    <img src={Loading} alt="loading" />
  </LoadingContainer>
);

export default LoadingWrapper;
