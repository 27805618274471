import * as React from "react";
import Icon from "../icon";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import moment from "moment";
import _ from "lodash";
import {
  canSkipNextInterval,
  canSkipPrevInterval,
  getInterval
} from "../../lib/date-helpers";

interface IProps {
  handlePrev: () => void;
  handlePlayPause: () => void;
  handleNext: () => void;
  dates: string[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  playIcon: "play" | "play-from-start" | "pause";
}

const ImageControlsWrapper = styled.div`
  display: flex;
  height: 100%;

  justify-content: center;
  width: 100%;

  @media (min-width: ${styles.mobileBreakPoint}) {
    margin-right: 10px;
  }
`;

const IconWrapper = styled.div`
  display: block;
  padding: 10px;

  &.disabled {
    opacity: 0.3;
    &:hover {
      svg {
        fill: ${styles.textColor};
      }
    }
  }

  svg {
    display: block;
  }
`;

const ImageControls = ({
  handlePrev,
  handlePlayPause,
  handleNext,
  dates,
  startDate,
  endDate,
  playIcon
}: IProps) => {
  const interval = getInterval(startDate, endDate);
  const targetEndDate = endDate.clone().subtract(interval, "days");
  const targetStartDate = startDate.clone().add(interval, "days");

  const prevEnabled = canSkipPrevInterval(targetEndDate,
    moment.utc(dates[dates.length - 1]));

  const nextEnabled = canSkipNextInterval(targetStartDate, moment.utc(dates[0]));

  return (
    <ImageControlsWrapper>
      <IconWrapper
        className={prevEnabled ? "active" : "disabled"}
        onClick={handlePrev}
      >
        <Icon size={15} icon="step-back" />
      </IconWrapper>
      <IconWrapper onClick={handlePlayPause}>
        <Icon size={15} icon={playIcon} />
      </IconWrapper>
      <IconWrapper
        className={nextEnabled ? "active" : "disabled"}
        onClick={handleNext}
      >
        <Icon size={15} icon="step-forward" />
      </IconWrapper>
    </ImageControlsWrapper>
  );
};

export default ImageControls;
