import * as React from "react";
import { Column, Row } from "hedron";

interface IProps {
  left: JSX.Element | null;
  midLeft: JSX.Element | null;
  midCenter: JSX.Element | null;
  midRight: JSX.Element | null;
  right: JSX.Element | null;
}

const DesktopFooterLayout: React.FC<IProps> = ({
  left,
  midLeft,
  midCenter,
  midRight,
  right
}) => {
  if (midLeft && midRight) {
    return (
      <Row justifyContent="space-between" alignItems="center">
        <Column className="calender-wrapper" fluid={true} xs={6} md={2}>
          {left}
        </Column>
        <Column fluid={true} xs={12} md={8}>
          <Row justifyContent="space-between" alignItems="center">
            <Column fluid={true} xs={2} md={2}>
              {midLeft}
            </Column>
            <Column className="scrubber-wrapper" fluid={true} xs={12} md={8}>
              {midCenter}
            </Column>
            <Column
              fluid={true}
              xs={4}
              md={2}
              className="controls-wrapper"
              style={{
                alignItems: "flex-end",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div>{midRight}</div>
            </Column>
          </Row>
        </Column>
        <Column
          fluid={true}
          xs={12}
          md={2}
          style={{
            justifyContent: "flex-end",
            display: "flex"
          }}
        >
          {right}
        </Column>
      </Row>
    );
  } else {
    return (
      <Row justifyContent="space-between" alignItems="center">
        <Column
          className="calender-wrapper"
          fluid={true}
          xs={6}
          md={3}
          style={{
            justifyContent: "flex-end",
            display: "flex"
          }}
        >
          {left}
        </Column>
        <Column fluid={true} xs={12} md={6}>
          <Row justifyContent="space-between" alignItems="center">
            <Column className="scrubber-wrapper" fluid={true} xs={12} md={12}>
              {midCenter}
            </Column>
          </Row>
        </Column>
        <Column
          fluid={true}
          xs={12}
          md={3}
          style={{
            justifyContent: "flex-start",
            display: "flex"
          }}
        >
          {right}
        </Column>
      </Row>
    );
  }
};

export default DesktopFooterLayout;
