export const getSubDomain = (url: string) => {
  // If found, removes white space
  url = url.replace(new RegExp(/^\s+/), ""); // From start
  url = url.replace(new RegExp(/\s+$/), ""); // From end
  // If found, converts back slashes to forward slashes
  url = url.replace(new RegExp(/\\/g), "/");
  // If found, removes 'http://', 'https://' or 'ftp://' from the start
  url = url.replace(new RegExp(/^http:\/\/|^https:\/\/|^ftp:\/\//i), "");
  // If found, removes 'www.' from the start of the string
  url = url.replace(new RegExp(/^www\./i), "");
  // Remove complete string from the first slash on
  url = url.replace(new RegExp(/\/(.*)/), "");

  // Removes '.??.??' or '.???.??' from the end - e.g. '.co.uk' and '.com.uk'
  if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
    url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), "");

    // Removes '.??' or '.???' or '.????' from the end - e.g. '.tv', '.com', '.info'
  } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
    url = url.replace(new RegExp(/\.[a-z]{2,4}$/i), "");
  }

  // Check to see if there is a dot '.' left in the string
  const sd = url.match(new RegExp(/\./g));

  // If theres a dot, there's a sub domain
  if (sd && sd[0] !== "staging") {
    // Return the sub domain
    const subdomain = url.split(new RegExp(/\./g), 1)[0];

    // Exclude staging as a sub domain - this is the actual staging environment!
    if (subdomain !== "staging") return subdomain;

    return null;
  } else {
    // Or return null
    return null;
  }
};
