import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";

interface IDropdownItemProps {
  kind?: string;
  text: string;
  style?: any;
  onSelect: (e: Event) => void;
  isDisabled?: boolean;
}

const textColor = theme.variants(
  "mode", "kind", { default: { normal: styles.textColor } }
);

const DropdowItemWrapper: any = styled.div`
  color: ${textColor};
  cursor: pointer;
  padding: 6px 0;
  font-size: 1rem;
  border-bottom: 1px solid ${textColor};

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &:hover {
    color: ${styles.primaryAccentColor};
  }
  &:last-child {
    border-bottom: none;
  }
`;

const DropdownItem: React.SFC<IDropdownItemProps> = ({
  kind,
  style,
  text,
  isDisabled,
  onSelect
}) => (
  <DropdowItemWrapper
    className={isDisabled ? "disabled" : "active"}
    style={style}
    kind={kind}
    onClick={onSelect}
    disabled={isDisabled}
  >
    <span>{text}</span>
  </DropdowItemWrapper>
);

DropdownItem.defaultProps = { kind: "default" };

export default DropdownItem;
