import * as React from "react";
import styled from "styled-components";
import Slider from "react-rangeslider";
import { styles } from "../../lib/styles";

interface IProps {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
  floating?: boolean;
}

const SliderContainer = styled.div`
border-radius: 30px;

${props => props["data-floating"] ? `
    width: 150px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: ${styles.primaryOverlayColour};
    padding: 0 20px;
    z-index: 3;

    & .rangeslider__handle {
      padding: 10px !important;
    }

    @media (max-width: ${styles.mobileBreakPoint}) {
      bottom: 10px;
      width: 110px;
    }
  ` : ""}
`;

const RangeSlider = styled(Slider)`
  margin: 18px 0;
  position: relative;
  background: #afbbcc50;
  -ms-touch-action: none;
  touch-action: none;

  .rangeslider__fill {
    display: block;
  }
  .rangeslider__handle {
    width: 13px;
    height: 13px;
    border-radius: 30px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    cursor: pointer;
    display: inline-block;
    position: absolute;

    &:focus {
      outline: none;
    }

    .rangeslider__handle-label {
      width: 13px;
      height: 13px;
      border-radius: 30px;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      background: ${styles.primaryAccentColor};
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
      cursor: pointer;
      display: inline-block;
      position: absolute;
    }
  }
  .rangeslider__handle .rangeslider__active {
    opacity: 1;
  }

  &.rangeslider-horizontal {
    height: 2px;
    border-radius: 10px;
  }
  &.rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: ${styles.secondaryTextColor};
    border-radius: 10px;
    top: 0;
  }

  .rangeslider__handle-tooltip {
    display: none;
  }
`;

// defaulting to floating=true for legacy support 
const StyledSlider = ({ floating = true, ...props }: IProps) => (
  <SliderContainer data-floating={floating}>
    <RangeSlider {...props} />
  </SliderContainer>
);

export default StyledSlider;
