import React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";

const DarkWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: ${styles.primaryDarkColor};
  z-index: 2;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0.3;
`;

const ImagePreviewWrapper: React.SFC<IImagePreviewWrapperProps> = ({ src }) => (
  <DarkWrapper>
    <ImagePreview src={`${process.env.REACT_APP_IMAGE_URL}/${src}`} />
  </DarkWrapper>
);

interface IImagePreviewWrapperProps {
  src: string;
}

export default ImagePreviewWrapper;
