import logger from "src/lib/logger";
import axios from "axios";
import Headers from "../../lib/auth";

import { ICustomer } from "src/store/customers/customers.api";
import { IFolderSizeMetric } from "../camera-locations/camera-locations.api";

export const getSites = async (
  customerId: string,
  status?: string,
  role?: string
): Promise<ISite[]> => {
  try {
    const headers = await Headers(role);

    const result = await axios({
      headers,
      method: "GET",
      params: { status },
      url: `${
        process.env.REACT_APP_API_URL
      }/${role}/sites/customer/${customerId}`
    });

    logger.info(`GET SITES, USING ${role?.toLocaleUpperCase()} CONTROLLER`, result);

    return result.data.data;
  } catch (error) {
    logger.error("GET SITES ERROR", error);
    throw error;
  }
};

export const getSiteById = async (siteId: string): Promise<ISite> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/admin/site/${siteId}`
    });

    logger.info("GET SITE BY ID", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET SITE BY ID ERROR", error);
    throw error;
  }
};

export type CreateSiteParams = Pick<ISite, "geolocation" | "name" | "customer_id" | "security_level"> & { whitelist: string; };
export const createSite = async (site: CreateSiteParams): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: site,
      headers,
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/admin/site`
    });

    logger.info("CREATE SITE", result);

    return result.data.data;
  } catch (error) {
    logger.error("CREATE SITE ERROR", error);
    throw error;
  }
};

export const updateSite = async (siteId: string, site: ISite): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: site,
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/site/${siteId}`
    });

    logger.info("UPDATE SITE", result);

    return result.data.data;
  } catch (error) {
    logger.error("UPDATE SITE ERROR", error);
    throw error;
  }
};

export const suspendSite = async (siteId: string): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/site/status/${siteId}`
    });

    logger.info("SUSPEND SITE", result);

    return result.data.data;
  } catch (error) {
    logger.error("SUSPEND SITE ERROR", error);
    throw error;
  }
};

export const deleteSite = async (siteId: string): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/admin/site/${siteId}`
    });

    logger.info("DELETE SITE", result);

    return result.data.data;
  } catch (error) {
    logger.error("DELETE SITE ERROR", error);
    throw error;
  }
};

/**
 * Interface for the sites reducer
 */
export interface ISiteStore {
  sites: ISite[];
  currentSite: ISite | null;
  site: number | null;
  gettingSites: boolean;
  updatingSite: boolean;
  creatingSite: boolean;
  deletingSite: boolean;
  suspendingSite: boolean;
}

interface IPWhitelist {
  whitelistedIPs: string[];
}

export interface ISite {
  id: number;
  name: string;
  slug: string;
  geolocation: string;
  customer_id: string;
  status: "active" | "suspended";
  created_at: string;
  updated_at: string;
  customer?: ICustomer;
  latest_metrics?: IFolderSizeMetric[];
  security_level: number;
  ip_whitelist: IPWhitelist | null;
}
