import { styles } from "src/lib/styles";
import styled from "styled-components";

export const TableRow = styled.tr`
  @media screen and (max-width: ${styles.mobileBreakPoint}) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background: #141b27;
    border-radius: 5px;
    padding: 10px;
    &::hover {
      background: #141b27 !important;
    }
  }
`;