import React from "react";
import styled from "styled-components";

const TableLoadingContainer = styled.div`
  display: flex;
  height: 40px;
  justify-content: center;
  background: #0A1118;
  padding: 10px;
  @media (min-width: 640px) {
    display: grid;
    place-items: center;
  }
`;

const TableLoadingInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
  color: #a0aec0;
`;

export const TableLoading = () => (
  <TableLoadingContainer>
    <TableLoadingInner></TableLoadingInner>
  </TableLoadingContainer>
);
