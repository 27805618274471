import React from "react";
import styled from "styled-components";
import { Icon } from "../../components";
import { styles } from "../../lib/styles";
import { IImage } from "src/types/store/images";
import getImageByType from "src/lib/getImageByType";
import { IUser } from "src/types/store/users";

const SharingActionsContainer = styled.div`
  max-height: 40px;
  max-width: 100%;
  display: flex;

  > * {
    padding-right: 10px;
  }

  .action-button {
    display: inline-block;
    padding-right: 10px;
  }

  @media (max-width: ${styles.mobileBreakPoint}) {
    display: none;
  }
`;

interface IState {
  originalImage: string | undefined;
}

const initialState: IState = { originalImage: undefined };

class SharingActions extends React.Component<ISharingActions> {
  public readonly state: IState = initialState;

  public async componentDidUpdate(prevProps: ISharingActions) {
    if (this.props.image && this.props.image !== prevProps.image) {
      const imageType = prevProps.auth.admin ? "original" : "desktop-max";

      await getImageByType(imageType, this.props.image.thumbnails).then(result => {
        if (result.s3_url) {
          this.setState({ originalImage: result.s3_url });
        }
      });
    }
  }

  public render() {
    const {
      image,
      auth,
      handleSuspendImage,
      handleHiddenPlayImage,
      user,
      fullSecurity
    } = this.props;

    return (
      <>
        <SharingActionsContainer>

          {user.can_download && !fullSecurity && 
            <a href={this.state.originalImage} download={true} rel="noreferrer" target="_blank">
              <Icon icon="download" size={25} />
            </a>
          }
          {image && auth.admin && (
            <div>
              <div
                className="action-button"
                onClick={() => handleSuspendImage()}
              >
                {image.status !== "suspend" ? (
                  <Icon icon="eye-unhidden" size={25} />
                ) : (
                  <Icon
                    icon="eye-hidden"
                    style={{ fill: styles.secondaryAccentColor }}
                    size={25}
                  />
                )}
              </div>
              <div
                className="action-button"
                style={{ visibility: image.status !== "suspend" ? "visible" : "hidden" }}
                onClick={() => handleHiddenPlayImage()}
              >
                {image.status === "hidden-during-play" ? (
                  <Icon
                    icon="disable-video"
                    size={25}
                    style={{ fill: styles.secondaryAccentColor }}
                  />
                ) : (
                  <Icon icon="disable-video" size={25} />
                )}
              </div>
            </div>
          )}
        </SharingActionsContainer>
      </>
    );
  }
}
interface ISharingActions {
  currentImage?: number;
  auth: { admin: boolean; authenticated: boolean };
  handleSuspendImage: any;
  handleHiddenPlayImage: any;
  image?: IImage;
  user: IUser;
  fullSecurity: boolean;
}

export default SharingActions;
