import { FormikProps } from "formik";
import { Row, Column } from "hedron";
import React from "react";
import { EditCameraFormValues } from "src/containers/admin/edit-camera-location";
import {
  Text,
  TextInput
} from "..";
import FormikCheckbox from "../checkbox/formik-checkbox";

interface CameraHealthSettingsProps {
  formProps: FormikProps<EditCameraFormValues>;
}

export const CameraHealthSettings: React.FC<CameraHealthSettingsProps> = ({ formProps }) => {
  const {
    values, handleChange, handleBlur
  } = formProps;

  return (
    <div>
      <Row>
        <Column>
          <Text fontSize="h3" weight="bold">Camera Health</Text>
        </Column>
      </Row>

      <Row>
        <Column>
          <Text weight="bold">Image frequency</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Text>Maximum time between images for the camera to be considered healthy</Text>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
            marginTop: "0.5rem"
          }}>
            <TextInput
              onChange={handleChange}
              value={values.minimum_image_frequency}
              name="minimum_image_frequency"
              type="number"
              onBlur={handleBlur}
            />
            <Text>Minutes</Text>
          </div>
        </Column>
      </Row>

      <Row>
        <Column>
          <Text weight="bold">Minimum voltage</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Text>Minimum voltage for the camera to be considered healthy</Text>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
            marginTop: "0.5rem"
          }}>
            <TextInput
              onChange={handleChange}
              value={values.minimum_healthy_voltage}
              name="minimum_healthy_voltage"
              type="number"
              onBlur={handleBlur}
            />
            <Text>V</Text>
          </div>
        </Column>
      </Row>

      <Row>
        <Column>
          <Text weight="bold">Health notifications</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <div style={{ marginTop: "0.5rem" }}>
            <FormikCheckbox
              value={values.notifications_muted}
              name="notifications_muted"
              label="All notifications muted"
            />

            <FormikCheckbox
              value={values.voltage_notifications_muted}
              name="voltage_notifications_muted"
              label="Voltage notifications muted"
              disabled={values.notifications_muted}
            />
          </div>
        </Column>
      </Row>
    </div>
  );
};