import logger from "src/lib/logger";
import axios from "axios";
import Headers from "../../lib/auth";

export const getDeletionStats = async (

): Promise<IDeletionStats> => {
  try {
    const headers = await Headers("admin");

    const result = await axios({
      headers,
      method: "GET",
      url: `${
        process.env.REACT_APP_API_URL
      }/admin/deletion-queue/stats`
    });

    logger.info("GET DELETION STATS, USING ADMIN CONTROLLER", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET DELETION STATS ERROR", error);
    throw error;
  }
};

/**
 * Interface for the deletion queue reducer
 */
export interface IDeletionStore {
  deletionStats: IDeletionStats;
  gettingDeletionStats: boolean;
  }
  
export interface IDeletionStats {
    num_camera_locations: number;
    num_images: number;
    num_thumbnails: number;
    num_days_until_deletion: number;
    deletion_items: IDeletionItem[];
  }
  
export interface IDeletionItem {
    id: number | null;
    name: string | null;
    site_name: string | null;
    images_remaining: number | null;
  }
