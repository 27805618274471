import * as React from "react";
import { Column, Row } from "hedron";

interface IProps {
  top: JSX.Element | null;
  topLeft: JSX.Element | null;
  topCenter: JSX.Element | null;
  topRight: JSX.Element | null;
  bottomLeft: JSX.Element | null;
  bottomCenter: JSX.Element | null;
  bottomRight: JSX.Element | null;
}

const MobileFooterLayout = ({
  top,
  topLeft,
  topCenter,
  topRight,
  bottomLeft,
  bottomCenter,
  bottomRight
}: IProps) => {
  return (
    <Row justifyContent="space-between" alignItems="center">
      <Row>
        <Column className="scrubber-wrapper" fluid={true} xs={12} md={8}>
          {top}
        </Column>
      </Row>
      <Row
        justifyContent="space-between"
        alignItems="center"
        style={{ width: "100%" }}
      >
        <Column fluid={true} xs={5}>
          {topLeft}
        </Column>
        <Column
          style={{ whiteSpace: "nowrap" }}
          className="scrubber-wrapper"
          fluid={true}
          xs={2}
        >
          {topCenter}
        </Column>
        <Column
          fluid={true}
          xs={5}
          className="controls-wrapper"
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          {topRight}
        </Column>
      </Row>
      <Row
        justifyContent="space-between"
        alignItems="center"
        style={{
          width: "100%",
          padding: "10px 0" 
        }}
      >
        <Column fluid={true} xs={4}>
          {bottomLeft}
        </Column>
        <Column className="scrubber-wrapper" fluid={true} xs={4}>
          {bottomCenter}
        </Column>
        <Column
          fluid={true}
          xs={4}
          className="controls-wrapper"
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          {bottomRight}
        </Column>
      </Row>
    </Row>
  );
};

export default MobileFooterLayout;
