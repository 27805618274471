import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Card, Header, Text
} from "../../components";
import {
  Row, Column, Page
} from "hedron";
import { styles } from "../../lib/styles";
import styled from "styled-components";
import { signInRedirect, signOutRequest } from "../../store/auth/auth.actions";
import { ThunkDispatch } from "redux-thunk";
import { IStore } from "../../types/store/store";
import * as Redux from "redux";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";

interface IRouterProps {
  message: string;
}

interface IProps extends RouteComponentProps<IRouterProps, any, {code?: string; message?: string; linkText?: string; }> {
  title: string;
}

interface IDispatchProps {
  redirect: (auth: any) => void;
  signOut: () => void;
}

type Props = IProps & IDispatchProps;

const LogoImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  padding: 10px;
  margin: auto;
`;

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: center;
`;

const CardWrapper = styled.div`
  display: flex;
  height: 75vh;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
    padding: 30px;
    width: auto;
  }

  section {
    padding: 20px;
    @media (max-width: 768px) {
      padding: 10px;
    }
  }
`;

const CardPadding = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Link = styled.a`
  color: ${styles.primaryAccentColor};
  transition: ease-in-out 0.2;

  :hover {
    color: ${styles.primaryAccentColor};
    opacity: 0.8;
  }
`;

const ErrorScreen: React.FC<Props> = ({
  location, history, signOut, redirect 
}) => {
  const handleRedirect = () => {
    Auth.currentAuthenticatedUser()
      .then(auth => {
        redirect(auth);
      })
      .catch(err => {
        history.push("/login");
      });
  };

  const errorTitle = React.useMemo(() => {
    switch (location.state.code) {
      case "404": return "Page not found";
      case "403": return "Access denied";
      default: return `Something went wrong${location.state.code ? ` (${location.state.code})` : ""}`;
    }
  }, [location.state.code]);

  return (
    <Page
      fluid={true}
      style={{
        background: styles.secondaryDarkColor,
        height: "100vh",
        overflowY: "scroll"
      }}
    >
      <StyledHeader>
        <LogoImage src={"/logo.jpg"} />
      </StyledHeader>
      <CardWrapper>
        <Card>
          <CardPadding>
            <Column>
              <Text
                style={{
                  color: styles.primaryDarkColor,
                  display: "block",
                  textAlign: "center"
                }}
                weight="bold"
                fontSize="h4"
              >
                {errorTitle}
              </Text>
            </Column>
            <Column fluid={true}>
              <Text
                style={{
                  color: styles.primaryDarkColor,
                  display: "block",
                  textAlign: "center"
                }}
                weight="bold"
                fontSize="h5"
              >
                {location.state.message ||
                    "An unknown error occurred."}
              </Text>
            </Column>
            <Row style={{
              width: "100%",
              justifyContent: "between" 
            }}>
              <Column style={{
                textAlign: "center",
                width: "50%" 
              }}>
                <Link
                  href="#"
                  // @ts-ignore
                  onClick={(e: Event) => {
                    e.preventDefault();
                    handleRedirect();
                  }}
                >
                  {location.state.linkText || "Go back to site"}
                </Link>
              </Column>
              <Column style={{
                textAlign: "center",
                width: "50%" 
              }}>
                <Link href="/logout" onClick={() => signOut()}>Logout</Link>
              </Column>
            </Row>
          </CardPadding>
        </Card>
      </CardWrapper>
      <Row
        justifyContent="center"
        alignItems="center"
        style={{
          width: "100%",
          textAlign: "center"
        }}
      >
        <Column fluid={true} style={{ margin: "50px 0" }}>
          <Text fontSize="p">Interval Films Timelapse Viewer</Text>
        </Column>
      </Row>
    </Page>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return {
    redirect: auth => dispatch(signInRedirect(auth)),
    signOut: () => dispatch(signOutRequest(true)) 
  };
};

export default connect(null,
  mapDispatchToProps)(ErrorScreen);
