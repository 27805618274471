import { Column, Row } from "hedron";
import _ from "lodash";
import * as moment from "moment";
import * as React from "react";
import GoogleAnalytics from "react-ga";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as Redux from "redux";
import { ThunkDispatch } from "redux-thunk";
import { CombinedCamerasDropdown } from "src/components/camera-dropdown";
import RenderSingleDatePicker from "src/components/render-date-picker/render-single-date-picker";
import logger from "src/lib/logger";
import { signOutRequest } from "src/store/auth/auth.actions";
import { getSitesRequest } from "src/store/sites/sites.actions";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { ICustomer } from "src/store/customers/customers.api";
import { IImage } from "src/types/store/images";
import { ISite } from "src/store/sites/sites.api";
import { IStore } from "src/types/store/store";
import { IUser, IUserCameraAssociations } from "src/types/store/users";

import {
  BimToggle,
  CompareDateTimeControls,
  CompareModeDateTimeIndicator,
  DesktopFooterLayout,
  Dropdown,
  ExitCompare,
  Footer,
  Header,
  Icon,
  ImageArrows,
  ImageScrubber,
  ImageViewPanel,
  Loading,
  Logo,
  Menu,
  MobileFooterLayout,
  SuspendedView,
  Text
} from "../../components";
import {
  getClosestDateTo,
  getInterval
} from "../../lib/date-helpers";
import { styles } from "../../lib/styles";
import { getCameraLocationsRequest } from "../../store/camera-locations/camera-locations.actions";
import { cameraLocationGetByUUID } from "../../store/camera-locations/camera-locations.getters";
import { getCustomerRequest } from "../../store/customers/customers.actions";
import { customerGetById } from "../../store/customers/customers.getters";
import {
  getAvailableDatesRequest,
  getImagesByDateRequest,
  purgeImages
} from "../../store/images/images.actions";
import { siteGetById } from "../../store/sites/sites.getters";
import { getUserCamerasRequest } from "../../store/users/users.actions";
import { IAuthStore } from "../../types/store/auth";

interface IImageCompareRouterProps {
  customerId: string;
  siteId: string;
  cameraLocationUUID: string;
}

type IImageCompareProps = RouteComponentProps<IImageCompareRouterProps>;

interface IDispatchProps {
  getUserCameras: (userId: number) => void;
  getAvailableDates: (cameraLocationId: string) => void;
  getCustomer: (customerId: string) => void;
  getCameraLocations: (siteId: string, status: string, redirect: boolean) => void;
  getSites: (customerId: string) => void;
  getImages: (
    startDate: string,
    endDate: string,
    jobRef: string,
    status: string,
    compare: boolean
  ) => void;
  purgeImageStore: () => void;
  signOut: () => void;
}

interface IStateProps {
  user: IUser | null;
  auth: IAuthStore;
  customer: ICustomer | null;
  site: ISite | null;
  sites: ISite[];
  dates: string[];
  images: IImage[];
  compareImages: IImage[];
  loadingCustomer: boolean;
  loadingLocations: boolean;
  userCameras: IUserCameraAssociations[];
  cameraLocations: ICameraLocation[];
  cameraLocation: ICameraLocation | null;
}

interface ICompareState {
  name: "compare-a" | "compare-b";
  images: IImage[];
  active: number | null;
  hovered: number | null;
  dragged: number | null;
  selected: boolean;
  date: moment.Moment;
  isCalendarOpen: boolean;
}

interface IState {
  transform: {
    left: number;
    top: number;
    scale: number | string;
    posTop: number;
    posLeft: number;
  };
  compareMode: "vertical" | "horizontal" | "overlay" | "difference";
  zoomed: boolean;
  isChangingDate: boolean;
  isOverlayActive: boolean;
  compareA: ICompareState;
  compareB: ICompareState;
  loadingImages: boolean;
}

const initialState: IState = {
  compareA: {
    active: null,
    date: moment.utc().startOf("day"),
    dragged: null,
    hovered: null,
    images: [],
    isCalendarOpen: false,
    name: "compare-a",
    selected: true
  },
  compareB: {
    active: null,
    date: moment.utc().startOf("day"),
    dragged: null,
    hovered: null,
    images: [],
    isCalendarOpen: false,
    name: "compare-b",
    selected: false
  },
  compareMode: "vertical",
  isChangingDate: false,
  isOverlayActive: true,
  loadingImages: false,
  transform: {
    left: 0,
    top: 0,
    scale: "auto",
    posTop: 0,
    posLeft: 0
  },
  zoomed: false
};

type Props = IImageCompareProps & IStateProps & IDispatchProps;
class ImageCompare extends React.Component<Props> {
  public readonly state: IState = initialState;
  public imageRef: any;
  public timer: NodeJS.Timer;
  private boundOnKeydown: () => void;
  private wrapper = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.boundOnKeydown = this.handleKeyEvent.bind(this);
  }

  public UNSAFE_componentWillMount() {
    document.addEventListener(
      "keydown", this.boundOnKeydown, { passive: true }
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.boundOnKeydown);
  }

  public async componentDidMount() {
    const {
      auth,
      getAvailableDates,
      getCameraLocations,
      getCustomer,
      customer,
      getSites,
      site,
      userCameras,
      getUserCameras,
      user
    } = this.props;

    const {
      cameraLocationUUID, siteId, customerId
    } = this.props.match.params;

    const isPublic = this.props.location.pathname.includes("public");

    if (isPublic && user) {
      const newPath = this.props.location.pathname.replace("public",
        "customer");

      // If user is logged in, and link is public, then redirect
      this.props.history.push(newPath);
    }

    try {
      if (cameraLocationUUID === "find") {
        await getCameraLocations(
          siteId, "active", true
        );

        return;
      } else if (!site || site.id.toString() !== siteId) {
        await getCameraLocations(
          siteId, "active", false
        );
      }

      await getAvailableDates(cameraLocationUUID);
      this.handleSetActive(0);
      this.getInitialImageSets();

      if (!customer || customer.id.toString() !== customerId) {
        await getCustomer(customerId);

        if (!auth.authenticated) {
          // If admin, or if they aren't logged in at all,
          // we need to get sites rather than use userCameras
          await getSites(customerId);
        }

        if ((!userCameras || userCameras.length === 0) && user) {
          getUserCameras(user.id);
        }
      }

      GoogleAnalytics.event({
        action: "Compare Mode",
        category: "Navigation",
        label: `${
          this.props.customer ? this.props.customer.name : "Unknown"
        } - ${this.props.site ? this.props.site.name : "Unknown"} - ${
          this.props.cameraLocation ? this.props.cameraLocation.name : "Unknown"
        }`
      });
    } catch (error) {
      logger.error(error);
    }

    setTimeout(() => {
      this.setState({ isOverlayActive: false });
    }, 3000);
  }

  public async UNSAFE_componentWillReceiveProps(newProps: Props) {
    const {
      auth,
      getAvailableDates,
      getCustomer,
      getCameraLocations,
      getSites,
      userCameras,
      getUserCameras,
      user
    } = this.props;

    const {
      cameraLocationUUID, customerId, siteId
    } = this.props.match.params;

    const newParams = newProps.match.params;

    if (newProps.images !== this.state.compareA.images) {
      this.setSelectedState("compare-a", { images: newProps.images });
    }

    if (newProps.compareImages !== this.state.compareB.images) {
      this.setSelectedState("compare-b", { images: newProps.compareImages });
    }

    // handle change is site
    // handles change camera
    if (siteId !== newParams.siteId && newParams.cameraLocationUUID === "find") {
      // handle change in camera
      await getCameraLocations(
        newParams.siteId, "active", true
      );

      GoogleAnalytics.event({
        action: "Site",
        category: "Navigation",
        label: `${
          this.props.customer ? this.props.customer.name : "Unknown"
        } - ${this.props.site ? this.props.site.name : "Unknown"}`
      });

      return;
    } else if (siteId !== newParams.siteId) {
      await getCameraLocations(
        newParams.siteId, "active", false
      );

      GoogleAnalytics.event({
        action: "Site",
        category: "Navigation",
        label: `${
          this.props.customer ? this.props.customer.name : "Unknown"
        } - ${this.props.site ? this.props.site.name : "Unknown"}`
      });
    } else if (
      cameraLocationUUID !== "find" &&
      newParams.cameraLocationUUID === "find"
    ) {
      this.props.history.goBack();

      return;
    }

    // handle change in camera
    if (cameraLocationUUID !== newParams.cameraLocationUUID) {
      try {
        await getAvailableDates(newParams.cameraLocationUUID);
        this.handleSetActive(0);

        this.setState({
          transform: {
            left: 0,
            top: 0,
            scale: "auto"
          }
        },
        () => this.getInitialImageSets());

        GoogleAnalytics.event({
          action: "Camera location",
          category: "Navigation",
          label: `${
            this.props.customer ? this.props.customer.name : "Unknown"
          } - ${this.props.site ? this.props.site.name : "Unknown"} - ${
            this.props.cameraLocation
              ? this.props.cameraLocation.name
              : "Unknown"
          }`
        });
      } catch (error) {
        logger.error(error);
      }
    }

    if (customerId !== newParams.customerId) {
      await getCustomer(newParams.customerId);

      if (!auth.authenticated) {
        // If admin, or if they aren't logged in at all,
        // we need to get sites rather than use userCameras
        await getSites(customerId);
      }

      if ((!userCameras || userCameras.length === 0) && user) {
        getUserCameras(user.id);
      }

      GoogleAnalytics.event({
        action: "Customer",
        category: "Navigation",
        label: this.props.customer ? this.props.customer.name : "Unknown"
      });
    }
  }

  public render() {
    const { customer } = this.props;
    const { compareA, compareB } = this.state;
    const selected = compareA.selected ? compareA : compareB;

    const adminMenuitem = this.props.auth.admin
      ? [
        {
          function: () => this.props.history.push("/admin/customers"),
          label: "Admin"
        }
      ]
      : [];

    const loginMenuItem = this.props.auth.authenticated
      ? [
        {
          function: () => this.props.signOut(),
          label: "Logout"
        }
      ]
      : [
        {
          function: () => this.props.signOut(),
          label: "Login"
        }
      ];

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    let minusHeight = 0;
    const footer = document.getElementById("footer");
    const header = document.getElementById("header");

    if (footer && header) {
      minusHeight = footer.clientHeight + header.clientHeight;
    }

    return (
      <div ref={this.wrapper} key={this.props.match.params.cameraLocationUUID}>
        <Header kind="compare">
          <Row alignItems="center" style={{
            width: "100vw",
            padding: "10px"
          }}>
            <Logo logo={customer ? customer.logo || "" : ""} />
            <Column xs={7} sm={6} md={5} fluid={true}>
              <CombinedCamerasDropdown
                auth={this.props.auth}
                customer={this.props.customer}
                sites={this.props.sites}
                handleCameraChange={data => this.handleCameraChange(data)}
                userCameras={this.props.userCameras}
                cameraLocations={this.props.cameraLocations}
                cameraLocation={this.props.cameraLocation}
              />
            </Column>
            {this.renderCompareDropdown("header")}
            <ExitCompare
              handleToggleCompare={() => this.handleToggleCompare()}
            />
            <Menu menuItems={[...adminMenuitem, ...loginMenuItem]} />
          </Row>
        </Header>
        {this.renderLoading()}
        <div
          style={{
            height: windowHeight - minusHeight,
            position: "relative",
            width: windowWidth
          }}
        >
          {this.renderImageCompare(selected)}
        </div>

        <Footer>
          {window.matchMedia("(max-width: 800px)").matches ? (
            <MobileFooterLayout
              top={this.renderImageScrubber()}
              topLeft={
                <CompareDateTimeControls
                  active={this.getSelected().name === "compare-b"}
                >
                  <React.Fragment>
                    <div
                      className="compareTimeIndicator"
                      onClick={() => this.handleSelectToggle("compare-b")}
                    >
                      <Icon icon="clock" />
                    </div>
                    {this.renderDatePicker("compare-b")}
                    <div className="compareDateIndicator">
                      <Text>
                        {this.getSelected("compare-b").date.format("DD MMM")}
                      </Text>
                      <Text>
                        {this.getSelected("compare-b").date.format("YYYY")}
                      </Text>
                    </div>
                  </React.Fragment>
                </CompareDateTimeControls>
              }
              topCenter={null}
              topRight={
                <CompareDateTimeControls
                  style={{ textAlign: "right" }}
                  active={this.getSelected().name === "compare-a"}
                >
                  <React.Fragment>
                    <div className="compareDateIndicator">
                      <Text>
                        {this.getSelected("compare-a").date.format("DD MMM")}
                      </Text>
                      <Text>
                        {this.getSelected("compare-a").date.format("YYYY")}
                      </Text>
                    </div>
                    {this.renderDatePicker("compare-a")}
                    <div
                      className="compareTimeIndicator"
                      onClick={() => this.handleSelectToggle("compare-a")}
                    >
                      <Icon icon="clock" />
                    </div>
                  </React.Fragment>
                </CompareDateTimeControls>
              }
              bottomLeft={this.renderCompareDropdown("footer")}
              bottomCenter={null}
              bottomRight={this.renderBimToggle()}
            />
          ) : (
            <DesktopFooterLayout
              midLeft={null}
              left={
                <CompareDateTimeControls
                  style={{ textAlign: "right" }}
                  active={this.getSelected().name === "compare-b"}
                >
                  <React.Fragment>
                    <div className="compareDateIndicator">
                      <Text>
                        {this.getSelected("compare-b").date.format("DD MMM")}
                      </Text>
                      <Text>
                        {this.getSelected("compare-b").date.format("YYYY")}
                      </Text>
                    </div>
                    {this.renderDatePicker("compare-b")}
                    <div
                      className="compareTimeIndicator"
                      onClick={() => this.handleSelectToggle("compare-b")}
                    >
                      <Text>{this.getImageTime("compare-b")}</Text>
                      <Icon icon="clock" />
                    </div>
                  </React.Fragment>
                </CompareDateTimeControls>
              }
              midCenter={this.renderImageScrubber()}
              right={
                <CompareDateTimeControls
                  active={this.getSelected().name === "compare-a"}
                >
                  <React.Fragment>
                    <div
                      className="compareTimeIndicator"
                      onClick={() => this.handleSelectToggle("compare-a")}
                    >
                      <Icon icon="clock" />
                      <Text>{this.getImageTime("compare-a")}</Text>
                    </div>
                    {this.renderDatePicker("compare-a")}
                    <div className="compareDateIndicator">
                      <Text>
                        {this.getSelected("compare-a").date.format("DD MMM")}
                      </Text>
                      <Text>
                        {this.getSelected("compare-a").date.format("YYYY")}
                      </Text>
                    </div>
                  </React.Fragment>
                </CompareDateTimeControls>
              }
              midRight={null}
            />
          )}
        </Footer>
      </div>
    );
  }

  private getImageTime(type: "compare-a" | "compare-b", format?: string) {
    const image = this.getActiveImage(type);

    if (image) {
      return moment.utc(image.taken_at).format(format || "HH:mm");
    } else {
      return "";
    }
  }

  private renderBimToggle() {
    return <BimToggle disabled={true} />;
  }
  private renderCompareDropdown(kind: string) {
    const { compareMode } = this.state;

    const data = [
      {
        data: "vertical",
        text: "Vert. Wipe"
      },
      {
        data: "horizontal",
        text: "Horz. Wipe"
      },
      {
        data: "overlay",
        text: "Overlay"
      },
      {
        data: "difference",
        text: "Difference"
      }
    ];

    return (
      <React.Fragment>
        <Dropdown
          direction={kind === "footer" ? "up" : "down"}
          kind={kind}
          selected={data.map(e => e.data).indexOf(compareMode)}
          isOverlayActive={true}
          onSelect={(selection: any) =>
            this.setState({ compareMode: selection })
          }
          items={data}
        />
      </React.Fragment>
    );
  }

  private handleSetActive(active: number, name?: "compare-a" | "compare-b") {
    this.setState({ zoomed: false });
    const selected = this.getSelected(name);

    this.setSelectedState(selected.name, { active });
  }

  private handleHover(hovered: number | null) {
    const { compareA, compareB } = this.state;

    if (compareA.selected) {
      if (compareA.dragged === null) {
        this.setState({
          compareA: {
            ...compareA,
            hovered
          }
        });
      }
    } else {
      if (compareB.dragged === null) {
        this.setState({
          compareB: {
            ...compareB,
            hovered
          }
        });
      }
    }
  }

  private handleDrag(dragged: number | null) {
    const selected = this.getSelected();

    this.setSelectedState(selected.name, { dragged });
  }

  private renderImageScrubber() {
    const selected = this.getSelected();

    const {
      loadingImages, isChangingDate, compareMode
    } = this.state;

    return (
      <div style={{ position: "relative" }}>
        {(loadingImages || isChangingDate) && <Loading />}
        <ImageScrubber
          images={selected.images}
          compareImage={selected.name === "compare-b" ? "left" : "right"}
          handleMouseMove={() => this.handleMouseMove()}
          dragged={selected.dragged}
          hovered={selected.hovered}
          active={selected.active}
          setActive={active => this.handleSetActive(active)}
          setHovered={hovered => this.handleHover(hovered)}
          setDragged={dragged => this.handleDrag(dragged)}
          isPlaying={false}
          renderDateTimeIndicator={
            <React.Fragment>
              <CompareModeDateTimeIndicator
                kind="left"
                compareMode={compareMode}
                handleClick={() => this.handleSelectToggle("compare-b")}
                active={this.getSelected().name === "compare-b"}
                dateTime={this.getImageTime("compare-b",
                  "ddd DD MMM YYYY HH:mm")}
              />
              <CompareModeDateTimeIndicator
                kind="right"
                compareMode={compareMode}
                handleClick={() => this.handleSelectToggle("compare-a")}
                active={this.getSelected().name === "compare-a"}
                dateTime={this.getImageTime("compare-a",
                  "ddd DD MMM YYYY HH:mm")}
              />
            </React.Fragment>
          }
        />
      </div>
    );
  }

  private getActiveImageDateTime(): moment.Moment | null {
    const currentImage = this.getActiveImage();

    if (currentImage) {
      return moment.utc(currentImage.taken_at);
    }

    return null;
  }

  private getActiveImage(name?: "compare-a" | "compare-b"): IImage | null {
    const selected = this.getSelected(name);

    if (
      !this.state.loadingImages &&
      selected.active !== null &&
      selected.images.length
    ) {
      return selected.images[selected.active];
    } else {
      return null;
    }
  }

  private getDraggedImage(name?: "compare-a" | "compare-b"): IImage | null {
    const selected = this.getSelected(name);

    if (
      !this.state.loadingImages &&
      selected.dragged !== null &&
      selected.images.length
    ) {
      return selected.images[selected.dragged];
    } else {
      return null;
    }
  }

  private renderDatePicker = (name: "compare-a" | "compare-b") => {
    const selected = this.getSelected(name);
    const { dates } = this.props;

    return (
      <RenderSingleDatePicker
        className={name}
        initialDate={selected.date}
        activeTime={selected.date}
        datesList={dates}
        handleDateChange={(date: moment.Moment) =>
          this.handleDateChange(name, date)
        }
      />
    );
  };

  private handleSelectToggle(name: "compare-a" | "compare-b") {
    this.setSelectedState(name, {
      selected: true,
      isCalendarOpen: false
    });

    this.setSelectedState(name === "compare-a" ? "compare-b" : "compare-a", {
      isCalendarOpen: false,
      selected: false
    });
  }

  private renderImageCompare(selected: ICompareState): React.ReactChild {
    const {
      customer, site, cameraLocation, dates
    } = this.props;

    const { zoomed } = this.state;
    let suspended = false;

    if (cameraLocation && cameraLocation.status === "suspended") {
      suspended = true;
    } else if (site && site.status === "suspended") {
      suspended = true;
    } else if (customer && customer.status === "suspended") {
      suspended = true;
    }

    if (suspended && !this.props.auth.admin) {
      return <SuspendedView />;
    } else if (selected.images.length > 0) {
      return (
        <React.Fragment>
          <Row style={{ position: "relative" }}>
            <ImageViewPanel
              wait={500}
              zoomed={zoomed}
              handleZoom={(didZoom: boolean) => this.handleZoom(didZoom)}
              dragged={this.getDraggedImage("compare-a")}
              compareDragged={this.getDraggedImage("compare-b")}
              image={this.getActiveImage("compare-a")}
              compareImage={this.getActiveImage("compare-b")}
              activeCompareImage={this.getSelected().name}
              compareMode={this.state.compareMode}
              isCompareActive={true}
              isOverlayActive={this.state.isOverlayActive}
              isPlaying={false}
              handleMouseMove={() => this.handleMouseMove()}
              handleToggleCompare={() => this.handleToggleCompare()}
              transform={{
                left: this.state.transform.left,
                posLeft: this.state.transform.posLeft,
                posTop: this.state.transform.posTop,
                scale: this.state.transform.scale,
                top: this.state.transform.top
              }}
            />
            <ImageArrows
              isOverlayActive={this.state.isOverlayActive}
              total={selected.images.length}
              current={selected.active}
              startDate={selected.date}
              endDate={selected.date}
              dates={dates}
              handleNext={() => this.handleNextImage()}
              handlePrev={() => this.handlePrevImage()}
            />
          </Row>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  }

  private handleToggleCompare() {
    const { match, history } = this.props;
    let url = "customer";

    if (match.path.includes("/public/")) {
      url = "public";
    }

    history.push(`/${url}/${match.params.customerId}/site/${match.params.siteId}/camera/${match.params.cameraLocationUUID}/images`);
  }

  private handleMouseMove() {
    if (!this.state.isOverlayActive) {
      this.setState({ isOverlayActive: true });

      setTimeout(() => {
        this.setState({ isOverlayActive: false });

        return;
      }, 3000);
    }
  }

  private getClosestImagetoTimestamp(
    images: IImage[],
    time: moment.Moment,
    latest?: boolean
  ) {
    let target = moment.utc(time).utc();

    if (latest) {
      return images.length - 1;
    }

    if (images.length) {
      const firstImage = moment.utc(images[0].taken_at).utc();
      let diff = firstImage.startOf("day").diff(target, "days");
      const adjust = firstImage.isAfter(target) ? 1 : 0;

      if (latest) {
        diff = 0;
      }

      diff = diff + adjust;
      target = target.add(diff, "days").utc();

      const sorted: IImage[] = _.filter(images,
        image => image.taken_at !== null);

      sorted.sort((a, b) => {
        const timeA = moment.utc(a.taken_at).utc();
        const timeB = moment.utc(b.taken_at).utc();
        const dA = Math.abs(timeA.valueOf() - target.valueOf());
        const dB = Math.abs(timeB.valueOf() - target.valueOf());

        if (dA < dB) {
          return -1;
        } else if (dA > dB) {
          return 1;
        } else {
          return 0;
        }
      });

      const indexToReturn = images
        .map(item => item.taken_at)
        .indexOf(sorted[0].taken_at);

      return indexToReturn;
    }

    return 0;
  }

  private handleKeyEvent(e: KeyboardEvent) {
    const key = e.key;

    this.setState({ isOverlayActive: false });

    if (e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) {
      return;
    }

    switch (key) {
      case "ArrowRight":
        this.handleNextImage();
        break;
      case "ArrowLeft":
        this.handlePrevImage();
        break;
      case ",":
        this.handlePrevInterval();
        break;
      case ".":
        this.handleNextInterval();
        break;
      case "a":
        this.handleSelectToggle("compare-a");
        break;
      case "b":
        this.handleSelectToggle("compare-b");
        break;

      default:
    }
  }

  private handleNextImage() {
    const selected = this.getSelected();

    if (this.wrapper) {
      if (selected.images && selected.active !== null) {
        if (selected.active < selected.images.length - 1) {
          this.handleSetActive(selected.active + 1);
        } else {
          this.handleNextInterval();
        }
      }
    }
  }

  private handlePrevImage() {
    const selected = this.getSelected();

    if (this.wrapper) {
      if (selected.active !== null && selected.active > 0) {
        this.handleSetActive(selected.active - 1);
      } else {
        this.handlePrevInterval();
      }
    }
  }

  private getSelected(name?: "compare-a" | "compare-b"): ICompareState {
    const { compareA, compareB } = this.state;

    if (name) {
      return name === "compare-a" ? compareA : compareB;
    }

    return compareA.selected ? compareA : compareB;
  }

  private handlePrevInterval(targetDate?: moment.Moment) {
    const { dates } = this.props;
    const selected = this.getSelected();
    const interval = getInterval(selected.date, selected.date);
    const dateIndex = _.findIndex(dates, d => moment(d, "YYYY-MM-DD").isSame(selected.date.format("YYYY-MM-DD"), "day"));
    const targetStartDate = targetDate || selected.date.clone().subtract(interval, "days");
    const nextImageDate = moment(new Date(dates[dateIndex + 1])).startOf("day"); // start of day for start of interval
    const selectedDate = selected.date.clone().subtract(interval, "days");

    if (dateIndex !== dates.length - 1) {
      if (nextImageDate.isSameOrBefore(targetStartDate, "day")) {
        this.handleDateChange(selected.name, nextImageDate);
      } else {
        let newState = {};

        if (selected.name === "compare-a") {
          newState = {
            compareA: {
              ...this.state.compareA,
              date: selectedDate.clone()
            }
          };
        } else {
          newState = {
            compareB: {
              ...this.state.compareB,
              date: selectedDate.clone()
            }
          };
        }

        this.setState(newState, () => {
          this.handlePrevInterval(targetStartDate);
        });
      }
    }
  }

  private handleNextInterval(targetDate?: moment.Moment) {
    const selected = this.getSelected();
    const { dates } = this.props;
    const interval = getInterval(selected.date, selected.date);
    const dateIndex = _.findIndex(dates, d => moment(d, "YYYY-MM-DD").isSame(selected.date.format("YYYY-MM-DD"), "day"));
    const targetStartDate = targetDate || selected.date.clone().add(interval, "days");
    const nextImageDate = moment(new Date(dates[dateIndex - 1])).startOf("day"); // start of day for start of interval

    if (dateIndex > 0) {
      if (nextImageDate.isSameOrAfter(targetStartDate, "day")) {
        this.handleDateChange(selected.name, nextImageDate);
      } else {
        let newState = {};

        if (selected.name === "compare-a") {
          newState = {
            compareA: {
              ...this.state.compareA,
              date: nextImageDate.clone()
            }
          };
        } else {
          newState = {
            compareB: {
              ...this.state.compareB,
              date: nextImageDate.clone()
            }
          };
        }

        this.setState(newState, () => {
          this.handleNextInterval(targetStartDate);
        });
      }
    }
  }

  private handleZoom(didZoom: boolean) {
    if (didZoom) {
      this.setState({ zoomed: didZoom });
    }
  }

  private async getInitialImageSets() {
    const { compareA, compareB } = this.state;
    const { dates } = this.props;

    if (dates) {
      const compareDate = getClosestDateTo(moment.utc(dates[0]).subtract(31, "days"),
        dates);

      this.setState({
        compareA: {
          ...compareA,
          active: null,
          date: moment.utc(dates[0])
        },
        compareB: {
          ...compareB,
          active: null,
          date: compareDate
        },
        loadingImages: true
      },
      async () => {
        await this.getViewerImages("compare-a", true);
        await this.getViewerImages("compare-b", true);
        this.setState({ loadingImages: false });
      });
    }
  }

  private async handleDateChange(name: "compare-a" | "compare-b",
    date: moment.Moment) {
    this.setState({ isChangingDate: true });

    this.setSelectedState(
      name,
      {
        date: date.clone(),
        isCalendarOpen: false
      },
      async () => {
        await this.getViewerImages(name);
        this.setState({ isChangingDate: false });
      }
    );
  }

  private renderLoading() {
    if (this.props.loadingCustomer || this.props.loadingLocations) {
      return (
        <Row>
          <Column
            style={{
              alignItems: "center",
              display: "flex",
              height: `calc(100vh - ${styles.header})`,
              justifyContent: "center"
            }}
          >
            <Text fontSize="h4">Loading...</Text>
          </Column>
        </Row>
      );
    } else if (this.props.images.length <= 0) {
      return (
        <Row>
          <Column
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              height: `calc(100vh - ${styles.header})`,
              justifyContent: "center"
            }}
          >
            <Text fontSize="h4">Sorry, but there aren&apos;t any images to show you for this camera!</Text>
            <Text fontSize="h4">If this doesn&apos;t seem right please contact the office on 0117 3708519 or email <a href="mailto:support@intervalfilms.com" style={{ color: styles.primaryAccentColor }}>support@intervalfilms.com</a>.</Text>
          </Column>
        </Row>
      );
    }

    return null;
  }

  private async getViewerImages(compare?: "compare-a" | "compare-b",
    latest = false) {
    const { cameraLocation, auth } = this.props;
    const selected = this.getSelected(compare);

    const time = latest
      ? moment.utc().endOf("day")
      : this.getActiveImageDateTime();

    const start = selected.date.startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    const end = selected.date.endOf("day").format("YYYY-MM-DDTHH:mm:ss");
    const status = auth.admin ? "" : "active";

    try {
      if (cameraLocation) {
        await this.props.getImages(
          start,
          end,
          cameraLocation.job_ref,
          status,
          compare === "compare-b"
        );

        const closestIndex = this.getClosestImagetoTimestamp(this.getSelected(compare).images,
          time!);

        this.handleSetActive(closestIndex, compare);
      }
    } catch (error) {
      logger.error(error);
    }
  }

  private async handleCameraChange(userCameraLocation: IUserCameraAssociations) {
    const { history, match } = this.props;
    let url = "customer";

    if (match.path.includes("/public/")) {
      url = "public";
    }

    history.push(`/${url}/${userCameraLocation.site.customer.id}/site/${userCameraLocation.site.id}/camera/${userCameraLocation.uuid}/images`);
  }

  private setSelectedState(
    name: "compare-a" | "compare-b",
    fields: { [P in keyof ICompareState]?: ICompareState[P] },
    callback?: () => void
  ) {
    const { compareA, compareB } = this.state;

    if (name === "compare-a") {
      this.setState({
        compareA: {
          ...compareA,
          ...fields
        }
      },
      () => {
        if (callback) {
          callback();
        }

        return;
      });
    } else {
      this.setState({
        compareB: {
          ...compareB,
          ...fields
        }
      },
      () => {
        if (callback) {
          callback();
        }

        return;
      });
    }
  }
}

const mapStateToProps = (state: IStore, props: Props): IStateProps => {
  const {
    dates, images, compareImages
  } = state.images;

  const {
    cameraLocationUUID, customerId, siteId
  } = props.match.params;

  return {
    auth: state.auth,
    cameraLocation: cameraLocationGetByUUID(state, cameraLocationUUID),
    cameraLocations: state.cameraLocations.cameraLocations,
    compareImages,
    customer: customerGetById(state, customerId),
    dates,
    images,
    loadingCustomer: state.customers.gettingCustomer,
    loadingLocations: state.cameraLocations.gettingCameraLocations,
    site: siteGetById(state, siteId),
    sites: state.sites.sites,
    user: state.users.currentUser,
    userCameras: state.users.userCameras
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>,
  props: Props): IDispatchProps => {
  // based on the url we know if we want to run public or private actions
  let role = "client";

  if (props.match.path.includes("/public/")) {
    role = "public";
  }

  return {
    getAvailableDates: (cameraLocationId: string) =>
      dispatch(getAvailableDatesRequest(cameraLocationId, role)),
    getCameraLocations: (
      siteId: string, status: string, redirect: boolean
    ) =>
      dispatch(getCameraLocationsRequest(
        siteId, role, status, 1, redirect
      )),
    getCustomer: (customerId: string) =>
      dispatch(getCustomerRequest(customerId, role)),

    getImages: (
      startDate: string,
      endDate: string,
      jobRef: string,
      status: string,
      compare: boolean
    ) =>
      dispatch(getImagesByDateRequest(
        startDate,
        endDate,
        jobRef,
        status,
        role,
        compare
      )),
    getSites: (customerId: string) =>
      dispatch(getSitesRequest(
        customerId, "active", role
      )),
    getUserCameras: userId => dispatch(getUserCamerasRequest(userId, false)),
    purgeImageStore: () => dispatch(purgeImages()),
    signOut: () => dispatch(signOutRequest(true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCompare);
