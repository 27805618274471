import * as AT from "./deletion-queue.constants"; 
import * as Redux from "redux";
import { push, RouterAction } from "react-router-redux";
import { toast } from "react-toastify";
import apiError from "src/lib/apiError";
import { getDeletionStats } from "./deletion-queue.api"; 
import { AxiosError } from "axios";
import { IDeletionStats } from "./deletion-queue.api";
import { IStore } from "src/types/store/store";
import { ThunkAction } from "redux-thunk";

export type DeletionQueueActions =
| IGetDeletionStats
| IGetDeletionStatsSuccess
| IGetDeletionStatsFailure;
type ThunkResult<R> = ThunkAction<
  R,
  IStore,
  undefined,
  DeletionQueueActions | RouterAction
>;

export interface IGetDeletionStats {
    type: AT.GET_DELETION_STATS;
}

export interface IGetDeletionStatsSuccess {
    data: IDeletionStats;
    type: AT.GET_DELETION_STATS_SUCCESS;
}

export interface IGetDeletionStatsFailure {
    type: AT.GET_DELETION_STATS_FAILURE;
}

export const getDeletionStatsAction: Redux.ActionCreator<IGetDeletionStats> = () => {
  return { type: AT.GET_DELETION_STATS };
};

export const getDeletionStatsSuccess: Redux.ActionCreator<IGetDeletionStatsSuccess> = (data: IDeletionStats) => {
  return {
    data,
    type: AT.GET_DELETION_STATS_SUCCESS
  };
};

export const getDeletionStatsFailure: Redux.ActionCreator<IGetDeletionStatsFailure> = () => {
  return { type: AT.GET_DELETION_STATS_FAILURE };
};

export const getDeletionStatsRequest = (): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getDeletionStatsAction());

    try {
      const data = await getDeletionStats();

      dispatch(getDeletionStatsSuccess(data));
    } catch (error) {
      handleApiError(dispatch, error);
      dispatch(getDeletionStatsFailure());
    }
  };
};

const handleApiError = (dispatch: (arg0: RouterAction) => void, error: AxiosError<any>) => {
  if (error.response && error.response.status === 401) {
    dispatch(push("/logout"));
  }

  if (error.response && error.response.status === 403) {
    dispatch(push("/error", {
      code: "403",
      message: "You do not have permission to access this item."
    }));
  }

  toast.error(apiError(error));
};
