import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import Text from "../text/text";

interface ImageUploaderProps {
  handleUpload: (acceptedFiles: File[]) => void;
  allowedFiles: string[];
  multiple: boolean;
  label?: string;
  files?: File[];
  loading?: boolean;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  handleUpload, allowedFiles, multiple, label, loading
}) => {
  const onDrop = useCallback(acceptedFiles => {
    handleUpload(acceptedFiles);
  }, [handleUpload]);

  const {
    getRootProps, getInputProps, isDragActive, isDragReject
  } = useDropzone({
    onDrop,
    multiple,
    accept: allowedFiles
  });

  return (
    <StyledDropZone {...getRootProps()} className={isDragReject ? "reject" : isDragActive ? "active" : ""}>
      {loading && <Text>Loading...</Text>}
      <input {...getInputProps()} />
      {isDragReject && <p>File type not accepted</p>}
      {(isDragActive && !isDragReject) && <p>Drop file here ...</p>}
      {!isDragActive && <p>{label || "Drag and drop file here, or click to select from the file browser."}</p>}
    </StyledDropZone>
  );
};

const StyledDropZone = styled.div`
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  border-radius: 6px;
  border: none;
  box-shadow: 0 0 0 1px ${styles.fadedTextColor};
  margin: 0.5rem 0;
  background-color: ${styles.formBackground};
  color: ${styles.fadedTextColor};
  font-size: smaller;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  &.accept {
    box-shadow: 0 0 0 2px ${styles.primaryAccentColor};
    color: ${styles.primaryAccentColor};
  }
  &.reject {
    box-shadow: 0 0 0 2px ${styles.secondaryAccentColor};
    color: ${styles.secondaryAccentColor};
  }
`;
