import * as React from "react";
import { styles } from "src/lib/styles";
import styled from "styled-components";
import Text from "../text/text";

const Section: React.FC<{
  header: string | ((headerWrapper: React.FC) => JSX.Element);
}> = ({ children, header }) => {
  const HeadingWrapper = React.useMemo(() => (({ children }) => <Text fontSize="h5" weight="bold">{children}</Text>) as React.FC, []);
  
  return (
    <Styled.Section>
      {typeof header === "string" ? <HeadingWrapper>{header}</HeadingWrapper> : header(HeadingWrapper)}

      <br />
      <br />
      
      {children}
    </Styled.Section>
  );
};

const SectionsContainer = (args: Parameters<typeof Styled.SectionsContainer>[0]) => <Styled.SectionsContainer {...args} />;

const Styled = {
  SectionsContainer: styled.div`
    border-radius: 15px;
    overflow: hidden;
    background: ${styles.primaryOverlayColour};
    width: 100%;
  `,
  Section: styled.section`
    padding: 1rem;

    @media screen and (min-width: 768px) {
      padding: 20px;
      padding-right: 26px;
    }

    @media screen and (min-width: 1200px) {
      padding: 2rem;
      padding-right: 2.5rem;
      padding-bottom: 2.5rem;
    }

    & + & {
      border-top: 2px solid ${styles.primaryDarkColor};
    }
  `
};

export const Sections = {
  Container: SectionsContainer,
  Section
};