import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import { styles } from "../../lib/styles";

interface ICompareDateTimeControls {
  active?: boolean;
  kind?: "left" | "right";
  dateTime: moment.Moment | string;
  handleClick: () => void;
  compareMode: "vertical" | "horizontal" | "overlay" | "difference";
}

interface IWrapper {
  active?: boolean;
  kind?: "left" | "right";
  compareMode: "vertical" | "horizontal" | "overlay" | "difference";
}

const Wrapper = styled.div<IWrapper>`
  display: flex;
  opacity: ${props => (props.active ? 1 : 0.6)};
  transition: opacity 0.3s ease-in-out;
  z-index: 10;
  position: fixed;
  bottom: 80px;
  bottom: ${props =>
    props.kind === "left" && props.compareMode === "horizontal" && "auto"};
  top: ${props =>
    props.kind === "left" && props.compareMode === "horizontal" && 80}px;
  left: ${props => props.kind === "left" && 0}px;
  right: ${props => props.kind === "right" && 0}px;
  margin: 20px;

  @media (max-width: ${styles.mobileBreakPoint}) {
    position: absolute;
    bottom: 40px;
    left: ${props => props.kind === "left" && -10}px;
    right: ${props => props.kind === "right" && -10}px;
    position: ${props =>
    props.kind === "left" && props.compareMode === "horizontal" && "fixed"};
    top: ${props =>
    props.kind === "left" && props.compareMode === "horizontal" && "40px"};
    bottom: ${props =>
    props.kind === "left" && props.compareMode === "horizontal" && "auto"};
  }

  div {
    color: ${props =>
    props.active ? styles.primaryAccentColor : styles.secondaryTextColor};
  }
`;

const DateTimeText = styled.div`
  padding: 10px 25px;
  background: ${styles.primaryOverlayColour};
  border-radius: 30px;
  font-weight: 400;
  font-size: 14px;
  color: ${styles.textColor};
  text-align: center;
  @media (max-width: ${styles.mobileBreakPoint}) {
    padding: 8px 18px;
  }
`;

const Spacer = styled.span`
  padding: 0 6px;
`;

const CompareModeDateTimeIndicator: React.SFC<ICompareDateTimeControls> = ({
  active,
  kind,
  dateTime,
  handleClick,
  compareMode
}) => (
  <Wrapper
    kind={kind}
    active={active}
    onClick={handleClick}
    compareMode={compareMode}
  >
    <DateTimeText>
      {window.innerWidth > 768 &&
        moment.utc(dateTime).format("ddd DD MMM YYYY")}
      {window.innerWidth > 768 && <Spacer />}
      {moment.utc(dateTime).format("HH:mm")}
    </DateTimeText>
  </Wrapper>
);

export default CompareModeDateTimeIndicator;
