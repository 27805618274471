import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  background: #0A1118;
  justify-content: center;
  align-items: center;
  height: 40rem;
  padding: 1rem;
  @media (min-width: 640px) {
    display: grid;
    place-items: center;
  }
`;

const Title = styled.h2`
  font-family: monospace;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.125rem;
  text-align: center;
`;

export const TableEmpty = () => (
  <Container>
    <Title>
      No data to display
    </Title>
  </Container>
);
