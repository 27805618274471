import React from "react";
import { Box } from "../box";
import Text from "../text";
import Dropdown from "../dropdown";

interface SelectVideoLengthProps {
  setVideoLength: (val: string) => void;
  videoLength: string;
}

const videoLengthOptions = [
  {
    data: "15",
    text: "15 seconds"
  },
  {
    data: "30",
    text: "30 seconds"
  },
  {
    data: "45",
    text: "45 seconds"
  },
  {
    data: "60",
    text: "1 minute"
  },
  {
    data: "75",
    text: "1 minute 15 seconds"
  },
  {
    data: "90",
    text: "1 minute 30 seconds"
  },
  {
    data: "105",
    text: "1 minute 45 seconds"
  },
  {
    data: "120",
    text: "2 minutes"
  },
  {
    data: "150",
    text: "2 minutes 30 seconds"
  },
  {
    data: "180",
    text: "3 minutes"
  }
];

export const SelectVideoLength: React.FC<SelectVideoLengthProps> = props => {
  return (
    <Box pad={{ vertical: "md" }}>
      <Box>
        <Text weight="bold">
        How long do you want your video to be?
        </Text>
        <Box pad={{ top: "sm" }}>
          <Dropdown 
            direction="down"
            isOverlayActive
            onSelect={val => props.setVideoLength(val as string)}
            items={videoLengthOptions}
            selected={videoLengthOptions.findIndex(opt => opt.data === props.videoLength)} />
        </Box>
      </Box>
    </Box>
  );
};