import React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import {
  Field, FieldAttributes, FormikActions
} from "formik";
import { Icon, Text } from "../";

const CheckboxLabel = styled.label<{kind?: string}>``;

const CheckboxInput = styled.input`
  display: none;

  .check_wrapper .label_check{
    margin-right: 20px
  }

  &:disabled + .check_wrapper {
    cursor: not-allowed;
    opacity: 0.5;
    filter: contrast(0.5);
  }
 
  &:checked + .check_wrapper .label_check{
    opacity: 1;
    background: ${styles.formBackground};
    border-color: ${styles.formBackground};

    .icon {
      opacity: 1;
    }
  }
`;

export const CheckWrapper = styled.div`
  &.checked {
    .icon {
      fill: white;
      opacity: 1;
    }

  }
`;

export const LabelCheck = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 2px solid ${styles.formBackground};
  background: ${styles.formBackground};
  width: 18px;
  height: 18px;
  cursor: pointer;
  transition: border 0.3s ease;

  :hover {
    border: 2px solid ${styles.primaryTextColor};
  }

  .icon {
    opacity: 0;
    fill: ${styles.lightGrey};
    color: transparent;
    transition: opacity 0.3s 0.1s ease;
  }
`;

const FormikCheckbox: React.FC<ICheckboxFormikProps> = props => {
  return (
    <Field name={props.name}>
      {({
        field,
        form
      }: {
        field: FieldAttributes<unknown>;
        form: FormikActions<unknown>;
      }) => {
        return (

          <Checkbox {...props}
            checked={field.value}
            onChange={() => {
              if (field.value === false) {
                form.setFieldValue(props.name, true);
              } else {
                form.setFieldValue(props.name, false);
              }

              if (props.onChange) {
                props.onChange(field.value);
              }
            }}/>
        );
      }}
    </Field>
  );
};

interface ICheckboxProps {
  kind?: string;
  name: string;
  label?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

export const Checkbox: React.FC<ICheckboxProps> = ({ onChange, ...props }) => {
  return <React.Fragment>
    {props.label && (
      <Text
        style={{
          display: "block",
          marginBottom: `${styles.paddingUnit}px`,
          width: "100%"
        }}
      >
        {props.label}
      </Text>
    )}
    <CheckboxLabel className="label">
      <CheckboxInput
        {...props}
        onChange={() => {
          onChange(!props.checked);
        }}
        type="checkbox"
      />
      <CheckWrapper className="check_wrapper">
        <LabelCheck className="label_check">
          <Icon size={15} className="icon" icon="tick" />
        </LabelCheck>
      </CheckWrapper>
    </CheckboxLabel>
  </React.Fragment>;
};

interface ICheckboxFormikProps {
  kind?: string;
  name: string;
  value: boolean;
  label: string;
  onChange?: (value: any) => void;
  disabled?: boolean;
}

CheckboxLabel.defaultProps = { kind: "default" };

export default FormikCheckbox;
