import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import { Row, ColumnProps } from "hedron";
import ImageIntervalLine from "../image-interval-line";
import ImageIntervalPlayhead from "../image-interval-playhead";
import { IImage } from "src/types/store/images";
import { Text, Icon } from "../../components";
import * as moment from "moment";
import { getInterval } from "../../lib/date-helpers";
import Loading from "../loading";

interface IImageScrubberProps {
  kind?: string;
  compareImage?: "left" | "right";
  images: IImage[];
  hovered: number | null;
  dragged: number | null;
  active: number | null;
  setActive: (index: number) => void;
  setHovered: (index: number | null) => void;
  setDragged: (index: number | null) => void;
  renderDateTimeIndicator: JSX.Element | false;
  handleMouseMove: () => void;
  isPlaying: boolean;
  loading?: boolean;
}

interface IImageIntervalWrapper extends ColumnProps {
  image: IImage;
  imagesCount: number;
}

const backgroundColor = theme.variants(
  "mode", "kind", { default: { normal: theme.primaryDarkColor } }
);

const maximumScrubberLines = 100;

const ImageScrubberWrapper: any = styled.div`
  background: ${backgroundColor};
  padding: 0;
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 650px;
  height: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${styles.mobileBreakPoint}) {
    order: 1;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;

    background: ${styles.primaryDarkColor};
    top: 0px;
    height: 50px;
    max-width: none;
    width: 100vw;
    left: 0;
    border-top: 1px solid white;

    z-index: 10;

    &.compareModeActive {
      border-bottom: 1px solid ${styles.primaryAccentColor};
      margin-top: -15px;
      margin-bottom: 9px;

      &:before,
      &:after {
        content: "";
        position: absolute;
      }

      &:before {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        bottom: -8px;
        left: 24px;
        border-top: 8px solid ${styles.primaryAccentColor};
      }

      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        bottom: -6px;
        left: 26px;
        border-top: 6px solid ${styles.primaryDarkColor};
      }

      &.rightActive {
        &:before {
          left: auto;
          right: 24px;
        }
        &:after {
          left: auto;
          right: 26px;
        }
      }
    }
  }
`;

const ImageIntervalWrapper = styled.div<IImageIntervalWrapper>`
  align-items: center;
  display: flex;
  height: 35px;
  justify-content: center;
  opacity: ${props => (props.image.status !== "active" ? 0.3 : 1)};

  > div > div:first-child {
    transition: transform 0.2s ease-in-out;
  }
  &:hover > div > div:first-child {
    transform: scale(1.4);
  }
`;

const CompareIndicator = styled.div`
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    transform: translateY(2px);
    fill: ${styles.primaryAccentColor};
  }
`;

const ImageScrubber: React.SFC<IImageScrubberProps> = ({
  kind,
  images,
  hovered,
  dragged,
  active,
  setActive,
  setHovered,
  setDragged,
  renderDateTimeIndicator,
  handleMouseMove,
  isPlaying,
  compareImage,
  loading
}) => {
  const handleActiveChange = (index: number, timestamp: string) => {
    setActive(index);
  };

  const isSingleDay = () => {
    if (images) {
      if (
        getInterval(moment.utc(images[0].taken_at),
          moment.utc(images[images.length - 1].taken_at)) <= 1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  function renderStartDateTime() {
    if (isSingleDay()) {
      return (
        <Text
          fontSize="small"
          style={{
            fontSize: "12px",
            letterSpacing: "1px",
            padding: "10px"
          }}
        >
          {moment.utc(images[0].taken_at).format("HH:mm")}
        </Text>
      );
    } else {
      return (
        <Text
          style={{
            lineHeight: 1,
            padding: "5px 10px",
            textAlign: "right",
            width: "60px"
          }}
        >
          <React.Fragment>
            <Text
              style={{
                fontSize: "10px",
                letterSpacing: "1px",
                textTransform: "uppercase"
              }}
            >
              {moment.utc(images[0].taken_at).format("DD MMM")}
            </Text>
            <br />
            <Text
              style={{
                fontSize: "10px",
                letterSpacing: "1px",
                opacity: 0.5,
                textTransform: "uppercase"
              }}
            >
              {moment.utc(images[0].taken_at).format("YYYY")}
            </Text>
          </React.Fragment>
        </Text>
      );
    }
  }

  function renderEndDateTime() {
    if (isSingleDay()) {
      return (
        <Text
          fontSize="small"
          style={{
            fontSize: "12px",
            letterSpacing: "1px",
            padding: "10px"
          }}
        >
          {moment.utc(images[images.length - 1].taken_at).format("HH:mm")}
        </Text>
      );
    } else {
      return (
        <Text
          style={{
            lineHeight: 1,
            padding: "5px 10px",
            width: "60px"
          }}
        >
          <React.Fragment>
            <Text
              style={{
                fontSize: "10px",
                letterSpacing: "1px",
                textTransform: "uppercase"
              }}
            >
              {moment.utc(images[images.length - 1].taken_at).format("DD MMM")}
            </Text>
            <br />
            <Text
              style={{
                fontSize: "10px",
                letterSpacing: "1px",
                opacity: 0.5,
                textTransform: "uppercase"
              }}
            >
              {moment.utc(images[images.length - 1].taken_at).format("YYYY")}
            </Text>
          </React.Fragment>
        </Text>
      );
    }
  }

  function renderImages() {
    if (images.length) {
      // This ensures that the scrubber bar has a maximum number of visible ImageIntervalLines
      // The user can still navigate left and right or drag, but visibly, we will only
      // ever show a maximum of <maximumScrubberLines>.
      const multiplier = images.length / maximumScrubberLines;
      let noOfLines = 0;

      for (let i = 0; i < images.length; i++) {
        if (
          Math.round(multiplier) <= 1 ||
          (i + 1) % Math.round(multiplier) === 1
        ) {
          noOfLines++;
        }
      }

      return images.map((image, index) => {
        if (
          // Returns an even selection of ImageIntervalLine from 0 to <maximumScrubberLines>
          Math.round(multiplier) <= 1 ||
          (index + 1) % Math.round(multiplier) === 1
        ) {
          return (
            <ImageIntervalWrapper
              key={`image-line-${index}`}
              style={{
                alignItems: "center",
                display: "flex",
                height: "35px",
                justifyContent: "center",
                width: `calc(99.9% / ${noOfLines})`
              }}
              image={image}
              imagesCount={images.length}
              onClick={() => handleActiveChange(index, images[index].taken_at)}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
            >
              <ImageIntervalLine
                hovered={index === hovered}
                isDragging={dragged !== null}
                image={image}
                kind="default"
                isPlaying={isPlaying}
              />
            </ImageIntervalWrapper>
          );
        }

        return null;
      });
    }

    return null;
  }

  if (loading) return (
    <Loading />
  );

  if (images.length) {
    return (
      <ImageScrubberWrapper
        kind={kind}
        className={
          compareImage
            ? compareImage === "right"
              ? "compareModeActive rightActive"
              : "compareModeActive"
            : ""
        }
      >
        {renderDateTimeIndicator}
        <CompareIndicator
          style={{
            left: compareImage === "left" ? "-20px" : "auto",
            right: compareImage === "right" ? "-20px" : "auto"
          }}
        >
          {compareImage &&
            (compareImage === "left" ? (
              <Icon icon="chevron-left" size={15} />
            ) : (
              <Icon icon="chevron-right" size={15} />
            ))}
        </CompareIndicator>
        {renderStartDateTime()}
        <Row
          alignItems="center"
          divisions={images.length}
          style={{
            position: "relative",
            width: "100%" 
          }}
        >
          {active !== null && (
            <ImageIntervalPlayhead
              imageCount={images.length}
              active={active}
              dragged={dragged}
              setDragged={(index: number | null) => setDragged(index)}
              handleMouseMove={handleMouseMove}
              setActive={(index: number) =>
                handleActiveChange(index, images[index].taken_at)
              }
              image={dragged !== null ? images[dragged] : images[active]}
            />
          )}
          {renderImages()}
        </Row>
        {renderEndDateTime()}
      </ImageScrubberWrapper>
    );
  }

  return null;
};

ImageScrubber.defaultProps = { kind: "default" };

export default ImageScrubber;
