import React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import {
  Text, TextInput, Button, ValidationError
} from "../../components";
import { Column, Row } from "hedron";
import {
  Formik, FormikProps, Form
} from "formik";
import * as Yup from "yup";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import { createSiteRequest } from "../../store/sites/sites.actions";
import { CreateSiteParams } from "src/store/sites/sites.api";
import { SecuritySection } from "src/components/security-section";
import { styles } from "src/lib/styles";
import MobileSpacer from "src/components/MobileSpacer";

interface IRouterProps {
  siteId: string;
  customerId: string;
}

type ISiteProps = RouteComponentProps<IRouterProps>;

interface IStateProps {
  isCreating: boolean;
}

interface ISiteDispatchProps {
  createSite: (site: CreateSiteParams) => void;
}
type Props = ISiteProps & IStateProps & ISiteDispatchProps;

export const CreateSite: React.FC<Props> = ({
  isCreating, match, createSite
}) => {
  const handleSubmit = (values: CreateSiteParams) => {
    values.customer_id = match.params.customerId;
    createSite(values);
  };

  return (
    <>
      <div style={{
        background: styles.secondaryDarkColor,
        borderRadius: "15px",
        paddingBottom: "60px"
      }}>
        <Row alignContent="space-between">
          <Column xs={9}>
            <Text fontSize="h1">Create new site</Text>
          </Column>
        </Row>
        <Row />
        <Row>
          <Column>
            <Formik
              initialValues={{
                geolocation: "",
                name: "",
                whitelist: "",
                security_level: 0,
                customer_id: match.params.customerId
              }}
              onSubmit={(values: CreateSiteParams) => handleSubmit(values)}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Required"),
                security_level: Yup.number().required(),
                whitelist: Yup.string().when("security_level", {
                  is: 1,
                  then: Yup.string().matches(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}(,((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4})?$/, { message: "Please use valid IP addresses." })
                }) 
              })}
            >
              {(props: FormikProps<CreateSiteParams>) => {
                const {
                  values, handleChange, handleBlur, errors, setFieldValue
                } = props;

                return (
                  <Form>
                    <Row>
                      <Column xs={12} lg={5}>
                        <TextInput
                          onChange={handleChange}
                          value={values.name}
                          name="name"
                          placeholder="Name"
                          onBlur={handleBlur}
                        />
                        <ValidationError>{errors.name}</ValidationError>
                      </Column>
                    </Row>
                    <Row>
                      <Column xs={12} lg={5}>
                        <TextInput
                          onChange={handleChange}
                          value={values.geolocation}
                          name="geolocation"
                          placeholder="Geolocation"
                          onBlur={handleBlur}
                        />
                      </Column>
                    </Row>
                    <div style={{ padding: "20px" }}>
                      <SecuritySection 
                        onSelectSecurityLevel={value => setFieldValue("security_level", value)} 
                        securityLevel={values.security_level}
                        handleWhitelistChange={handleChange}
                        whitelistValue={values.whitelist}
                        whitelistErrors={errors.whitelist}
                        onBlurWhitelistTextInput={handleBlur}
                      />
                    </div>
                    <Row>
                      <Column xs={12} lg={5}>
                        <Button
                          disabled={isCreating}
                          text="Submit"
                          type="submit"
                        />
                      </Column>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Column>
        </Row>
        <Row />
      </div>
      <MobileSpacer />
    </>
  );
};

const mapStateToProps = (state: IStore): IStateProps => {
  return { isCreating: state.sites.creatingSite };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): ISiteDispatchProps => {
  return { createSite: (site: CreateSiteParams) => dispatch(createSiteRequest(site)) };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(CreateSite);
