import {
  applyMiddleware, combineReducers, createStore, Store 
} from "redux";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { auth } from "./auth/auth.reducer";
import { users } from "./users/users.reducer";
import { IStore } from "../types/store/store";
import { routerMiddleware } from "react-router-redux";
import {
  persistStore, persistReducer, Persistor 
} from "redux-persist";
import { History } from "history";
import { customers } from "./customers/customers.reducer";
import { videos } from "./videos/videos.reducer";
import { cameraLocations } from "./camera-locations/camera-locations.reducer";
import { images } from "./images/images.reducer";
import { sites } from "./sites/sites.reducer";
import { deletionStats } from "./deletion-queue/deletion-queue.reducer";

const middleware: any[] = [];

middleware.push(thunk as ThunkMiddleware<IStore, any>);

const rootReducer = combineReducers({
  auth,
  cameraLocations,
  customers,
  images,
  sites,
  users,
  videos,
  deletionStats
});

const persistConfig = {
  key: "root",
  blacklist: ["videos"],
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default (browserHistory: History<any>): { store: Store<IStore>; persistor: Persistor } => {
  middleware.push(routerMiddleware(browserHistory));

  const composeEnhancers = composeWithDevTools({});

  const store: Store<IStore> = createStore<IStore, any, any, any>(persistedReducer,
    composeEnhancers(applyMiddleware(...middleware)));

  const persistor = persistStore(store);

  return {
    store,
    persistor 
  };
};
