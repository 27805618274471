import { IStore } from "../../types/store/store";
import { IVideo } from "../../types/store/videos";
import _ from "lodash";

export const videoGetById = (state: IStore, videoId: string): IVideo | null => {
  if (videoId) {
    const video = _.find(state.videos.videos,
      c => c.id === parseInt(videoId, 10));

    return video ? video : null;
  } else {
    return null;
  }
};
