import React from "react";
import { Box } from "../box";
import Text from "../text";
import TextInput from "../text-input";

interface SetVideoNameProps {
  setName: (val: string) => void;
  name: string;
  allowKeys?: (allow: boolean) => void;
}

export const SetVideoName: React.FC<SetVideoNameProps> = props => {
  return (
    <Box pad={{ vertical: "md" }} width="100%">
      <Text weight="bold">
          What do you want to call the video?
      </Text>
      <Box pad={{ top: "sm" }} width="100%">
        <TextInput 
          value={props.name} 
          type="text" 
          onFocus={() => props.allowKeys && props.allowKeys(false)}
          onBlur={() => props.allowKeys && props.allowKeys(true)}
          onChange={e => props.setName(e.target.value || "")}
        />
      </Box>
    </Box>
  );
};
