import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import icons from "./icons";
import { styles, theme } from "../../lib/styles";
import { SvgProperties } from "csstype";

const iconColor = theme.variants(
  "mode", "kind", {
    default: { normal: styles.textColor },
    hover: { normal: styles.textColor }
  }
);

const iconHoverColor = theme.variants(
  "mode", "kind", {
    default: { normal: styles.primaryTextColor },
    hover: { normal: styles.primaryAccentColor }
  }
);

const IconWrapper: any = styled.svg`
  fill: ${iconColor};
  overflow: visible;
  transition: 0.2s ease;

  &[onClick]:hover {
    cursor: pointer;
    fill: ${iconHoverColor};
  }
`;

const Icon: React.SFC<IIconWrapperProps> = ({
  icon, size, ...rest 
}) => {
  return (
    <IconWrapper {...rest} width={size} height={size} viewBox="0 0 1024 1024">
      <path d={icons[icon]} />
    </IconWrapper>
  );
};

interface IIconWrapperProps extends HTMLAttributes<SvgProperties> {
  kind?: string;
  icon: string;
  size?: number;
}

IconWrapper.defaultProps = {
  height: 25,
  kind: "default",
  width: 25
};

export default Icon;
