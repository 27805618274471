import logger from "src/lib/logger";
import axios from "axios";
import Headers from "../../lib/auth";
import {
  IUser,
  IUserCameraInput,
  IUserCameraAssociations,
  IUserInput
} from "src/types/store/users";

export const getUser = async (): Promise<IUser> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/client/user/`
    });

    logger.info("GET USER", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET USER ERROR", error);
    throw error;
  }
};

export const getUsers = async (): Promise<IUser[]> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/admin/users/`
    });

    logger.info("GET USERS", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET USERS ERROR", error);
    throw error;
  }
};

export const getUserCameras = async (
  userId: number
): Promise<IUserCameraAssociations[]> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/client/user/${userId}/cameras`
    });

    logger.info("GET USER CAMERAS", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET USER CAMERAS ERROR", error);
    throw error;
  }
};

export const createUser = async (user: IUserInput): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: user,
      headers,
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/admin/user`
    });

    logger.info("CREATE USER", result);

    return result.data.data;
  } catch (error) {
    logger.error("CREATE USER ERROR", error);
    throw error;
  }
};

export const updateUser = async (userId: number, user: IUser): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: user,
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/user/${userId}`
    });

    logger.info("UPDATE USER", result);

    return result.data.data;
  } catch (error) {
    logger.error("UPDATE USER ERROR", error);
    throw error;
  }
};

export const deleteUser = async (userId: string): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/admin/user/${userId}`
    });

    logger.info("DELETE USER", result);

    return result.data.data;
  } catch (error) {
    logger.error("DELETE USER ERROR", error);
    throw error;
  }
};

export const adminResetUserPassword = async (data: {
  username: string;
  newPassword: string;
}): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data,
      headers,
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/admin/user/reset-password`
    });

    logger.info("RESET USER PASSWORD", result);

    return result.data.data;
  } catch (error) {
    logger.error("RESET USER PASSWORD ERROR", error);
    throw error;
  }
};

export const adminUpdateUserAccess = async (
  userId: number,
  userCameras: IUserCameraInput[],
  canDownload: boolean
): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: {
        userId,
        userCameras,
        canDownload
      },
      headers,
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/admin/user/user-cameras`
    });

    logger.info("UPDATE USER CAMERAS", result);

    return result.data.data;
  } catch (error) {
    logger.error("UPDATE USER CAMERAS ERROR", error);
    throw error;
  }
};
