import * as Redux from "redux";
import * as AT from "./customers.constants";
import { ICustomerStore } from "src/store/customers/customers.api";
import { CustomerActions } from "./customers.actions";

const initialState: ICustomerStore = {
  creatingCustomer: false,
  currentCustomer: null,
  customer: null,
  customerConfig: {
    dark_logo: "",
    logo: "",
    name: ""
  },
  customers: [],
  customerCount: 0,
  deletingCustomer: false,
  gettingCustomer: false,
  gettingCustomers: false,
  suspendingCustomer: false,
  updatingCustomer: false
};

export const customers: Redux.Reducer<ICustomerStore> = (state: ICustomerStore = initialState,
  action: CustomerActions): ICustomerStore => {
  switch (action.type) {
    case AT.SET_CUSTOMER:
      return { ...state };
    // GET CUSTOMER
    case AT.GET_CUSTOMER:
      return {
        ...state,
        gettingCustomer: true,
        currentCustomer: null,
        customer: null,
        customers: []
      };
    case AT.GET_CUSTOMER_SUCCESS: 
      return {
        ...state,
        currentCustomer: action.customer,
        customer: action.customer.id,
        customers: [action.customer],
        gettingCustomer: false
      };
    case AT.GET_CUSTOMER_FAILURE:
      return {
        ...state,
        gettingCustomer: false
      };

    // GET CUSTOMERS
    case AT.GET_CUSTOMERS:
      return {
        ...state,
        gettingCustomers: true,
        customers: [],
        customerCount: 0
      };
    case AT.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        gettingCustomers: false,
        customers: action.customers,
        customerCount: action.count
      };
    case AT.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        gettingCustomers: false,
        customerCount: 0
      };

    // CREATE CUSTOMER
    case AT.CREATE_CUSTOMER:
      return {
        ...state,
        creatingCustomer: true
      };
    case AT.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        creatingCustomer: false
      };
    case AT.CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        creatingCustomer: false
      };

    // UPDATE CUSTOMER
    case AT.UPDATE_CUSTOMER:
      return {
        ...state,
        updatingCustomer: true
      };
    case AT.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        updatingCustomer: false
      };
    case AT.UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        updatingCustomer: false
      };

    // SUSPEND CUSTOMER
    case AT.SUSPEND_CUSTOMER:
      return {
        ...state,
        suspendingCustomer: true
      };
    case AT.SUSPEND_CUSTOMER_SUCCESS:
      return {
        ...state,
        suspendingCustomer: false
      };
    case AT.SUSPEND_CUSTOMER_FAILURE:
      return {
        ...state,
        suspendingCustomer: false
      };

    // DELETE CUSTOMER
    case AT.DELETE_CUSTOMER:
      return {
        ...state,
        deletingCustomer: true
      };
    case AT.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deletingCustomer: false
      };
    case AT.DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        deletingCustomer: false
      };

    // GET CUSTOMER CONFIG
    case AT.GET_CUSTOMER_CONFIG:
      return {
        ...state,
        deletingCustomer: true
      };
    case AT.GET_CUSTOMER_CONFIG_SUCCESS:
      return {
        ...state,
        customerConfig: action.customerConfig,
        deletingCustomer: false
      };
    case AT.GET_CUSTOMER_CONFIG_FAILURE:
      return {
        ...state,
        deletingCustomer: false
      };

    // PURGE CUSTOMER
    case AT.PURGE_CUSTOMERS:
      return initialState;
    default:
      return state;
  }
};
