import * as React from "react";
import { Row, Column } from "hedron";
import TextInput from "../text-input/text-input";
import {
  ICameraLocation, ImagePosition, LiveImageSettings
} from "src/store/camera-locations/camera-locations.api";
import Text from "../text";
import { Checkbox } from "../checkbox/formik-checkbox";
import { DropdownFormik } from "../dropdown-formik";
import * as _ from "lodash";
import { SettingName, OverlaySetting } from "src/store/camera-locations/camera-locations.api";
import styled from "styled-components";
import { styles } from "src/lib/styles";

interface LiveCameraSettingsProps {
  cameraLocation: ICameraLocation | null;
}

const defaultClientSettings = {
  timestamp: {
    enabled: false,
    position: ImagePosition.bottomRight
  },
  text: {
    message: "interval.tv",
    enabled: false,
    position: ImagePosition.bottomLeft
  }
};

const defaultAdminSettings = {
  timestamp: {
    enabled: true,
    position: ImagePosition.bottomRight
  },
  hardware_ref: {
    enabled: true,
    position: ImagePosition.bottomLeft
  },
  text: {
    message: "",
    enabled: false,
    position: ImagePosition.bottomLeft
  }
};

export const CameraLiveImageSettings = React.forwardRef<{liveCameraSettings: ICameraLocation["live_image_settings"]}, LiveCameraSettingsProps>(({ cameraLocation }, ref) => {
  const [isClientLiveImageEnabled, setIsClientLiveImageEnabled] = React.useState(false);

  const [liveCameraSettings, setLiveCameraSettings] = React.useState<LiveImageSettings>({
    crop: {
      height: 3375,
      width: 6000,
      offsetY: 250,
      offsetX: 0
    },
    resize: {
      height: 1080,
      width: 1920
    },
    client: defaultClientSettings,
    admin: defaultAdminSettings
  });

  React.useImperativeHandle(
    ref, () => ({ liveCameraSettings }), [liveCameraSettings]
  );

  React.useEffect(() => {
    // set the initial state from props
    if (cameraLocation && cameraLocation.live_image_settings) {
      const filledLiveCameraSettings = cameraLocation.live_image_settings;

      if (!filledLiveCameraSettings.client) {
        filledLiveCameraSettings.client = defaultClientSettings;
      } else {
        setIsClientLiveImageEnabled(true);
      }

      if (!filledLiveCameraSettings.admin) {
        filledLiveCameraSettings.admin = defaultAdminSettings;
      }

      setLiveCameraSettings(cameraLocation.live_image_settings);
    }
  }, [cameraLocation]);

  const setCrop = React.useCallback((key: keyof LiveImageSettings["crop"], value: number) => {
    if (liveCameraSettings) {
      setLiveCameraSettings({
        ...liveCameraSettings,
        crop: {
          ...liveCameraSettings.crop,
          [key]: value
        }
      });
    }
  }, [liveCameraSettings]);

  const setResize = React.useCallback((key: keyof LiveImageSettings["resize"], value: number) => {
    if (liveCameraSettings) {
      setLiveCameraSettings({
        ...liveCameraSettings,
        resize: {
          ...liveCameraSettings.resize,
          [key]: value
        }
      });
    }
  }, [liveCameraSettings]);

  const setClientSetting = React.useCallback((type: SettingName, value: OverlaySetting) => {
    if (liveCameraSettings && liveCameraSettings.client) {
      liveCameraSettings.client[type] = value;
      setLiveCameraSettings({ ...liveCameraSettings });
    }
  }, [liveCameraSettings]);

  const setAdminSetting = React.useCallback((type: SettingName, value: OverlaySetting) => {
    if (liveCameraSettings && liveCameraSettings.admin) {
      liveCameraSettings.admin[type] = value;
      setLiveCameraSettings({ ...liveCameraSettings });
    }
  }, [liveCameraSettings]);

  const handleEnableClientLiveImage = () => {
    if (isClientLiveImageEnabled) {
      setLiveCameraSettings({
        ...liveCameraSettings,
        client: null 
      });
    } else {
      setLiveCameraSettings({
        ...liveCameraSettings,
        client: defaultClientSettings
      });
    }

    setIsClientLiveImageEnabled(!isClientLiveImageEnabled);
  };

  return (<CameraLiveImageSettingsWrapper>
    <Section>

      <Row>
        <Column>
          <Text fontSize="h3" weight="bold">{"Live Image Settings"}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Text weight="bold">{"Crop"}</Text>
        </Column>
      </Row>
      <Row>
        <Column xs={12} lg={6}>
          <Text >{"Width"}</Text>
          <TextInput
            onChange={e => setCrop("width", parseInt(e.currentTarget.value, 10))}
            value={liveCameraSettings.crop.width}
            name="width"
            type="number"
          />
        </Column>
        <Column xs={12} lg={6}>
          <Text >{"Height"}</Text>
          <TextInput

            onChange={e => setCrop("height", parseInt(e.currentTarget.value, 10))}
            value={liveCameraSettings.crop.height}
            name="height"
            type="number"
          />
        </Column>

        <Column xs={12} lg={6}>
          <Text >{"Offset Left"}</Text>
          <TextInput

            onChange={e => setCrop("offsetX", parseInt(e.currentTarget.value, 10))}
            value={liveCameraSettings.crop.offsetX}
            name="offsetX"
            type="number"
          />
        </Column>
        <Column xs={12} lg={6}>
          <Text >{"Offset Top"}</Text>
          <TextInput
            onChange={e => setCrop("offsetY", parseInt(e.currentTarget.value, 10))}
            value={liveCameraSettings.crop.offsetY}
            name="offsetY"
            type="number"
          />
        </Column>
      </Row>

      <Row>
        <Column>
          <Text weight="bold">{"Resize"}</Text>
        </Column>
      </Row>
      <Row>
        <Column xs={12} lg={6}>
          <Text >{"Width"}</Text>
          <TextInput
            onChange={e => setResize("width", parseInt(e.currentTarget.value, 10))}
            value={liveCameraSettings.resize.width}
            name="width"
            type="number"
          />
        </Column>
        <Column xs={12} lg={6}>
          <Text >{"Height"}</Text>
          <TextInput
            onChange={e => setResize("height", parseInt(e.currentTarget.value, 10))}
            value={liveCameraSettings.resize.height}
            name="height"
            type="number"
          />
        </Column>
      </Row>
    </Section>
    <Section>
      <Row>
        <Column>
          <Text fontSize="h4" weight="bold">{"Client Live Image"}</Text>
        </Column>
        <Row>
          <Column>
            <Checkbox
              name="isClientLiveImageEnabled"
              checked={isClientLiveImageEnabled}
              onChange={handleEnableClientLiveImage}
              label="Enabled"
            />
          </Column>
        </Row>
      </Row>
      {isClientLiveImageEnabled && 
      <>
        {_.keys(liveCameraSettings.client).map((key: SettingName) =>
          liveCameraSettings.client && liveCameraSettings.client[key] && <ImageSetting setSetting={setting => setClientSetting(key, setting)} key={`client-${key}`} name={key} setting={liveCameraSettings.client[key] as OverlaySetting} />)}
      </>
      }
   
    </Section>

    <Section>

      <Row>
        <Column>
          <Text fontSize="h4" weight="bold">{"Admin Live Image"}</Text>
        </Column>
      </Row>

      <>
        {_.keys(liveCameraSettings.admin).map((key: SettingName) =>
          liveCameraSettings.admin && liveCameraSettings.admin[key] ? <ImageSetting setSetting={setting => setAdminSetting(key, setting)} key={`admin-${key}`} name={key} setting={liveCameraSettings.admin[key] as OverlaySetting} /> : null)}
      </>
    </Section>
  </CameraLiveImageSettingsWrapper>);
});

interface ImageSettingProp {
  name: SettingName;
  setting: OverlaySetting;
  setSetting: (setting: OverlaySetting) => void;
}

const ImageSetting: React.FC<ImageSettingProp> = props => {
  const [settingState, setSettingState] = React.useState(props.setting);

  React.useEffect(() => {
    setSettingState(props.setting);
  }, [props.setting]);

  return <ImageSettingWrapper>
    <Row>
      <Column>
        <Text style={{ textTransform: "capitalize" }} fontSize="h5" weight="bold">{props.name}</Text>
      </Column>
    </Row>
    <Row>
      <Column xs={12} lg={6}>
        <Checkbox
          onChange={() => props.setSetting({
            ...settingState,
            enabled: !settingState.enabled
          })}

          checked={settingState.enabled}
          name={props.name}
          label="enabled"/>

      </Column>
    </Row>
    {settingState.enabled &&
      <>
        <Row>
          <Column xs={12} lg={6}>
            <Text >{"Position"}</Text>
            <DropdownFormik
              onChange={value => props.setSetting({
                ...settingState,
                position: value
              })}
              placeholder="Position"
              defaultValue={settingState.position}
              items={[
                {
                  text: "Bottom Left",
                  value: "bottomLeft"
                },
                {
                  text: "Bottom Right",
                  value: "bottomRight"
                }
              ]} name="position" />
          </Column>
        </Row>
        {props.name === SettingName.text && <Row>
          <Column>
            <TextInput
              onChange={e => props.setSetting({
                ...settingState,
                message: e.target.value
              })}
              placeholder="Enter text here"
              value={settingState.message}
              name="text"
            />
          </Column>

        </Row>}
      </>
    }
  </ImageSettingWrapper>;
};

const Section = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid ${styles.secondaryDarkColor};
`;

const ImageSettingWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;

const CameraLiveImageSettingsWrapper = styled.div`
  background: ${styles.secondaryDarkColor};
  margin-top: 3rem;
`;
