import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import {
  Text,
  Button,
  ModalStyles,
  CheckListMenu
} from "src/components";
import { Column, Row } from "hedron";
import { userGetById } from "src/store/users/users.getters";
import _ from "lodash";
import { IUser } from "src/types/store/users";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import {
  deleteUserRequest,
  adminResetUserPasswordRequest,
  adminUpdateUserAccessRequest,
  updateUserRequest
} from "src/store/users/users.actions";
import Modal from "react-modal";
import { ICheckListMenuItem } from "src/components/check-list-menu/check-list-menu";
import { getCustomersRequest } from "src/store/customers/customers.actions";
import {
  ICustomer, IMinifiedSite, IMinifiedCameraLocation
} from "src/store/customers/customers.api";
import SelectListMenu from "src/components/select-list-menu/select-list-menu";
import { StyledButton } from "src/components/button/button";
import { IUserCameraInput } from "src/types/store/users";
import { Checkbox } from "src/components/checkbox/formik-checkbox";
import { NewPasswordForm, FormValues as NewPasswordFormValues } from "./new-password-form";
import { UpdatePhoneNumberForm, FormValues as UpdatePhoneNumberFormValues } from "./update-phone-number-form";
import { styles } from "src/lib/styles";
import { PaginatedParams } from "src/types/pagination";
import MobileSpacer from "src/components/MobileSpacer";

Modal.setAppElement("#root");
interface IUserRouterProps {
  userId: string;
}

type IUserProps = RouteComponentProps<IUserRouterProps>;

interface IStateProps {
  user: IUser | null;
  customers: ICustomer[] | null;
  isUpdating: boolean;
  isSavingUserCameras: boolean;
  isDeleting: boolean;
  adminSettingPassword: boolean;
  isLoadingCustomers: boolean;
}

interface ICheckedCamera {
  cameraId: number
  siteId: number
}

type Props = IUserProps & IStateProps & IUsersDispatchProps;
interface IUsersDispatchProps {
  deleteUser: (userId: string) => void;
  getCustomers: (
    pagination: PaginatedParams<ICustomer>,
    filters: Partial<ICustomer>
  ) => void;
  resetPassword: (username: string, password: string) => void;
  updateUserAccess: (userId: number, userCameras: IUserCameraInput[], canDownload: boolean) => void;
  updateUser: (userId: number, user: IUser) => void;
}

const EditUser: React.FC<Props> = ({
  user,
  deleteUser,
  match,
  adminSettingPassword,
  isLoadingCustomers,
  customers,
  isDeleting,
  getCustomers,
  resetPassword,
  updateUserAccess,
  updateUser
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [checkedCameras, setCheckedCameras] = React.useState<ICheckedCamera[]>([]);
  const [selectedCameras, setSelectedCameras] = React.useState<IMinifiedCameraLocation[]>([]);
  const [selectedSites, setSelectedSites] = React.useState<IMinifiedSite[]>([]);
  const [canDownload, setCanDownload] = React.useState(user?.can_download || false);
  const [selectedSiteId, setSelectedSiteId] = React.useState<number | undefined>();
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<number | undefined>();

  React.useEffect(() => {
    getCustomers({
      limit: 99999,
      offset: 0,
      sort: [["name", "ASC"]],
      page: 1
    }, {});
  }, [getCustomers]);

  React.useEffect(() => {
    if (customers && user && user.userCameras) {
      const checkedCameras = user.userCameras.map(userCamera => {
        return {
          cameraId: userCamera.id,
          siteId: userCamera.site_id
        };
      });

      setCheckedCameras(checkedCameras);
    }
  }, [customers, user]);

  const handleSubmitNewPassword = (values: NewPasswordFormValues) => {
    if (user) {
      resetPassword(user.username, values.newPassword);
    }
  };

  const handleSubmitPhoneNumber = (values: UpdatePhoneNumberFormValues) => {
    if (user) {
      updateUser(user.id, {
        ...user,
        phone_number: values.phone_number || null
      });
    }
  };

  const handleSaveUserAccess = () => {
    if (user && user.id && checkedCameras) {
      const newUserCameras = _.map(checkedCameras, userCamera => {
        return { camera_location_id: userCamera.cameraId };
      });

      updateUserAccess(
        user.id, newUserCameras, canDownload
      );
    }
  };

  const onSelectCustomer = (id: number) => {
    if (id !== selectedCustomerId && customers) {
      let newSelectedSites: IMinifiedSite[] = [];
      const selectedCustomer = customers.find(customer => customer.id === id);

      if (selectedCustomer) {
        newSelectedSites = selectedCustomer.sites || [];
      }

      setSelectedCustomerId(id);
      setSelectedSiteId(undefined);
      setSelectedSites(newSelectedSites);
      setSelectedCameras([]);
    }
  };

  const onSelectSite = (id: number) => {
    if (id !== selectedSiteId && selectedSites) {
      let newSelectedCameras: IMinifiedCameraLocation[] = [];
      const selectedSite = selectedSites.find(site => site.id === id);

      if (selectedSite) {
        newSelectedCameras = selectedSite.camera_locations || [];
      }

      setSelectedSiteId(id);
      setSelectedCameras(newSelectedCameras);
    }
  };

  const onCheck = React.useCallback((cameraId: number) => {
    if (selectedSiteId && selectedCustomerId) {
      let checkedItems = checkedCameras;

      if (checkedCameras.find(checkedItem => checkedItem.cameraId === cameraId)) {
        checkedItems = _.filter(checkedCameras, checked => checked.cameraId !== cameraId);
      } else {
        checkedItems.push({
          cameraId,
          siteId: selectedSiteId
        });
      }

      setCheckedCameras(checkedItems);
      setSelectedCameras([...selectedCameras]);
    }
  }, [
    checkedCameras,
    selectedCameras,
    selectedCustomerId,
    selectedSiteId
  ]);

  return (
    <>
      <div style={{
        background: styles.secondaryDarkColor,
        borderRadius: "15px",
        marginBottom: "100px"
      }}>
        <Row alignContent="space-between">
          <Column xs={9}>
            <Text fontSize="h1">{user ? user.username : ""}</Text>
          </Column>
          <Column xs={3}>
            <Button
              kind="delete"
              text="Delete User"
              clickHandler={() => setConfirmModalOpen(true)}
            />
          </Column>
        </Row>
        <Row />
        <Row>
          <Column>
            <Row justifyContent="flex-start">
              <NewPasswordForm 
                handleSubmit={handleSubmitNewPassword} 
                adminSettingPassword={adminSettingPassword}
              />

              <UpdatePhoneNumberForm 
                handleSubmit={handleSubmitPhoneNumber} 
                adminSettingPassword={adminSettingPassword}
                currentPhoneNumber={user?.phone_number}
              />
            </Row>
          
            <>
              <h1 style={{
                fontSize: "1.4rem",
                margin: "1rem",
                marginTop: "2rem"
              }}>Access to cameras</h1>
              <Row style={{ flexWrap: "nowrap" }}>
                <SelectListMenu
                  selected={selectedCustomerId}
                  loading={isLoadingCustomers}
                  items={customers && customers.map(item => {
                    const sites = item.sites;
                    let qtyOfCams = 0;

                    if (sites) {
                      sites.map(site => {
                        qtyOfCams += checkedCameras.filter(checkedCamera => checkedCamera.siteId === site.id).length;
                      });
                    }

                    return {
                      value: item.id,
                      label: item.name,
                      checkType: "unchecked",
                      qtyOfCams
                    } as ICheckListMenuItem;
                  })}

                  onSelect={customer => onSelectCustomer(customer.value)}
                  label="Customers"
                />
                <SelectListMenu
                  selected={selectedSiteId}
                  loading={isLoadingCustomers}
                  items={selectedSites.map(item => {
                    const qtyOfCams = checkedCameras.filter(checkedCamera => checkedCamera.siteId === item.id).length;

                    return {
                      value: item.id,
                      label: item.name,
                      checkType: "unchecked",
                      qtyOfCams
                    } as ICheckListMenuItem;
                  })}
                  onSelect={site => onSelectSite(site.value)}
                  label="Sites"
                  parent="Customer"
                />
                <CheckListMenu
                  loading={isLoadingCustomers}
                  items={selectedCameras.map(item => {
                    if (checkedCameras.find(checkedCamera => checkedCamera.cameraId === item.id)) {
                      return {
                        value: item.id,
                        label: item.name,
                        checkType: "checked"
                      } as ICheckListMenuItem;
                    } else {
                      return {
                        value: item.id,
                        label: item.name,
                        checkType: "unchecked"
                      } as ICheckListMenuItem;
                    }
                  })}
                  onClick={camera => onCheck(camera.value)}
                  label="Cameras"
                  parent="Site"
                />
              </Row>
              <Row>
                <Column>
                  <Checkbox
                    onChange={() => setCanDownload(!canDownload)}
                    checked={canDownload}
                    name={"can_download"}
                    label="Can download"/>
                </Column>
              </Row>
              <StyledButton as="button" onClick={() => handleSaveUserAccess()}>Save</StyledButton>
            </>
          </Column>
        </Row>
        {user && (
          <Modal
            isOpen={confirmModalOpen}
            onRequestClose={() => setConfirmModalOpen(false)}
            style={ModalStyles("normal", "default")}
          >
            <Row justifyContent="center">
              <Column style={{ textAlign: "center" }}>
                <Text fontSize="h1">{"Are you sure?"}</Text>
              </Column>
            </Row>
            <Row>
              <Column style={{ textAlign: "center" }}>
                <Text>{`This will delete ${
                  user.username
                } and cannot be undone!`}</Text>
              </Column>
            </Row>
            <Row justifyContent="center">
              <Column md={3} style={{ textAlign: "center" }}>
                <Button
                  align="none"
                  disabled={isDeleting}
                  text="Yes, delete user"
                  clickHandler={() => deleteUser(match.params.userId)}
                />
              </Column>
              <Column md={3} style={{ textAlign: "center" }}>
                <Button
                  align="none"
                  text="Cancel"
                  kind="delete"
                  clickHandler={() => setConfirmModalOpen(false)}
                />
              </Column>
            </Row>
          </Modal>
        )}
      
      </div>
      <MobileSpacer/>
    </>
  );
};

const mapStateToProps = (state: IStore, props: Props): IStateProps => {
  return {
    adminSettingPassword: state.users.adminSettingPassord,
    customers: state.customers.customers,
    isDeleting: state.users.deletingUser,
    isLoadingCustomers: state.customers.gettingCustomers,
    isSavingUserCameras: state.users.isUpdatingUserCameras,
    isUpdating: state.users.updatingUser,
    user: userGetById(state, parseInt(props.match.params.userId, 10))
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IUsersDispatchProps => {
  return {
    deleteUser: (userId: string) => dispatch(deleteUserRequest(userId)),
    getCustomers: (pagination: PaginatedParams<ICustomer>,
      filters: Partial<ICustomer>) => dispatch(getCustomersRequest(pagination, filters)),  
    resetPassword: (username: string, newPassword: string) =>
      dispatch(adminResetUserPasswordRequest(username, newPassword)),
    updateUserAccess: (
      userId, userCameras, canDownload
    ) => dispatch(adminUpdateUserAccessRequest(
      userId, userCameras, canDownload
    )),
    updateUser: (userId, user) => dispatch(updateUserRequest(userId, user))
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(EditUser);
