import styled from "styled-components";

export const VideoList = styled.div`
    width: 75%;
    @media (max-width: 600px){
      width: 100%;
    }
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
`;