import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import { Text } from "../../components";

interface IFooterProps {
  kind?: string;
  children: React.ReactChild;
}

const backgroundColor = theme.variants(
  "mode", "kind", { default: { normal: styles.primaryDarkColor } }
);

const FooterWrapper: any = styled.div`
  background: ${backgroundColor};
  border-top: ${styles.secondaryDarkColor} 2px solid;
  bottom: 0px;
  width: 100vw;
  z-index: 10;
  user-select: none;
  @media (min-width: ${styles.mobileBreakPoint}) {
    position: absolute;
  }
`;

const FooterContent: any = styled.div`
  background: ${backgroundColor};
  border-top: ${styles.secondaryDarkColor} 2px solid;
  padding: 10px 20px;

  @media (max-width: ${styles.mobileBreakPoint}) {
    padding: 10px;
  }
`;

const FooterCopyright: any = styled.div`
  background: ${styles.secondaryDarkColor};
  width: 100%;
  text-align: center;
  bottom: 0;
  padding-bottom: 2px;
`;

const Footer: React.SFC<IFooterProps> = props => (
  <FooterWrapper kind={props.kind} id="footer">
    <FooterContent>{props.children}</FooterContent>
    <FooterCopyright>
      <Text
        style={{
          color: `${styles.fadedTextColor}`,
          fontSize: "11px",
          fontWeight: 300,
          letterSpacing: "1px"
        }}
      >
        <span>
          Powered by{" "}
          <a
            href="https://timelapse.co.uk"
            target="_blank"
            rel="noreferrer"
            style={{
              color: styles.secondaryTextColor,
              textDecoration: "none"
            }}
          >
            Interval Films
          </a>
        </span>
      </Text>
    </FooterCopyright>
  </FooterWrapper>
);

FooterWrapper.defaultProps = { kind: "default" };

export default Footer;
