import * as React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import Text from "../text";
import { Column, Row } from "hedron";

interface IDeletionItemProps {
  id: number;
  name: string;
  siteName: string;
  imagesRemaining: number;
}

const DeletionItemWrapper = styled.div`
  background: ${styles.secondaryDarkColor};
  padding: ${styles.paddingUnit * 2}px;
  border-radius: 20px;

  @media screen and (max-width: ${styles.mobileBreakPoint}) {
    padding: ${styles.paddingUnit}px; // reduces the padding by half on mobile
  }
`;

const ImageRemainingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const SiteName = styled(Text)`
  display: inline;

  @media screen and (max-width: ${styles.mobileBreakPoint}) {
    display: block;
  }
`;

const DeletionItem: React.FC<IDeletionItemProps> = ({
  name,
  siteName,
  imagesRemaining
}) => {
  return (
    <DeletionItemWrapper>
      <Row justifyContent="space-between" wrap="true">
        <Column sm={12} md={6}>
          <Text fontSize="h3">{name}</Text>
          <SiteName fontSize="h4" style={{ color: "#afbbcc50" }}>
            {" "}({siteName})
          </SiteName>
        </Column>
        <Column sm={12} md={6}>
          <ImageRemainingWrapper>
            <Text fontSize="h4" style={{ fontWeight: "bold" }}>
              {imagesRemaining}
            </Text>
            <Text fontSize="h4">images remaining</Text>
          </ImageRemainingWrapper>
        </Column>
      </Row>
    </DeletionItemWrapper>
  );
};

export default DeletionItem;
