import React from "react";
import {
  Route, Redirect, RouteProps 
} from "react-router-dom";
import AdminContainer from "../admin-container/admin-container";

interface IProps extends RouteProps {
  component: typeof React.Component;
  isAuthenticated: boolean;
  redirect: string;
  isAdmin?: boolean;
}

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isAdmin,
  redirect: pathname,
  ...rest
}: IProps) => {
  function renderRoute(props: any) {
    if (isAuthenticated && isAdmin) {
      return (
        <AdminContainer {...props}>
          <Component {...rest} {...props} />
        </AdminContainer>
      );
    } else if (isAuthenticated) {
      return <Component {...rest} {...props} />;
    } else {
      return (
        <Redirect
          to={{
            pathname,
            state: { from: props.location }
          }}
        />
      );
    }
  }

  return <Route {...rest} render={renderRoute} />;
};

export default PrivateRoute;
