import * as React from "react";
import styled from "styled-components";
import { Icon } from "..";
import { styles } from "src/lib/styles";

interface IProps {
  disabled?: boolean;
  active?: boolean;
  handleToggle?: () => void;
}

const Wrapper = styled.div<IProps>`
  border-radius: 30px;
  border: 1px solid ${styles.fadedTextColor};
  padding: 8px 20px;
  display: flex;
  align-items: center;

  cursor: ${props => (props.disabled ? "default" : "pointer")};
  svg {
    padding-right: 10px;
    fill: ${props =>
    props.disabled ? styles.fadedTextColor : styles.secondaryTextColor};

    &:hover {
      cursor: ${props => (props.disabled ? "default" : "pointer")};
      fill: ${props =>
    props.disabled ? styles.fadedTextColor : styles.primaryTextColor};
    }
  }

  span {
    color: ${props =>
    props.disabled ? styles.fadedTextColor : styles.secondaryTextColor};
  }
`;

const BimToggle = (props: IProps) => {
  const handleToggle = () => {
    if (props.handleToggle && !props.disabled) {
      props.handleToggle();
    }
  };

  return (
    <Wrapper
      active={props.active}
      disabled={props.disabled}
      onClick={handleToggle}
    >
      <Icon icon="bim" size={20} />
      <span
        style={{
          fontSize: "14px",
          letterSpacing: "4px",
          textTransform: "uppercase"
        }}
      >
        BIM
      </span>
    </Wrapper>
  );
};

export default BimToggle;
