import * as React from "react";
import signUrl from "src/lib/signS3Url";

interface S3ImageProps {
  s3Url: string | null;
  className?:string;
}
export const S3Image:React.FC<S3ImageProps> = ({ s3Url, className }) => {
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (s3Url && s3Url.includes("amazonaws")) {
      signUrl(s3Url).then(url => {
        setImageSrc(url);
      }).catch(error => {
        console.error(error);
        setImageSrc(null);
      });
    }
  }, [s3Url]);

  if (!imageSrc) {
    return null;
  }

  return <img className={className} src={imageSrc} width="100%" height="auto"/>;
};