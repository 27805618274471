import * as React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { IVideo } from "../../types/store/videos";
import { Text, Icon } from "..";
import { styles } from "src/lib/styles";
import Modal from "react-modal";
import Button from "../button";
import { IAuthStore } from "src/types/store/auth";
import { Column, Row } from "hedron";
import { History } from "history";
import signUrl from "src/lib/signS3Url";
import { Box } from "../box/Box";
import * as moment from "moment";

interface IVideoCardProps {
  video: IVideo;
  auth: IAuthStore;
  history: History;
  canDownload: boolean;
}

export const VideoCard : React.FC<IVideoCardProps> = props => {
  const { video } = props;
  const [videoUrl, setVideoUrl] = React.useState<string>();
  const [modalOpen, setModal] = React.useState<boolean>(false);

  /**
   * Only allow if authenticated. Show modal to prompt login.
   */
  const lockDownload = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!props.auth.authenticated) {
      e.preventDefault();
      setModal(true);
    }
  };

  React.useEffect(() => {
    if (props.video.download_url.includes("amazonaws")) {
      signUrl(props.video.download_url).then(signedUrl => {
        setVideoUrl(signedUrl);
      });
    } else {
      setVideoUrl(props.video.download_url);
    }
  }, [props.video.download_url]);

  const typeName = React.useMemo(() => {
    if (video.type === "manual") {
      return "Interval Films Edit";
    } else if (video.type === "client_generated") {
      return "User generated";
    } else {
      return "Auto generated";
    }
  }, [video]);

  return (
    <Box 
      width="100%"
      style={{ flexShrink: 0 }}
      id="video-wrapper"
      margins={{ bottom: "md" }}
    >
      <VideoCardInner>
        <ReactPlayer
          className="player"
          width="100%"
          height="100%"
          controls={true}
          url={video.embed_url}
          style={{ backgroundColor: "black" }}
          vimeoConfig={{
            playerOptions: {
              colors: [
                "000000",
                "00ADEF",
                "FFFFFF",
                "192638"
              ] 
            }
          }}
        />
      </VideoCardInner>
      <Box
        style={{
          background: styles.secondaryDarkColor,
          borderRadius: "0 0 4px 4px" 
        }}
        width="100%"
        flexDir="row"
        justify="space-between"
        pad="md"
      >
        <Box>
          <Text fontSize="h4">{video.name}</Text>
          <Box pad={{ top: "sm" }} flexDir="row" align="center">
            <Text fontSize="p" type="faded">{moment(video.updatedAt).format("DD MMMM YYYY")}</Text>
            <Type>{typeName}</Type>
          </Box>
        </Box>
        <Box>
          {props.canDownload && videoUrl && (
            <a
              href={videoUrl}
              download={video.name}
              onClick={e => lockDownload(e)}
              style={{
                alignItems: "center",
                background: "transparent",
                border: "none",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Icon icon="download" size={30} />
            </a>
          )}
        </Box>
      </Box>
      <StyledModal isOpen={modalOpen} className="video">
        <div className="modal-content">
          <Row className="modal-inner">
            <Column>
              <Text>
                {
                  "Downloads are only available to logged in users. Please login and try again!"
                }
              </Text>
            </Column>
            <Box flexDir="row" className="button-container" width="100%">
              <Button
                text="Login"
                clickHandler={() => props.history.push("/")}
              />

              <Button
                text="Cancel"
                clickHandler={() => setModal(false)}
              />
            </Box>
          </Row>
        </div>
      </StyledModal>
    </Box>
  );
};

const StyledModal = styled(Modal)`
  background: ${styles.primaryDarkColor};

  .modal-content {
    background: ${styles.primaryDarkColor};
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;

    .modal-inner {
      display: flex;
      justify-content: center;
      text-align: center;
      border: 1px solid white;
      padding: 1rem;

      .button-container {
        width: 100%;
        flex-direction: row;
        justify-content: center;

        button {
          margin: 0 0.5rem;
        }
      }
    }
  }
`;

const VideoCardInner = styled.div`
  position: relative;
  border-radius: 4px 4px 0 0 ;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
  width: 100%;
  background: ${styles.formBackground};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > div {
      background-color: transparent !important;
    }
  }
`;

const Type = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${styles.fadedTextColor};
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: black;
  margin-left: 10px;
`;
