import * as React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";

interface ICompareDateTimeControls {
  active?: boolean;
  style?: any;
  children: React.ReactChild;
}

const Wrapper = styled.div<ICompareDateTimeControls>`
  display: flex;
  opacity: ${props => (props.active ? 1 : 0.6)};
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  position: relative;

  svg {
    fill: ${styles.fadedTextColor};
  }

  .compareDateIndicator {
    flex-direction: column;
    display: flex;
    padding: 0 7px;
    white-space: nowrap;
    span:first-child {
      font-size: 12px;
      color: ${props =>
    props.active ? styles.secondaryTextColor : styles.fadedTextColor};
    }
    span:last-child {
      font-size: 11px;
      color: ${styles.fadedTextColor};
    }
  }
  .compareTimeIndicator {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 20px;

    &:hover {
      svg {
        fill: ${props =>
    props.active ? styles.primaryAccentColor : styles.secondaryTextColor};
      }
      span {
        color: ${props =>
    props.active ? styles.primaryAccentColor : styles.secondaryTextColor};
      }
    }

    @media (max-width: ${styles.mobileBreakPoint}) {
      padding: 0 10px;
    }
    svg {
      fill: ${props =>
    props.active ? styles.primaryAccentColor : styles.fadedTextColor};
    }

    span {
      padding: 0 7px;
      font-size: 12px;
      transition: color 0.1s ease-in-out;
      color: ${props =>
    props.active ? styles.primaryAccentColor : styles.fadedTextColor};
    }
  }
`;

const CompareDateTimeControls: React.SFC<ICompareDateTimeControls> = ({
  active,
  style,
  children
}) => (
  <Wrapper style={style} active={active}>
    {children}
  </Wrapper>
);

export default CompareDateTimeControls;
