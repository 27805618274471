import * as Redux from "redux";
import * as AT from "./deletion-queue.constants";
import { IDeletionStore } from "./deletion-queue.api";
import { DeletionQueueActions } from "./deletion-queue.actions";

const initialState: IDeletionStore = {
  deletionStats: {
    num_camera_locations: 0,
    num_images: 0,
    num_thumbnails: 0,
    num_days_until_deletion: 0,
    deletion_items: []
  },
  gettingDeletionStats: false
};

export const deletionStats: Redux.Reducer<IDeletionStore> = (state: IDeletionStore = initialState,
  action: DeletionQueueActions): IDeletionStore => {
  switch (action.type) {
    // GET DELETION STATS
    case AT.GET_DELETION_STATS:
      return {
        ...state,
        deletionStats: {
          num_camera_locations: 0,
          num_images: 0,
          num_thumbnails: 0,
          num_days_until_deletion: 0,
          deletion_items: []
        },
        gettingDeletionStats: true
      };
    case AT.GET_DELETION_STATS_SUCCESS:
      return {
        ...state,
        deletionStats: action.data,
        gettingDeletionStats: false
      };
    case AT.GET_DELETION_STATS_FAILURE:
      return {
        ...state,
        gettingDeletionStats: false 
      };
    
    default:
      return state;
  }
};
