import React from "react";
import GoogleAnalytics from "react-ga";

GoogleAnalytics.initialize(`${process.env.REACT_APP_GA_TRACKING_CODE}`);
interface IProps {
  location: { pathname: string };
}

const withTracker = (WrappedComponent: React.ComponentClass | React.FunctionComponent, options = {}) => {
  const trackPage = (page: string) => {
    GoogleAnalytics.set({
      page,
      ...options
    });
    GoogleAnalytics.pageview(page);
  };

  class HOC extends React.Component<IProps> {
    public componentDidMount() {
      const page = this.props.location.pathname;

      trackPage(page);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    public render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return HOC;
};

export default withTracker;
