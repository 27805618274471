export const PURGE_SITES = "@site/PURGE_SITES";
export type PURGE_SITES = typeof PURGE_SITES;

export const SET_SITE = "@site/SET_SITE";
export type SET_SITE = typeof SET_SITE;

export const GET_SITES = "@site/GET_SITES";
export type GET_SITES = typeof GET_SITES;
export const GET_SITES_SUCCESS = "@site/GET_SITES_SUCCESS";
export type GET_SITES_SUCCESS = typeof GET_SITES_SUCCESS;
export const GET_SITES_FAILURE = "@site/GET_SITES_FAILURE";
export type GET_SITES_FAILURE = typeof GET_SITES_FAILURE;

export const GET_SITE_BY_ID = "@site/GET_SITE_BY_ID";
export type GET_SITE_BY_ID = typeof GET_SITE_BY_ID;
export const GET_SITE_BY_ID_SUCCESS = "@site/GET_SITE_BY_ID_SUCCESS";
export type GET_SITE_BY_ID_SUCCESS = typeof GET_SITE_BY_ID_SUCCESS;
export const GET_SITE_BY_ID_FAILURE = "@site/GET_SITE_BY_ID_FAILURE";
export type GET_SITE_BY_ID_FAILURE = typeof GET_SITE_BY_ID_FAILURE;

export const CREATE_SITE = "@site/CREATE_SITE";
export type CREATE_SITE = typeof CREATE_SITE;
export const CREATE_SITE_SUCCESS = "@site/CREATE_SITE_SUCCESS";
export type CREATE_SITE_SUCCESS = typeof CREATE_SITE_SUCCESS;
export const CREATE_SITE_FAILURE = "@site/CREATE_SITE_FAILURE";
export type CREATE_SITE_FAILURE = typeof CREATE_SITE_FAILURE;

export const UPDATE_SITE = "@site/UPDATE_SITE";
export type UPDATE_SITE = typeof UPDATE_SITE;
export const UPDATE_SITE_SUCCESS = "@site/UPDATE_SITE_SUCCESS";
export type UPDATE_SITE_SUCCESS = typeof UPDATE_SITE_SUCCESS;
export const UPDATE_SITE_FAILURE = "@site/UPDATE_SITE_FAILURE";
export type UPDATE_SITE_FAILURE = typeof UPDATE_SITE_FAILURE;

export const SUSPEND_SITE = "@site/SUSPEND_SITE";
export type SUSPEND_SITE = typeof SUSPEND_SITE;
export const SUSPEND_SITE_SUCCESS = "@site/SUSPEND_SITE_SUCCESS";
export type SUSPEND_SITE_SUCCESS = typeof SUSPEND_SITE_SUCCESS;
export const SUSPEND_SITE_FAILURE = "@site/SUSPEND_SITE_FAILURE";
export type SUSPEND_SITE_FAILURE = typeof SUSPEND_SITE_FAILURE;

export const DELETE_SITE = "@site/DELETE_SITE";
export type DELETE_SITE = typeof DELETE_SITE;
export const DELETE_SITE_SUCCESS = "@site/DELETE_SITE_SUCCESS";
export type DELETE_SITE_SUCCESS = typeof DELETE_SITE_SUCCESS;
export const DELETE_SITE_FAILURE = "@site/DELETE_SITE_FAILURE";
export type DELETE_SITE_FAILURE = typeof DELETE_SITE_FAILURE;
