import * as React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";

interface IHeaderProps {
  kind?: string;
  children: React.ReactChild;
}

const HeaderWrapper = styled.div<IHeaderProps>`
  display: flex;
  background: ${styles.secondaryDarkColor};
  z-index: 10;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: auto;
  position: relative;
  @media screen and (max-width: ${styles.mobileBreakPoint}) {
   
    display: block;
    
  }
`;

const Header: React.SFC<IHeaderProps> = ({ kind, children }) => (
  <HeaderWrapper id="header" kind={kind}>
    {children}
  </HeaderWrapper>
);

HeaderWrapper.defaultProps = { kind: "default" };

export default Header;
