import React from "react";
import DayPicker, { RangeModifier, DayModifiers } from "react-day-picker";
import "react-day-picker/lib/style.css";
import styled from "styled-components";
import * as moment from "moment";
import YearMonthSelect from "./year-month-select";
import { isDisabled, convertDateToUTC } from "./utils/date-helpers";
import { styles } from "../../lib/styles";

const Container = styled.div`
  position: absolute;
  bottom: 40px;
  background: ${styles.secondaryDarkColor};
  outline: none;
  left: 0;
  z-index: 99;

  @media (max-width: 768px) {
    background: ${styles.secondaryDarkColor};
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    bottom: 0;
    padding-bottom: 30px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`;

const RangeIndicator = styled.div`
  padding: 30px;
  padding-bottom: 5px;
  font-size: 0.9rem;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 30px;
    border: 1px solid ${styles.textColor};
    text-transform: uppercase;
    background: transparent;
    color: ${styles.textColor};
    margin: 15px;
    font-size: 1rem;

    &[disabled] {
      opacity: 0.5;
    }
  }
`;

const StyledRangePicker = styled.div`
  * {
    outline: none;
  }
  .IntervalRangePicker {
    min-width: 330px;
    background: rgb(16, 28, 46);
    min-height: 345px;

    .DayPicker-Day {
      height: 25px;
      width: 25px;
      font-size: 15px;
      color: white;

      border-radius: 100%;
      outline: none;
      &:not(.DayPicker-Day--outside) {
        &:not(.DayPicker-Day--disabled):hover {
          background-color: rgb(82, 179, 174) !important;
        }

        &.DayPicker-Day--disabled {
          opacity: 0.2;
        }

        &.DayPicker-Day--selected {
          color: rgb(16, 28, 46);
          background-color: rgb(82, 179, 174);
          border-radius: 0px;

          &.DayPicker-Day--end {
            border-radius: 0 20px 20px 0;
          }
          &.DayPicker-Day--start {
            border-radius: 20px 0 0 20px;
          }
          &.DayPicker-Day--end.DayPicker-Day--start {
            border-radius: 100%;
          }
        }
      }
    }

    .DayPicker-Week {
      padding-bottom: 1px;
    }
  }
`;

interface IProps {
  handleDateChange: (startDate: moment.Moment, endDate: moment.Moment) => void;
  startDate: string;
  endDate: string;
  datesList: string[];
  handleClose: () => void;
}

interface IState {
  from: Date;
  to: Date;
  range: RangeModifier | null;
  month: Date;
}

const initialState: IState = {
  from: moment.utc().toDate(),
  month: new Date(),
  range: null,
  to: moment.utc().toDate()
};

export default class DateRangePicker extends React.Component<IProps> {
  public readonly state: IState = initialState;

  constructor(props: IProps) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
  }

  public componentDidMount() {
    const startDate = moment.utc(this.props.startDate).toDate();
    const endDate = moment.utc(this.props.endDate).toDate();

    this.setState({
      from: convertDateToUTC(startDate),
      month: moment.utc(this.props.startDate).toDate(),
      to: convertDateToUTC(endDate)
    });
  }

  public render() {
    const { datesList } = this.props;
    const { from, to } = this.state;

    const modifiers = {
      disabled: (day: Date) => isDisabled(day, datesList),
      end: to,
      start: from
    };

    const fromMonth = new Date(new Date(datesList[datesList.length - 1]));
    const toMonth = new Date(new Date(datesList[0]));

    return (
      <StyledRangePicker className="RangeExample">
        <Container>
          <RangeIndicator>
            {to && from
              ? from.toDateString() === to.toDateString()
                ? moment(to).format("ddd DD MMM YYYY")
                : `${moment(from).format("ddd DD MMM YYYY")} - ${moment(to).format("ddd DD MMM YYYY")}`
              : "Pick a date"}
          </RangeIndicator>
          <DayPicker
            className="IntervalRangePicker"
            initialMonth={convertDateToUTC(moment(this.props.startDate).toDate())}
            month={this.state.month}
            numberOfMonths={1}
            fromMonth={new Date(datesList[datesList.length - 1])}
            toMonth={new Date(datesList[0])}
            selectedDays={[
              from,
              {
                from,
                to 
              }
            ]}
            modifiers={modifiers}
            onDayClick={(day, dayModifiers) =>
              this.handleDayClick(day, dayModifiers)
            }
            captionElement={({ date, localeUtils }) => (
              <YearMonthSelect
                date={date}
                localeUtils={localeUtils}
                onChange={this.handleYearMonthChange}
                fromMonth={fromMonth}
                toMonth={toMonth}
                datesList={datesList}
              />
            )}
          />
          <SubmitButtonWrapper>
            <button
              onClick={() => this.handleDateSubmit()}
              disabled={!to || !from}
            >
              Apply
            </button>
          </SubmitButtonWrapper>
        </Container>
      </StyledRangePicker>
    );
  }

  private handleDateSubmit() {
    const { from, to } = this.state;

    this.props.handleDateChange(moment(convertDateToUTC(from)).utc(),
      moment(convertDateToUTC(to)).utc());
  }

  private handleYearMonthChange(month: Date) {
    const { datesList } = this.props;
    const fromMonth = new Date(new Date(datesList[datesList.length - 1]));
    const toMonth = new Date(new Date(datesList[0]));
    let setMonth = month;

    if (month < fromMonth) {
      setMonth = fromMonth;
    }

    if (month > toMonth) {
      setMonth = toMonth;
    }

    this.setState({ month: setMonth });
  }

  private handleDayClick(day: Date, modifiers?: DayModifiers) {
    if (modifiers && modifiers.disabled) {
      return;
    }

    // No matter what timezone we are in, we want to format to the middle of the day, UTC timezone.
    const utcTime = new Date(Date.UTC(
      day.getUTCFullYear(), 
      day.getUTCMonth(), 
      day.getUTCDate(), 
      12, 
      0,
      0
    ));

    const range = DayPicker.DateUtils.addDayToRange(utcTime, this.state);

    this.setState(range);
  }
}
