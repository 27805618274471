import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import { Link } from "react-router-dom";

interface IAdminMenuProps {
  kind?: string;
  text: string;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const menuColor = theme.variants(
  "mode", "kind", {
    active: { normal: styles.primaryDarkColor },
    default: { normal: styles.secondaryDarkColor }
  }
);

const menuHoverColor = theme.variants(
  "mode", "kind", {
    active: { normal: styles.primaryDarkColor },
    default: { normal: styles.secondaryDarkColor }
  }
);

const textColor = theme.variants(
  "mode", "kind", {
    active: { normal: styles.primaryAccentColor },
    default: { normal: styles.primaryTextColor }
  }
);

const AdminMenuItemWrapper: any = styled.div`
  background: ${menuColor};
  width: 100%;
  padding-left: 10px;

  :hover {
    background: ${menuHoverColor};
  }
`;

const AdminMenuItemText: any = styled.span`
  color: ${textColor};
`;

const AdminMenu: React.FC<IAdminMenuProps> = ({
  kind, text, to, onClick
}) => (
  <AdminMenuItemWrapper kind={kind}>
    {
      to ? (
        <Link
          style={{
            display: "block",
            lineHeight: "40px",
            padding: "5px 10px",
            textDecoration: "none"
          }}
          to={to}
        >
          <AdminMenuItemText>{text}</AdminMenuItemText>
        </Link>
      ) : (
        <div
          style={{
            display: "block",
            lineHeight: "40px",
            padding: "5px 10px",
            textDecoration: "none"
          }}
          onClick={onClick}
        >
          <AdminMenuItemText>{text}</AdminMenuItemText>
        </div>
      )
    }
    
  </AdminMenuItemWrapper>
);

AdminMenuItemWrapper.defaultProps = { kind: "default" };
AdminMenuItemText.defaultProps = { kind: "default" };

export default AdminMenu;
