import React from "react";
import { styles } from "src/lib/styles";
import styled from "styled-components";
import { Icon } from "..";

interface TableActionButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "onClick"> {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    newTabPath: string;
    text: string;
}

export const TableActionButton: React.FC<TableActionButtonProps> = ({
  text,
  onClick,
  newTabPath
}) => (
  <ButtonContainer>
    <StyledButton
      onClick={onClick}
    >
      <span>{text}</span>
    </StyledButton>
 
    <NewTabButton
      onClick={() => {
        window.open(newTabPath, "_blank");
      }}
    >
      <Icon size={16} icon="openNew" className="icon" color="white"/>
    </NewTabButton>

  </ButtonContainer>
);

const StyledButton = styled.div`
border: 1px solid ${styles.textColor};  
background: ${styles.primaryDarkColor}; 
padding: 8px 14px;
user-select: none;
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
color: ${styles.textColor};
transition: ease 0.3s;
:hover {
    color: ${styles.primaryAccentColor};
    border-color: ${styles.primaryAccentColor};
    box-shadow: 0 0 1px 0px ${styles.primaryAccentColor};
    cursor: pointer;
    border-right: 1px solid ${styles.textColor};
}
span {
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  }
}
`;

const NewTabButton = styled.div`
display: flex;
align-items: center;
user-select: none;
background: ${styles.primaryDarkColor};
padding: 0px 10px;
border: 1px solid ${styles.textColor};
border-left: none;
border-top-right-radius: 6px;
border-bottom-right-radius: 6px;
transition: ease 0.3s;
:hover {
  color: ${styles.primaryAccentColor};
  border-color: ${styles.primaryAccentColor};
  box-shadow: 0 0 1px 0px ${styles.primaryAccentColor};
  cursor: pointer;

  svg {
    fill: ${styles.primaryAccentColor};
  }
}
`;

const ButtonContainer = styled.div`
display: flex;
align-items: stretch;
align-content: center;
`;