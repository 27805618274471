import moment from "moment";
import React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";

const DateTimeText = styled.div<IDateTimeIndicatorProps>`
  opacity: ${props => (props.isOverlayActive ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  padding: 10px 25px;
  background: ${styles.primaryOverlayColour};
  position: absolute;
  top: ${props => props.top || 0}px;
  left: 50%;
  transform: translate(-50%, -100%);
  border-radius: 30px;
  font-weight: 400;
  font-size: 14px;
  color: ${styles.textColor};
  min-width: 175px;
  text-align: center;

  @media (max-width: ${styles.mobileBreakPoint}) {
    display: none;
  }

  @media (min-width: ${styles.mobileBreakPoint}) {
    display: ${props =>
    props.hidesOnDesktopDrag === true &&
      (props.activeDraggedImage !== null || undefined)
      ? "none"
      : "block"};
  }
`;

const DateTimeIndicator: React.SFC<IDateTimeIndicatorProps> = props => {
  if (props.dateTime) {
    let date = moment.utc(props.dateTime).format("ddd DD MMM YYYY");
    let time = moment.utc(props.dateTime).format("HH:mm");

    if (props.activeDraggedImage) {
      date = moment.utc(props.activeDraggedImage).format("ddd DD MMM YYYY");
      time = moment.utc(props.activeDraggedImage).format("HH:mm");
    }

    return (
      <DateTimeText {...props}>
        {date}
        &nbsp; &nbsp; &nbsp;
        {time}
      </DateTimeText>
    );
  }

  return null;
};

interface IDateTimeIndicatorProps {
  isOverlayActive?: boolean;
  dateTime: moment.Moment | string | null;
  activeDraggedImage?: string | null;
  hidesOnDesktopDrag?: boolean;
  top?: number;
}

DateTimeIndicator.defaultProps = { isOverlayActive: true };

export default DateTimeIndicator;
