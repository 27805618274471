import * as React from "react";

interface IReturn {
  width: number;
  height: number;
}

export const useWindowSize: () => IReturn = () => {
  const getSize = React.useCallback(() => {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }, []);

  const [windowSize, setWindowSize] = React.useState(getSize);

  // On mount
  React.useEffect(() => {
    // const handleResize = () => {
    setWindowSize(getSize());
    // };

    // window.addEventListener("resize", handleResize);

    // return () => window.removeEventListener("resize", handleResize);
  }, [getSize]); // Empty array ensures that effect is only run on mount and unmount

  return { ...windowSize };
};
