import _ from "lodash";
import { Storage } from "aws-amplify";

export default async (url: string): Promise<string> => {
  const urlSplit = url.split("amazonaws.com/")[1];

  return (await Storage.get(urlSplit, {
    customPrefix: {
      public: "",
      private: "",
      protected: ""
    },
    expires: 86400,
    level: "public"
  })) as string;
};
