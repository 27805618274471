import {
  Form, Formik, FormikProps
} from "formik";
import { Column, Row } from "hedron";
import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import * as Redux from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ICheckListMenuItem } from "src/components/check-list-menu/check-list-menu";
import { getCustomersRequest } from "src/store/customers/customers.actions";
import { IStore } from "src/types/store/store";
import * as Yup from "yup";

import {
  Button, CheckListMenu, DropdownFormik, SelectListMenu, Text, TextInput, ValidationError
} from "src/components";
import { createUserRequest } from "src/store/users/users.actions";
import {
  ICustomer, IMinifiedCameraLocation, IMinifiedSite
} from "src/store/customers/customers.api";
import { IUserInput } from "src/types/store/users";
import { Checkbox } from "src/components/checkbox/formik-checkbox";
import { styles } from "../../lib/styles";
import { PaginatedParams } from "src/types/pagination";
import MobileSpacer from "src/components/MobileSpacer";

interface IUserDispatchProps {
  createUser: (user: IUserInput) => void;
  getCustomers: (
    pagination: PaginatedParams<ICustomer>,
    filters: Partial<ICustomer>
  ) => void;
}

interface ICreateUserProps {
  isCreating: boolean;
  customers: ICustomer[] | null;
  isSavingUserCameras: boolean;
  isLoadingCustomers: boolean;
}

interface ICheckedCamera {
  cameraId: number
  siteId: number
}

interface ICreateUserFormProps {
  email: string;
  password: string;
  role: string;
  username: string;
  phone_number?: string | null;
}

type Props = ICreateUserProps & IUserDispatchProps;

export const CreateUser: React.FC<Props> = ({
  isCreating, customers, isSavingUserCameras, isLoadingCustomers, createUser, getCustomers 
}) => {
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<number | undefined>();
  const [selectedSiteId, setSelectedSiteId] = React.useState<number | undefined>();
  const [selectedSites, setSelectedSites] = React.useState<IMinifiedSite[]>([]);
  const [selectedCameras, setSelectedCameras] = React.useState<IMinifiedCameraLocation[]>([]);
  const [checkedCameras, setCheckedCameras] = React.useState<ICheckedCamera[]>([]);
  const [canDownload, setCanDownload] = React.useState(true);

  const handleSubmit = (user: IUserInput) => {
    const userCameras = checkedCameras.map(camera => {
      return { camera_location_id: camera.cameraId };
    });

    createUser({
      ...user,
      can_download: canDownload,
      userCameras
    });
  };

  React.useEffect(() => {
    getCustomers({
      limit: 99999,
      offset: 0,
      sort: [["name", "ASC"]],
      page: 1
    }, {});
  }, [getCustomers]);

  const onSelectCustomer = (id: number) => {
    if (id !== selectedCustomerId && customers) {
      let selectedSites: IMinifiedSite[] = [];
      const selectedCustomer = customers.find(customer => customer.id === id);

      if (selectedCustomer) {
        selectedSites = selectedCustomer.sites || [];
      }

      setSelectedCustomerId(id);
      setSelectedSiteId(undefined);
      setSelectedSites(selectedSites);
      setSelectedCameras([]);
    }
  };

  const onSelectSite = (id: number) => {
    if (id !== selectedSiteId && selectedSites) {
      let selectedCameras: IMinifiedCameraLocation[] = [];
      const selectedSite = selectedSites.find(site => site.id === id);

      if (selectedSite) {
        selectedCameras = selectedSite.camera_locations || [];
      }

      setSelectedSiteId(id);
      setSelectedCameras(selectedCameras);
    }
  };

  const onCheck = React.useCallback((cameraId: number) => {
    if (selectedSiteId && selectedCustomerId) {
      let checkedItems = checkedCameras;

      if (checkedCameras.find(checkedItem => checkedItem.cameraId === cameraId)) {
        checkedItems = _.filter(checkedCameras, checked => checked.cameraId !== cameraId);
      } else {
        checkedItems.push({
          cameraId,
          siteId: selectedSiteId
        });
      }
      
      setCheckedCameras(checkedItems);
      setSelectedCameras([...selectedCameras]);
    }
  }, [
    checkedCameras,
    selectedCameras,
    selectedCustomerId,
    selectedSiteId
  ]);

  return (
    <>
      <div style={{
        background: styles.secondaryDarkColor,
        borderRadius: "15px",
        marginBottom: "80px"
   
      }}>
        <Row alignContent="space-between">
          <Column xs={9}>
            <Text fontSize="h1">Invite new user</Text>
          </Column>
        </Row>
        <Row />
        <Row>
          <Column>
            <Formik
              initialValues={{
                email: "",
                password: "",
                role: "client",
                username: "",
                phone_number: null
              }}
              onSubmit={(values: any) => handleSubmit(values)}
              validationSchema={
                Yup.object().shape({
                  email: Yup.string()
                    .email()
                    .required("Required"),
                  password: Yup.string()
                    .min(8, "Password must be at least 8 characters long.")
                    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!-{}"'|_^=@#$&£()\\`.+,/"]{8,}/,
                      { message: "Password must contain one capital, lowercase and number." })
                    .required("Required"),
                  role: Yup.string().required("Required"),
                  username: Yup.string().required("Required"),
                  phone_number: Yup.string().matches(/^\+[1-9]{1}[0-9]{3,14}$/, { message: "Please enter a valid international phone number (e.g. use +44 rather than 0 for UK numbers)" }).nullable()
                })}
            >
              {(props: FormikProps<ICreateUserFormProps>) => {
                const {
                  values, handleChange, handleBlur, errors, setFieldValue
                } = props;

                return (
                  <Form>
                    <Row>
                      <Column xs={12} lg={5}>
                        <TextInput
                          onChange={handleChange}
                          value={values.username}
                          name="username"
                          placeholder="Username"
                          onBlur={handleBlur}
                        />
                        <ValidationError>{errors.username}</ValidationError>
                      </Column>
                      <Column xs={12} lg={5}>
                        <TextInput
                          onChange={handleChange}
                          value={values.email}
                          name="email"
                          placeholder="Email"
                          onBlur={handleBlur}
                        />
                        <ValidationError>{errors.email}</ValidationError>
                      </Column>
                      <Column xs={12} lg={5}>
                        <Text fontSize="small">Provide a valid phone number to enable 2FA for this user</Text>
                        <TextInput
                          onChange={handleChange}
                          value={values.phone_number ?? undefined}
                          name="phone_number"
                          placeholder="Phone number"
                          onBlur={handleBlur}
                        />
                        <ValidationError>{errors.phone_number}</ValidationError>
                      </Column>
                    </Row>

                    <Row>
                      <Column xs={12} lg={5}>
                        <TextInput
                          onChange={handleChange}
                          value={values.password}
                          type="password"
                          name="password"
                          placeholder="Password"
                          onBlur={handleBlur}
                        />
                        <ValidationError>{errors.password}</ValidationError>
                      </Column>
                      <Column xs={12} lg={5}>
                        <DropdownFormik
                          name="role"
                          kind="default"
                          placeholder="Role"
                          onChange={value => {
                            setFieldValue("role", value);
                          }}
                          items={[
                            {
                              value: "admin",
                              text: "Admin"
                            },
                            {
                              value: "client",
                              text: "Client"
                            }
                          ]}
                        />
                        <ValidationError>{errors.role}</ValidationError>
                      </Column>
                    </Row>

                    <Row>
                      <Column xs={12} lg={5}>
                        <Button
                          disabled={isCreating}
                          text="Submit"
                          type="submit"
                        />
                      </Column>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
            <>
              <h1 style={{
                fontSize: "1.4rem",
                margin: "1rem",
                marginTop: "2rem"
              }}>Access to cameras</h1>
              <Row style={{ flexWrap: "nowrap" }}>
                <SelectListMenu
                  selected={selectedCustomerId}
                  loading={isLoadingCustomers}
                  items={customers && customers.map(item => {
                    const sites = item.sites;
                    let qtyOfCams = 0;

                    if (sites) {
                      sites.map(site => {
                        qtyOfCams += checkedCameras.filter(checkedCamera => checkedCamera.siteId === site.id).length;
                      });
                    }

                    return {
                      value: item.id,
                      label: item.name,
                      checkType: "unchecked",
                      qtyOfCams
                    } as ICheckListMenuItem;
                  })}

                  onSelect={customer => onSelectCustomer(customer.value)}
                  label="Customers"
                />
                <SelectListMenu
                  selected={selectedSiteId}
                  loading={isLoadingCustomers}
                  items={selectedSites.map(item => {
                    const qtyOfCams = checkedCameras.filter(checkedCamera => checkedCamera.siteId === item.id).length;

                    return {
                      value: item.id,
                      label: item.name,
                      checkType: "unchecked",
                      qtyOfCams
                    } as ICheckListMenuItem;
                  })}
                  onSelect={site => onSelectSite(site.value)}
                  label="Sites"
                  parent="Customer"
                />
                <CheckListMenu
                  loading={isLoadingCustomers}
                  items={selectedCameras.map(item => {
                    if (checkedCameras.find(checkedCamera => checkedCamera.cameraId === item.id)) {
                      return {
                        value: item.id,
                        label: item.name,
                        checkType: "checked"
                      } as ICheckListMenuItem;
                    } else {
                      return {
                        value: item.id,
                        label: item.name,
                        checkType: "unchecked"
                      } as ICheckListMenuItem;
                    }
                  })}
                  onClick={camera => onCheck(camera.value)}
                  label="Cameras"
                  parent="Site"
                />
              </Row>

              <Row>
                <Column>
                  <Checkbox
                    onChange={() => setCanDownload(!canDownload)}
                    checked={canDownload}
                    name={"can_download"}
                    label="Can download"/>
                </Column>
              </Row>
            </>
          </Column>
        </Row>
        <Row />
      </div>
      <MobileSpacer/>
    </>
  );
};

const mapStateToProps = (state: IStore, _props: Props): ICreateUserProps => {
  return {
    customers: state.customers.customers,
    isCreating: state.users.creatingUser,
    isLoadingCustomers: state.customers.gettingCustomers,
    isSavingUserCameras: state.users.isUpdatingUserCameras
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IUserDispatchProps => {
  return {
    createUser: (user: IUserInput) => dispatch(createUserRequest(user)),
    getCustomers: (pagination: PaginatedParams<ICustomer>,
      filters: Partial<ICustomer>) => dispatch(getCustomersRequest(pagination, filters))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
