import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import { Row, Column } from "hedron";
import { Text } from "../";

interface ISuspendedProps extends React.HTMLProps<HTMLDivElement> {
  kind?: string;
}

const backgroundColor = theme.variants(
  "mode", "kind", { default: { normal: styles.primaryDarkColor } }
);

const SuspendedViewWrapper: any = styled.span`
  background: ${backgroundColor};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
`;

const SuspendedView: React.SFC<ISuspendedProps> = ({ style, ...rest }) => (
  <SuspendedViewWrapper {...rest} style={style}>
    <div>
      <Row>
        <Column>
          <Text style={{
            textAlign: "center",
            display: "block" 
          }} fontSize="h1">
            Camera Suspended!
          </Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Text style={{
            textAlign: "center",
            display: "block" 
          }} fontSize="h5">
            To reactivate this camera please call us on 0117 370 8519 or send an
            email to info@timelapse.co.uk.
          </Text>
        </Column>
      </Row>
    </div>
  </SuspendedViewWrapper>
);

SuspendedViewWrapper.defaultProps = { kind: "default" };

export default SuspendedView;
