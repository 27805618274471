import * as React from "react";
import ImageSelectThumbnail from "../image-select-thumbnail";
import TextButton from "../text-button";

const RemovableImageSelectThumbnail: React.FC<{imageSrc: string | null, onRemove?: () => void, removeText?: string}> = ({
  imageSrc,
  onRemove,
  removeText
}) => {
  return (
    <div>
      <div style={{
        margin: "10px 0",
        maxWidth: 300 
      }}>
        <ImageSelectThumbnail dimensions={{
          width: 200,
          height: 200 
        }}>
          <img src={imageSrc ?? ""} width="100%" height="auto" />
        </ImageSelectThumbnail>
      </div>
      <TextButton type="button" clickHandler={onRemove} text={removeText} align="none" style={{ margin: "10px 0" }} />

    </div>
  );
};

export default RemovableImageSelectThumbnail;