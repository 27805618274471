import * as AT from "./sites.constants";
import { IStore } from "../../types/store/store";
import * as Redux from "redux";
import { ThunkAction } from "redux-thunk";
import {
  goBack, RouterAction, push
} from "react-router-redux";
import { toast } from "react-toastify";
import apiError from "src/lib/apiError";
import { ISite } from "src/store/sites/sites.api";
import * as sitesApi from "./sites.api";
import { CreateSiteParams } from "./sites.api";

export type SiteActions =
  | ISetSite
  | IPurgeSites
  | IGetSites
  | IGetSitesSuccess
  | IGetSitesFailure
  | IGetSiteById
  | IGetSiteByIdSuccess
  | IGetSiteByIdFailure
  | ICreateSite
  | ICreateSiteSuccess
  | ICreateSiteFailure
  | IUpdateSite
  | IUpdateSiteSuccess
  | IUpdateSiteFailure
  | ISuspendSite
  | ISuspendSiteSuccess
  | ISuspendSiteFailure
  | IDeleteSite
  | IDeleteSiteSuccess
  | IDeleteSiteFailure;

type ThunkResult<R> = ThunkAction<
  R,
  IStore,
  undefined,
  SiteActions | RouterAction
>;

export interface IPurgeSites {
  type: AT.PURGE_SITES;
}

export const purgeSites: Redux.ActionCreator<IPurgeSites> = () => {
  return { type: AT.PURGE_SITES };
};

export interface ISetSite {
  type: AT.SET_SITE;
}

export const setSite: Redux.ActionCreator<ISetSite> = () => {
  return { type: AT.SET_SITE };
};

// ----------- GET SITES ACTIONS -------- //
export interface IGetSites {
  type: AT.GET_SITES;
}

export interface IGetSitesSuccess {
  sites: ISite[];
  type: AT.GET_SITES_SUCCESS;
}

export interface IGetSitesFailure {
  type: AT.GET_SITES_FAILURE;
}

export const getSites: Redux.ActionCreator<IGetSites> = () => {
  return { type: AT.GET_SITES };
};

export const getSitesSuccess: Redux.ActionCreator<IGetSitesSuccess> = (sites: ISite[]) => {
  return {
    sites,
    type: AT.GET_SITES_SUCCESS
  };
};

export const getSitesFailure: Redux.ActionCreator<IGetSitesFailure> = () => {
  return { type: AT.GET_SITES_FAILURE };
};

export const getSitesRequest = (
  customerId: string,
  status?: string,
  role?: string
): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getSites());

    try {
      const sites = await sitesApi.getSites(
        customerId, status, role
      );

      dispatch(getSitesSuccess(sites));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getSitesFailure());
      toast.error(apiError(error));
    }
  };
};
// ----------- END GET SITES ACTIONS -------- //

// ----------- GET SITE BY ID ACTIONS -------- //

export interface IGetSiteById {
  type: AT.GET_SITE_BY_ID;
}

export interface IGetSiteByIdSuccess {
  site: ISite;
  type: AT.GET_SITE_BY_ID_SUCCESS;
}

export interface IGetSiteByIdFailure {
  type: AT.GET_SITE_BY_ID_FAILURE;
}

export const getSiteById: Redux.ActionCreator<IGetSiteById> = () => {
  return { type: AT.GET_SITE_BY_ID };
};

export const getSiteByIdSuccess: Redux.ActionCreator<IGetSiteByIdSuccess> = (site: ISite) => {
  return {
    site,
    type: AT.GET_SITE_BY_ID_SUCCESS
  };
};

export const getSiteByIdFailure: Redux.ActionCreator<IGetSiteByIdFailure> = () => {
  return { type: AT.GET_SITE_BY_ID_FAILURE };
};

export const getSiteByIdRequest = (siteId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getSiteById());

    try {
      const site = await sitesApi.getSiteById(siteId);

      dispatch(getSiteByIdSuccess(site));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getSiteByIdFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END GET SITE BY ID ACTIONS -------- //

// ----------- CREATE SITES ACTIONS -------- //
export interface ICreateSite {
  type: AT.CREATE_SITE;
}

export interface ICreateSiteSuccess {
  type: AT.CREATE_SITE_SUCCESS;
}

export interface ICreateSiteFailure {
  type: AT.CREATE_SITE_FAILURE;
}

export const createSite: Redux.ActionCreator<ICreateSite> = () => {
  return { type: AT.CREATE_SITE };
};

export const createSiteSuccess: Redux.ActionCreator<
  ICreateSiteSuccess
> = () => {
  return { type: AT.CREATE_SITE_SUCCESS };
};

export const createSiteFailure: Redux.ActionCreator<
  ICreateSiteFailure
> = () => {
  return { type: AT.CREATE_SITE_FAILURE };
};

export const createSiteRequest = (site: CreateSiteParams): ThunkResult<void> => {
  return async dispatch => {
    dispatch(createSite());

    try {
      await sitesApi.createSite(site);
      dispatch(createSiteSuccess());
      toast.success("Successfully created site!");

      dispatch(getSitesRequest(
        site.customer_id, undefined, "admin"
      ));
      dispatch(goBack());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(createSiteFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END CREATE SITES ACTIONS -------- //

// ----------- UPDATE SITES ACTIONS -------- //
export interface IUpdateSite {
  type: AT.UPDATE_SITE;
}

export interface IUpdateSiteSuccess {
  type: AT.UPDATE_SITE_SUCCESS;
}

export interface IUpdateSiteFailure {
  type: AT.UPDATE_SITE_FAILURE;
}

export const updateSite: Redux.ActionCreator<IUpdateSite> = () => {
  return { type: AT.UPDATE_SITE };
};

export const updateSiteSuccess: Redux.ActionCreator<
  IUpdateSiteSuccess
> = () => {
  return { type: AT.UPDATE_SITE_SUCCESS };
};

export const updateSiteFailure: Redux.ActionCreator<
  IUpdateSiteFailure
> = () => {
  return { type: AT.UPDATE_SITE_FAILURE };
};

export const updateSiteRequest = (siteId: string,
  site: ISite): ThunkResult<void> => {
  return async dispatch => {
    dispatch(updateSite());

    try {
      await sitesApi.updateSite(siteId, site);
      dispatch(updateSiteSuccess());
      dispatch(goBack());
      toast.success("Successfully updated site details!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(updateSiteFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END UPDATE SITES ACTIONS -------- //

// ----------- SUSPEND SITES ACTIONS -------- //
export interface ISuspendSite {
  type: AT.SUSPEND_SITE;
}

export interface ISuspendSiteSuccess {
  type: AT.SUSPEND_SITE_SUCCESS;
}

export interface ISuspendSiteFailure {
  type: AT.SUSPEND_SITE_FAILURE;
}

export const suspendSite: Redux.ActionCreator<ISuspendSite> = () => {
  return { type: AT.SUSPEND_SITE };
};

export const suspendSiteSuccess: Redux.ActionCreator<
  ISuspendSiteSuccess
> = () => {
  return { type: AT.SUSPEND_SITE_SUCCESS };
};

export const suspendSiteFailure: Redux.ActionCreator<
  ISuspendSiteFailure
> = () => {
  return { type: AT.SUSPEND_SITE_FAILURE };
};

export const suspendSiteRequest = (siteId: string,
  customerId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(suspendSite());

    try {
      await sitesApi.suspendSite(siteId);
      dispatch(suspendSiteSuccess());
      toast.success("Successfully updated site status!");
      dispatch(getSiteByIdRequest(siteId));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(suspendSiteFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END SUSPEND SITES ACTIONS -------- //

// ----------- DELETE SITES ACTIONS -------- //
export interface IDeleteSite {
  type: AT.DELETE_SITE;
}

export interface IDeleteSiteSuccess {
  type: AT.DELETE_SITE_SUCCESS;
}

export interface IDeleteSiteFailure {
  type: AT.DELETE_SITE_FAILURE;
}

export const deleteSite: Redux.ActionCreator<IDeleteSite> = () => {
  return { type: AT.DELETE_SITE };
};

export const deleteSiteSuccess: Redux.ActionCreator<
  IDeleteSiteSuccess
> = () => {
  return { type: AT.DELETE_SITE_SUCCESS };
};

export const deleteSiteFailure: Redux.ActionCreator<
  IDeleteSiteFailure
> = () => {
  return { type: AT.DELETE_SITE_FAILURE };
};

export const deleteSiteRequest = (siteId: string,
  customerId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(deleteSite());

    try {
      await sitesApi.deleteSite(siteId);
      dispatch(deleteSiteSuccess());
      toast.success("Successfully deleted site!");

      dispatch(getSitesRequest(
        customerId, undefined, "admin"
      ));
      dispatch(goBack());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(deleteSiteFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END DELETE SITES ACTIONS -------- //
