import * as _ from "lodash";

export const delays = [
  {
    text: "15 mins",
    value: 0.25 
  },
  {
    text: "30 mins",
    value: 0.5
  },

  ..._.map(Array.from(Array(25).keys()), hour =>({
    text: `${hour} Hour${hour != 1 ? "s" : ""}`,
    value: hour 
  }))
];
