import { IThumbnail, ImageType } from "../types/store/images";
import _ from "lodash";
import { Storage } from "aws-amplify";
import moment from "moment";

export default async (
  type: ImageType,
  thumbnails: IThumbnail[]
): Promise<IThumbnail> => {
  const backupSizes = [
    "tablet",
    "desktop-sd",
    "desktop-max",
    "original"
  ];

  const image = _.find(thumbnails, thumb => thumb.type === type);

  if (image) {
    if (moment.utc().isAfter(image.expires)) {
      delete image.expires;
    }

    if (!image.expires) {
      // @ts-ignore
      image.s3_url = await Storage.get(image.s3_uri, {
        customPrefix: {
          public: "",
          private: "",
          protected: "" 
        },
        expires: 86400,
        level: "public"
      });
      image.expires = moment.utc().add(86400, "seconds");
    }

    return image;
  } else {
    for (const size in backupSizes) {
      if (size) {
        const newImage = _.find(thumbnails,
          thumb => thumb.type === backupSizes[size]);

        if (newImage) {
          if (!newImage.expires || moment.utc().isAfter(newImage.expires)) {
            // @ts-ignore
            newImage.s3_url = await Storage.get(newImage.s3_uri, {
              customPrefix: {
                public: "",
                private: "",
                protected: "" 
              },
              expires: 86400,
              level: "public"
            });
            newImage.expires = moment.utc().add(86400, "seconds");
          }

          return newImage;
        }
      }
    }

    return (
      image || {
        filesize: "",
        height: 0,
        id: 0,
        mime: "",
        orientation: "",
        processing_status: "error",
        s3_uri: "",
        s3_url: "",
        type,
        width: 0
      }
    );
  }
};
