import React from "react";
import { Text } from "src/components";
import DeletionLoader from "./DeletionLoader";

export const DeletionStatsLoading = () => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start", 
      height: "100vh",
      paddingTop: "33vh",
      gap: "20px"
    }}>
      <Text fontSize="h3">Loading deletion stats, please wait, this can take a few minutes.</Text>
      <DeletionLoader />
    </div>
  );
};
