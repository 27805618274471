// @ts-ignore
import * as React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";

const ValidationErrorWrapper = styled.div`
  font-size: 10px;
  padding-top: 5px;
  color: ${styles.secondaryAccentColor};
`;

export default ValidationErrorWrapper;
