import { IStore } from "../../types/store/store";
import { ISite } from "src/store/sites/sites.api";
import _ from "lodash";

export const siteGetById = (state: IStore, siteId: string | number): ISite | null => {
  if (siteId) {
    siteId = typeof siteId === "number" ? siteId : parseInt(siteId, 10);
    const site = _.find(state.sites.sites, c => c.id === siteId);

    return site ? site : null;
  } else {
    return null;
  }
};
