import React from "react";
import styled from "styled-components";
import { styles } from "src/lib/styles";
import { getContainerHeight } from "./utils/getContainerHeight";

interface ActiveLineProps {
  compareMode: "vertical" | "horizontal" | "overlay" | "difference";
  activeCompareImage?: "compare-b" | "compare-a";
  swipePos: {
    x: number;
    y: number;
  }
}

export const ActiveLine: React.FC<ActiveLineProps> = props => {
  const {
    compareMode, activeCompareImage, swipePos 
  } = props;

  const top = compareMode === "vertical" ? "calc(100% - 4px)" : "0px";

  if (compareMode === "vertical" || compareMode === "horizontal") {
    return (
      <Line
        style={{
          height:
            compareMode === "horizontal"
              ? activeCompareImage === "compare-b"
                ? `${swipePos.y || getContainerHeight() / 2 - 20}px`
                : "100%"
              : "4px",
          right:
            compareMode === "horizontal" && activeCompareImage === "compare-a"
              ? "0px"
              : "auto",
          top,
          width:
            compareMode === "vertical"
              ? activeCompareImage === "compare-b"
                ? `${swipePos.x}px`
                : "100%"
              : "2px",
          zIndex: activeCompareImage === "compare-b" ? 3 : 1
        }}
      />
    );
  } else {
    return null;
  }
};

const Line = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 1;
  background-color: ${styles.primaryAccentColor};
`;