import logger from "src/lib/logger";
import axios from "axios";
import Headers from "../../lib/auth";
import { IImage } from "../../types/store/images";
import _ from "lodash";
import moment from "moment";

export const getImages = async (
  startDate: string,
  endDate: string,
  jobRef: string,
  status: string,
  role = "client"
) => {
  try {
    const headers = await Headers(role);

    const times =
      moment.utc(startDate).diff(moment.utc(endDate), "days") === 0
        ? `start_time=${moment.utc("00:00", "HH:mm")}&end_time=${moment.utc("23:99",
          "HH:mm")}`
        : "";

    const result = await axios({
      headers,
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/${role}/images?job_ref=${jobRef}${
        status.length <= 0 ? "" : "&status=active"
      }&start_date=${startDate}&end_date=${endDate}&processing_status=processed&${times}`
    });

    logger.info("GET IMAGES", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET IMAGES ERROR", error);
    throw error;
  }
};

export const getAvailableDates = async (
  cameraLocationUUID: string,
  role = "client"
) => {
  try {
    const headers = await Headers(role);

    const result = await axios({
      headers,
      method: "GET",
      url: `${
        process.env.REACT_APP_API_URL
      }/${role}/image/dates/camera-location/${cameraLocationUUID}`
    });

    logger.info("GET IMAGES DATES RESULT", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET AVAILABLE DATES ERROR", error);
    throw error;
  }
};

export const checkUnMigratedImages = async (jobRef: string) => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/admin/images/unmigrated/${jobRef}`
    });

    logger.info("CHECK UNMIGRATED IMAGES RESULT", result);

    return result.data.data;
  } catch (error) {
    logger.error("CHECK UNMIGRATED IMAGES ERROR", error);
    throw error;
  }
};

export const migrateImagesRequest = async (jobRef: string) => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/images/migrate/${jobRef}`
    });

    logger.info("MIGRATE IMAGES RESULT", result);

    return result.data.data;
  } catch (error) {
    logger.error("MIGRATE IMAGES ERROR", error);
    throw error;
  }
};

export const updateImage = async (imageId: number, image: IImage) => {
  try {
    const headers = await Headers();

    delete image.id;

    const result = await axios({
      data: _.pickBy(image, _.identity),
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/image/${imageId}`
    });

    logger.info("UPDATE IMAGE", result);

    return result.data.data;
  } catch (error) {
    logger.error("UPDATE IMAGE ERROR", error);
    throw error;
  }
};

export const checkNewImage = async (jobRef: string, role = "client") => {
  try {
    const headers = await Headers(role);

    const result = await axios({
      headers,
      method: "GET",
      url: `${
        process.env.REACT_APP_API_URL
      }/${role}/latest-image?job_ref=${jobRef}`
    });

    logger.info("CHECK NEW IMAGES", result);

    return result.data.data;
  } catch (error) {
    logger.error("CHECK NEW IMAGES ERROR", error);
    throw error;
  }
};

export const getLiveImageUrl = async (cameraLocationId: string, type: "admin" | "client" | "public"): Promise<{url: string}> => {
  if (type !== "public") {
    try {
      const headers = await Headers();

      const result = await axios({
        headers,
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/${type}/images/live-image/${cameraLocationId}`
      });

      logger.info("GET LIVE IMAGE URL", result);

      return result.data.data;
    } catch (error) {
      logger.error("GET LIVE IMAGE URL ERROR", error);
      throw error;
    }
  } else {
    try {
      // run as a public request
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/public/images/live-image/${cameraLocationId}`
      });

      logger.info("GET LIVE IMAGE URL", result);

      return result.data.data;
    } catch (error) {
      logger.error("GET LIVE IMAGE URL ERROR", error);
      throw error;
    }
  }
};
