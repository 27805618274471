import React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import { Text } from "../";

const RadioLabel = styled.label<{kind?: string}>``;

interface IRadioProps {
  label?: string;
  checked: boolean;
  value: string;
  onChange: (value: boolean) => void;
}

export const Radio: React.FC<IRadioProps> = ({ onChange, ...props }) => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      gap: "0.25rem",
      justifyContent: "center"
    }}>
      <RadioLabel className="label">
        <input
          {...props}
          onChange={() => {
            onChange(!props.checked);
          }}
          type="radio"
        />
      </RadioLabel>
      {props.label && (
        <Text
          style={{
            display: "block",
            marginBottom: `${styles.paddingUnit}px`,
            width: "100%"
          }}
        >
          {props.label}
        </Text>
      )}
    </div>
  );
};

interface RadioGroupProps {
  options: Record<string, string>;
  value: string;
  onChange: (value: string) => void;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  options, value, onChange 
}) => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      gap: "1rem"
    }}>
      {
        Object.keys(options).map(key => (
          <Radio
            key={key}
            label={options[key]}
            value={key}
            checked={value === key}
            onChange={() => onChange(key)}
          />
        ))
      }
    </div>
  );
};

export default RadioGroup;