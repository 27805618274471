import React, { TdHTMLAttributes } from "react";
import { styles } from "src/lib/styles";
import styled from "styled-components";

const TableCellContainer = styled.td`
    padding: 10px 5px;
    @media screen and (max-width: ${styles.mobileBreakPoint}) {
        * {
            text-align: right;
        }
        * label {
            text-align: left;
        }
        width: 98% !important;
        border-left: none !important;
        border-right: none !important;
        
        
        padding: 10px 4px;
    }
`;

export const TableCell: React.FC<{
    name: string;
    hideName: boolean;
    tdProps: TdHTMLAttributes<HTMLTableCellElement>
}> = ({
  children, name, tdProps, hideName
}) => {
  return (
    <TableCellContainer
      {...tdProps}
    >
      <Wrapper hideName={hideName}>
        {!hideName && (

          <CellTitle style={{ fontWeight: "bold" }}>{name}</CellTitle>
        )}
        
        <div>
          {children}
        </div>
      </Wrapper>
    </TableCellContainer>
  );
};

const CellTitle = styled.label`
    display: none;
    @media screen and (max-width: ${styles.mobileBreakPoint}) {
        display: block;
    }
`;

const Wrapper = styled.label<{hideName: boolean}>`



    @media screen and (max-width: ${styles.mobileBreakPoint}) {

      > div {
        width: ${({ hideName }) => hideName ? "100%" : ""};
      }

        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;
