import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import { Icon } from "..";

interface IButtonStyleProps {
  kind?: string;
  bordered?: boolean;
}

interface IButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "onClick">, IButtonStyleProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  text: string | NonNullable<(React.ReactNode & {})>;
  clickHandler?: (e: React.MouseEvent<HTMLElement>) => void;
  align?: "left" | "right" | "none";
  disabled?: boolean;
  newTabPath?: string;
}

const textColor = theme.variants(
  "mode", "kind", {
    default: { normal: styles.secondaryTextColor },
    delete: { normal: styles.secondaryAccentColor },
    outline: { normal: styles.primaryDarkColor },
    secondary: { normal: styles.primaryAccentColor }
  }
);

const hoverTextColor = theme.variants(
  "mode", "kind", {
    default: { normal: styles.primaryAccentColor },
    delete: { normal: styles.lightGrey },
    outline: { normal: styles.primaryAccentColor },
    secondary: { normal: styles.lightGrey }
  }
);

const buttonColor = theme.variants(
  "mode", "kind", {
    default: { normal: styles.primaryDarkColor },
    delete: { normal: styles.primaryDarkColor },
    outline: { normal: "transparent" },
    secondary: { normal: styles.primaryDarkColor }
  }
);

const buttonBorder = theme.variants(
  "mode", "kind", {
    default: { normal: textColor },
    delete: { normal: textColor },
    outline: { normal: styles.primaryDarkColor },
    secondary: { normal: textColor }
  }
);

export const StyledButton = styled.button<IButtonStyleProps>`
  border: 1px solid ${textColor};  
  background: ${buttonColor}; 
  padding: 8px 14px;
  border-radius: 6px;
  color: ${textColor};
  transition: ease 0.3s;
  span {
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & > svg:first-child:not(:only-child) {
      margin-right: 1ch;
    }
    & > svg:last-child:not(:only-child) {
      margin-left: 1ch;
    }
  }
  :focus {
    outline: none;
  }
  :hover {
    color: ${hoverTextColor};
    cursor: pointer;
  }

  ${props => props.bordered ? `
    border: 1px solid ${textColor};
    box-shadow: 0 0 1px 0px ${textColor} inset, 0 0 1px 0px ${textColor};
    :hover {
      border: 1px solid ${hoverTextColor};
      box-shadow: 0 0 1px 0px ${hoverTextColor};
    }
  ` : ""}
`;

const ButtonWrapper = styled.div<Pick<IButtonProps, "kind">>`
  box-sizing: border-box;
  &.has-new-tab {
    display: flex;  
    align-items: stretch;
    align-content: center;

    button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid ${buttonBorder};
    }

    .new-tab {
      display: flex;
      align-items: center;
      background: ${buttonColor};
      padding: 0px 10px;
      border: 1px solid ${buttonBorder};
      border-left: none;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      :hover {
        color: ${hoverTextColor};
        border-color: ${hoverTextColor};
        box-shadow: 0 0 1px 0px ${hoverTextColor};
        cursor: pointer;

        svg {
          fill: ${hoverTextColor};
        }
      }
    }
  }
`;

ButtonWrapper.defaultProps = { kind: "default" };

/**
 * for awareness - `props.clickHandler` === `props.onClick`
 */
const Button: React.FC<IButtonProps> = ({
  text,
  clickHandler,
  align,
  style,
  kind,
  newTabPath,
  bordered = true,
  ...rest
}) => (
  <ButtonWrapper 
    kind={kind}
    style={{
      ...style,
      float: align ? align : "left"
    }}
    className={`${newTabPath ? "has-new-tab" : ""}`}
  >
    {//@ts-ignore
    } <StyledButton
      {...rest}
      kind={kind}
      onClick={clickHandler}
      bordered={bordered}
    >
      <span>{text}</span>
    </StyledButton>
    {newTabPath && (
      <div className="new-tab" onClick={() => window.open(newTabPath, "_blank")} title="Open in new tab">
        <Icon size={16} icon="openNew" className="icon" color="white"/>
      </div>
    )}
  </ButtonWrapper>
);

StyledButton.defaultProps = { kind: "default" };

export default Button;