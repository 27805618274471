import * as React from "react";
import { connect } from "react-redux";
import { styles } from "src/lib/styles";
import { IStore } from "src/types/store/store";
import { IUser } from "src/types/store/users";
import styled from "styled-components";
import { Icon } from "..";

interface IProps {
  isOverlayActive?: boolean;
  handleToggleCompare: () => void;
  zoomOut: (amount: number) => void;
  zoomIn: (amount: number) => void;
  scale?: number | null;
  minScale?: number | null;
  maxScale: number;
  toggleUserVideoGenerationForm: () => void;
  isCompareActive: boolean;
  fullSecurity: boolean;
}

interface IWrapperProps {
  isOverlayActive?: boolean;
}

interface IButtonProps {
  disabled?: boolean;
  onClick: () => void;
  hideOnMobile?: boolean;
}

interface IStoreProps {
  user?: IUser | null;
}

const DisplayModeButtons: React.FC<IProps & IStoreProps> = props => {
  return (
    <Wrapper isOverlayActive={props.isOverlayActive}>
      {!props.isCompareActive && props.user?.can_download && !props.fullSecurity && (
        <FadingButton onClick={() => props.toggleUserVideoGenerationForm()}>
          <Icon icon="video-generation" size={15} />
        </FadingButton>
      )}
      <FadingButton onClick={() => props.handleToggleCompare()} disabled={false}>
        <Icon icon="compare" size={15} />
      </FadingButton>
      <FadingButton
        hideOnMobile={true}
        onClick={() => props.zoomOut(0.9)}
        disabled={props.scale && props.minScale ? props.scale <= props.minScale : false}
      >
        <Icon icon="zoom-out" size={15} />
      </FadingButton>
      <FadingButton
        hideOnMobile={true}
        onClick={() => props.zoomIn(1.1)}
        disabled={props.scale ? props.scale >= props.maxScale : false}
      >
        <Icon icon="zoom-in" size={15} />
      </FadingButton>
    </Wrapper>
  )
  ;
};

const mapStateToProps = (state: IStore): IStoreProps => ({ user: state.users.currentUser });

export default connect(mapStateToProps, () => ({}))(DisplayModeButtons);

export const FadingButton = styled.button<IButtonProps>`
  transition: opacity 0.3s ease-in-out;
  background: ${styles.primaryOverlayColour};
  margin: 10px 5px;
  border-radius: 100%;
  box-shadow: 0 0 1px 0px ${styles.secondaryTextColor} inset,
    0 0 1px 0px ${styles.secondaryTextColor};
  border: 1px solid ${styles.secondaryTextColor};
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;

  &[disabled] {
    opacity: 0.3;
  }

  &:active {
    border-style: solid;
  }

  @media (max-width: ${styles.mobileBreakPoint}) {
    display: ${props => props.hideOnMobile && "none"};
  }
`;

const Wrapper = styled.div<IWrapperProps>`
  opacity: ${props => (props.isOverlayActive ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 5;
  display: flex;
  flex-direction: row;
`;
