import * as Redux from "redux";
import * as AT from "./sites.constants";
import { ISiteStore } from "src/store/sites/sites.api";
import { SiteActions } from "./sites.actions";

const initialState: ISiteStore = {
  creatingSite: false,
  deletingSite: false,
  gettingSites: false,
  site: null,
  currentSite: null,
  sites: [],
  suspendingSite: false,
  updatingSite: false
};

export const sites: Redux.Reducer<ISiteStore> = (state: ISiteStore = initialState,
  action: SiteActions): ISiteStore => {
  switch (action.type) {
    case AT.SET_SITE:
      return { ...state };
    // GET SITE
    case AT.GET_SITES:
      return {
        ...state,
        gettingSites: true,
        sites: []
      };
    case AT.GET_SITES_SUCCESS:
      return {
        ...state,
        gettingSites: false,
        sites: action.sites
      };
    case AT.GET_SITES_FAILURE:
      return {
        ...state,
        gettingSites: false
      };

    case AT.GET_SITE_BY_ID:
      return {
        ...state,
        gettingSites: true,
        currentSite: null
      };

    case AT.GET_SITE_BY_ID_SUCCESS:
      return {
        ...state,
        gettingSites: false,
        currentSite: action.site
      };

    case AT.GET_SITE_BY_ID_FAILURE:
      return {
        ...state,
        gettingSites: false
      };

    // CREATE SITE
    case AT.CREATE_SITE:
      return {
        ...state,
        creatingSite: true
      };
    case AT.CREATE_SITE_SUCCESS:
      return {
        ...state,
        creatingSite: false
      };
    case AT.CREATE_SITE_FAILURE:
      return {
        ...state,
        creatingSite: false
      };

    // UPDATE SITE
    case AT.UPDATE_SITE:
      return {
        ...state,
        updatingSite: true
      };
    case AT.UPDATE_SITE_SUCCESS:
      return {
        ...state,
        updatingSite: false
      };
    case AT.UPDATE_SITE_FAILURE:
      return {
        ...state,
        updatingSite: false
      };

    // SUSPEND SITE
    case AT.SUSPEND_SITE:
      return {
        ...state,
        suspendingSite: true
      };
    case AT.SUSPEND_SITE_SUCCESS:
      return {
        ...state,
        suspendingSite: false
      };
    case AT.SUSPEND_SITE_FAILURE:
      return {
        ...state,
        suspendingSite: false
      };

    // DELETE SITE
    case AT.DELETE_SITE:
      return {
        ...state,
        deletingSite: true
      };
    case AT.DELETE_SITE_SUCCESS:
      return {
        ...state,
        deletingSite: false
      };
    case AT.DELETE_SITE_FAILURE:
      return {
        ...state,
        deletingSite: false
      };
    case AT.PURGE_SITES:
      return initialState;
    default:
      return state;
  }
};
