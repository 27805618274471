import * as React from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import styled from "styled-components";
import { styles } from "../../lib/styles";

interface IProps {
  compareMode: "vertical" | "horizontal";
  containerHeight: number;
  handleDrag: DraggableEventHandler;
  isOverlayActive: boolean;
}

const VerticalCompareHandle = styled.div`
  position: absolute;
  user-select: none;
  top: 0;
  width: 40px;
  display: flex;
  align-items: center;
  cursor: ew-resize;
  justify-content: center;
  z-index: 3;

  &:after,
  &:before {
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &:after {
    border-right: 8px solid ${styles.primaryTextColor};
    left: 6px;
  }

  &:before {
    border-left: 8px solid ${styles.primaryTextColor};
    right: 6px;
  }

  &.active {
    &:after,
    &:before {
      opacity: 1;
    }
  }
`;

const VerticalCompareSwipe = styled.div`
  top: 0;
  width: 4px;
  background: ${styles.primaryTextColor};
`;

const HorizontalCompareHandle = styled.div`
  position: absolute;
  top: 0;
  height: 40px;
  width: 100vw;
  display: flex;
  align-items: center;
  cursor: ns-resize;
  justify-content: center;
  z-index: 3;

  &:after,
  &:before {
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &:after {
    border-bottom: 8px solid ${styles.primaryTextColor};
    top: 6px;
  }
  &:before {
    border-top: 8px solid ${styles.primaryTextColor};
    bottom: 6px;
  }
  &.active {
    &:after,
    &:before {
      opacity: 1;
    }
  }
`;

const HorizontalCompareSwipe = styled.div`
  top: 0;
  height: 4px;
  width: 100vw;
  background: ${styles.primaryTextColor};
`;

const WipeControls = (props: IProps) => {
  const {
    compareMode, containerHeight, handleDrag, isOverlayActive
  } = props;

  return (
    <React.Fragment>
      {compareMode === "vertical" && (
        <Draggable
          axis="x"
          defaultPosition={{
            x: window.innerWidth / 2,
            y: 0
          }}
          bounds={{
            bottom: containerHeight - 90,
            left: 5,
            right: window.innerWidth - 45,
            top: 40
          }}
          onDrag={handleDrag}
        >
          <VerticalCompareHandle
            className={isOverlayActive ? "active" : ""}
            style={{ height: `${containerHeight}px` }}
          >
            <VerticalCompareSwipe
              style={{ height: `${containerHeight}px` }}
            />
          </VerticalCompareHandle>
        </Draggable>
      )}
      {compareMode === "horizontal" && (
        <Draggable
          axis="y"
          onStart={e => preventZoom(e)}
          bounds={{
            bottom: containerHeight - 40,
            left: 0,
            right: 0,
            top: 0
          }}
          defaultPosition={{
            x: 0,
            y: containerHeight / 2 - 20
          }}
          onDrag={handleDrag}
        >
          <HorizontalCompareHandle className={isOverlayActive ? "active" : ""}>
            <HorizontalCompareSwipe />
          </HorizontalCompareHandle>
        </Draggable>
      )}
    </React.Fragment>
  );
};

const preventZoom = (e: React.TouchEvent | any) => {
  const touches = e.touches;

  if (touches && touches.length === 2) {
    e.preventDefault(e);

    return false;
  }

  return;
};

export default WipeControls;
