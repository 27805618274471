import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import getImageByType from "src/lib/getImageByType";
import { IThumbnail } from "src/types/store/images";

interface ILatestImageTableProps {
  thumbnails: IThumbnail[];
  taken_at: string;
  voltage?: number;
  temperature?: number;
  disk_space?: number;
}

interface ILatestImageTableState {
  url: string;
  taken_at: string;
}

const LatestImage = styled.img`
  max-width: 300px;
  min-width: 100px;
  width: 100%;
  border-radius: 5px;
`;

const LatestContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LatestLabel = styled.div`
 padding-top: 5px;
`;

class LatestImageTable extends React.Component<
  ILatestImageTableProps,
  ILatestImageTableState
> {
  constructor(props: ILatestImageTableProps) {
    super(props);

    this.state = {
      taken_at: "",
      url: ""
    };
  }

  public async componentDidUpdate(prevProps: ILatestImageTableProps) {
    const { thumbnails } = this.props;

    if (prevProps.thumbnails !== thumbnails) {
      const imageUrl = await getImageByType("thumbnail", this.props.thumbnails);

      if (imageUrl.s3_url) {
        this.setState({
          taken_at: moment
            .utc(this.props.taken_at)
            .format("DD-MM-YYYY HH:mm:SS"),
          url: imageUrl.s3_url
        });
      }
    }
  }

  public async componentDidMount() {
    const { thumbnails } = this.props;
    const imageUrl = await getImageByType("thumbnail", thumbnails);

    if (imageUrl.s3_url) {
      this.setState({
        taken_at: moment.utc(this.props.taken_at).format("DD-MM-YYYY HH:mm:ss"),
        url: imageUrl.s3_url
      });
    }
  }

  public render() {
    const { url } = this.state;

    return (
      <>
        <LatestContainer>
          <LatestImage src={url} />
          <LatestLabel>{this.state.taken_at}</LatestLabel>
        </LatestContainer>

      </>
    );
  }
}

export { LatestImageTable };
