export const PURGE_CUSTOMERS = "@customer/PURGE_CUSTOMERS";
export type PURGE_CUSTOMERS = typeof PURGE_CUSTOMERS;

export const SET_CUSTOMER = "@customer/SET_CUSTOMER";
export type SET_CUSTOMER = typeof SET_CUSTOMER;

export const GET_CUSTOMERS = "@customer/GET_CUSTOMERS";
export type GET_CUSTOMERS = typeof GET_CUSTOMERS;
export const GET_CUSTOMERS_SUCCESS = "@customer/GET_CUSTOMERS_SUCCESS";
export type GET_CUSTOMERS_SUCCESS = typeof GET_CUSTOMERS_SUCCESS;
export const GET_CUSTOMERS_FAILURE = "@customer/GET_CUSTOMERS_FAILURE";
export type GET_CUSTOMERS_FAILURE = typeof GET_CUSTOMERS_FAILURE;

export const GET_CUSTOMER = "@customer/GET_CUSTOMER";
export type GET_CUSTOMER = typeof GET_CUSTOMER;
export const GET_CUSTOMER_SUCCESS = "@customer/GET_CUSTOMER_SUCCESS";
export type GET_CUSTOMER_SUCCESS = typeof GET_CUSTOMER_SUCCESS;
export const GET_CUSTOMER_FAILURE = "@customer/GET_CUSTOMER_FAILURE";
export type GET_CUSTOMER_FAILURE = typeof GET_CUSTOMER_FAILURE;

export const CREATE_CUSTOMER = "@customer/CREATE_CUSTOMER";
export type CREATE_CUSTOMER = typeof CREATE_CUSTOMER;
export const CREATE_CUSTOMER_SUCCESS = "@customer/CREATE_CUSTOMER_SUCCESS";
export type CREATE_CUSTOMER_SUCCESS = typeof CREATE_CUSTOMER_SUCCESS;
export const CREATE_CUSTOMER_FAILURE = "@customer/CREATE_CUSTOMER_FAILURE";
export type CREATE_CUSTOMER_FAILURE = typeof CREATE_CUSTOMER_FAILURE;

export const UPDATE_CUSTOMER = "@customer/UPDATE_CUSTOMER";
export type UPDATE_CUSTOMER = typeof UPDATE_CUSTOMER;
export const UPDATE_CUSTOMER_SUCCESS = "@customer/UPDATE_CUSTOMER_SUCCESS";
export type UPDATE_CUSTOMER_SUCCESS = typeof UPDATE_CUSTOMER_SUCCESS;
export const UPDATE_CUSTOMER_FAILURE = "@customer/UPDATE_CUSTOMER_FAILURE";
export type UPDATE_CUSTOMER_FAILURE = typeof UPDATE_CUSTOMER_FAILURE;

export const SUSPEND_CUSTOMER = "@customer/SUSPEND_CUSTOMER";
export type SUSPEND_CUSTOMER = typeof SUSPEND_CUSTOMER;
export const SUSPEND_CUSTOMER_SUCCESS = "@customer/SUSPEND_CUSTOMER_SUCCESS";
export type SUSPEND_CUSTOMER_SUCCESS = typeof SUSPEND_CUSTOMER_SUCCESS;
export const SUSPEND_CUSTOMER_FAILURE = "@customer/SUSPEND_CUSTOMER_FAILURE";
export type SUSPEND_CUSTOMER_FAILURE = typeof SUSPEND_CUSTOMER_FAILURE;

export const DELETE_CUSTOMER = "@customer/DELETE_CUSTOMER";
export type DELETE_CUSTOMER = typeof DELETE_CUSTOMER;
export const DELETE_CUSTOMER_SUCCESS = "@customer/DELETE_CUSTOMER_SUCCESS";
export type DELETE_CUSTOMER_SUCCESS = typeof DELETE_CUSTOMER_SUCCESS;
export const DELETE_CUSTOMER_FAILURE = "@customer/DELETE_CUSTOMER_FAILURE";
export type DELETE_CUSTOMER_FAILURE = typeof DELETE_CUSTOMER_FAILURE;

export const GET_CUSTOMER_CONFIG = "@customer/GET_CUSTOMER_CONFIG";
export type GET_CUSTOMER_CONFIG = typeof GET_CUSTOMER_CONFIG;
export const GET_CUSTOMER_CONFIG_SUCCESS =
  "@customer/GET_CUSTOMER_CONFIG_SUCCESS";
export type GET_CUSTOMER_CONFIG_SUCCESS = typeof GET_CUSTOMER_CONFIG_SUCCESS;
export const GET_CUSTOMER_CONFIG_FAILURE =
  "@customer/GET_CUSTOMER_CONFIG_FAILURE";
export type GET_CUSTOMER_CONFIG_FAILURE = typeof GET_CUSTOMER_CONFIG_FAILURE;
