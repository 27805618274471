import React from "react";
import { styles } from "src/lib/styles";
import styled from "styled-components";
import { Box, Text } from "..";
import { Checkbox } from "../checkbox/formik-checkbox";
import { VideoFilterType } from "../../types/store/videos";

interface IVideoFiltersProps {
  onChange: (filters: VideoFilterType[]) => void;
  active: VideoFilterType[];
}
export const VideoFilters : React.FC<IVideoFiltersProps> = props => {
  const checkboxes: VideoFilterType[] = [
    "Edits",
    "Auto generated",
    "User generated"
  ];

  const handleChangeFilters = React.useCallback((label: VideoFilterType) => {
    const typeExists = props.active.findIndex(d => d === label);
    const existingTypes = props.active;

    if (typeExists > -1) {
      existingTypes.splice(typeExists, 1);
    } else {
      existingTypes.push(label);
    }

    props.onChange([...existingTypes]);
  }, [props]);

  return (
    <Wrapper>
      <FiltersBox>
        <Text fontSize="h3">Videos</Text>
        <Box
          margins={{ top: "sm" }}
          pad={{ top: "sm" }}
          style={{ borderTop: `1px solid ${styles.formBackground}` }}
        >
          {checkboxes.map((label, i) => (
            <Box flexDir="row" align="center" pad={{ bottom: "sm" }} key={i}>
              <Checkbox name={label} checked={props.active.includes(label)} onChange={() => handleChangeFilters(label)} />
              <Box pad={{ left: "sm" }}>
                <Text fontSize="p">{label}</Text>
              </Box>
            </Box>

          ))}
        </Box>
      </FiltersBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
    width: 25%;
    padding: 50px 15px 0 0;
    @media (max-width: 600px){
      padding: 50px 0 0 0;
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

const FiltersBox = styled.div`
    padding: 15px;
    border-radius: 5px;
    background: ${styles.secondaryDarkColor}
`;