import * as React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import Text from "../text";
import { IVideo } from "../../types/store/videos";
import ReactPlayer from "react-player";
import { Column, Row } from "hedron";

interface IVideoListItemProps {
  kind?: string;
  video: IVideo;
}

const VideoListItemWrapper = styled.div<{kind?: string}>`
  background: "white";
  padding: ${styles.paddingUnit * 2}px;
  height: 100%;
  text-align: left;
  pointer-events: none;
`;

const VideoListItem: React.SFC<IVideoListItemProps> = ({ kind, video }) => (
  <VideoListItemWrapper kind={kind}>
    <ReactPlayer url={video.embed_url} width="100%" height="100%" vimeoConfig={{
      playerOptions: {
        colors: [
          "000000",
          "00ADEF",
          "FFFFFF",
          "192638"
        ]
      }
    }} />
    <Row style={{ padding: 5 }}>
      <Column fluid={true} sm={8}>
        <Text fontSize="h4">{video.name}</Text>
      </Column>
    </Row>
  </VideoListItemWrapper>
);

VideoListItemWrapper.defaultProps = { kind: "default" };

export default VideoListItem;
