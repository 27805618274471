import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import {
  Page, Column, Row 
} from "hedron";
import { RouteComponentProps } from "react-router-dom";
import {
  AdminMenu, Header, Icon 
} from "../";
import Logo from "../../assets/images/logo.jpg";

interface IAdminContainerProps extends RouteComponentProps {
  kind?: string;
  children: React.ReactChild;
}

interface IAdminContainerState {
  open: boolean;
}
interface IAdminMenuWrapper {
  open?: boolean;
}

const menuColor = theme.variants(
  "mode", "kind", { default: { normal: styles.primaryDarkColor } }
);

const AdminContainerWrapper: any = styled.div`
  background: ${menuColor};
  height: 100vh;
  padding: 15px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const AdminMenuWrapper = styled.div<IAdminMenuWrapper>`
  width: 25%;
  max-width: 200px;

  @media screen and (max-width: ${styles.mobileBreakPoint}) {
    ${props =>
    props.open
      ? `
      width: 100vw;
    `
      : `
    display: none;
    `};
  }
`;

const AdminHeaderWrapper = styled.div<IAdminMenuWrapper>`
display: block;
@media screen and (min-width: ${styles.mobileBreakPoint}) {
  display: none;
}
`;

const MenuIconWrapper = styled.div`
  margin-left: auto;
  padding-right: 20px;
  display: block;

  @media screen and (min-width: ${styles.mobileBreakPoint}) {
    display: none;
  }
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AppBody = styled.div`
  width: 100%;
`;

class AdminContainer extends React.Component<
  IAdminContainerProps,
  IAdminContainerState
> {
  constructor(props: IAdminContainerProps) {
    super(props);
    this.state = { open: false };
    this.goBack = this.goBack.bind(this);
  }

  public render() {
    const { kind, children } = this.props;
    const { open } = this.state;

    return (
      <Page fluid={true} width="100vw" height="100vh">
        <AdminHeaderWrapper>
          <Header kind="dark">
            <Row alignItems="center" style={{ width: "100vw" }}>
              <img src={Logo} alt="logo" style={{
                width: 140,
                height: "100%"
              }}/>
              <Column xs={4}>
              </Column>
              <MenuIconWrapper onClick={() => this.setState({ open: !open })}>
                <Icon icon="menu" />
              </MenuIconWrapper>
            </Row>
          </Header>
        </AdminHeaderWrapper>
        <AppWrapper>
          <AdminMenuWrapper open={open}>
            <AdminMenu />
            
          </AdminMenuWrapper>
          <AppBody >
            <AdminContainerWrapper kind={kind}>
            
              {children}
            
            </AdminContainerWrapper>
          </AppBody>
        </AppWrapper>
      </Page>
    );
  }

  private goBack = () => {
    this.props.history.goBack();
  };
}

AdminContainerWrapper.defaultProps = { kind: "default" };

export default AdminContainer;
