import transition from "styled-transition-group";

export default transition.div.attrs({ timeout: 500 })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:enter {
    z-index: 2;
  }
  &:enter-active {
    z-index: 2;

  }
  &.enter-done: {
    z-index: 2;
  }
  &:exit {
    z-index: 1;
  }
  &:exit-active {
    z-index: 1;

  }
  &.exit-done: {
    z-index: 1;
  }
`;
