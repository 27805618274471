import * as React from "react";
import styled from "styled-components";
import { styles } from "src/lib/styles";
import { Text } from "..";

interface IProps {
  handleToggleCompare: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;

  @media (max-width: ${styles.mobileBreakPoint}) {
    position: relative;
    left: auto;
    margin-left: auto;
    margin-right: inherit;
    transform: none;
  }
  div {
    cursor: pointer;
    padding-right: 15px;
  }
  span {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 11px;
    position: relative;

    &:before {
      content: "×";
      font-size: 28px;
      position: absolute;
      left: -27px;
      top: -13px;
      font-weight: 100;
    }
  }
`;

const ExitCompare = ({ handleToggleCompare }: IProps) => (
  <Wrapper>
    <div onClick={handleToggleCompare}>
      <Text>Exit</Text>
    </div>
  </Wrapper>
);

export default ExitCompare;
