import * as React from "react";
import { Text } from "../../components";
import { Row, Column } from "hedron";

class NotFound extends React.Component {
  public render() {
    return (
      <React.Fragment>
        <Row>
          <Column>
            <Text>404! No page found at this URL.</Text>
          </Column>
        </Row>
      </React.Fragment>
    );
  }
}

export default NotFound;
