import React from "react";
import { Box } from "../box";
import Text from "../text";
import { Checkbox } from "../checkbox/formik-checkbox";

interface SelectDaysProps {
  setDaysToInclude: (val: number[]) => void;
  daysToInclude: number[];
}

const daysOfWeek = [
  "S",
  "M",
  "T",
  "W",
  "T",
  "F",
  "S"
];

const days = [
  1,
  2,
  3,
  4,
  5,
  6,
  7
];

export const SelectDays: React.FC<SelectDaysProps> = props => {
  const handleChangeDaysToInclude = React.useCallback((day:number) => {
    const dayExists = props.daysToInclude.findIndex(d => d === day);
    const existingDays = props.daysToInclude;

    if (dayExists > -1) {
      existingDays.splice(dayExists, 1);
    } else {
      existingDays.push(day);
    }

    props.setDaysToInclude([...existingDays]);
  }, [props]);

  return (
    <Box pad={{ vertical: "md" }}>
      <Box pad={{ top: "sm" }}>
        <Text weight="bold">
          Select which days to include
        </Text>
      </Box>
      <Box
        pad={{ top: "sm" }} 
        flexDir="row" 
        width="240px"
        align="center"
        justify="space-between"
      >
        {days.map(day => (
          <Box key={day} align="center" justify="center">
            <Box align="center">
              <Checkbox 
                name="Day of week"
                checked={props.daysToInclude.findIndex(d => d === day) > -1}
                onChange={() => handleChangeDaysToInclude(day)}
              />
            </Box>
            <Box pad={{ top: "sm" }}>
              <Text type="small" weight="bold">
                {daysOfWeek[day - 1]}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
