export const PURGE_USERS = "@user/PURGE_USERS";
export type PURGE_USERS = typeof PURGE_USERS;

export const SET_USER = "@user/SET_USER";
export type SET_USER = typeof SET_USER;

export const GET_USER = "@user/GET_USER";
export type GET_USER = typeof GET_USER;
export const GET_USER_SUCCESS = "@user/GET_USER_SUCCESS";
export type GET_USER_SUCCESS = typeof GET_USER_SUCCESS;
export const GET_USER_FAILURE = "@user/GET_USER_FAILURE";
export type GET_USER_FAILURE = typeof GET_USER_FAILURE;

export const GET_USERS = "@user/GET_USERS";
export type GET_USERS = typeof GET_USERS;
export const GET_USERS_SUCCESS = "@user/GET_USERS_SUCCESS";
export type GET_USERS_SUCCESS = typeof GET_USERS_SUCCESS;
export const GET_USERS_FAILURE = "@user/GET_USERS_FAILURE";
export type GET_USERS_FAILURE = typeof GET_USERS_FAILURE;

export const CREATE_USER = "@user/CREATE_USER";
export type CREATE_USER = typeof CREATE_USER;
export const CREATE_USER_SUCCESS = "@user/CREATE_USER_SUCCESS";
export type CREATE_USER_SUCCESS = typeof CREATE_USER_SUCCESS;
export const CREATE_USER_FAILURE = "@user/CREATE_USER_FAILURE";
export type CREATE_USER_FAILURE = typeof CREATE_USER_FAILURE;

export const UPDATE_USER = "@user/UPDATE_USER";
export type UPDATE_USER = typeof UPDATE_USER;
export const UPDATE_USER_SUCCESS = "@user/UPDATE_USER_SUCCESS";
export type UPDATE_USER_SUCCESS = typeof UPDATE_USER_SUCCESS;
export const UPDATE_USER_FAILURE = "@user/UPDATE_USER_FAILURE";
export type UPDATE_USER_FAILURE = typeof UPDATE_USER_FAILURE;

export const DELETE_USER = "@user/DELETE_USER";
export type DELETE_USER = typeof DELETE_USER;
export const DELETE_USER_SUCCESS = "@user/DELETE_USER_SUCCESS";
export type DELETE_USER_SUCCESS = typeof DELETE_USER_SUCCESS;
export const DELETE_USER_FAILURE = "@user/DELETE_USER_FAILURE";
export type DELETE_USER_FAILURE = typeof DELETE_USER_FAILURE;

export const ADMIN_RESET_PASSWORD = "@user/ADMIN_RESET_PASSWORD";
export type ADMIN_RESET_PASSWORD = typeof ADMIN_RESET_PASSWORD;
export const ADMIN_RESET_PASSWORD_SUCCESS =
  "@user/ADMIN_RESET_PASSWORD_SUCCESS";
export type ADMIN_RESET_PASSWORD_SUCCESS = typeof ADMIN_RESET_PASSWORD_SUCCESS;
export const ADMIN_RESET_PASSWORD_FAILURE =
  "@user/ADMIN_RESET_PASSWORD_FAILURE";
export type ADMIN_RESET_PASSWORD_FAILURE = typeof ADMIN_RESET_PASSWORD_FAILURE;

export const ADMIN_UPDATE_USER_ACCESS = "@user/ADMIN_UPDATE_USER_ACCESS";
export type ADMIN_UPDATE_USER_ACCESS = typeof ADMIN_UPDATE_USER_ACCESS;
export const ADMIN_UPDATE_USER_ACCESS_SUCCESS =
  "@user/ADMIN_UPDATE_USER_ACCESS_SUCCESS";
export type ADMIN_UPDATE_USER_ACCESS_SUCCESS = typeof ADMIN_UPDATE_USER_ACCESS_SUCCESS;
export const ADMIN_UPDATE_USER_ACCESS_FAILURE =
  "@user/ADMIN_UPDATE_USER_ACCESS_FAILURE";
export type ADMIN_UPDATE_USER_ACCESS_FAILURE = typeof ADMIN_UPDATE_USER_ACCESS_FAILURE;

export const GET_USER_CAMERAS = "@user/GET_USER_CAMERAS";
export type GET_USER_CAMERAS = typeof GET_USER_CAMERAS;
export const GET_USER_CAMERAS_SUCCESS = "@user/GET_USER_CAMERAS_SUCCESS";
export type GET_USER_CAMERAS_SUCCESS = typeof GET_USER_CAMERAS_SUCCESS;
export const GET_USER_CAMERAS_FAILURE = "@user/GET_USER_CAMERAS_FAILURE";
export type GET_USER_CAMERAS_FAILURE = typeof GET_USER_CAMERAS_FAILURE;
