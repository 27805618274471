import * as React from "react";

import { Text } from "../../components";
import { Column, Row } from "hedron";
import { IDeletionStats } from "src/store/deletion-queue/deletion-queue.api";
import styled from "styled-components";
import { styles } from "src/lib/styles";

interface IDeletionStatsProps {
    deletionStats: IDeletionStats;
    }

const MobileColumn = styled(Column)`
  display: flex;
  align-items: center;
  justify-content: center; 
  text-align: center;

  @media screen and (min-width: ${styles.mobileBreakPoint}) {
    text-align: left;
    justify-content: flex-start;
  }
`;

const FlexEndColumn = styled(Column)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (min-width: ${styles.mobileBreakPoint}) {
    justify-content: flex-end;
  }
`;

export const DeletionStats = (props: IDeletionStatsProps) => {
  return (
    <Row>
      <MobileColumn sm={12} md={8}>
        <Text fontSize="h2">
      Cameras: <span style={{ fontWeight: "bold" }}>{props.deletionStats.num_camera_locations}</span>
        </Text>
        <Text fontSize="h2" style={{ paddingLeft: "10px" }}>
      Images: <span style={{ fontWeight: "bold" }}>{props.deletionStats.num_images}</span>
        </Text>
        <Text fontSize="h2" style={{ paddingLeft: "10px" }}>
      Thumbnails: <span style={{ fontWeight: "bold" }}>{props.deletionStats.num_thumbnails}</span>
        </Text>
      </MobileColumn>
      <FlexEndColumn sm={12} md={4}>
        <Text fontSize="h2">
      Estimated Removal: 
          <Text style={{
            fontWeight: "bold",
            paddingLeft: "10px" 
          }} fontSize="h2">
            {props.deletionStats.num_days_until_deletion} {props.deletionStats.num_days_until_deletion === 1 ? "day" : "days"}
          </Text>
        </Text>
      </FlexEndColumn>
    </Row>
  );
};