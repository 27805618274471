import {
  AiFillCaretDown,
  AiFillCaretRight,
  AiFillCaretUp
} from "react-icons/ai";
import { TiArrowUnsorted } from "react-icons/ti";
import { HiCheckCircle } from "react-icons/hi";
import { TbSeparatorHorizontal } from "react-icons/tb";
import { BiLoaderAlt } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";

export const Icon = {
  CheckCircle: HiCheckCircle,
  FillCaretDown: AiFillCaretDown,
  FillCaretRight: AiFillCaretRight,
  FillCaretsVertical: TiArrowUnsorted,
  FillCaretUp: AiFillCaretUp,
  ResizeHorizontal: TbSeparatorHorizontal,
  Loader: BiLoaderAlt,
  MoreDotsVertical: BsThreeDotsVertical
};