import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import signUrl from "src/lib/signS3Url";

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  margin: -10px;
  max-width: 300px;
  text-align: "center";

  @media (max-width: ${styles.mobileBreakPoint}) {
    display: none;
  }
`;

const LogoImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const ErrorMessage = styled.span`
  padding-left: 10px;
`;

export const CustomerTableLogo: React.FC<ILogoWrapperProps> = ({ logo }) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(logo);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (logo?.includes("amazonaws")) {
      signUrl(logo)
        .then(signedUrl => {
          setImgSrc(signedUrl);
        })
        .catch(() => {
          setHasError(true);
        });
    }
  }, [logo]);

  const handleImageError = () => {
    setHasError(true);
  };

  return (
    <LogoWrapper>
      {hasError ? (
        <ErrorMessage>⚠️ Broken Link</ErrorMessage>
      ) : (
        imgSrc && <LogoImage src={imgSrc} onError={handleImageError} />
      )}
    </LogoWrapper>
  );
};

interface ILogoWrapperProps {
  logo?: string;
}
