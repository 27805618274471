import React, { useCallback } from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import {
  Text,
  TextInput,
  CameraLocationList,
  ValidationError,
  TextButton
} from "../../components";
import { ISite } from "src/store/sites/sites.api";
import {
  Formik, FormikProps, Form
} from "formik";
import * as Yup from "yup";
import {
  updateSiteRequest,
  deleteSiteRequest,
  suspendSiteRequest,
  getSiteByIdRequest
} from "../../store/sites/sites.actions";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import _ from "lodash";
import {
  CameraTotals, getCameraLocationCountsRequest, getCameraLocationsRequest 
} from "../../store/camera-locations/camera-locations.actions";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { styles } from "src/lib/styles";
import Modal from "react-modal";
import { PaginatedParams, SortType } from "../../types/pagination/index";
import { PaginationState } from "@tanstack/react-table";
import { SecuritySection } from "src/components/security-section";
import styled from "styled-components";
import { ICustomer } from "src/store/customers/customers.api";
import { IoIosArrowBack } from "react-icons/io";
import Spacer from "src/components/Spacer";
import { Sections } from "src/components/sections";
import { useSessionStorage } from "usehooks-ts";
import { getCustomerRequest } from "src/store/customers/customers.actions";
import MobileSpacer from "src/components/MobileSpacer";
import { CameraFilter } from "src/components/camera-location-list/camera-location-list";

Modal.setAppElement("#root");

export interface ISiteRouterProps {
  siteId: string;
  customerId: string;
}

type ISiteProps = RouteComponentProps<ISiteRouterProps>;

interface IStateProps {
  site: ISite | null;
  isUpdating: boolean;
  isDeleting: boolean;
  cameraLocations: ICameraLocation[];
  cameraLocationsCount: number;
  cameraTotals: CameraTotals;
  gettingCameraLocations: boolean;
  customer: ICustomer | null;
}

interface FormValues {
  geolocation: string;
  name: string;
  security_level: number;
  whitelist: string;
}
interface ISiteDispatchProps {
  toggleSiteSuspend: (siteId: string, customerId: string) => void;
  deleteSite: (siteId: string, customerId: string) => void;
  getCameraLocationCounts: (siteId: string) => void;
  getCameraLocations: (
    siteId: string,
    status: string | undefined,
    pagination: PaginatedParams<ICameraLocation>
  ) => void;
  updateSite: (siteId: string, site: ISite) => void;
  getSiteById: (siteId: string) => void;
  getCustomer: (customerId: string) => void;
}
type Props = ISiteProps & IStateProps & ISiteDispatchProps;

const EditSite: React.FC<Props> = ({
  site, toggleSiteSuspend, deleteSite, match, getCameraLocationCounts, getCameraLocations, cameraLocations, gettingCameraLocations, isUpdating, history, updateSite, isDeleting, cameraTotals, customer, cameraLocationsCount, getSiteById, getCustomer
}) => {
  const [cameraLocationStatus, setCameraLocationStatus] = React.useState<string | undefined>(CameraFilter.Live);
  const [sortOrder, setSortOrder] = React.useState<SortType<ICameraLocation>>([["job_ref", "DESC"]]);
  const { customerId } = match.params;
  const formikRef = React.useRef<Formik<any> | null>(null);

  const [pagination, setPagination] = useSessionStorage<PaginationState>("SiteCameraLocationPaginationState", {
    pageSize: 10,
    pageIndex: 0
  });

  const paginationFormatted = {
    page: pagination.pageIndex,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    sort: sortOrder as PaginatedParams<ICameraLocation>["sort"]
  };

  const onFetchData = () => {
    getCameraLocations(
      match.params.siteId, cameraLocationStatus, paginationFormatted
    );
  };

  React.useEffect(() => {
    getCameraLocationCounts(match.params.siteId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.siteId]);

  React.useEffect(() => {
    onFetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination,
    cameraLocationStatus,
    sortOrder
  ]);

  React.useEffect(() => {
    getCustomer(match.params.customerId);
    getSiteById(match.params.siteId);
  }, [
    match,
    getCustomer,
    getSiteById
  ]);
  
  const handleSubmit = useCallback((values: FormValues) => {
    updateSite(match.params.siteId, _.extend(site, values));
  }, [
    match.params.siteId,
    site,
    updateSite
  ]);
  
  const backIcon = React.useRef(<IoIosArrowBack color="currentColor" size="20" />).current;
  const customerLink = `/admin/customer/${customerId}`;

  return (
   
    <div style={{ paddingBottom: "60px" }}>
      <Styled.Controls>
        <Styled.BackButton onClick={() => history.goBack()} disabled={!customer}>
          { backIcon}
        </Styled.BackButton>
        {customer && (<Styled.CustomerLink href={customerLink} style={{ marginLeft: "auto" }}> 
          {customer.name}
        </Styled.CustomerLink>)}
        {site && (<Styled.SiteName>{`/ ${site.name}`}</Styled.SiteName>)}

        <Spacer/>

        <Styled.CTAButton
          disabled={isUpdating || isDeleting}
          type="button"
          onClick={() => formikRef.current?.submitForm() ?? alert("Form action not found")}>
          Save
        </Styled.CTAButton>
      </Styled.Controls>
      
      {/* SITE */}

      <Sections.Container>
        {site && (
          <Formik
            initialValues={{
              geolocation: site.geolocation,
              name: site.name,
              security_level: site.security_level,
              whitelist: site.ip_whitelist?.whitelistedIPs ? site.ip_whitelist.whitelistedIPs.join(",") : ""
            }}
            ref={formikRef}
            onSubmit={(values: FormValues) => handleSubmit(values)}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              security_level: Yup.number().required(),
              whitelist: Yup.string().when("security_level", {
                is: 1,
                then: Yup.string().matches(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}(,((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4})?$/, { message: "Please use valid IP addresses." })
              }) 
            })}
          >
            {(props: FormikProps<FormValues>) => {
              const {
                values, errors, handleChange, handleBlur, setFieldValue
              } = props;

              return (
                <Form>
                  <Sections.Section header={HeaderComponent => (
                    <HeaderComponent>
                      This site is <span style={{ color: site?.status === "active" ? "#00C853" : "#FF3D00" }}>
                        {_.lowerCase(site?.status)}
                      </span>.
                    </HeaderComponent>
                  )}>
                    <TextButton align="none" text={
                      (site?.status === "active" ? "Suspend" : "Activate") + " site"
                    } clickHandler={() => toggleSiteSuspend(match.params.siteId, match.params.customerId)} type="button" style={{
                      display: "inline",
                      margin: "0 2ch 0 0"
                    }} />
                    <TextButton align="none" text="Delete" textProps={{}} clickHandler={() => window.confirm("Are you sure?\n\nThis will delete all cameras and images associated with " + site!.name + " and cannot be undone!") && customer && deleteSite(match.params.siteId, match.params.customerId)} type="button" style={{
                      display: "inline",
                      margin: "0 2ch 0 0"
                    }} />
                  </Sections.Section>
                  
                  <Sections.Section header="Details">
                    <div style={{ maxWidth: 350 }}>
                      <Text fontSize="small">Name</Text>
                      <TextInput
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                        onBlur={handleBlur}
                      />
                      <ValidationError>{errors.name}</ValidationError>
                    </div>
                    <br />
                    <div style={{ maxWidth: 350 }}>
                      <Text fontSize="small">Geolocation</Text>
                      <TextInput
                        onChange={handleChange}
                        value={values.geolocation}
                        name="geolocation"
                        onBlur={handleBlur}
                      />
                    </div>
                  </Sections.Section>

                  <Sections.Section header="Security">
                    <SecuritySection 
                      onSelectSecurityLevel={value => setFieldValue("security_level", value)} 
                      securityLevel={values.security_level}
                      handleWhitelistChange={handleChange}
                      whitelistValue={values.whitelist}
                      whitelistErrors={errors.whitelist}
                      onBlurWhitelistTextInput={handleBlur}
                    />
                  </Sections.Section>
                </Form>
              );
            }}
          </Formik>
        )}
        
      </Sections.Container>

      <br /><br />

      {/* CAMERAS */}
    
      <CameraLocationList
        rhsCallToAction={(
          <Styled.CTAButton onClick={() => {
            const { customerId, siteId } = match.params;

            return history.push(`/admin/customer/${customerId}/site/${siteId}/new-camera`);
          }}>
            Create new
          </Styled.CTAButton>
        )}
        onFetchData={() => onFetchData()}
        cameraLocations={cameraLocations}
        history={history}
        setCameraStatus={status => setCameraLocationStatus(status)}
        cameraStatus={cameraLocationStatus}
        customerId={customerId}
        count={cameraLocationsCount}
        loading={gettingCameraLocations}
        pagination={pagination}
        setPagination={setPagination}
        totals={cameraTotals}
        setSortOrder={setSortOrder}
      />
      <MobileSpacer/>
    </div>
  );
};

const Styled = {
  BackButton: styled.button`
    background: none;
    border: none;
    color: ${styles.textColor};
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    padding: 0;

    svg {
      margin-right: 1ch;
    }
  `,
  CTAButton: styled.button`
    background: ${styles.primaryAccentColor};
    border: none;
    border-radius: 6px;
    color: ${styles.primaryDarkColor};
    padding: 8px 12px;
    cursor: pointer;
    &:disabled {
      background: ${styles.formBackground};
      cursor: not-allowed;
    }
  `,
  Controls: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block: 16px;
    color: ${styles.textColor};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 22px;
    font-weight: 500;
  `,
  CustomerLink: styled.a`
  text-decoration: none;
  margin-right: 10px;
  color: ${styles.textColor};
    &:hover {
      color: ${styles.primaryAccentColor};
    }
  `,
  SiteName: styled.div`
    
  `
};

const mapStateToProps = (state: IStore): IStateProps => {
  return {
    cameraLocations: state.cameraLocations.cameraLocations,
    gettingCameraLocations: state.cameraLocations.gettingCameraLocations,
    cameraLocationsCount: state.cameraLocations.cameraLocationsCount,
    isDeleting: state.sites.deletingSite,
    isUpdating: state.sites.updatingSite,
    site: state.sites.currentSite,
    cameraTotals: state.cameraLocations.cameraLocationsTotals,
    customer: state.customers.currentCustomer
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): ISiteDispatchProps => {
  return {
    deleteSite: (siteId: string, customerId: string) =>
      dispatch(deleteSiteRequest(siteId, customerId)),
    getCameraLocationCounts: (siteId: string) => dispatch(getCameraLocationCountsRequest(siteId)),
    getCameraLocations: (
      siteId: string,
      status: string | undefined,
      pagination: PaginatedParams<ICameraLocation>
    ) =>
      dispatch(getCameraLocationsRequest(
        siteId, "admin", status, undefined, false, pagination
      )),
    toggleSiteSuspend: (siteId: string, customerId: string) =>
      dispatch(suspendSiteRequest(siteId, customerId)),
    updateSite: (siteId: string, site: ISite) =>
      dispatch(updateSiteRequest(siteId, site)),
    getSiteById: (siteId: string) => dispatch(getSiteByIdRequest(siteId)),
    getCustomer: (customerId: string) => dispatch(getCustomerRequest(customerId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSite);
