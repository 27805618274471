import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import { Icon } from "..";
import { styles } from "../../lib/styles";

interface IProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const Wrapper = styled.div`
  letter-spacing: 1px;
  line-height: 11px;
  padding-left: 10px;
  transform: translateY(-2px);
`;

const CustomText = styled.span`
  color: ${styles.secondaryTextColor};
  font-size: 9px;
  text-transform: uppercase;
`;

const RangeIndicator = (props: IProps) => (
  <Wrapper>
    <CustomText>{props.startDate.format("DD MMM YYYY")}</CustomText>
    <br />
    <Icon
      style={{
        opacity: 0.4,
        paddingRight: "5px",
        transform: "translateY(2px)"
      }}
      icon="chevron-right"
      size={9}
    />
    <CustomText>{props.endDate.format("DD MMM YYYY")}</CustomText>
  </Wrapper>
);

export default RangeIndicator;
