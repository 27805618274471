import React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";

const WeatherInfo = styled.div`
  max-height: 40px;
  max-width: 100%;

  @media (max-width: ${styles.mobileBreakPoint}) {
    display: none;
  }
`;

const ImageWeatherInfo: React.SFC<IImageWeatherInfo> = ({ weather }) => (
  <WeatherInfo>&nbsp;</WeatherInfo>
);

interface IImageWeatherInfo {
  weather: string;
}

export default ImageWeatherInfo;
