import * as React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import { Icon, Text } from "..";

interface INavigationProps {
  kind?: string;
  menuItems?: IMenuProps[];
}

interface INavigationState {
  open: boolean;
}

interface IMenuProps {
  child?: boolean;
  title?: string;
  function: () => void;
  label: string;
}

const NavigationWrapper: any = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MenuDrawerWrapper: any = styled.div`
  padding-right: 15px;
  position: relative;
`;

const MenuDrawerButton: any = styled.div``;

const MenuWrapper = styled.div<INavigationState>`
  display: ${props => (props.open ? "block" : "none")};
`;

const Menu: any = styled.div`
  right: 100%;
  margin: 0 10px;
  padding: 5px 10px;
  z-index: 99999;
  position: absolute;
  width: 240px;
  top: -30%;
  background: ${styles.secondaryDarkColor};
  border-radius: 0;
  padding-top: 25px;
  padding-bottom: 10px;
  border-right: 3px solid ${styles.primaryDarkColor};
  border-bottom: 3px solid ${styles.primaryDarkColor};
`;

const MenuItem: any = styled.div`
  color: ${styles.secondaryTextColor};
  cursor: pointer;
  border-top: 1px solid #303c4e;
  padding: 10px;
  margin: 5px 15px;
  margin-top: 0;
  padding-top: 15px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${styles.primaryAccentColor};
  }
`;

class Navigation extends React.Component<INavigationProps, INavigationState> {
  protected wrapper: any;
  protected boundMousedown: () => void;
  constructor(props: INavigationProps) {
    super(props);
    this.boundMousedown = this.handleClickOutside.bind(this);
    this.state = { open: false };
  }

  public UNSAFE_componentWillMount() {
    document.addEventListener(
      "mousedown", this.boundMousedown, false
    );
  }

  public componentWillUnmount() {
    document.removeEventListener(
      "mousedown", this.boundMousedown, false
    );
  }

  public render() {
    const { menuItems } = this.props;
    const { open } = this.state;

    const ref = (element: HTMLDivElement) => {
      this.wrapper = element;
    };

    return (
      <div ref={ref}>
        <NavigationWrapper>
          {menuItems && (
            <MenuDrawerWrapper>
              <MenuDrawerButton
                open={open}
                onClick={() => this.setState({ open: !open })}
              >
                <Icon icon="menu" />
              </MenuDrawerButton>
              <MenuWrapper open={open}>
                <Menu>
                  {menuItems.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.title && (
                        <MenuItem style={{ cursor: "default" }}>
                          <Text fontSize="p">{item.title}</Text>
                        </MenuItem>
                      )}
                      <MenuItem
                        style={{ paddingLeft: item.child ? "30px" : "10px" }}
                        key={index}
                        onClick={item.function}
                      >
                        <Text fontSize={item.child ? "h6" : "p"}>
                          {item.label}
                        </Text>
                      </MenuItem>
                    </React.Fragment>
                  ))}
                  <React.Fragment>
                    <MenuItem style={{ cursor: "default" }}>
                      <Text fontSize="p">
                        <span>
                          Powered by{" "}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://timelapse.co.uk/"
                            style={{
                              color: "white",
                              textDecoration: "none"
                            }}
                          >
                            Interval Films
                          </a>
                        </span>
                      </Text>
                    </MenuItem>
                  </React.Fragment>
                </Menu>
              </MenuWrapper>
            </MenuDrawerWrapper>
          )}
        </NavigationWrapper>
      </div>
    );
  }

  private handleClickOutside(e: Event) {
    if (this.wrapper && this.wrapper.contains(e.target)) {
      return;
    } else {
      if (this.wrapper) {
        this.setState({ open: false });
      }
    }
  }
}

export default Navigation;
