import React from "react";
import { Row } from "hedron";
import { Dropdown, IPWhitelistTextarea } from "src/components";
import styled from "styled-components";
import { HiOutlineLockClosed, HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { Text } from "src/components";

const securityLevels = [
  {
    data: 0,
    text: "Standard security"
  },
  {
    data: 1,
    text: "Full security"
  }
];

interface SecuritySectionProps {
  onSelectSecurityLevel: (value: number) => void;
  securityLevel?: number;
  handleWhitelistChange: JSX.IntrinsicElements["textarea"]["onChange"];
  whitelistValue: string;
  onBlurWhitelistTextInput?: JSX.IntrinsicElements["textarea"]["onBlur"];
  whitelistErrors?: string;
}

export const SecuritySection: React.FC<SecuritySectionProps> = ({
  onSelectSecurityLevel, securityLevel, handleWhitelistChange, whitelistValue, onBlurWhitelistTextInput, whitelistErrors
}) => {
  return (
    <>
      <ItemWrapper>
        <Dropdown
          items={securityLevels}
          style={{ marginTop: "0.75rem" }}
          direction="down"
          selected={securityLevels.findIndex(status => securityLevel === status.data)}
          isOverlayActive
          onSelect={sel => onSelectSecurityLevel(sel as number)}
        />
      </ItemWrapper>

      {securityLevel ? 
        (
          <>
            <ItemWrapper>
              <Row style={{ marginBlock: 10 }}>
                <HiOutlineLockClosed 
                  size={20} 
                  color="#e4dd8fd9"
                  style={{ marginRight: "0.375rem" }}
                />
                <p>2FA required</p>
              </Row>
            </ItemWrapper>

            <ItemWrapper>
              <Row alignItems="center">
                <HiOutlineQuestionMarkCircle 
                  size={20} 
                  color="gray"
                  style={{ marginRight: "0.375rem" }}
                />
                <p>IP Whitelist</p>
              </Row>
            </ItemWrapper>
          </>
        ) : <Text fontSize="small">Clients can login with or without 2FA, on any IP address. Cameras can be public</Text>}

      {/** IP WHITELIST */}
      {!!securityLevel && 
        <IPWhitelistTextarea
          textAreaProps={{
            onChange: handleWhitelistChange,
            value: whitelistValue,
            name: "whitelist",
            onBlur: onBlurWhitelistTextInput
          }}
          errors={whitelistErrors}
        />
      }
    </>
  );
};

const ItemWrapper = styled.div`
  margin: 1rem 0;
  max-width: 350px;
`;