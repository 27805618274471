import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";
import Text from "../text";
import { IUser } from "../../types/store/users";
import { Column, Row } from "hedron";
import { Button } from "../";
import { History } from "history";

interface IUserListItemProps {
  kind?: string;
  user: IUser;
  history: History;
}

const menuColor = theme.variants(
  "mode", "kind", { default: { normal: styles.secondaryDarkColor } }
);

const UserListItemWrapper: any = styled.div`
  background: ${menuColor};
  padding: ${styles.paddingUnit * 2}px;
  border-radius: 20px;
`;

const UserListItem: React.SFC<IUserListItemProps> = ({
  kind,
  user,
  history
}) => (
  <UserListItemWrapper kind={kind}>
    <Row justifyContent="space-between">
      <Column xs={9}>
        <Text fontSize="h3">{user.username}</Text>
        <Text fontSize="h4" style={{ color: "#afbbcc50" }}><> ({user.userCameras.length} cameras)</></Text>
      </Column>
      <Column xs={2}>
        <Button
          align="right"
          text="Manage"
          clickHandler={() => history.push(`/admin/user/${user.id}`)}
        />
      </Column>
    </Row>
  </UserListItemWrapper>
);

UserListItemWrapper.defaultProps = { kind: "default" };

export default UserListItem;
