import React from "react";
import { Table } from "@tanstack/react-table";
import styled from "styled-components";

interface TablePaginationProps<T> {
  table: Table<T>;
  loading?: boolean;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
}

const PaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding: 8px 0;
  
`;

const PaginationButton = styled.button`
  display: grid;
  place-items: center;
  height: 32px;
  width: 32px;
  padding: 4px;
  cursor: pointer;
  border: 1px solid #091118;
  border-radius: 4px;
  background-color: #091118;
  color: #AFBBCC;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const PaginationLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 0;

`;

const PaginationInput = styled.input`
  width: 48px;
  height: 32px;
  border: 1px solid #091118;
  border-radius: 4px;
  padding: 0px;
  text-align: center;
  items-align: center;
  justify-content: center;
  display: flex;
  background-color: #091118;
  color: #AFBBCC;
`;

export function TablePagination<T>({
  table,
  loading,
  pagination
}: TablePaginationProps<T>) {
  const pageIndex = table.getState().pagination.pageIndex;
  const pageCount = table.getPageCount();
  const canPreviousPage = table.getCanPreviousPage();
  const canNextPage = table.getCanNextPage();

  const handleFirstPageClick = () => {
    table.setPageIndex(0);
  };

  const handlePreviousPageClick = () => {
    table.previousPage();
  };

  const handleNextPageClick = () => {
    table.nextPage();
  };

  const handleLastPageClick = () => {
    table.setPageIndex(pageCount - 1);
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0;

    table.setPageIndex(page);
  };

  const handleChangePageLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    table.setPageSize(Number(e.target.value));
  };

  return (
    <PaginationContainer>
      <PaginationLabel>
        <div>Page</div>
        <strong>{pageIndex + 1} of {pageCount}</strong>
      </PaginationLabel>
      <PaginationButton
        onClick={handleFirstPageClick}
        disabled={!canPreviousPage || loading}
      >
        {"<<"}
      </PaginationButton>
      <PaginationButton
        onClick={handlePreviousPageClick}
        disabled={!canPreviousPage || loading}
      >
        {"<"}
      </PaginationButton>
      <PaginationButton
        onClick={handleNextPageClick}
        disabled={!canNextPage || loading}
      >
        {">"}
      </PaginationButton>
      <PaginationButton
        onClick={handleLastPageClick}
        disabled={!canNextPage || loading}
      >
        {">>"}
      </PaginationButton>
    
      <PaginationLabel>
        <div style={{ paddingLeft: 4 }}> {"Go to page:"}</div>
       
        <PaginationInput
          type="number"
          min={1}
          max={pageCount}
          defaultValue={pageIndex + 1}
          onChange={handlePageInputChange}
        />
      </PaginationLabel>
      <PaginationLabel>
        <div>Items per page:</div>
      </PaginationLabel>
      <PaginationLabel>
       
        <PaginationInput
          type="number"
          min={1}
          max={100}
          defaultValue={pagination.pageSize}
          onChange={handleChangePageLimit}
        />
      </PaginationLabel>
    </PaginationContainer>
  );
}
