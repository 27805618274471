import * as React from "react";
import styled from "styled-components";
import ImageIntervalLine from "../image-interval-line/image-interval-line";
import { ColumnProps } from "hedron";
import Draggable, { DraggableData } from "react-draggable";
import ReactResizeDetector from "react-resize-detector";
import { IImage } from "src/types/store/images";

interface IImageIntervalPlayheadProps {
  imageCount: number;
  active: number;
  dragged: number | null;
  setDragged: (index: number | null) => void;
  setActive: (index: number) => void;
  image?: IImage;
  handleMouseMove: () => void;
}
interface IImageIntervalPlayheadState {
  grid: [number, number];
  isDragging: boolean;
}

interface IImageIntervalWrapper extends ColumnProps {
  imageCount: number;
  isDragging: boolean;
}

const ImageIntervalWrapper = styled.div<IImageIntervalWrapper>`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  width: calc(100% / ${props => props.imageCount});
  z-index: 10;
  top: 0;
  padding: 5px 15px;
  margin: 0px -15px;
  > div > div:first-child {
    transition: transform ease-in-out 0.2s;
  }
  &:hover > div > div:first-child {
    transform: scale(1.2);
  }
`;

class ImageIntervalPlayhead extends React.Component<
  IImageIntervalPlayheadProps,
  IImageIntervalPlayheadState
> {
  protected playhead: any;
  constructor(props: IImageIntervalPlayheadProps) {
    super(props);

    this.state = {
      grid: [0, 0],
      isDragging: false
    };
  }

  public render() {
    const {
      dragged, active, imageCount, image, handleMouseMove 
    } = this.props;

    const { isDragging } = this.state;

    return (
      <ReactResizeDetector handleWidth={true} resizableElementId="playhead">
        {(width: number) => {
          return (
            <Draggable
              axis="x"
              position={{
                x: (dragged !== null ? dragged : active) * width,
                y: 0
              }}
              bounds={{
                bottom: 0,
                left: 0,
                right: (imageCount - 1) * width,
                top: 0
              }}
              grid={[width, 0]}
              onDrag={(e: MouseEvent, data: DraggableData) => {
                this.handleDrag(
                  e, data, width
                );
                handleMouseMove();
              }}
              onStop={(e: MouseEvent, data: DraggableData) =>
                this.handleDragEnd(
                  e, data, width
                )
              }
            >
              <ImageIntervalWrapper
                id="playhead"
                fluid={true}
                imageCount={imageCount}
                isDragging={isDragging}
              >
                <ImageIntervalLine
                  kind="active"
                  dragged={dragged !== null}
                  isDragging={true}
                  image={image}
                  isPlaying={false}
                />
              </ImageIntervalWrapper>
            </Draggable>
          );
        }}
      </ReactResizeDetector>
    );
  }

  private handleDragEnd(
    e: MouseEvent, position: DraggableData, width: number
  ) {
    this.setState({ isDragging: false });
    const offset = position.lastX / width;

    this.props.setActive(offset);
    this.props.setDragged(null);
  }

  private handleDrag(
    e: MouseEvent, position: DraggableData, width: number
  ) {
    this.setState({ isDragging: true });
    const offset = position.x / width;

    this.props.setDragged(offset);
  }
}

export default ImageIntervalPlayhead;
