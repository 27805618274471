import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import {
  Text,
  TextInput,
  Button,
  ModalStyles,
  ValidationError
} from "../../components";
import { Column, Row } from "hedron";
import {
  Formik, FormikProps, Form
} from "formik";
import * as Yup from "yup";
import { videoGetById } from "../../store/videos/videos.getters";
import {
  updateVideoRequest,
  deleteVideoRequest
} from "../../store/videos/videos.actions";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import _ from "lodash";
import Modal from "react-modal";
import { getVideosRequest } from "src/store/videos/videos.actions";
import { IVideo, IVideoParams } from "../../types/store/videos";
import { cameraLocationGetByUUID } from "src/store/camera-locations/camera-locations.getters";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import MobileSpacer from "src/components/MobileSpacer";

Modal.setAppElement("#root");

interface IVideoRouterProps {
  cameraLocationUUID: string;
  siteId: string;
  customerId: string;
  videoId: string;
}

type IVideoProps = RouteComponentProps<IVideoRouterProps>;

interface IStateProps {
  isDeleting: boolean;
  isUpdating: boolean;
  videos: IVideo[];
  video: IVideo | null;
  cameraLocation: ICameraLocation | null;
}

interface IState {
  confirmModalOpen: boolean;
}

interface IVideoDispatchProps {
  getVideos: (cameraLocationId: string, params: IVideoParams) => void;
  deleteVideo: (videoId: string) => void;
  updateVideo: (videoId: string, video: IVideo) => void;
}
interface FormValues {
  download_url: string;
  embed_url: string;
  name: string;
}
type Props = IVideoProps & IStateProps & IVideoDispatchProps;
class EditVideo extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = { confirmModalOpen: false };
  }

  public componentDidMount() {
    const { videos, cameraLocation } = this.props;

    if (videos.length === 0 && cameraLocation) {
      this.props.getVideos(String(cameraLocation.id), {});
    }
  }

  public render() {
    const { match, video } = this.props;

    return (
      <React.Fragment>
        <Row alignContent="space-between">
          <Column>
            <Text fontSize="h1">{video ? video.name : ""}</Text>
          </Column>
        </Row>
        <Row />
        <Row>
          <Column fluid={true} xs={12} lg={6}>
            {video && (
              <Formik
                initialValues={{
                  download_url: video.download_url,
                  embed_url: video.embed_url,
                  name: video.name
                }}
                onSubmit={(values: FormValues) => this.handleSubmit(values)}
                validationSchema={Yup.object().shape({
                  download_url: Yup.string().url("Must be a valid URL"),
                  embed_url: Yup.string()
                    .url("Must be a valid URL")
                    .required("Required"),
                  name: Yup.string().required("Required")
                })}
              >
                {(props: FormikProps<FormValues>) => {
                  const {
                    values, handleChange, handleBlur, errors
                  } = props;

                  return (
                    <Form>
                      <Row>
                        <Column style={{ paddingTop: 0 }}>
                          <Text fontSize="small">Name</Text>
                          <TextInput
                            onChange={handleChange}
                            value={values.name}
                            name="name"
                            placeholder="Name"
                            onBlur={handleBlur}
                          />
                          <ValidationError>{errors.name}</ValidationError>
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <Text fontSize="small">Embed URL</Text>
                          <TextInput
                            onChange={handleChange}
                            value={values.embed_url}
                            name="embed_url"
                            onBlur={handleBlur}
                          />
                          <ValidationError>{errors.embed_url}</ValidationError>
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <Text fontSize="small">Download URL</Text>
                          <TextInput
                            onChange={handleChange}
                            value={values.download_url}
                            name="download_url"
                            onBlur={handleBlur}
                          />
                          <ValidationError>
                            {errors.download_url}
                          </ValidationError>
                        </Column>
                      </Row>

                      <Row>
                        <Column>
                          <Button
                            disabled={this.props.isUpdating}
                            text="Save"
                            type="submit"
                          />
                        </Column>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Column>
          <Column fluid={true} xs={12} lg={6}>
            <Row>
              <Column>
                <Button
                  align="none"
                  text="View"
                  clickHandler={() =>
                    this.props.history.push(`/customer/${match.params.customerId}/site/${
                      match.params.siteId
                    }/camera/${match.params.cameraLocationUUID}/videos`)
                  }
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Button
                  kind="delete"
                  text={"Delete Video"}
                  clickHandler={() =>
                    this.setState({ confirmModalOpen: true })
                  }
                />
              </Column>
            </Row>
          </Column>
        </Row>
        <Row />

        {video && (
          <Modal
            isOpen={this.state.confirmModalOpen}
            onRequestClose={() => this.closeModal()}
            style={ModalStyles("normal", "default")}
          >
            {this.renderModalContent()}
          </Modal>
        )}
      </React.Fragment>
    );
  }

  private renderModalContent() {
    const {
      video, deleteVideo, match, isDeleting
    } = this.props;

    return (
      <React.Fragment>
        {video && (
          <React.Fragment>
            <Row justifyContent="center">
              <Column style={{ textAlign: "center" }}>
                <Text fontSize="h1">{"Are you sure?"}</Text>
              </Column>
            </Row>
            <Row>
              <Column style={{ textAlign: "center" }}>
                <Text>{`This will delete ${
                  video.name
                } and cannot be undone`}</Text>
              </Column>
            </Row>
            <Row justifyContent="center">
              <Column md={3} style={{ textAlign: "center" }}>
                <Button
                  align="none"
                  disabled={isDeleting}
                  text="Yes"
                  clickHandler={() => deleteVideo(match.params.videoId)}
                />
              </Column>
              <Column md={3} style={{ textAlign: "center" }}>
                <Button
                  align="none"
                  text="Cancel"
                  kind="delete"
                  clickHandler={() => this.closeModal()}
                />
              </Column>
            </Row>
          </React.Fragment>
        )}
        <MobileSpacer/>
      </React.Fragment>
    );
  }

  private closeModal() {
    this.setState({ confirmModalOpen: false });
  }

  private handleSubmit(values: FormValues) {
    const {
      match, updateVideo, video
    } = this.props;

    updateVideo(match.params.videoId, _.extend(video, values));
  }
}

const mapStateToProps = (state: IStore, props: Props): IStateProps => {
  const { match } = props;

  return {
    isDeleting: state.videos.deletingVideo,
    isUpdating: state.videos.updatingVideo,
    video: videoGetById(state, match.params.videoId),
    videos: state.videos.videos,
    cameraLocation: cameraLocationGetByUUID(state, match.params.cameraLocationUUID)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IVideoDispatchProps => {
  return {
    deleteVideo: (videoId: string) => dispatch(deleteVideoRequest(videoId)),
    getVideos: (cameraLocationId: string, params: IVideoParams) =>
      dispatch(getVideosRequest(cameraLocationId, params)),
    updateVideo: (videoId: string, video: IVideo) =>
      dispatch(updateVideoRequest(videoId, video))
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(EditVideo);
