import * as Redux from "redux";
import * as AT from "./users.constants";
import { IUserStore } from "../../types/store/users";
import { UserActions } from "./users.actions";

const initialState: IUserStore = {
  adminSettingPassord: false,
  creatingUser: false,
  currentUser: null,
  deletingUser: false,
  gettingUser: false,
  gettingUsers: false,
  isLoadingUserCameras: false,
  isUpdatingUserCameras: false,
  updatingUser: false,
  user: null,
  userCameras: [],
  users: []
};

export const users: Redux.Reducer<IUserStore> = (state: IUserStore = initialState,
  action: UserActions): IUserStore => {
  switch (action.type) {
    case AT.SET_USER:
      return { ...state };
    // GET USERS
    case AT.GET_USER:
      return {
        ...state,
        gettingUser: true,
        currentUser: null 
      };
    case AT.GET_USER_SUCCESS:
      return {
        ...state,
        gettingUser: false,
        currentUser: action.user 
      };
    case AT.GET_USER_FAILURE:
      return {
        ...state,
        gettingUser: false 
      };

    // GET USERS
    case AT.GET_USERS:
      return {
        ...state,
        gettingUsers: true,
        users: [] 
      };
    case AT.GET_USERS_SUCCESS:
      return {
        ...state,
        gettingUsers: false,
        users: action.users 
      };
    case AT.GET_USERS_FAILURE:
      return {
        ...state,
        gettingUsers: false 
      };

    // CREATE USER
    case AT.CREATE_USER:
      return {
        ...state,
        creatingUser: true 
      };
    case AT.CREATE_USER_SUCCESS:
      return {
        ...state,
        creatingUser: false 
      };
    case AT.CREATE_USER_FAILURE:
      return {
        ...state,
        creatingUser: false 
      };

    // UPDATE USER
    case AT.UPDATE_USER:
      return {
        ...state,
        updatingUser: true 
      };
    case AT.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updatingUser: false 
      };
    case AT.UPDATE_USER_FAILURE:
      return {
        ...state,
        updatingUser: false 
      };

    // DELETE USER
    case AT.DELETE_USER:
      return {
        ...state,
        deletingUser: true 
      };
    case AT.DELETE_USER_SUCCESS:
      return {
        ...state,
        deletingUser: false 
      };
    case AT.DELETE_USER_FAILURE:
      return {
        ...state,
        deletingUser: false 
      };
    // ADMIN RESET USER PASSWORD
    case AT.ADMIN_RESET_PASSWORD:
      return {
        ...state,
        adminSettingPassord: true 
      };
    case AT.ADMIN_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        adminSettingPassord: false 
      };
    case AT.ADMIN_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        adminSettingPassord: false 
      };

    // ADMIN UPDATE USER ACCESS
    case AT.ADMIN_UPDATE_USER_ACCESS:
      return {
        ...state,
        isUpdatingUserCameras: true 
      };
    case AT.ADMIN_UPDATE_USER_ACCESS_SUCCESS:
      return {
        ...state,
        isUpdatingUserCameras: false 
      };
    case AT.ADMIN_UPDATE_USER_ACCESS_FAILURE:
      return {
        ...state,
        isUpdatingUserCameras: false 
      };

    // USER CAMERAS
    case AT.GET_USER_CAMERAS:
      return {
        ...state,
        isLoadingUserCameras: true 
      };
    case AT.GET_USER_CAMERAS_SUCCESS:
      return {
        ...state,
        isLoadingUserCameras: false,
        userCameras: action.userCameras
      };
    case AT.GET_USER_CAMERAS_FAILURE:
      return {
        ...state,
        isLoadingUserCameras: false 
      };

    case AT.PURGE_USERS:
      return initialState;
    default:
      return state;
  }
};
