import { styles } from "../../lib/styles";

export default (mode = "normal", kind: "default") => {
  const modalStyles = {
    normal: {
      default: {
        content: {
          background: styles.secondaryDarkColor,
          bottom: "auto",
          flex: 1,
          left: 100,
          right: 100,
          top: 100
        },
        overlay: { background: styles.primaryDarkColor }
      }
    },
    video: {
      default: {
        content: { background: styles.secondaryDarkColor },
        overlay: { background: styles.primaryDarkColor }
      }
    }
  };

  return modalStyles[mode][kind];
};
