import React from "react";
import { Row, Column } from "hedron";
import { Text, TextButton } from "../../../components";
import { styles } from "src/lib/styles";

interface IRequestNewPasswordProps {
  handleBack: () => void;
}

export const RequestNewPassword: React.FC<IRequestNewPasswordProps> = ({ handleBack }) => {
  return (
    <React.Fragment>
      <Row>
        <Column fluid={true}>
          <Text
            style={{
              color: styles.primaryDarkColor,
              display: "block",
              textAlign: "center"
            }}
            fontSize="h4"
          >
                To request a new password, please email info@timelapse.co.uk or
                call 0117 370 8519.
          </Text>
        </Column>
      </Row>
      <Row>
        <Column fluid={true}>
          <TextButton text="Back to login" clickHandler={handleBack}/>
        </Column>
      </Row>
    </React.Fragment>
  );
};