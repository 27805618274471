import * as React from "react";
import styled from "styled-components";
import { styles, theme } from "../../lib/styles";

interface ITextInputProps {
  kind?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement> & {target: {value: string | undefined}}) => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  value?: string | number;
  name?: string;
  type?: string;
  placeholder?: string;
  onBlur?: (e: React.FormEvent<HTMLInputElement> & {target: {value: string | undefined}}) => void;
  onFocus?: (e: React.FormEvent<HTMLInputElement> & {target: {value: string | undefined}}) => void;
}

const textColor = theme.variants(
  "mode", "kind", {
    default: { normal: styles.secondaryTextColor },
    secondary: { normal: styles.primaryDarkColor }
  }
);

const backgroundColour = theme.variants(
  "mode", "kind", {
    default: { normal: styles.formBackground },
    secondary: { normal: "transparent" }
  }
);

const TextInputWrapper = styled.input<ITextInputProps>`
  background: ${backgroundColour};
  border: 0.5px solid #46556A;
  outline: none;
  color: ${textColor};
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
  font-size: 14px;
  font-family: inherit;
  box-shadow: 0 0 0 1px ${styles.fadedTextColor};

  &:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: ${textColor};
    transition: background-color 5000s ease-in-out 0s;
    color: ${textColor};
    box-shadow: 0 0 0 1px ${styles.primaryAccentColor};
  }
`;

const TextInput: React.FC<ITextInputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({ kind, ...rest }) => (
  <TextInputWrapper {...rest} kind={kind} />
);

TextInput.defaultProps = { kind: "default" };

export default TextInput;
