import * as React from "react";
import { IImage } from "../../types/store/images";
import _ from "lodash";
import getImageByType from "../../lib/getImageByType";
import styled from "styled-components";
import { ImageType } from "../../types/store/images";

interface IState {
  bufferIndex: number;
  currentlyLoading: number;
  buffering: boolean;
}
interface IProps {
  images: IImage[];
  active: number | null;
  setActive: (index: number) => void;
  playerDidFinish: () => void;
  isPlaying: boolean;
  setImageAttribute: (imageIndex?: number) => void;
}

const initialState: IState = {
  bufferIndex: 0,
  buffering: true,
  currentlyLoading: 0
};

const imageTimer = 100;
const loadAtATime = 3;
const loadAhead = 40;

const ImagePlayerWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 200;
  transition: ease-in-out 0.1s;
  display: flex;
`;

class ImagePlayer extends React.Component<IProps, IState> {
  public readonly state: IState = initialState;
  protected timer: number | null = null;

  // make sure we stop the timer
  public componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  public async componentDidMount() {
    const { images, active } = this.props;

    this.props.setImageAttribute();

    if (active !== null) {
      const imageIndex = this.getNextImageIndex(active);

      if (images[imageIndex] && images[imageIndex].loaded) {
        this.props.setActive(imageIndex);
      }
    }

    this.setState({ bufferIndex: this.props.active || 0 },
      () => this.preloadImageBatch());
  }

  public render() {
    return <ImagePlayerWrapper style={{
      width: "100%",
      height: "100%" 
    }} />;
  }

  // start the interval for going to next image
  private startTimer() {
    if (this.state.buffering === true) {
      this.setState({ buffering: false }, () => {
        if (this.timer === null) {
          setTimeout(() => {
            this.timer = setInterval(() => {
              this.handleNext();
            }, imageTimer);
          }, 200);
        }
      });
    }
  }

  // stop the interval for going to next image
  private stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    this.setState({ buffering: true }, () => {
      this.checkForPreload();
    });
  }

  private getNextImageIndex(imageIndex: number): number {
    const { images } = this.props;

    if (images[imageIndex].status === "active") {
      return imageIndex;
    } else if (!images[imageIndex + 1]) {
      this.stopTimer();
      this.props.playerDidFinish();

      return imageIndex;
    }
    {
      return this.getNextImageIndex(imageIndex + 1);
    }
  }

  // decide what to do at interval
  private handleNext() {
    const { images, active } = this.props;

    // if it isnt the last image
    if (active !== null && active < images.length - 1) {
      // if it isnt buffering then go to next image
      if (!this.state.buffering) {
        if (this.props.isPlaying) {
          const imageIndex = this.getNextImageIndex(active + 1);

          if (images[imageIndex] && images[imageIndex].loaded) {
            this.props.setActive(imageIndex);
          }

          this.checkForPreload();
        }
      }
    } else {
      // finished play functionality so clear up
      this.stopTimer();
      this.props.playerDidFinish();
    }
  }

  private checkForPreload() {
    const { bufferIndex, buffering } = this.state;
    const { images, active } = this.props;

    if (active !== null) {
      if (buffering) {
        this.preloadImageBatch();
        // if we are before
      } else if (
        bufferIndex <= active + loadAhead &&
        bufferIndex <= images.length - 1
      ) {
        this.preloadImageBatch();
      }
    }
  }

  private preloadImageBatch() {
    const { bufferIndex, buffering } = this.state;
    const { images, active } = this.props;

    // check batch exists
    if (active !== null) {
      // if we catch up then pause timer until we have enough
      if (active + 1 >= bufferIndex && !buffering) {
        this.stopTimer();
      }

      // loop through batch
      this.preloadImage(
        images[bufferIndex], bufferIndex, () => {
          this.setState({ bufferIndex: bufferIndex + 1 }, () => {
            if (buffering) {
              this.checkForPreload();

              // if we reached the end of the images
              if (bufferIndex >= images.length - 1) {
              // and it isnt playing then start playing
                this.startTimer();
              } else {
              // if buffering and we have enough images then restart the timer
                if (bufferIndex > active + loadAhead) {
                  this.startTimer();
                }
              }
            }
          });
        }
      );
    }
  }

  private async preloadImage(
    image: IImage,
    index: number,
    callback: () => void
  ) {
    const preloadImage = new Image();

    if (image) {
      if (this.state.currentlyLoading < loadAtATime) {
        const thumb = await getImageByType(this.getImageType(),
          image.thumbnails);

        this.setState({ currentlyLoading: this.state.currentlyLoading + 1 },
          () => {
            // preload an image
            if (thumb.s3_url) {
              preloadImage.src = thumb.s3_url;
            }

            preloadImage.onload = () => {
              this.props.setImageAttribute(index);

              this.setState({ currentlyLoading: this.state.currentlyLoading - 1 },
                () => {
                  if (this.state.currentlyLoading < loadAtATime) {
                    this.preloadImageBatch();
                  }
                });
            };
          });
        callback();
      }
    }
  }

  private getImageType(): ImageType {
    let imageType: ImageType = "desktop-sd";
    const width = window.innerWidth;

    if (width <= 800) {
      imageType = "mobile";
    }

    return imageType;
  }
}

export default ImagePlayer;
