import moment from "moment";
import React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";

const TimeTextWrapper = styled.div<ITimeIndicatorProps>`
  padding: 8px 14px;
  display: flex;
  order: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${styles.mobileBreakPoint}) {
    display: none;
  }
`;

const TimeText = styled.div<ITimeIndicatorProps>`
  color: ${styles.primaryAccentColor};
  font-weight: 500;
  font-size: 17px;

  @media (min-width: ${styles.mobileBreakPoint}) {
    display: none;
  }
`;

const DateText = styled(TimeText)`
  font-size: 10px;
`;

const TimeIndicator: React.SFC<ITimeIndicatorProps> = props => {
  if (props.time) {
    const time = moment.utc(props.time).format("HH:mm");
    const date = moment.utc(props.time).format("DD MMM YYYY");

    return (
      <TimeTextWrapper {...props}>
        <TimeText {...props}>{time}</TimeText>
        <DateText {...props}>{date}</DateText>
      </TimeTextWrapper>
    );
  }

  return null;
};

interface ITimeIndicatorProps {
  time: moment.Moment | null;
}

export default TimeIndicator;
