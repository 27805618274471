import moment from "moment";
import _ from "lodash";

export const canSkipNextInterval = (targetStartDate: moment.Moment,
  lastDate: moment.Moment) => {
  if (targetStartDate.isAfter(lastDate.endOf("day"))) {
    return false;
  }

  return true;
};

export const canSkipPrevInterval = (targetEndDate: moment.Moment,
  firstDate: moment.Moment) => {
  if (targetEndDate.isBefore(firstDate)) {
    return false;
  }

  return true;
};

export const getInterval = (startDate: moment.Moment,
  endDate: moment.Moment) => {
  return endDate.diff(startDate, "days") + 1;
};

export const checkDateRangeHasImages = (
  datesAvailable: string[],
  startDate: moment.Moment,
  endDate: moment.Moment
): boolean => {
  const found = _.findIndex(datesAvailable, d => {
    return moment.utc(d).isBetween(startDate, endDate);
  });

  return found > -1;
};

export const getClosestDateTo = (targetDate: moment.Moment,
  dates: string[]): moment.Moment => {
  // get a sorted list of dates but diff to target date
  const newDates = _.sortBy(_.map(dates, d => {
    const diff = targetDate.diff(moment.utc(d), "days");

    // negatives we turn into positives and deprioritise by one
    // not perfcect but should do
    return {
      date: d,
      diff: diff < 0 ? diff * -1 + 1 : diff
    };
  }),
  "diff");

  // return the first in the array
  return moment.utc(newDates[0].date);
};
