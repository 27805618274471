export const isMonthDisabled = (
  month: number,
  year: number,
  datesList: string[]
) => {
  const found = datesList
    .filter(date => new Date(date).getFullYear() === year)
    .find(date => month === new Date(date).getMonth());

  if (found) {
    return false;
  }

  return true;
};

export const isDisabled = (day: Date, datesList: string[]) => {
  const found = datesList.find(date => {
    const parsedDate = new Date(date);
    const utcDate = convertDateToUTC(parsedDate);

    if (day.getFullYear() !== utcDate.getFullYear()) {
      return false;
    }

    if (day.getMonth() !== utcDate.getMonth()) {
      return false;
    }

    return day.getDate() === utcDate.getDate();
  });

  if (found) {
    return false;
  }

  return true;
};

export const convertDateToUTC = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};
