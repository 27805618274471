import { BreakpointProvider } from "hedron";
import createHistory from "history/createBrowserHistory";
import * as React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";

import BaseStyles from "./components/base-styles/base-styles";
import { CookieNotice } from "./components/cookie-notice";
import AppRouter from "./router";
import configureStore from "./store";

const history = createHistory();
const { store, persistor } = configureStore(history);
export const App: React.FC = () => {
  console.log("new");

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BreakpointProvider breakpoints={{
          sm: 500,
          md: 800,
          lg: 1024
        }}>
          <ThemeProvider theme={{ mode: "normal" }}>
            <>
              <BaseStyles/>
              <AppRouter history={history} />
              <CookieNotice />
            </>
          </ThemeProvider>
        </BreakpointProvider>
      </PersistGate>
    </Provider>
  );
};
