import logger from "src/lib/logger";
import axios from "axios";
import Headers from "../../lib/auth";
import _ from "lodash";
import { IVideo, IVideoParams } from "../../types/store/videos";
import { IVideoGenerationParams } from "./videos.actions";

export const getVideos = async (cameraLocationId: string, params: IVideoParams, role = "client") => {
  try {
    const headers = await Headers(role);

    const result = await axios({
      headers,
      method: "GET",
      params,
      url: `${
        process.env.REACT_APP_API_URL
      }/${role}/videos/camera-location/${cameraLocationId}`
    });

    logger.info("GET VIDEO", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET VIDEO ERROR", error);
    throw error;
  }
};

export const createVideo = async (video: IVideo) => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: video,
      headers,
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/admin/video`
    });

    logger.info("CREATE VIDEO, result");

    return result.data.data;
  } catch (error) {
    logger.error("CREATE VIDEO ERROR", error);
    throw error;
  }
};

export const updateVideo = async (
  videoId: string,
  video: IVideo
): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: video,
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/video/${videoId}`
    });

    logger.info("UPDATE VIDEO", result);

    return result.data.data;
  } catch (error) {
    logger.error("UPDATE VIDEO ERROR", error);
    throw error;
  }
};

export const deleteVideo = async (videoId: string): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/admin/video/${videoId}`
    });

    logger.info("DELETE VIDEO", result);

    return result.data.data;
  } catch (error) {
    logger.error("DELETE VIDEO ERROR", error);
    throw error;
  }
};

export const generateVideoForCameraLocation = async (cameraLocationId: string, params: IVideoGenerationParams): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/client/videos/camera-location/${cameraLocationId}`,
      data: params
    });

    logger.info("POST", result);

    return result.data.data;
  } catch (error) {
    logger.error("POST ERROR", error);
    throw error;
  }
};
